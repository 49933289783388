import * as events from "../PlayerPlatformAPIEvents";
import * as constants from "../PlayerPlatformConstants";

/**
 * Playback State Monitor
 * Monitors playback to determine the state of playback.
 * @constructor
 */
export class PlaybackStateMonitor {

    private lastAdEvent: events.PlayerPlatformAPIEvent;

    public init(): void {
        this.attachEvents();
    }

    private attachEvents(): void {
        events.addEventListener(events.AD_START, this.onAdEvent, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.AD_EXITED, this.onAdEvent, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.AD_COMPLETE, this.onAdEvent, constants.PRIORITY_DEFAULT, this);
    }

    private onAdEvent(event: events.PlayerPlatformAPIEvent): void {
        this.lastAdEvent = event;
    }

    /**
     * Determines if an ad is currently playing.
     * @returns iff an ad is being played
     */
    public isAdPlaying(): boolean {
        return this.lastAdEvent instanceof events.AdStartEvent;
    }

    public destroy(): void {
        events.removeEventListener(events.AD_START, this.onAdEvent);
        events.removeEventListener(events.AD_EXITED, this.onAdEvent);
        events.removeEventListener(events.AD_COMPLETE, this.onAdEvent);
    }

}

