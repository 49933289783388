
declare let global: any;

import { IPPModule, IPPSandbox } from "../PlayerPlatformApplication";
import * as events from "../PlayerPlatformAPIEvents";
import { registerModule } from "../Application";
import { BaseAsset, AssetTypeMapping } from "../assets/BaseAsset";
import { ConfigurationManager } from "../ConfigurationManager";
import { Logger } from "../util/Logger";
import { PPError, MEDIA_ERROR, MediaErrorCodes } from "../PPError";
import { Subject, Observable } from "rxjs";
import { distinctUntilChanged, filter, find, mapTo, scan, switchMap, takeUntil } from "rxjs/operators";
import { MediatorChannel } from "publicious";
import * as constants from "../PlayerPlatformConstants";
import * as urlService from "../services/urlService/URLService";
import { StreamFormat } from "../assets/ContentOptions";

export const MAXIMUM_DELAY: number = 10000;
export const ROLLBACK_DELAY: number = 1;
export const RETRY_PLAYBACK_TIME: number = 10;
export const UPDATES_PER_SECOND: number = 4;

export class RetryHandler implements IPPModule<RetryHandler> {

    private readonly logger = new Logger("RetryHandler");

    private sandbox: IPPSandbox;
    private maxRetries: number;
    private interval: number;
    private timer?: number;
    private retryCount: number = 0;

    private restarter: Subject<events.MediaFailedEvent> = new Subject<events.MediaFailedEvent>();

    public init(sandbox: IPPSandbox): RetryHandler {

        this.sandbox = sandbox;
        this.interval = sandbox.config[ConfigurationManager.RETRY_INTERVAL];
        this.maxRetries = ConfigurationManager.getInstance().getByAssetType(AssetTypeMapping.DEFAULT, ConfigurationManager.MAXIMUM_RETRIES);

        if (sandbox.config[ConfigurationManager.RETRY_ON_MEDIA_FAILED]) {
            this.logger.trace(`RetryHandler init : maxRetries=${this.maxRetries} : interval=${this.interval}`);
            sandbox.subscribe(constants.STOP, this.onStop, constants.PRIORITY_DEFAULT, this);
            events.addEventListener(events.MEDIA_FAILED, this.onMediaFailed, constants.PRIORITY_DEFAULT, this);
            this.initializeRetryCountResetter(sandbox);
        } else {
            this.logger.trace(`RetryHandler init : RETRY_ON_MEDIA_FAILED = false, no handlers attached.`);
        }

        return this;
    }

    public destroy(sandbox: IPPSandbox): void {
        events.removeEventListener(events.MEDIA_FAILED, this.onMediaFailed);
        sandbox.remove(constants.STOP, this.onStop);
        this.retryCount = 0;
        this.stopTimer();
    }

    /**
     * Handles api:stop
     */
    private onStop(): void {
        if (this.timer !== undefined) {
            this.stopTimer();
        }
    }

    private stopTimer(): void {
        global.clearTimeout(this.timer);
        this.timer = undefined;
    }

    /**
     * Initializes and returns subscription which after every media failed
     * resets the retry count back to 0 if we get a defined amount of
     * media progress events where a change has been made.
     * @return subscription which resets retry count after threshold
     */
    private initializeRetryCountResetter(sandbox: IPPSandbox) {

        sandbox.streams.setAssets
            .pipe(
                distinctUntilChanged(),
                takeUntil(sandbox.destroyed)
            )
            .subscribe((asset: BaseAsset) => {
                this.retryCount = 0;
                this.maxRetries = ConfigurationManager.getInstance().getByAssetType(asset.assetType, ConfigurationManager.MAXIMUM_RETRIES);

                this.logger.trace(`Attempting a different asset, resetting count to ${this.retryCount} and maximum to ${this.maxRetries}`);
                this.stopTimer();
            });

        sandbox.streams.setAssets
            .pipe(
                filter(asset => asset.isRetry),
                switchMap((asset) => takeUntilMillisecondsProgressed(asset, sandbox.streams.mediaProgresses, this.interval + 1000)),
                filter((asset) => asset === this.sandbox.asset),
                takeUntil(sandbox.destroyed)
            )
            .subscribe(() => {
                this.logger.info(`Playback appears to have resumed, resetting count`);
                this.retryCount = 0;
                this.stopTimer();
            });
    }

    /**
     * This function attempts to play the next url in the asset.urls fallback array
     * @param asset the currently playing asset
     * @param _event the media failed event that we are trying to recover from
     */
    private tryRollbackUrl(asset: BaseAsset, _event: events.MediaFailedEvent): void {

        asset.isRollback = true;

        asset.url = asset.urls.shift();
        this.logger.info(`Attempting rollback ${asset.url} in ${ROLLBACK_DELAY} seconds`);

        this.timer = global.setTimeout(() => {
            this.stopTimer();
            this.sandbox.publish(constants.SET_ASSET, asset);
        }, ROLLBACK_DELAY * constants.MILLISECONDS_PER_SECOND);
    }

    /**
     * Set a timeout event that fires the retry callback where the delay doubles with
     * each media failed event. If the retry count has been reached, don't cancel the
     * event and reset this handler;
     *
     * @param {MediaFailedEvent} event
     * @param channel - mediator channel
     */
    private onMediaFailed(event: events.MediaFailedEvent, channel: MediatorChannel): void {

        const asset: BaseAsset = this.sandbox.asset;

        if (this.sandbox.lastKnownPosition !== undefined) {
            this.logger.trace("Setting resumePosition of asset to", this.sandbox.lastKnownPosition);
            asset.resumePosition = this.sandbox.getContentLastKnownPosition();
            asset.isContentPosition = true;
        }

        if (!event.retry) {
            this.logger.trace("MediaFailedEvent indicates no retry, sending MediaFailedEvent");
            this.sandbox.publish(constants.STOP);
            return;
        }

        if (!asset.shouldRetry()) {
            this.logger.trace("Asset indicates no retry, sending MediaFailedEvent");
            this.sandbox.publish(constants.STOP);
            return;
        }

        if (this.tryFallbackAssetUrls(event, channel, asset)) {
            return;
        }

        if (this.retryCount >= this.maxRetries) {
            this.logger.trace("onMediaFailed - retry count exhausted. Media failure is unrecoverable");
            this.sandbox.publish(constants.STOP);
            return;
        }

        // We could get multiple MediaFailedEvents at a time
        // we let a current retry happen before trying another
        // one.
        if (this.timer === undefined) {
            this.restarter.next(event);
            const delay: number = Math.min(MAXIMUM_DELAY, this.interval << this.retryCount);
            this.logger.warn(`onMediaFailed error: "${JSON.stringify(event.error)}" will retry in ${delay} ms`);
            this.timer = global.setTimeout(() => {
                this.onRetryTimerFired(event, asset);
            }, delay);

        }

        // stop this event from going further
        channel.stopPropagation();
    }

    private tryFallbackAssetUrls(event: events.MediaFailedEvent, channel: MediatorChannel, asset: BaseAsset): boolean {

        if (asset.urls.length > 0) {
            channel.stopPropagation();
            this.sandbox.publish(constants.STOP);
            this.tryRollbackUrl(asset, event);
            return true;
        } else if (asset.urls.length === 0
                && !asset.isFallback
                && asset.contentOptions.fallbackToOriginalStreamingFormat
                && asset.contentOptions.preferredStreamingFormat === StreamFormat.DASH
                && this.retryCount >= this.maxRetries) {

            channel.stopPropagation();
            this.sandbox.publish(constants.STOP);
            asset.isRollback = true;
            asset.isFallback = true;
            this.retryCount = 0;

            // if the current url is DASH and we are falling back to HLS, we are required to emit an error
            // so that the frequency of these occurences can be measured (VPLAY-1503)
            const error = new PPError(
                MEDIA_ERROR,
                MediaErrorCodes.MEDIA_TYPE_RETRY,
                "All DASH retries have been exhausted, falling back to original HLS.",
                true
            );

            events.dispatchEvent(new events.MediaRetryEvent(new events.MediaFailedEvent(error)));

            urlService.getURLPlaylistForAsset(asset.originalUrl, asset, false).then((urls) => {
                asset.urls = urls;
                this.tryRollbackUrl(asset, event);
            });

            return true;
        }

        return false;
    }

    private onRetryTimerFired(event: events.MediaFailedEvent, asset: BaseAsset): void {

        this.stopTimer();
        this.retryCount++;

        this.logger.info(`onRetryTimerFired: retry count = ${this.retryCount} out of ${this.maxRetries}`);

        // set retry count on event
        event.retryCount = this.retryCount;

        // dispatch event
        events.dispatchEvent(new events.MediaRetryEvent(event));

        this.sandbox.player.stop();

        asset.isRollback = false;
        asset.isRetry = true;

        this.sandbox.setAsset(asset);
    }

}

interface IAccumulatingTime {
    start: number;
    total: number;
}

/**
 * creates an observable from progress events that will complete once the total progression
 * reaches given `maxMillis`.
 *
 * @param asset asset object for which mediaprogress is measured
 * @param progressEvents progress event observable
 * @param maxMillis max progression in milliseconds
 */
function takeUntilMillisecondsProgressed(asset: BaseAsset, progressEvents: Observable<events.MediaProgressEvent>, maxMillis: number): Observable<BaseAsset> {
    return progressEvents
        .pipe(
            scan<events.MediaProgressEvent, IAccumulatingTime>((acc, curr: events.MediaProgressEvent, idx) => {
                if (idx === 0) {
                    acc.start = curr.position;
                } else {
                    acc.total = curr.position - acc.start;
                }
                return acc;
            }, { start: 0, total: 0 }),
            find(({ total }) => total >= maxMillis),
            mapTo(asset)
        );
}

registerModule("RetryHandler", RetryHandler, { autostart: true });
