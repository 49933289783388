import { OttAsset } from "./OttAsset";
import { PPError } from "../../../PPError";
import { Logger } from "../../../util/Logger";
import { IOttAuthResult } from "../../../assets/ContentOptions";

const MAJOR_ERROR = 7900;

const logger: Logger = new Logger("Ott");

type AuthTypeHandler = (asset: OttAsset, authResult: IOttAuthResult) => string;

export enum OttAuthTypes {
    NBCSports = "NBCSports",
    // this flow uses a legacy token format and is used for comcast vod only
    DisneyEspn = "DisneyEspn",
    // this flow uses a new token format and is used for vod for partners, e.g. cox
    EspnGatekeeper = "EspnGatekeeper",
    // this flow is used for vod on the stb
    EspnHostedStream = "EspnHostedStream"
}

const urlGetters: { [index: string]: AuthTypeHandler } = {
    [OttAuthTypes.NBCSports.toLowerCase()]: getNBCSportsUrl,
    [OttAuthTypes.DisneyEspn.toLowerCase()]: getDisneyEspnUrl,
    [OttAuthTypes.EspnGatekeeper.toLowerCase()]: getEspnGateKeeperUrl,
    [OttAuthTypes.EspnHostedStream.toLowerCase()]: getEspnHostedStreamUrl
};

/**
 * For over the top requests, we call out to a callback to provide us with our fully resolved URL.
 *
 * This asset type is for 3rd party hosted assets that require player platform to authenticate with the 3rd party
 * before accessing the asset files.  The asset url provided to Asset.create will be a locator.  Authentication
 * occurs in a function provided to player platform by the host application.  The authentication function accepts
 * the locator and returns a promise with the resolved http url of the authenticated asset.
 *
 * see https://etwiki.sys.comcast.net/display/AAE/Third+Party+Hosted+IP+Stream+Authorization+and+Playback
 *
 * Interface for the function that player platform will call on the hosting app for authentication.  This
 * function should be provided as ottAuthCallback in asset content options for OTT assets.
 *  example:
 *
 * let ottAsset = Asset.create(
 * "comcast:ott-stream:nbcsports:NBCSports: .... m3u8",
 * {
 *     assetType: "OTT",
 *     ottAuthCallback: (locator) => {
 *         return new Promise((resolve, reject) => {
 *             try {
 *                 let authResult = ....;
 *                 resolve(authResult);
 *             } catch(e) {
 *                 reject(e);
 *             }
 *         });
 *     }
 * }
 *
 * @param OTT locator, e.g. "comcast:ott-stream:nbcsports:NBCSports: .... m3u8"
 * @param asset associated asset with url
 * @returns list of URLs from over the top callback
 */

export function getOttUrl(locator: string, asset: OttAsset): Promise<string[]> {

    if (!asset.ottAuthCallback) {
        return Promise.reject(new PPError(MAJOR_ERROR, 1, "ottAuthCallback was not set in content options for ott asset"));
    }
    try {
        return asset.ottAuthCallback(locator).then(
            authResult => {

                if (typeof authResult !== "object") {
                    throw buildAuthError(13, authResult, "OTT auth result is not an object");
                }

                if (getAuthResultProperty(authResult, "authorized", 3) !== true) {
                    throw buildAuthError(2, authResult, "OTT authentication failed");
                }

                const urlGetter: AuthTypeHandler | undefined = urlGetters[
                    getAuthResultProperty(authResult, "authType", 10).toLowerCase()
                ];

                if (urlGetter === undefined) {
                    throw buildAuthError(11, authResult, "OTT auth response contains unrecognized authType");
                }

                const url = urlGetter(asset, authResult);

                logger.trace(`OTT resolved URL to ${url}`);
                return url === undefined ? [] : [ url ];
            },
            err => {
                throw new PPError(
                    MAJOR_ERROR, 5, `ottAuthCallback returned an error ${err.name} ${err.message} ${err.stack}`
                );
            }
        );
    } catch (err) {
        return Promise.reject(
            new PPError(
                MAJOR_ERROR, 6, `ottAuthCallback threw an error ${err.name} ${err.message} ${err.stack}`
            )
        );
    }
}

function getNBCSportsUrl(asset: OttAsset, authResult: IOttAuthResult): string {
    logger.trace(`handling NBCSports OTT auth type`);
    logger.trace(`setting assetId to ${authResult.caid}`);
    asset.assetId = String(authResult.caid);
    return String(getAuthResultProperty(authResult, "playbackUrl", 4));
}

function getDisneyEspnUrl(asset: OttAsset, authResult: IOttAuthResult): string {
    logger.trace(`handling DisneyEspn OTT auth type`);
    asset.contentOptions.authToken = getAuthResultProperty(authResult, "authToken", 11);
    return String(getAuthResultProperty(authResult, "simulcastAiringId", 12));
}

function getEspnGateKeeperUrl(asset: OttAsset, authResult: IOttAuthResult): string {
    logger.trace(`handling EspnGatekeeper OTT auth type`);
    // the flow for this auth type is the same as DisneyEspn, but the format of the auth token
    // is different.  we don't care about the token format - we just pass it to the ESPN SDK
    return getDisneyEspnUrl(asset, authResult);
}

function getEspnHostedStreamUrl(_asset: OttAsset, authResult: IOttAuthResult): string {
    logger.trace(`handling EspnHostedStream OTT auth type`);
    return String(getAuthResultProperty(authResult, "playbackUrl", 13));
}

function getAuthResultProperty<K extends keyof IOttAuthResult>(authResult: IOttAuthResult, prop: K, minorCode: number): IOttAuthResult[K] {
    if (authResult[prop] === undefined) {
        throw buildAuthError(minorCode, authResult, `OTT auth response contains no ${prop} property`);
    }
    return authResult[prop];
}

function buildAuthError(minor: number, authResult: IOttAuthResult, message: string): PPError {
    return new PPError(MAJOR_ERROR, minor, `${message}; auth result = ${JSON.stringify(authResult, null, 4)}`);
}

