
import { PubSub as Mediator } from "publicious";
import { Logger } from "./util/Logger";
import { IContentOptions, isEAS } from "./assets/ContentOptions";
import { getNestedProperty } from "./util/JSUtil";
import { INbcConditionedStreamConfig } from "./assets/NbcConditionedStreamApi";
import { ajax } from "rxjs/ajax";
import { tap, mapTo, catchError } from "rxjs/operators";

import { DefaultConfigOptions } from "./DefaultConfigOptions";

/**
 * @hidden
 */
let instance: ConfigurationManager;

/**
 * Use to load configuration values from external JSON files or an object containing
 * config properties. This class should be used as a singleton.
 *
 * #### Example:
 * ```
 *  let configManager = pp.ConfigurationManager.getInstance();
 *
 *  // setup callbacks. use these when loading configuration from a remote URL
 *  configManager.onFailure(function() {
 *      // config failed to load, possibly retry
 *  });
 *
 *  configManager.onSuccess(function() {
 *      // config values loaded successfully
 *  });
 *
 *  configManager.loadConfiguration("http://someConfigUrl/config.json");
 *
 *  // or just load configuration with a plain JS object. No callbacks required here
 *
 *  configManager.loadConfiguration({
 *      retryOnMediaFailed: "true",
 *      defaultAsset: {
 *          initialBitrate: 0,
 *          initialBufferTime: 2000
 *      }
 *  });
 *
 *
 * ```
 */
export class ConfigurationManager {

    //PLAYER
    public static readonly PLAYER_NETWORK_REQUEST_TIMEOUT: string = "playerNetworkRequestTimeout";
    public static readonly HEARTBEAT_INTERVAL: string = "heartbeatInterval";
    public static readonly AUTOPLAY: string = "autoplay";
    public static readonly MAXIMUM_BITRATE: string = "maximumBitrate";
    public static readonly MINIMUM_BITRATE: string = "minimumBitrate";
    public static readonly INITIAL_BITRATE: string = "initialBitrate";
    public static readonly INITIAL_DEFAULT_AD_BITRATE: string = "initialDefaultAdBitrate";
    public static readonly INITIAL_POLICY: string = "initialPolicy";
    public static readonly INITIAL_BUFFER_TIME: string = "initialBufferTime";
    public static readonly INTERSEGMENT_DELAY: string = "intersegmentDelay";
    public static readonly PLAYING_VOD_BUFFER_TIME: string = "playingVODBufferTime";
    public static readonly PLAYING_LINEAR_BUFFER_TIME: string = "playingLinearBufferTime";
    public static readonly PLAYING_POLICY: string = "playingPolicy";
    public static readonly RETRY_INTERVAL: string = "retryInterval";
    public static readonly MAXIMUM_RETRIES: string = "maximumRetries";
    public static readonly MANIFEST_MANIPULATOR_RETRIES: string = "manifestManipulatorRetries";
    public static readonly MANIFEST_MANIPULATOR_TIMEOUT: string = "manifestManipulatorTimeout";
    public static readonly ENABLE_MULTISITE_VOD_DAI: string = "enableMultiSiteVODDAI";
    public static readonly HTTP_START_TRANSFER_TIMEOUT: string = "httpStartTransferTimeout";
    public static readonly HTTP_TOTAL_TIMEOUT: string = "httpTotalTimeout";
    public static readonly VPAID_TIMEOUT: string = "vpaidTimeout";
    public static readonly FORCE_HTTPS: string = "forceHttps";
    public static readonly RGB_URL_REWRITE_HOST: string = "rgbUrlRewriteHost";

    public static readonly NETWORK_DOWN_TIMEOUT: string = "networkDownTimeout";
    public static readonly FAIL_ON_NETWORK_DOWN: string = "failOnNetworkDown";


    public static readonly RETRY_ON_MEDIA_FAILED: string = "retryOnMediaFailed";
    public static readonly UPDATE_INTERVAL: string = "updateInterval";
    public static readonly PARTNER_ID: string = "partnerId";
    public static readonly ENABLE_ADS: string = "enableAds";
    public static readonly DEFAULT_ENGINE: string = "defaultEngine";
    public static readonly DD_PLUS: string = "ddPlus";
    public static readonly CDN_REDUNDANT: string = "cdnRedundant";
    public static readonly CROSS_STREAM_PREVENTION: string = "crossStreamPrevention";
    public static readonly STALLED_TIMEOUT: string = "stalledTimeout";
    public static readonly TRICKPLAY_MAX_FPS: string = "trickPlayMaxFps";
    public static readonly USE_TR_HOST_PREFIX: string = "trHostPrefix";

    // PlayerPlatform Parameters
    public static readonly PLAYBACKSTALLED_ENABLED: string = "playbackStalledEnabled";
    public static readonly DISNEY_LOAD_TIMEOUT: string = "disneyLoadTimeout";
    public static readonly DISNEY_SWF_URL: string = "disneySwfUrl";
    public static readonly DISNEY_CHROMELESS_PATH: string = "disneyChromelessPath";
    public static readonly DISNEY_PARTNER: string = "disneyPartner";
    public static readonly DRM_SWF_URL: string = "drmSwfUrl";
    public static readonly ESPN_SWF_URL: string = "espnSwfUrl";
    public static readonly ESPN_CHROMELESS_PATH: string = "espnChromelessPath";
    public static readonly ESPN_PARTNER: string = "espnPartner";
    public static readonly ESPN_CONFIG_URL: string = "espnConfigUrl";
    public static readonly GLOBAL_TOKENS_URL: string = "globalTokensUrl";
    public static readonly SWF_URL: string = "swfUrl";
    public static readonly HULU_NEW_SITE_URL_STD: string = "huluNewSiteUrlStd";
    public static readonly HULU_NEW_SITE_URL_WIDE: string = "huluNewSiteUrlWide";
    public static readonly HULU_PARTNER: string = "huluPartner";
    public static readonly NBC_CONDITIONED_STREAM_API: string = "nbcConditionedStreamApi";

    //EAS
    public static readonly EAS_NETWORK_REQUEST_TIMEOUT: string = "easNetworkRequestTimeout";
    public static readonly ZIPS_TO_FIPS_END_POINT: string = "zipToFipsEndPoint";
    public static readonly ALERT_SERVICE_END_POINT: string = "alertServiceEndPoint";
    public static readonly EAS_UPDATE_INTERVAL: string = "easUpdateInterval";
    public static readonly EAS_ALERT_REPEAT: string = "easAlertRepeat";
    public static readonly EAS_ALERT_FONT_SIZE: string = "easAlertFontSize";
    public static readonly EAS_ALERT_FONT: string = "easAlertFont";
    public static readonly EAS_ENABLED: string = "easEnabled";
    public static readonly EAS_LANGUAGE: string = "easLanguage";
    public static readonly HELIO_EAS: string = "helioEas";
    public static readonly HELIOFUSION_EAS: string = "helioFusionEas";
    public static readonly EAS_TEXT_OVERLAY: string = "easTextOverlay";

    //ANALYTICS
    public static readonly ANALYTICS_NETWORK_REQUEST_TIMEOUT: string = "analyticsNetworkRequestTimeout";
    public static readonly ANALYTICS_END_POINT: string = "analyticsEndPoint";
    public static readonly ANALYTICS_PROTOCOL: string = "analyticsProtocol";
    public static readonly ANALYTICS_DEVICE_TYPE: string = "analyticsDeviceType";
    public static readonly MAX_BATCH_SIZE: string = "maxBatchSize";
    public static readonly MAX_QUEUE_SIZE: string = "maxQueueSize";
    public static readonly BATCH_INTERVAL: string = "batchInterval";
    public static readonly LOGSTASH_ENDPOINT: string = "logstashEndpoint";
    public static readonly TELEMETRY_ENDPOINT: string = "telemetryEndPoint";
    public static readonly FOG_ANALYTICS_END_POINT: string = "fogAnalyticsEndPoint";

    //Virtual Stream Stitcher
    public static readonly VSS_SERVICE_ZONE_CALLBACK_TIMEOUT: string = "vssServiceZoneCallbackTimeout";
    public static readonly VSS_DEFAULT_SERVICE_ZONE_TYPE: string = "vssDefaultServiceZoneType";

    //Adobe Audience Manager
    public static readonly AUDIENCE_MANAGER: string = "audienceManager";
    public static readonly AUDIENCE_MANAGER_ENABLED: string = "audienceManagerEnabled";

    //Adobe Video Heartbeat
    public static readonly ADOBE_VIDEO_HEARTBEAT: string = "adobeVideoHeartbeat";

    //DRM
    public static readonly DRM_ENABLED: string = "drmEnabled";
    public static readonly DRM_NETWORK_REQUEST_TIMEOUT: string = "drmNetworkRequestTimeout";
    public static readonly CIMA_END_POINT: string = "cimaEndPoint";
    public static readonly METADATA_END_POINT: string = "metadataEndPoint";
    public static readonly PRODUCT_TYPE: string = "productType";
    public static readonly LICENSE_SERVER_URL: string = "licenseServerUrl";
    public static readonly PREFERRED_DRM: string = "preferredDrm";

    //Auditude ADS
    public static readonly DOMAIN_ID: string = "domainId";
    public static readonly ZONE_ID: string = "zoneId";
    public static readonly CUSTOM_PARAMS: string = "customParams";
    public static readonly AUDITUDE_TIMEOUT: string = "auditudeTimeout";
    public static readonly HANDLE_CLICKS: string = "handleClicks";
    public static readonly CRS_RULES_ENDPOINT: string = "crsRulesEndpoint";

    //FreeWheel ADS
    public static readonly FREE_WHEEL_CONFIG: string = "freeWheelConfig";
    public static  readonly FW_USE_FUSION_RENDERER: string = "fwUseFusionRenderer";

    //Comcast ADS
    public static readonly PLACEMENT_STATUS_NOTIFICATION_URL_END_POINT: string = "placementStatusNotificationEndPoint";

    //Misc
    public static readonly TIMEOUT: number = 10000;
    public static readonly ZOOM: string = "zoom";
    public static readonly AUDIO_LANGUAGE: string = "audioLanguage";

    // Defaults
    public static readonly DEFAULT_ASSET: string = "defaultAsset";
    public static readonly DEFAULT_INITIAL_BUFFER_TIME: number = 250;
    public static readonly DEFAULT_PLAYING_BUFFER_TIME: number = 12000;
    public static readonly DEFAULT_PLAYING_VOD_BUFFER_TIME: number = 12000;
    public static readonly DEFAULT_PLAYING_LINEAR_BUFFER_TIME: number = 12000;

    // Helio
    public static readonly REPORT_HELIO_METRICS: string = "reportHelioMetrics";

    /**
     * There was an error loading or parsing the configuration.
     *
     * @event configurationFailure
     */
    public static readonly ERROR: string = "configurationFailure";
    public static readonly ERROR_EVENT_TYPE: string = ConfigurationManager.ERROR;

    /**
     * Configuration has been successfully loaded.
     *
     * @event configurationLoaded
     */
    public static readonly SUCCESS: string = "configurationLoaded";
    public static readonly SUCCESS_EVENT_TYPE: string = ConfigurationManager.SUCCESS;

    /**
     * Home Network Analytics Configuration
     */
    /**
     * Whether home network analytics is enabled for this asset type
     */
    public static readonly HNA_ENABLED: string = "hnaEnabled";

    /**
     * The timeout to use per network request on a test
     */
    public static readonly HNA_TIMEOUT: string = "hnaTimeout";

    /**
     * The endpoint to use for a CDN test, this will be appended to
     * the base url of the current asset
     */
    public static readonly HNA_CDN_ENDPOINT: string = "hnaCDNEndpoint";

    /**
     * The endpoint to use for AWS, this will be the AWS url we resolve
     * for the INTERNET test
     */
    public static readonly HNA_AWS_ENDPOINT: string = "hnaAWSEndpoint";

    public values: IConfigOptions = {};

    private _logger: Logger = new Logger("ConfigurationManager");
    private _isReady: boolean;
    private _mediator: Mediator = new Mediator();

    /**
     * Add a listener to the configuration manager. This should be either ERROR or SUCCESS.
     *
     * @param {string} type - either ERROR or SUCCESS
     * @param {Function} listener - callback
     * @param {*} context - function context
     */
    public addEventListener(type: string, listener: (...args: any[]) => void, context: any) {
        return this._mediator.on(type, listener, {}, context);
    }

    /**
     * Remove an event listener from the configuration manager.
     *
     * @param {string} type
     * @param {Function} listener
     */
    public removeEventListener(type: string, listener: (...args: any[]) => void) {
        this._mediator.off(type, listener);
    }

    /**
     * Add a success listener
     *
     * @param {Function} listener
     * @param {*} [context]
     */
    public onSuccess(listener: (...args: any[]) => void, context?: any) {
        return this.addEventListener(ConfigurationManager.SUCCESS, listener, context);
    }

    /**
     * Add a failure listener
     *
     * @param {Function} listener
     * @param {*} [context]
     */
    public onFailure(listener: (...args: any[]) => void, context?: any) {
        return this.addEventListener(ConfigurationManager.ERROR, listener, context);
    }

    /**
     * This returns true if the configuration manager has successfully loaded a configuration.
     *
     * @returns {boolean}
     */
    public isReady() {
        return this._isReady;
    }

    /**
     * Load values from the given object.
     *
     * @param configObject
     */
    public loadConfiguration(configObject: IConfigOptions): ConfigurationManager;

    /**
     * Load values by making a request to a JSON file located at the given `url`.
     *
     * @param url
     * @param defaultUrl
     */
    public loadConfiguration(url: string, defaultUrl?: string): ConfigurationManager;

    /**
     * Loads configuration values from the given object. The object
     * can be a URL string pointing to a JSON file, a JSON object. If
     * configurationObject is not specified, the default set is loaded.
     *
     *
     * @param {object|string} configurationObject
     * @param optionalStr
     */
    public loadConfiguration(configurationObject: any = DefaultConfigOptions, optionalStr?: string): ConfigurationManager {
        this._logger.trace("loadConfiguration: " + JSON.stringify(configurationObject, null, 2));
        if (typeof configurationObject === "string") {
            this.load(configurationObject, (err: Error) => {
                if (err) {
                    if (optionalStr) {
                        this.loadConfiguration(optionalStr);
                    } else {
                        this._isReady = false;
                        this._logger.error("Error loading config file at " + configurationObject);
                        this._mediator.publish(ConfigurationManager.ERROR, err.toString(), configurationObject);
                    }
                    return this;
                }
                this.success();
            });
        } else {
            this.loadValues(configurationObject);
            this.success();
        }
        return this;
    }

    public fetchConfiguration(url: string): Promise<ConfigurationManager> {
        return ajax.get(url)
            .pipe(
                tap(res => {
                    this.loadValues(res.response);
                    this.success();
                }),
                mapTo(this),
                catchError<ConfigurationManager, ConfigurationManager>(err => {
                    const description = err && err.toString() || "";
                    this._logger.error(`error loading config file at ${url}. ${description}, ${err.stack}`);
                    this._isReady = false;
                    this._mediator.publish(ConfigurationManager.ERROR, description, url);
                    throw err;
                })
            )
            .toPromise();
    }

    /**
     * Loads a nested set of parameters into the main config set. For example this is
     * used to load the set of parameters for a default asset into the main config set.
     *
     * @param propertyNames
     */
    public extendConfiguration(...propertyNames: string[]) {
        for (const key of propertyNames) {
            const obj = this.values[key];
            if (obj && typeof obj === "object") {
                this.values = deepExtend({}, this.values, obj, this.values.user || {});
            }
        }
    }

    public destroy() {
        this.values = {};
        this._isReady = false;
        instance = null;
    }

    /**
     * Retrieve a config value as a string. If a default value is given and no value is
     * found for the key, use the default value instead.
     *
     * @param {string} key
     * @param {string} [defaultValue]
     * @returns {*}
     */
    public get(key: string, defaultValue?: any): any {
        if (this.values.hasOwnProperty(key)) {
            return this.values[key];
        } else {
            this._logger.warn("get: " + key + " not found, using default value: " + defaultValue);
            return defaultValue;
        }
    }

    /**
     * In our configuration file we use a key based on the type of asset.
     * @param asset an asset
     * @return string key used in configuration file
     */
    public getAssetType(asset: IContentOptions): string {
        if (isEAS(asset)) {
            return "easAsset";
        }
        return asset.assetType;
    }

    /**
     * Retrieve an assetType-specific config value as it's JSON type. If a
     * default value is given and no value is found for the key, use the
     * default value instead.
     *
     * @param {string} assetType
     * @param {string} key
     * @param {string} [defaultValue]
     * @returns {*}
     */
    public getByAssetType(assetType: string, key: string, defaultValue?: any): any {

        let value = getNestedProperty(this.values, `${assetType}.${key}`);

        // use default values if nothing found
        if (value === undefined) {
            value = getNestedProperty(this.values, `defaultAsset.${key}`);
        }

        if (value !== undefined) {
            return value;
        } else {
            this._logger.warn(`get: ${key} not found, using default value: ${defaultValue}`);
            return defaultValue;
        }
    }

    /**
     * Update a single config value given a key/value pair.
     *
     * @param key
     * @param value
     */
    public update(key: string, value: any): void;

    /**
     * Update multiple config values by passing an object containing desired
     * key/value pairs.
     *
     * @param values
     */
    public update(values: IConfigOptions): void;

    /**
     * Combined overload method for updating config.
     *
     * @param keyOrValues
     * @param value
     */
    public update(keyOrValues: string | IConfigOptions, value?: any): void {
        if (!this.values.user) {
            this.values.user = {};
        }

        if (typeof keyOrValues === "string") {
            this.values.user[keyOrValues] = value;
        } else {
            deepExtend(this.values.user, keyOrValues);
        }

        deepExtend(this.values, this.values.user);
    }

    public static getInstance(): ConfigurationManager {
        if (!instance) {
            instance = new ConfigurationManager();
        }

        return instance;
    }

    private load(url: string, cb: (x?: string | Error) => void) {
        ajax.get(url)
            .subscribe(res => {
                try {
                    this.loadValues(res.response);
                } catch (error) {
                    cb(error.toString());
                    return;
                }

                cb();
            },
            err => {
                cb(err || "no response text");
            });
    }

    private loadValues(values: IConfigOptions) {
        this.values = deepExtend({}, DefaultConfigOptions, values);
        this.values.defaultAsset = this.loadDefaultParams(this.values);
        this.extendConfiguration(ConfigurationManager.DEFAULT_ASSET);
    }

    private success() {
        this._isReady = true;
        this._mediator.publish(ConfigurationManager.SUCCESS, this.values);
    }

    /**
     * we need to extend the given default asset settings with our own defaults, in case they don't provide
     * all options. This also contains backwards compatibility for when asset config options are passed in
     * with the flat JSON object (not contained in `defaultAsset` property).
     *
     * @param configurationObject
     * @returns {void|*}
     */
    private loadDefaultParams(configurationObject: IConfigOptions): IAssetConfigOptions {

        const defaultSettings: IAssetConfigOptions = DefaultConfigOptions.defaultAsset;

        // if it already exists, extend it with default settings
        if (configurationObject.defaultAsset) {
            return deepExtend({}, defaultSettings, configurationObject.defaultAsset);
        }

        // otherwise try and grab necessary keys from flat JSON object and place into `defaultAsset` object
        const defaultKeys: string[] = Object.keys(defaultSettings);
        const userSettings = defaultKeys.filter(key => configurationObject.hasOwnProperty(key)).reduce((obj: any, key: string) => {
            obj[key] = configurationObject[key];
            return obj;
        }, {});

        return deepExtend({}, defaultSettings, userSettings);
    }
}

export interface IAssetConfigOptions {
    cdnRedundant?: boolean;
    crossStreamPrevention?: string;
    easEnabled?: boolean;
    forceHttps?: boolean;
    hnaAWSEndpoint?: string;
    hnaCDNEndpoint?: string;
    hnaEnabled?: boolean;
    hnaTimeout?: number;
    initialBitrate?: number;
    initialBufferTime?: number;
    initialPolicy?: number;
    maximumBitrate?: number;
    minimumBitrate?: number;
    placementStatusNotificationEndPoint?: string;
    playingLinearBufferTime?: number;
    playingPolicy?: number;
    playingVODBufferTime?: number;
    trickPlayMaxFps?: number;
    urlRewrite?: string;
    [key: string]: any;
}

export interface IFreeWheelConfigSettings {
    serverUrl?: string;
    mvpdString?: string;
    contentType?: string;
    playerProfileMvpd?: string;
    siteSectionMvpd?: string;
    networkId?: string;
    siteSectionNetworkId?: string;
    freewheelPlatform?: string;
    playerProfilePlatform?: string;
    // turn on to prevent tracking events being fired to ipv6-incompatible endpoints
    preventFreeWheelTrackingEvents?: boolean;
    caidSuffix?: string;
    siteSectionFallbackBrand?: string;
    countryCode?: string;
    sendGeoLocationParams?: boolean;
}

/**
 * Configuration settings for use with the adobe
 * heartbeat handler. These values should be provided
 * by adobe.
 */
export interface IAdobeVideoHeartbeatOptions {
    enabled: boolean;
    debug: boolean;
    ssl: boolean;
    analyticsTrackingServer: string;
    heartbeatTrackingServer: string;
    reportSuiteAccountId: string;
    marketingExperienceCloudOrgId: string;
}

export interface IEWMAConfigOptions {
    switchInterval?: number;
    upgradeTarget?: number;
    downgradeTarget?: number;
    fastEstimateHalflife?: number;
    slowEstimateHalflife?: number;
    minSampleBytes?: number;
    minTotalBytes?: number;
}

export interface IConfigOptions extends IAssetConfigOptions {
    productType?: string;
    alertServiceEndPoint?: string;
    ddPlus?: boolean;
    analyticsEndPoint?: string;
    analyticsNetworkRequestTimeout?: number;
    analyticsProtocol?: string;
    auditudeTimeout?: number;
    autoplay?: boolean;
    batchInterval?: number;
    cimaEndPoint?: string;
    customParams?: any;
    defaultAsset?: IAssetConfigOptions;
    defaultEngine?: string;
    disneyLoadTimeout?: number;
    disneySwfUrl?: string;
    domainId?: string;
    drmEnabled?: boolean;
    drmNetworkRequestTimeout?: number;
    drmSwfUrl?: string;
    easAlertFont?: string;
    easAlertFontSize?: number;
    easAlertRepeat?: number;
    easNetworkRequestTimeout?: number;
    easUpdateInterval?: number;
    easTextOverlay?: boolean;
    enableAds?: boolean;
    espnSwfUrl?: string;
    fogAnalyticsEndPoint?: string;
    globalTokensUrl?: string;
    handleClicks?: string;
    heartbeatInterval?: number;
    helioEas?: boolean;
    helioFusionEas?: boolean;
    initialBufferTime?: number;
    manifestManipulatorRetries?: number;
    manifestManipulatorTimeout?: number;
    enableMultiSiteVODDAI?: boolean;
    maxBatchSize?: number;
    maxQueueSize?: number;
    maximumRetries?: number;
    metadataEndPoint?: string;
    freeWheelConfig?: IFreeWheelConfigSettings;
    fwUseFusionRenderer?: boolean;
    partnerId?: string;
    playerNetworkRequestTimeout?: number;
    retryInterval?: number;
    retryOnMediaFailed?: boolean;
    stalledTimeout?: number;
    startTransferTimeout?: number;
    swfUrl?: string;
    telemetryEndPoint?: string;
    totalTimeout?: number;
    updateInterval?: number;
    zipToFipsEndPoint?: string;
    zoneId?: string;
    user?: IConfigOptions;
    vpaidTimeout?: number;
    nbcConditionedStreamApi?: INbcConditionedStreamConfig;
    logstashEndpoint?: string;
    licenseServerUrl?: string;
    preferredDrm?: string;
    reportHelioMetrics?: boolean;
    trHostPrefix?: boolean; // flag that indicated the use of Rogers' ttr prefixed urls for manifest manipulation

    /**
     * **TVSDK-SPECIFIC**
     *
     * When a manifest _and_ fragment requests fail, TVSDK assumes network is
     * down, fires a **warning** event, and attempts to fetch fragments and refresh
     * the manifest indefinitely. In some cases it is desirable to treat this warning
     * as a failure and fail fast.
     *
     * Set this value to **true** to fail fast on a `NETWORK_DOWN` error.
     */
    failOnNetworkDown?: boolean;

    /**
     * How long to wait (in seconds) before emitting a MediaFailedEvent when
     * it is detected that the network is down.
     *
     * @deprecated - use `failOnNetworkDown`
     */
    networkDownTimeout?: number;

    /**
     * Config options for EWMA ABR algorithm
     */
    ewma?: IEWMAConfigOptions;

    [key: string]: any;
}

/**
 * This is like `JSUtil.extend` method but will extend all nested obects
 * recursively as well.
 *
 * Warning in some cases this function copies objects by reference, and can lead to unexpected results when extending
 * objects that contain objects.
 *
 * Example:
 * let obj1 = { param1: { param2: "val21" };
 * let obj2 = { param1: { param2: "val22" };
 * deepExtend({}, obj1, obj2};
 * console.log(obj1.param2); => val22
 *
 * This is because param1 is copied by reference from obj1 into the target object, then "val22" is copied from obj2
 * to the target object, overwriting the param2 value contained in the param1 object referenced by target and obj1.
 *
 * @param target
 * @param sources
 * @returns {any}
 *
 * @hidden
 * @notes
 */
function deepExtend(target: any, ...sources: any[]): any {

    if (!sources.length || !target) {
        return target;
    }

    for (const source of sources) {
        for (const key of Object.keys(source)) {
            if (target[key] && typeof source[key] === "object") {
                target[key] = deepExtend(target[key], source[key]);
            } else if (source[key] !== undefined) {
                target[key] = source[key];
            }
        }
    }

    return target;
}

export const getConfigurationManager = (): ConfigurationManager => {
    return ConfigurationManager.getInstance();
};
