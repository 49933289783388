import { Logger } from "../util/Logger";
import { registerModule, startModule, stopModule } from "../Application";
import { IPPSandbox } from "../PlayerPlatformApplication";
import { IAdConfig } from "./IAdConfig";
import * as constants from "../PlayerPlatformConstants";

export enum AdManagerType {
    NONE,
    AUDITUDE,
    MANIFEST,
    C3,
    HULU,
    TVELINEAR,
    TVELINEARHLS,
    FREEWHEELVOD,
    CUSTOM
}

export const AdManagerKeys: {
    NONE: string,
    AUDITUDE: string,
    MANIFEST: string,
    C3: string,
    HULU: string,
    TVELINEAR: string,
    TVELINEARHLS: string
    FREEWHEELVOD: string,
    CUSTOM: string
} = {
        NONE: "none",
        AUDITUDE: "auditude",
        MANIFEST: "manifest",
        C3: "c3",
        HULU: "hulu",
        TVELINEAR: "tvelinear",
        TVELINEARHLS: "tvelinearhls",
        FREEWHEELVOD: "freewheelvod",
        CUSTOM: "custom"
    };

export const AdManagerTypeMap: { [key: string]: AdManagerType } = {
    NONE: AdManagerType.NONE,
    AUDITUDE: AdManagerType.AUDITUDE,
    MANIFEST: AdManagerType.MANIFEST,
    C3: AdManagerType.C3,
    HULU: AdManagerType.HULU,
    CUSTOM: AdManagerType.CUSTOM,
    TVELINEAR: AdManagerType.TVELINEAR,
    TVELINEARHLS: AdManagerType.TVELINEARHLS,
    FREEWHEELVOD: AdManagerType.FREEWHEELVOD
};

/**
 * Handles starting and stopping ad managers.
 *
 * @constructor
 */
export function AdManagerFactory() {

    const logger: Logger = new Logger("AdManagerFactory");
    let currentAdManager: AdManagerType;
    let sandbox: IPPSandbox;

    function init(box: IPPSandbox) {
        sandbox = box;
        sandbox.subscribe("ads:configureAds", configureAds, constants.PRIORITY_DEFAULT, null);

        return this;
    }

    function destroy(box: IPPSandbox): void {
        if (box) {
            box.remove("ads:configureAds", configureAds);
        }
        if (currentAdManager) {
            stopModule(getModuleName(currentAdManager));
            currentAdManager = null;
            sandbox.publish(constants.AD_MANAGER_SELECTED, null);
        }
    }

    function configureAds(mgrType: string, cfg: IAdConfig): void {
        if (mgrType === undefined) {
            logger.error("Unable to configure ads: Ad manager type not defined");
            return;
        }

        const manager: AdManagerType = AdManagerTypeMap[mgrType.toUpperCase()];

        if (currentAdManager === manager) {
            if (sandbox.adManager) {
                sandbox.adManager.update(cfg);
            }
            return;
        }

        if (currentAdManager) {
            stopModule(getModuleName(currentAdManager));
        }

        if (manager === AdManagerType.NONE) {
            currentAdManager = null;
            sandbox.publish(constants.AD_MANAGER_SELECTED, null);
            return;
        }

        if (manager === undefined) {
            logger.error(`Unable to configure ads: Invalid ad manager type = ${mgrType}`);
            return;
        }

        currentAdManager = manager;

        const moduleName: string = getModuleName(manager);
        const mgr = startModule(moduleName, cfg);
        sandbox.publish(constants.AD_MANAGER_SELECTED, mgr);
    }

    return {
        init: init,
        destroy: destroy
    };
}

const Modules = {
    [AdManagerType.MANIFEST]: "ManifestManipulatorAdManager",
    [AdManagerType.AUDITUDE]: "AuditudeAdManager",
    [AdManagerType.C3]: "C3AdManager",
    [AdManagerType.HULU]: "HuluAdManager",
    [AdManagerType.CUSTOM]: "CustomAdManager",
    [AdManagerType.TVELINEAR]: "TVELinearAdManager",
    [AdManagerType.TVELINEARHLS]: "TVELinearHLSAdManager",
    [AdManagerType.FREEWHEELVOD]: "FreeWheelVODAdManager"
};

function getModuleName(mgrType: AdManagerType): string | undefined {
    return Modules[mgrType];
}

registerModule("AdManagerFactory", AdManagerFactory as any, { autostart: true });
