/// <reference path="../../typings/eas/EmergencyAlert.d.ts" />

import { Logger } from "../util/Logger";

export enum EmergencyAlertType {
    VIDEO_REPLACE = "videoReplace",
    TEXT_SCROLL = "textScroll"
}

export class EmergencyAlert {

    public static readonly PLAYBACK_START_POSITION: string = "PLAYBACK_START_POSITION";
    public static readonly START: string = "START";
    public static readonly LIVE: string = "LIVE";
    public static readonly MIME_HLS: string = "application/vnd.apple.mpegurl";
    public static readonly MIME_DASH: string = "application/dash+xml";

    private static _logger = new Logger("EmergencyAlert");

    private _info: IAlertInfo;

    constructor(private _rawJSON: IAlert, language: string, private _mimeType: string = EmergencyAlert.MIME_HLS) {
        this._info = this._resolveInfo(_rawJSON, language);
    }

    get eventCode(): string {
        if (this._info && this._info.eventCode) {
            return this._info.eventCode.valueName === "SAME" ? this._info.eventCode.value : "";
        }
    }

    get uri(): string | undefined {
        const resources = this.resources;

        if (!resources || !resources.length) {
            EmergencyAlert._logger.warn("No URI available on emergency alert!");
            return;
        }

        let uri: string;
        let hlsUri: string;

        for (const resource of resources) {
            if (resource.mimeType === EmergencyAlert.MIME_HLS) {
                hlsUri = resource.uri;
            }
            if (resource.mimeType === this._mimeType) {
                uri = resource.uri;
            }
        }

        return uri || hlsUri; // default to HLS
    }

    get id(): string {
        return this._rawJSON.identifier;
    }

    get language(): string {
        return this._info ? this._normalizeLanguage(this._info.language) : "";
    }

    get parameters(): IKeyValuePair[] {
        return [].concat(this._info.parameter || []);
    }

    get resources(): IAlertResource[] {
        return [].concat(this._info.resource || []);
    }

    get startPosition(): string {
        const positionParam = this.parameters.filter(param => param.valueName === EmergencyAlert.PLAYBACK_START_POSITION)[0];

        if (positionParam && (positionParam.value === EmergencyAlert.START || positionParam.value === EmergencyAlert.LIVE)) {
            return positionParam.value;
        } else {
            EmergencyAlert._logger.warn("No start position defined, returning 0 to start from beginning");
            return EmergencyAlert.START;
        }
    }

    get alertMessage() {
        let message = "A ";
        message += this._info.event;
        message += " has been issued for the following counties: ";
        message += this._info.area.areaDesc;
        message += " effective ";
        message += new Date(this._info.effective);
        message += " until ";
        message += new Date(this._info.expires);
        message += ". ";

        if (this._info.description) {
            message += this._info.description + ". ";
        }

        if (this._info.instruction) {
            message += this._info.instruction + ". ";
        }

        return message;
    }

    public isEan(): boolean {
        return this.eventCode === "EAN";
    }

    private _resolveInfo(alert: IAlert, language: string): IAlertInfo {

        if (!Array.isArray(alert.info)) {
            return alert.info;
        }

        if (alert.info.length === 0) {
            return undefined;
        }

        let result = alert.info[0];

        const normalized = this._normalizeLanguage(language);

        alert.info.forEach(info => {
            if (this._normalizeLanguage(info.language) === normalized) {
                result = info;
            }
        });

        return result;
    }

    private _normalizeLanguage(language: string) {
        const parts = /([A-Za-z]+)[-_]([A-Za-z]+)/.exec(language);
        if (!parts) {
            EmergencyAlert._logger.warn(`EAS language ${language} is not in expected format.`);
            return language;
        }
        return `${parts[1].toLowerCase()}-${parts[2].toUpperCase()}`;
    }
}
