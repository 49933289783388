
declare const global: any;
declare const XREReceiver: any;
declare const _playerPlatformAPI: PlayerPlatformAPI;

import { BaseAsset, getUrlExtension, OTT, AssetTypeMapping } from "../assets/BaseAsset";
import { IContentOptions, IOttAuthResult, IVirtualStreamStitcherOptions } from "../assets/ContentOptions";
import { AdManagerTypes, IAdConfig, IManifestManipulatorAdConfig } from "../ads/IAdConfig";
import { IMessageOptions } from "../analytics/IAnalyticsProvider";
import { BaseMessage } from "../analytics/Messages";
import { IXREContentOptions } from "./XREContentOptions";
import { ConfigurationManager } from "../ConfigurationManager";
import { PlayerPlatformAPI } from "../PlayerPlatformAPI";
import { convert3to2 } from "../util/LanguageUtil";
import { SessionManager } from "../handlers/SessionManager";
import { AssetFactory } from "../assets/AssetFactory";
import { Logger } from "../util/Logger";

const isOTTRegex: RegExp = /^[^=:]+:ott-stream/;

interface IXreOttAuthResult extends IOttAuthResult {
    eventId?: string;
}

/**
 * XREPlayerPlatform is XRE's wrapper to PlayerPlatformAPI
 * it allows for dynamic function calls based on name and params
 * via the onCallMethod, which is a function handler
 * to the XREReceiver's callMethod signal.
 */
export class XREPlayerPlatform {

    protected logger: Logger;

    private deviceID: string;
    private configMgr: ConfigurationManager;
    private _contentOptions: IXREContentOptions = {};
    private inFlightEventId?: string;
    private inFlightAuthResolve?: any;
    private sessionToken?: string;

    public isBuffering: boolean = false;
    public enableAds: boolean = false;
    public messageOptions: IMessageOptions;

    public get contentOptions(): IXREContentOptions {
        return this._contentOptions;
    }

    constructor(parameters: IXREPlayerPlatformParams) {
        this.logger = new Logger("XREPlayerPlatform");
        XREReceiver.callMethod.connect(this.onCallMethod.bind(this));
        this.deviceID = parameters.deviceID;
        this.configMgr = parameters.configurationManager;
        this.messageOptions = {
            preDispatch: (message: BaseMessage) => {
                // TODO(estobb200): This requires knowledge of the message formats
                // that should maybe only occur within the provider, or message itself.
                if (this.contentOptions) {
                    if (this.contentOptions.accountID) {
                        message.APP.SERVICE_ACCOUNT_ID = this.contentOptions.accountID;
                    }
                    if (this.contentOptions.deviceID) {
                        message.DEV.PHYSICAL_DEVICE_ID = this.contentOptions.deviceID;
                    }
                }
            }
        };
    }

    public onCallMethod(name: string, params: any[]) {
        this.logger.trace("onCallMethod: " + name);
        // An error is being thrown during playerPlatform.setAsset that gets
        // incorrectly reported as no such method `setContentUrl`.
        // https://ccp.sys.comcast.net/browse/ARRISXI6-596
        try {
            if (typeof (this as any)[name] === "function") {
                (this as any)[name].apply(this, params);
            } else {
                this.logger.error("no such method named: " + name);
            }

        } catch (e) {
            this.logger.error("Error occurred calling: " + name + " error: " + e);
        }
    }

    public cleanup() {
        _playerPlatformAPI.destroy();
        this.logger.trace("cleanup");
        XREReceiver.callMethod.connect(function() { /* */ });
        XREReceiver.gotRedirectionURL.connect(function() { /* */ });
        XREReceiver.onEvent("onCleanUp", {});
    }

    /**
     * Content options from AVEWebVideoItem as JSON
     * @param {object} options
     */
    public setContentOptions(options: IXREContentOptions | string): void {

        if (!options) {
            this.logger.warn("WARNING: invalid contentOptions: " + options);
            options = {};
        }

        try {
            if (typeof options === "string") {
                this._contentOptions = JSON.parse(options);
            } else {
                this._contentOptions = options;
            }
            this.logger.trace("Updated content options: " + JSON.stringify(this.contentOptions));
        } catch (error) {
            this.logger.error("Error parsing content options, setting to empty object");
            this._contentOptions = {};
        }

        if (typeof this.contentOptions.parentId === "number") {
            this.logger.warn("parentId should be a string, number is ignored.");
            this.contentOptions.parentId = undefined;
        }
        if (typeof this.contentOptions.spanId === "number") {
            this.logger.warn("spanId should be a string, number is ignored.");
            this.contentOptions.spanId = undefined;
        }

        if (this.contentOptions.traceId) {
            // Tell session manager to use our trace_id
            SessionManager.instance.setCustomTraceId(true);
            // Set our trace_id
            SessionManager.instance.moneyTrace.traceId = this.contentOptions.traceId;
        }
        if (this.contentOptions.spanId) {
            SessionManager.instance.moneyTrace.spanId = this.contentOptions.spanId;
        }
        if (this.contentOptions.parentId) {
            SessionManager.instance.moneyTrace.parentId = this.contentOptions.parentId;
        }
    }

    public setAVESessionToken(token: any): void {
        try {
            this.sessionToken = JSON.parse(token)["client:accessToken"];
        } catch (err) {
            this.logger.error("Failed to parse token string: " + err.message);
        }
    }

    /**
     * Gets manifest manipulator ad configuration for usage on set top box.
     * The set top box sometimes utilizes a manifest manipulator service which can proxy freewheel
     * requests.
     */
    private _getManifestManipulatorAdConfig(contentOptions: IContentOptions): IManifestManipulatorAdConfig {
        const defaultCfg = {};
        const fwCfg = ConfigurationManager.getInstance().getByAssetType(
            AssetTypeMapping[String(contentOptions.assetType)] || AssetTypeMapping.DEFAULT, ConfigurationManager.FREE_WHEEL_CONFIG, defaultCfg
        );
        const result: IManifestManipulatorAdConfig = {
            type: AdManagerTypes.MANIFEST,
            terminalAddress: this.deviceID,
            acrURL: this.configMgr.get(ConfigurationManager.PLACEMENT_STATUS_NOTIFICATION_URL_END_POINT)
        };

        // Plug free-wheel values in for proxy if we had configuration for freewheel
        if (fwCfg !== defaultCfg) {
            result.networkId = fwCfg.networkId;
            result.playerProfile = `${fwCfg.networkId}:${fwCfg.mvpdString}_TVE_vod_${fwCfg.freewheelPlatform}`;
            result.siteSectionId = `${fwCfg.mvpdString}_${(this.contentOptions.brand || "nobrand")}_vod_${fwCfg.freewheelPlatform}`;
            result.assetId = `${fwCfg.mvpdString}_${contentOptions.mediaGuid}`;
            result.countryCode = fwCfg.countryCode;
            result.postalCode = this._contentOptions.postalCode;
            result.serverUrl = fwCfg.serverUrl;
            result.vdur = this._contentOptions.duration;
        }

        return result;
    }

    private _configureAds(contentOptions: IContentOptions, url: string): IAdConfig {

        const adConf: IAdConfig = {
            type: AdManagerTypes.NONE
        };

        // OTT Does not use ads, Folts told me to GTFO of here
        if (isOTTRegex.test(url)) {
                return adConf;
        }

        if (this.enableAds && this.shouldUseManifestAds()) {
            return this._getManifestManipulatorAdConfig(contentOptions);
        }

        return adConf;
    }

    /**
     * Determines if we should use manifest ads.
     * Manifest ads should be configured only for VOD assets.
     * @returns true iff we should use the manifest manipulator ad manager
     */
    private shouldUseManifestAds(): boolean {
        return !this.contentOptions || (this.contentOptions.playbackMode === "VOD" && !this.isIVOD());
    }

    public enableAutoPlay(flag: boolean): void {
        this.logger.trace("enableAutoPlay: " + flag);
        _playerPlatformAPI.setAutoPlay(flag);
    }

    /**
     * @param url as .m3u8
     */
    public setContentUrl(url: string): void {
        this.logger.trace("setContentUrl: " + url);
        this.setAsset(url);
    }

    private ottAuthCallback: (locator: string) => Promise<IOttAuthResult> = (locator: string) => {
        return new Promise<IOttAuthResult>(resolve => {
            this.inFlightAuthResolve = resolve;
            this.inFlightEventId = Math.random().toString().substring(2, 12);
            global._xreEventManager.onAdditionalAuthRequired(locator, this.inFlightEventId);
        });
    }

    public createAsset(url: string): BaseAsset {

        const extension = getUrlExtension(url);
        const options: IXREContentOptions = this.mapContentOptions();

        // regex tests for beginning of OTT locator, such as comcast:ott-stream
        if (isOTTRegex.test(url)) {
            options.assetType = OTT;
            options.ottAuthCallback = this.ottAuthCallback;
        } else {
            this.inFlightAuthResolve = undefined;
            this.inFlightEventId = undefined;
        }

        if (extension === "mpd") {
            options.assetEngine = "aamp";

            // disable DD+ until production DASH content is ready
            options.surround = false;
        } else {

            if (this.contentOptions.playerEngine === "AAMP") {
                options.assetEngine = "aamp";
            } else if (this.contentOptions.playerEngine === "PSDK") {
                options.assetEngine = "psdk";
            }
        }

        options.adConfig = this._configureAds(options, url);

        this.logger.trace("contentOptions: " + JSON.stringify(options));

        return AssetFactory.create(url, options);
    }

    private mapContentOptions(): IXREContentOptions {

        const mappedValues = {
            assetType: this.getAssetTypeFromXREContentOptions(),
            assetId: this.contentOptions.assetID || undefined,
            providerId: this.contentOptions.providerID || undefined,
            streamId: this.contentOptions.streamId || this.contentOptions.streamID || undefined,
            recordingId: this.contentOptions.recordingID || undefined,
            resumePosition: this.contentOptions.position,
            isContentPosition: false,
            easPath: this.contentOptions.easContentID || undefined,
            authToken: this.sessionToken
        };

        if ((this.contentOptions.serviceZone !== undefined) && (this.contentOptions.serviceZone !== null) && (this.contentOptions.serviceZone !== "null")) {
            (mappedValues as IXREContentOptions).vss = {
                travelRightsEnabled: false,
                initialServiceZone: this.contentOptions.serviceZone
            } as IVirtualStreamStitcherOptions;
        }

        return { ...this.contentOptions, ...mappedValues };
    }

    public setAsset(url: string) {
        this.logger.trace("setAsset: " + url);

        const asset = this.createAsset(url);

        _playerPlatformAPI.setAsset(asset);
    }

    public setOffset(x: number, y: number) {
        this.logger.trace("setOffset: " + x + "x" + y);
        _playerPlatformAPI.setOffset(x, y);
    }

    public setScale(scaleX: number, scaleY: number) {
        this.logger.trace("setScale: " + scaleX + "x" + scaleY);
        if (_playerPlatformAPI) {
            _playerPlatformAPI.setScale(scaleX, scaleY);
        }
    }

    public play() {
        this.logger.trace("play");
        _playerPlatformAPI.play();
    }

    public stop() {
        this.logger.trace("stop");
        _playerPlatformAPI.stop();
    }

    public pause() {
        this.logger.trace("pause");
        _playerPlatformAPI.pause();
    }

    public seek(milliSeconds: number) {
        this.logger.trace("seek");
        const status = _playerPlatformAPI.getPlayerStatus();
        this.logger.trace("seek: " + milliSeconds + " status=" + status);
        _playerPlatformAPI.setPosition(milliSeconds);
    }

    public seekToLive() {
        this.logger.trace("seekToLive");
        _playerPlatformAPI.seekToLive();
    }

    public setSpeed(speed: number, overshootCorrection: number) {
        this.logger.trace(`setSpeed: ${speed} overshootCorrection: ${overshootCorrection}`);
        _playerPlatformAPI.setSpeed(speed, overshootCorrection);
    }

    public requestStatus() {
        this.logger.trace("requestStatus");
        const live = _playerPlatformAPI.getVideoType() === "live";
        const capacity = _playerPlatformAPI.getBufferTime();
        let percent = 0;
        if (capacity !== 0) {
            percent = _playerPlatformAPI.getBufferFilledLength() / capacity;
        }
        XREReceiver.onEvent("onVideoStatus", {
            width: _playerPlatformAPI.getVideoWidth(),
            height: _playerPlatformAPI.getVideoHeight(),
            duration: _playerPlatformAPI.getDuration(),
            position: _playerPlatformAPI.getCurrentPosition(),
            isLive: live,
            isBuffering: this.isBuffering,
            bufferPercentage: percent,
            connectionURL: _playerPlatformAPI.asset.url,
            dynamicProps: {},
            netStreamInfo: {}
        });
    }

    public setVolume(volume: number) {
        this.logger.trace("setVolume: " + volume);
        // XRE sends volume as an int, PlayerPlatform expects it as a float.
        _playerPlatformAPI.setVolume((volume / 100.0));
    }

    public setPreferredAudioLanguage(lang: string) {
        this.logger.trace("setPreferredAudioLanguage: " + lang);
        const langCorrected = convert3to2(lang);
        _playerPlatformAPI.setPreferredAudioLanguage(langCorrected);
    }

    public setPreferredZoomSetting(setting: string) {
        this.logger.trace("setPreferredZoomSetting");
        _playerPlatformAPI.setPreferredZoomSetting(setting);
    }

    public setVideoMute(mute: boolean): void {
        this.logger.trace("setVideoMute: " + mute);
        _playerPlatformAPI.setAudioOnly(mute);
    }

    public getContentPosition(): number {
        return _playerPlatformAPI.getContentPosition();
    }

    public setLiveFetchHoldTime(): void {
        this.logger.warn(`setLiveFetchHoldTime(${JSON.stringify(arguments)}) not supported.`);
    }

    public setAdditionalAuth(auth: IXreOttAuthResult): void {
        this.logger.trace(`OTT setAdditionalAuth called with auth ${JSON.stringify(auth)}`);
        if (!auth) {
            this.logger.error("ott auth result is not defined");
            return;
        }
        if (!auth.eventId) {
            this.logger.error("ott auth result event id is not defined");
            return;
        }
        if (!this.inFlightAuthResolve) {
            this.logger.warn("unexpected ott auth response");
            return;
        }
        if (auth.eventId !== this.inFlightEventId) {
            this.logger.warn("ott auth response event id does not match expected event id; ignoring auth result");
            return;
        }

        this.inFlightAuthResolve(auth);
        this.inFlightAuthResolve = undefined;
        this.inFlightEventId = undefined;
    }

    /**
     * Returns correct playerplatform assetType based on XREContentOptions values
     * @returns {string}
     */
    public getAssetTypeFromXREContentOptions(): string {
        let assetType: string = this.contentOptions.assetType || PLAYBACK_MODE_TO_ASSET_TYPE_MAP[String(this.contentOptions.playbackMode)];
        if (this.isIVOD()) {
            assetType = "IVOD";
        }
        return assetType;
    }

    /**
     * Returns true, if the asset is to be treated as an IVOD asset
     * @returns {boolean}
     */
    public isIVOD(): boolean {
        return this.contentOptions.playbackMode === "VOD" &&
            this.contentOptions.extendedPlaybackMode === "IVOD" &&
            Boolean(this.contentOptions.isIVOD || this.contentOptions.ivod);
    }

}

interface IXREPlayerPlatformParams {
    configurationManager: ConfigurationManager;
    deviceID: string;
}

export const PLAYBACK_MODE_TO_ASSET_TYPE_MAP: { [x: string]: string } = {
    "VOD": "T6_VOD",
    "DVR": "CDVR",
    "LINEAR_TV": "T6_LINEAR"
};
