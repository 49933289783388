import { HlsTag } from "./HlsTag";

const REQUIRED_FIELDS = ["ADID", "MODE"];

export class HlsTrickmodeRestrictionTag extends HlsTag {

    public id: string;
    public mode: string;
    public limit: string;
    public scale: string;


    constructor(time: number, content: string) {
        super("#EXT-X-TRICKMODE-RESTRICTION", time, content, REQUIRED_FIELDS);

        this.id = this.metadata["ADID"];
        this.mode = this.metadata["MODE"];
        this.limit = this.metadata["LIMIT"];
        this.scale = this.metadata["SCALE"];
    }
}
