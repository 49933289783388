
// Adobe's javascript relies on global variables
// these populate global for `Visitor` and `AppMeasurement`
import "./VisitorAPI";
import "./AppMeasurement";

import * as VideoHeartbeat from "./VideoHeartbeat";
import * as VisitorAPI from "./VisitorAPI";
import * as AppMeasurementTypes from "./AppMeasurement";
import * as constants from "../../PlayerPlatformConstants";
import { IPPModule, IPPSandbox } from "../../PlayerPlatformApplication";
import * as events from "../../PlayerPlatformAPIEvents";
import { registerModule } from "../../Application";
import { IAdobeVideoHeartbeatOptions, ConfigurationManager } from "../../ConfigurationManager";
import { Logger } from "../../util/Logger";
import { BaseAsset } from "../../assets/BaseAsset";
import { OttAsset } from "../../services/urlService/overTheTop/OttAsset";
import { IOttLocatorProperties } from "../../services/urlService/overTheTop/OttLocatorParser";

declare const global: any;

/**
 * Hopefully these were appended to the window
 * By someone putting AppMeasurement.js file onto the DOM
 */
declare const AppMeasurement: new (someId: string) => AppMeasurementTypes.AppMeasurement;

/**
 * Hopefully these were appended to the window
 * By someone putting VisitorAPI.js file onto the DOM
 */
declare const Visitor: VisitorAPI.Visitor;

export interface ICustomAnalyticsMetadata {
    "custom.page.rsid": string;
    "custom.media.sport": string;
    "custom.media.league": string;
}

enum ContentTypes {
    LIVE = "live",
    VOD = "vod"
}

/**
 * Adobe informed us to provide the number of seconds
 * in a day for live content where the duration is unknown
 */
const SECONDS_IN_DAY: number = 86400;

interface IAdobeAnalyticsMetadata {
    airingId?: string;
    simulcastAiringId?: string;
    network?: string;
    title?: string;
    espnContentType?: string;
    feedType?: string;
    trackingId?: string;
    originalAirDate?: string;
    sport?: string;
    genre?: string;
    league?: string;
    seriesId?: string;
}

export class AdobeVideoHeartbeatHandler implements IPPModule<AdobeVideoHeartbeatHandler> {

    private logger: Logger = new Logger("AdobeVideoHeartbeatHandler");
    private config?: IAdobeVideoHeartbeatOptions;
    private sandbox?: IPPSandbox;
    private mediaHeartbeat?: VideoHeartbeat.va.MediaHeartbeat;
    private currentBitrate: number = 0;
    private startupTime: number = 0;
    private currentFPS: number = 0;
    private droppedFrames: number = 0;
    private analyticsMetadata?: IAdobeAnalyticsMetadata;
    private partnerId: string = "";
    private liveAssetDefaultLength: number = SECONDS_IN_DAY;

    /**
     * Initializes the Adobe Video Heartbeat handler
     * @param sandbox
     * @returns {any}
     */
    public init(sandbox: IPPSandbox) {
        this.sandbox = sandbox;
        this.config = ConfigurationManager.getInstance().get(ConfigurationManager.ADOBE_VIDEO_HEARTBEAT);
        this.partnerId = ConfigurationManager.getInstance().get(ConfigurationManager.PARTNER_ID);

        if (!(this.config && this.config.enabled)) {
            this.logger.trace(`not initializing, disabled globally`);
            return this;
        }

        this.logger.trace(`init`);

        this.sandbox.subscribe(constants.SET_ASSET, this.onSetAsset, constants.PRIORITY_DEFAULT, this);

        return this;
    }

    /**
     * Should be called when the application is done using this handler.
     * @param sandbox
     * @returns {any}
     */
    public destroy(sandbox: IPPSandbox): void {
        this.sandbox = sandbox;
        this.destroyMediaHeartbeat();
        this.sandbox.remove(constants.SET_ASSET, this.onSetAsset);
        this.removeEventListeners();
    }

    /**
     * Adds all of the needed event listeners
     * @returns void
     */
    private addEventListeners(): void {
        if (this.sandbox) {
            this.sandbox.subscribe(constants.PAUSE, this.onMediaPause, constants.PRIORITY_DEFAULT, this);
            this.sandbox.subscribe(constants.PLAY, this.onMediaPlay, constants.PRIORITY_DEFAULT, this);
            this.sandbox.subscribe(constants.STOP, this.destroyMediaHeartbeat, constants.PRIORITY_DEFAULT, this);
        }
        events.addEventListener(events.MEDIA_OPENED, this.onMediaOpened, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.BITRATE_CHANGED, this.onBitrateChanged, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.FPS_CHANGED, this.onFPSChanged, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.PLAYBACK_STARTED, this.onMediaPlay, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.MEDIA_ENDED, this.onMediaComplete, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.DROPPED_FPS_CHANGED, this.onDroppedFPSChanged, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.MEDIA_FAILED, this.destroyMediaHeartbeat, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.MEDIA_RETRY, this.destroyMediaHeartbeat, constants.PRIORITY_DEFAULT, this);
    }

    /**
     * Removes all event listeners
     * @returns void
     */
    private removeEventListeners(): void {
        if (this.sandbox) {
            this.sandbox.remove(constants.PAUSE, this.onMediaPause);
            this.sandbox.remove(constants.PLAY, this.onMediaPlay);
            this.sandbox.remove(constants.STOP, this.destroyMediaHeartbeat);
        }
        events.removeEventListener(events.MEDIA_OPENED, this.onMediaOpened);
        events.removeEventListener(events.BITRATE_CHANGED, this.onBitrateChanged);
        events.removeEventListener(events.FPS_CHANGED, this.onFPSChanged);
        events.removeEventListener(events.PLAYBACK_STARTED, this.onMediaPlay);
        events.removeEventListener(events.MEDIA_ENDED, this.onMediaComplete);
        events.removeEventListener(events.DROPPED_FPS_CHANGED, this.onDroppedFPSChanged);
        events.removeEventListener(events.MEDIA_FAILED, this.destroyMediaHeartbeat);
        events.removeEventListener(events.MEDIA_RETRY, this.destroyMediaHeartbeat);
    }

    /**
     * Returns a MediaConfig object that Adobe Analytics consumes
     */
    private initMediaConfig(config: IAdobeVideoHeartbeatOptions): VideoHeartbeat.va.MediaHeartbeatConfig {
        const mediaConfig: VideoHeartbeat.va.MediaHeartbeatConfig = new VideoHeartbeat.va.MediaHeartbeatConfig();
        mediaConfig.trackingServer = config.heartbeatTrackingServer;
        mediaConfig.playerName = "player_platform_js";
        mediaConfig.channel = "ESPN3";
        mediaConfig.debugLogging = config.debug;
        mediaConfig.appVersion = global.__viperversion;
        mediaConfig.ssl = config.ssl;
        mediaConfig.ovp = "xfinity";
        this.logger.trace("<<<media config params>>>");
        this.logger.trace("tracking server: ", config.heartbeatTrackingServer);
        this.logger.trace("player name: player_platform_js (hardcoded)");
        this.logger.trace("channel: ESPN3 (hardcoded)");
        this.logger.trace("debugLogging: ", config.debug);
        this.logger.trace("appVersion: ", global.__viperversion);
        this.logger.trace("ssl: ", config.ssl);
        this.logger.trace("ovp: xfinity (hardcoded)");
        this.logger.trace("<<<END media config params>>>");
        return mediaConfig;
    }

    /**
     * Returns a MediaDelegate object that Adobe Analytics consumes
     */
    private initMediaDelegate(): VideoHeartbeat.va.MediaHeartbeatDelegate {
        const mediaDelegate: VideoHeartbeat.va.MediaHeartbeatDelegate = new VideoHeartbeat.va.MediaHeartbeatDelegate();
        /*
        * Returns the current position of the playhead.
        * For VOD tracking, the value is specified in seconds from the beginning of the media item.
        * For LIVE/LIVE tracking, the value is specified in seconds from the beginning of the program.
        */
        mediaDelegate.getCurrentPlaybackTime = () => this.getCurrentPlaybackTimeSeconds();

        /*
        * Returns the MediaObject instance that contains the current QoS information.
        * This method will be called multiple times during a playback session.
        * Player implementation must always return the most recently available QoS data.
        */
        mediaDelegate.getQoSObject = () => VideoHeartbeat.va.MediaHeartbeat.createQoSObject(this.currentBitrate, this.startupTime, this.currentFPS, this.droppedFrames);
        this.logger.trace("QoS Object = bitrate: ", this.currentBitrate, " startup time: ", this.startupTime, " fps: ", this.currentFPS, " dropped frames: ", this.droppedFrames);
        return mediaDelegate;
    }

    /**
     * Returns a AppMeasurement object that Adobe Analytics consumes
     */
    private initAppMeasurement(config: IAdobeVideoHeartbeatOptions): AppMeasurementTypes.AppMeasurement {
        const appMeasurement: AppMeasurementTypes.AppMeasurement = new AppMeasurement(config.reportSuiteAccountId);
        appMeasurement.visitor = Visitor.getInstance(config.marketingExperienceCloudOrgId);
        appMeasurement.trackingServer = config.analyticsTrackingServer;
        appMeasurement.account = config.reportSuiteAccountId;
        appMeasurement.pageName = "primary_page";
        appMeasurement.charSet = "UTF 8";
        this.logger.trace("<<<app measurement params>>>");
        this.logger.trace("trackingServer: ", config.analyticsTrackingServer);
        this.logger.trace("account: ", config.reportSuiteAccountId);
        this.logger.trace("pageName: primary_page (hardcoded)");
        this.logger.trace("charSet: UTF 8 (hardcoded)");
        this.logger.trace("<<<END app measurement params>>>");
        return appMeasurement;
    }

    /**
     * Returns a new MediaHeartbeat object that Adobe Analytics consumes
     */
    private initMediaHeartbeat(): VideoHeartbeat.va.MediaHeartbeat | undefined {
        this.logger.trace("init media heartbeat");
        if (this.config) {
            return new VideoHeartbeat.va.MediaHeartbeat(
                this.initMediaDelegate(),
                this.initMediaConfig(this.config),
                this.initAppMeasurement(this.config)
            );
        }
    }

    /**
     * Returns the MediaObject that Adobe Analytics consumes
     */
    private initMediaObject(analyticsMetadata: IAdobeAnalyticsMetadata): VideoHeartbeat.va.MediaObject {
        if (!analyticsMetadata.trackingId) {
            this.logger.warn("A trackingId was not provided on the locator! This value is crucial for Adobe Analytics to function correctly.");
            analyticsMetadata.trackingId = "trackingId";
        }
        this.logger.trace("Initializing mediaObject with title: ", String(analyticsMetadata.title), ", trackingId: ", String(analyticsMetadata.trackingId),
        ", length: ", this.getVideoLengthSeconds(analyticsMetadata), ", contentType: ", String(analyticsMetadata.espnContentType));
        let mediaObject: VideoHeartbeat.va.MediaObject = VideoHeartbeat.va.MediaHeartbeat.createMediaObject(String(analyticsMetadata.title),
            String(analyticsMetadata.trackingId), this.getVideoLengthSeconds(analyticsMetadata), String(analyticsMetadata.espnContentType));
        mediaObject = this.applyStandardVideoMetadata(mediaObject, analyticsMetadata);
        return mediaObject;
    }

    /**
     * Determines if we should start heartbeat for an asset.
     * This only runs for OTT ESPN assets for the time being.
     * Returns true if the asset is an OTT asset for ESPN
     */
    private startHeartbeatForAsset(asset: BaseAsset): boolean {
        return asset instanceof OttAsset && asset.isESPN();
    }

    private onSetAsset(asset: BaseAsset): void {

        if (!this.startHeartbeatForAsset(asset)) {
            this.logger.trace("Adobe heartbeat analytics not enabled for this asset");
            return;
        }

        this.removeEventListeners();
        this.addEventListeners();

        // set the analytics metadata from the locator
        this.analyticsMetadata = this.getAdobeAnalyticsMetadata((asset as OttAsset).getLocator());

        this.destroyMediaHeartbeat();

        //init media heartbeat instance
        this.mediaHeartbeat = this.initMediaHeartbeat();

    }

    private getAdobeAnalyticsMetadata(locatorProperties: IOttLocatorProperties): IAdobeAnalyticsMetadata {
        return {
            airingId: locatorProperties.airingid,
            simulcastAiringId: locatorProperties.simulcastairingid,
            network: locatorProperties.network,
            title: locatorProperties.title,
            espnContentType: locatorProperties.espncontenttype,
            feedType: locatorProperties.feedtype,
            trackingId: locatorProperties.trackingid,
            originalAirDate: locatorProperties.originalairdate,
            sport: locatorProperties.sport,
            genre: locatorProperties.genre,
            league: locatorProperties.league,
            seriesId: locatorProperties.seriesid
        };
    }

    /**
     * Calculates the startup time
     */
    private onMediaPlay(): void {
        this.logger.trace("play");
        if (this.mediaHeartbeat !== undefined) {
            this.logger.trace("track play");
            this.mediaHeartbeat.trackPlay();
        }
    }

    private onMediaOpened(event: events.MediaOpenedEvent): void {
        this.logger.trace("media opened");
        this.startupTime = event.openingLatency / constants.MILLISECONDS_PER_SECOND;

        //start tracking session
        if (this.mediaHeartbeat !== undefined && this.analyticsMetadata !== undefined) {
            this.logger.trace("track session start");
            this.mediaHeartbeat.trackSessionStart(
                this.initMediaObject(this.analyticsMetadata),
                this.createVideoMetadataObj()
            );
        }
    }

    /**
     * Maps the analytics metadata keys from the locator, config, as well as any hard coded values
     * @param obj {VideoHeartbeat.va.MediaObject}
     * @returns {VideoHeartbeat.va.MediaObject}
     */
    private applyStandardVideoMetadata(obj: VideoHeartbeat.va.MediaObject, analyticsMetadata: IAdobeAnalyticsMetadata): VideoHeartbeat.va.MediaObject {
        const standardVideoMetadata: { [x: string]: string | undefined } = {};
        standardVideoMetadata[VideoHeartbeat.va.MediaHeartbeat.VideoMetadataKeys.NETWORK] = analyticsMetadata.network;
        standardVideoMetadata[VideoHeartbeat.va.MediaHeartbeat.VideoMetadataKeys.AUTHORIZED] = "true";
        standardVideoMetadata[VideoHeartbeat.va.MediaHeartbeat.VideoMetadataKeys.MVPD] = this.partnerId;
        standardVideoMetadata[VideoHeartbeat.va.MediaHeartbeat.VideoMetadataKeys.ORIGINATOR] = "ESPN";
        standardVideoMetadata[VideoHeartbeat.va.MediaHeartbeat.VideoMetadataKeys.SHOW_TYPE] = "3";
        standardVideoMetadata[VideoHeartbeat.va.MediaHeartbeat.VideoMetadataKeys.ASSET_ID] = analyticsMetadata.simulcastAiringId;
        standardVideoMetadata[VideoHeartbeat.va.MediaHeartbeat.VideoMetadataKeys.FEED] = analyticsMetadata.feedType;
        standardVideoMetadata[VideoHeartbeat.va.MediaHeartbeat.VideoMetadataKeys.FIRST_AIR_DATE] = analyticsMetadata.originalAirDate;
        standardVideoMetadata[VideoHeartbeat.va.MediaHeartbeat.VideoMetadataKeys.FIRST_DIGITAL_DATE] = analyticsMetadata.originalAirDate;
        standardVideoMetadata[VideoHeartbeat.va.MediaHeartbeat.VideoMetadataKeys.GENRE] = analyticsMetadata.genre;
        standardVideoMetadata[VideoHeartbeat.va.MediaHeartbeat.VideoMetadataKeys.SHOW] = analyticsMetadata.seriesId;
        this.logger.trace("<<<standard video metadata>>>");
        this.logger.trace(JSON.stringify(standardVideoMetadata));
        this.logger.trace("<<< END standard video metadata>>>");
        if (!analyticsMetadata.network) {
            this.logger.warn("A network value was not set on the locator!");
        }
        obj.setValue(VideoHeartbeat.va.MediaHeartbeat.MediaObjectKey.StandardVideoMetadata, standardVideoMetadata);
        return obj;
    }

    /**
     * Updates the current bitrate
     */
    private onBitrateChanged(event: events.BitrateChangedEvent): void {
        this.currentBitrate = event.bitRate;
    }

    /**
     * Updates the current FPS
     */
    private onFPSChanged(event: events.FPSChangedEvent): void {
        this.currentFPS = event.fps;
    }

    private onDroppedFPSChanged(event: events.DroppedFPSChangedEvent): void {
        this.droppedFrames = event.droppedfps;
    }

    /**
     * The current playback time in seconds
     */
    private getCurrentPlaybackTimeSeconds(): number {
        if (this.sandbox) {
            return this.sandbox.getCurrentPosition() / constants.MILLISECONDS_PER_SECOND;
        }
        return NaN;
    }

    /**
     * The current playback time in seconds. Returns the hardcoded default value for live when the asset content
     * type is live, and the actual length otherwise.
     */
    private getVideoLengthSeconds(analyticsMetadata: IAdobeAnalyticsMetadata): number {
        if (analyticsMetadata.espnContentType !== ContentTypes.VOD) {
            this.logger.trace("live asset, using default value for length");
            return this.liveAssetDefaultLength;
        } else if (this.sandbox !== undefined) {
            return this.sandbox.getDuration() / constants.MILLISECONDS_PER_SECOND;
        }
        return NaN;
    }

    /**
     * @returns {ICustomAnalyticsMetadata}
     */
    private createVideoMetadataObj(): ICustomAnalyticsMetadata {
        const reportingSuiteAccountId = String(this.config && this.config.reportSuiteAccountId);
        const sport = String(this.analyticsMetadata.sport);
        const league = String(this.analyticsMetadata.league);
        this.logger.trace("custom.page.rsid: ", reportingSuiteAccountId);
        return {
            "custom.page.rsid": reportingSuiteAccountId,
            "custom.media.sport": sport,
            "custom.media.league": league
        };
    }

    /*
    * This method should be fired when the user has finished watching the asset
    */
    private onMediaComplete() {
        this.logger.trace("media complete");
        if (this.mediaHeartbeat !== undefined) {
            this.logger.trace("track complete");
            this.mediaHeartbeat.trackComplete();
        }
    }

    /*
    * Track the pause event
    */
    private onMediaPause() {
        this.logger.trace("media pause");
        if (this.mediaHeartbeat !== undefined) {
            this.logger.trace("track pause");
            this.mediaHeartbeat.trackPause();
        }
    }

    /*
    * Emit the termination of the session
    */
    private destroyMediaHeartbeat() {
        if (this.mediaHeartbeat !== undefined) {
            this.logger.trace("track end");
            this.mediaHeartbeat.trackSessionEnd();
            this.mediaHeartbeat = undefined;
        }
    }

}

registerModule("AdobeVideoHeartbeatHandler", AdobeVideoHeartbeatHandler, { autostart: true });
