
import { PPError } from "../../PPError";
import "./PSDKPlayerEvents";
import "../../handlers/CrossStreamPreventionHandler";
import "../../handlers/NetworkDownHandler";
import "../../handlers/VirtualStreamStitcherHandler";
import "./XRECCHandler";
import * as constants from "../../PlayerPlatformConstants";
import * as events from "../../PlayerPlatformAPIEvents";
import { getMediaPlayerConfig } from "./PSDKGetMediaPlayerConfig";
import { IPPSandbox } from "../../PlayerPlatformApplication";
import { MediatorChannel } from "publicious";
import { registerModule } from "../../Application";
import { BaseAsset, AssetUrlType } from "../../assets/BaseAsset";
import { BasePlayer } from "../base/BasePlayer";
import { ConfigurationManager } from "../../ConfigurationManager";
import { Logger } from "../../util/Logger";
import { AdobeRuntimeWrapper } from "./AdobeRuntimeWrapper";
import { HlsTag } from "../../util/hls/HlsTag";
import * as urlService from "../../services/urlService/URLService";
import { Observable, fromEventPattern } from "rxjs";
import { defaultIfEmpty, filter, map, switchMap, take, takeUntil } from "rxjs/operators";

/*
* This code runs on STB only
*/

interface IPSDKAssetTimeouts {
    httpStartTransferTimeout: number;
    httpTotalTimeout: number;
}


export class PSDKPlayer extends BasePlayer {

    public static readonly TARGET_DURATION_TAG: string = "#EXT-X-TARGETDURATION";
    public static readonly I_FRAME_DISTANCE_TAG: string = "#EXT-NOM-I-FRAME-DISTANCE";
    public static readonly LIVE_POINT: number = -2;
    public latencyStart = 0;
    public updateInterval = 250;
    public initialBufferTime = ConfigurationManager.DEFAULT_INITIAL_BUFFER_TIME;
    public playingVODBufferTime = ConfigurationManager.DEFAULT_PLAYING_VOD_BUFFER_TIME;
    public playingLinearBufferTime = ConfigurationManager.DEFAULT_PLAYING_LINEAR_BUFFER_TIME;
    public maxFragmentDuration: number = 0;

    //This variable ensures that the value in the manifest takes precedence over the configured value of TRICPLAY_MAX_FPS
    public playerTrickPlayFPSMaxAlreadySet: boolean = false;

    private asset: BaseAsset;
    private bufferingFlag = false;
    private playerVersion = "unavailable";
    private view: any;
    private qosProvider: any;
    private shouldInterruptLive: boolean = false;

    constructor() {
        super();
        this.logger = new Logger("PSDKPlayer");
    }

    public init(sandbox: IPPSandbox) {
        super.init(sandbox);

        const element = sandbox.params.videoElement;
        this.initPlayer(element);

        this.setPlayerReady();
        this._updateMaxFragmentDuration();
        this._updateTrickplayMaxFps();

        this.seekToLiveInterrupt();

        return this;
    }

    public destroy(sandbox: IPPSandbox) {
        super.destroy(sandbox);

        this.player.release();
    }

    /**
     * used internally in seekToLiveInterrupt which intentionally needs
     * a higher default priority, emits the channel object in the observable.
     */
    private applicationObservable(name: string): Observable<MediatorChannel> {
        return fromEventPattern<MediatorChannel>(
            handler => this.sandbox.subscribe(name, handler as any, constants.PRIORITY_LOW, null),
            handler => this.sandbox.remove(name, handler as any)
        )
            .pipe(map((t) => Array.isArray(t) ? t[t.length - 1] : t));
    }

    /**
     * seekToLiveInterrupt is an observable that listens to seekToLive requests
     * and interrupts that channel based on conditions being met so that
     * a seek request doesn't make it to PSDK if the playhead is considered to
     * be at the live point.
     */
    private seekToLiveInterrupt(): void {

        const assetObservable = this.applicationObservable(constants.SET_ASSET).pipe(defaultIfEmpty());

        assetObservable.pipe(switchMap(() => {
              // We set the interrupt flag on the first time we get the playing
              // event, which we're using as the "live playhead position"
              //
              // "f'it we'll do it live" - Bill O'Reilly
              return this.sandbox.streams
                                 .getPlayState(constants.STATUS_PLAYING)
                                 .pipe(take(1));
            }),
            takeUntil(this.sandbox.destroyed))
            .subscribe(() => this.shouldInterruptLive = this._isLive(),
                       () => undefined,
                       () => this.shouldInterruptLive = false);


        assetObservable.pipe(switchMap(() => {
            return this.applicationObservable(constants.SEEK_TO_LIVE);
        }),
        filter(() => this._isLive() && this.shouldInterruptLive),
        takeUntil(this.sandbox.destroyed))
        .subscribe((channel: MediatorChannel) => {
            this.logger.warn("Considered to be at live point, seekToLive request interrupted");
            channel.stopPropagation();
        });
    }

    private getContentFactory(): AdobePSDK.ContentFactory | null {
        return !this.sandbox.adManager || !this.sandbox.adManager.hasContentFactory() ? null : this.sandbox.adManager.getContentFactory();
    }

    private _assetTimeouts(asset: BaseAsset): IPSDKAssetTimeouts {

        const configMgr = ConfigurationManager.getInstance();

        return {
            httpStartTransferTimeout: configMgr.getByAssetType(configMgr.getAssetType(asset), ConfigurationManager.HTTP_START_TRANSFER_TIMEOUT),
            httpTotalTimeout: configMgr.getByAssetType(configMgr.getAssetType(asset), ConfigurationManager.HTTP_TOTAL_TIMEOUT)
        };

    }

    /**
     *
     * @param tag
     * @returns {boolean}
     * @private
     */
    private _isTargetDurationTag(tag: HlsTag): boolean {
        return tag.name === PSDKPlayer.TARGET_DURATION_TAG;
    }

    private _isIFrameDistanceTag(tag: HlsTag): boolean {
        return tag.name === PSDKPlayer.I_FRAME_DISTANCE_TAG;
    }

    /**
     * Updates maxFragmentDuration with #EXT-X-TARGETDURATION value
     * @private
     */
    private  _updateMaxFragmentDuration(): void {
        this.sandbox.streams.tags
            .pipe(
                takeUntil(this.sandbox.destroyed),
                filter((tag: HlsTag) => this._isTargetDurationTag(tag))
            )
            .subscribe((tag) => {
                this.maxFragmentDuration = parseInt(tag.content, 10) * 1000;
            });
    }

    /**
     * Updates trickPlayMaxFps with  #EXT-NOM-I-FRAME-DISTANCE value
     * @private
     */
    private  _updateTrickplayMaxFps(): void {
        this.sandbox.streams.tags
            .pipe(
                takeUntil(this.sandbox.destroyed),
                filter((tag: HlsTag) => this._isIFrameDistanceTag(tag))
            )
            .subscribe((tag) => {
                this.setTrickplayMaxFps(parseFloat(tag.content));
            });
    }

    /**
     * Adobe set's the exception pointer when accessing MediaPlayer.currentItem
     * if no item exists. This wrapper protects us from that exception.
     */
    private _getCurrentItem(): AdobePSDK.MediaPlayerItem | undefined {
        try {
            return this.player.currentItem;
        } catch (e) {
            this.logger.warn("this.player.currentItem is undefined: " + e);
        }
        return undefined;
    }

    /**
     * Since _getCurrentItem() can be undefined, this convenience function wraps
     * that undefined check and returns a boolean.
     */
    private _isLive(): boolean {
        const currentItem = this._getCurrentItem();
        return currentItem && currentItem.live;
    }

    /**
     * PSDKPlayer Functions
     */

    public initPlayer(element: HTMLElement) {

        this.logger.trace("initPlayer");

        this.view = new AdobePSDK.MediaPlayerView(element);

        this.player = new AdobePSDK.MediaPlayer();
        // TODO(estobb200): Remove when either we start using the latest rxjs
        // with this commit..
        // https://github.com/ReactiveX/rxjs/commit/e036e79b30c35df19aa036390e7fe0c94b7a8ff6
        // or when this merges..
        // https://gerrit.teamccp.com/#/c/95282/
        // Thanks Adobe.
        this.player.toString = () => {
            return "[object _AdobePSDK_MediaPlayer]";
        };
        this.player.view = this.view;

        // get the PSDK version
        // note: early versions of the PSDK throw an exception when the version function is called
        // therefore assume version 1.0 if an exception is thrown
        try {
            this.playerVersion = AdobePSDK.version.description;
            this.logger.info("initPlayer: PSDK version=" + this.playerVersion);
        } catch (error) {
            this.logger.warn("initPlayer: Warning!! Error occurred while getting PSDK player version: " + JSON.stringify(error));
        }

        try {
            this.qosProvider = new AdobePSDK.QOSProvider();
            this.qosProvider.attachMediaPlayer(this.player);
        } catch (error) {
            this.logger.warn("Warning!! Error occurred while creating PSDK QOS Provider: " + JSON.stringify(error));
        }
    }

    public play() {
        this.logger.trace("play");

        if (this.player.status === AdobeRuntimeWrapper.STATUS_PLAYING()) {
            // Note - this can occur when in trickplay mode.
            this.logger.warn("Warning! PSDKPlayer.play() called while status == 'PLAYER_STATUS_PLAYING'");
        } else {
            this.player.play();
        }
    }

    //TODO: If state comes in as paused due to buffering, does this fail?
    public pause() {
        this.logger.trace("pause");
        if (this.player.status === AdobeRuntimeWrapper.STATUS_PLAYING()) {
            this.player.pause();
            this.shouldInterruptLive = false;
        } else if (this.player.status === AdobeRuntimeWrapper.STATUS_PAUSED()) {
            this.player.play();
        }
    }

    public stop() {
        this.logger.trace("stop");
        this.player.reset();
    }

    public seekToLive() {
        this.logger.trace("seekToLive");
        this.setPosition(this.player.seekableRange.end);
    }

    public prepareToPlay() {
        this.logger.trace("prepareToPlay.ResumePosition:" + this.sandbox.asset.resumePosition);
        this.player.prepareToPlay(this.sandbox.asset.resumePosition);
    }

    /**
     * PSDKPlayer Setters
     */

    public setAsset(asset: BaseAsset): void {
        this.logger.trace("setAsset: asset=" + JSON.stringify(asset));

        this.asset = asset;

        this.asset.addSubscribedTag(PSDKPlayer.TARGET_DURATION_TAG);
        this.asset.addSubscribedTag(PSDKPlayer.I_FRAME_DISTANCE_TAG);

        this.latencyStart = Date.now();

        this.logger.info("PSDKPlayer reset status = " + this.player.status);
        this.player.reset();
        this.playerTrickPlayFPSMaxAlreadySet = false;

        const res = new AdobePSDK.MediaResource();
        this.logger.info("PSDKPlayer replaceCurrentResource status = " + this.player.status);

        let resourceType: number;

        switch (asset.getUrlType()) {
            case AssetUrlType.URLTYPE_M3U:
                resourceType = AdobeRuntimeWrapper.HLS();
                break;
            case AssetUrlType.URLTYPE_MPD:
                resourceType = AdobeRuntimeWrapper.DASH();
                break;
            case AssetUrlType.URLTYPE_MP4:
                resourceType = AdobeRuntimeWrapper.ISOBMFF();
                break;
            default:
                const desc = "Resource type not supported. Only types supported are HLS and DASH";
                const error = new PPError(AdobeRuntimeWrapper.INVALID_ARGUMENT() || 1, null, desc);
                events.emit(new events.MediaFailedEvent(error));
                return;
        }

        res.url = urlService.getURLForPlayback(asset.url, asset);
        res.type = resourceType;

        const mediaPlayerConfig = getMediaPlayerConfig(asset, this.getContentFactory());
        this.player.mediaPlayerConfig = mediaPlayerConfig;

        const timeouts: IPSDKAssetTimeouts = this._assetTimeouts(asset);
        this.setHTTPTimeouts(timeouts.httpStartTransferTimeout, timeouts.httpTotalTimeout);

        this.player.replaceCurrentResource(res, mediaPlayerConfig);

    }

    public setBitrateRange(min: number, max: number) {
        let currentParams: any;
        let newParams: any;
        this.logger.trace("setBitrateRange: " + min + "-" + max);
        currentParams = this.player.abrControlParameters;
        newParams = new AdobePSDK.ABRControlParameters(currentParams.initialBitRate,
            min,
            max,
            currentParams.abrPolicy);
        this.player.abrControlParameters = newParams;
    }

    public setBlock(flag: boolean) {
        this.logger.trace("setBlock: " + flag);
        // stop presenting sound samples
        const presenter = new AdobePSDK.VideoPresenter();
        presenter.StopSound();
        // hide the view by moving it offscreen
        this.view.setPos(-this.view.width, -this.view.height);
    }

    public setBufferControlParameters(initial: number, playback: number) {
        this.player.bufferControlParameters = new AdobePSDK.BufferControlParameters(initial, playback);
    }

    public setClosedCaptionsEnabled(flag: boolean) {
        this.player.ccVisibility = flag ? this.player.VISIBLE : this.player.INVISIBLE;
    }

    public setClosedCaptionsTrack(track: string) {
        this.logger.trace("setClosedCaptionsTrack: " + track);

        const currentItem = this._getCurrentItem();
        if (currentItem && currentItem.selectedClosedCaptionsTrack.language === track) {
            return;
        }
        const ccTracks: AdobePSDK.ClosedCaptionsTrack[] = currentItem ? currentItem.closedCaptionsTracks : [];
        if (!ccTracks || !ccTracks.length) {
            return;
        }

        for (const ccTrack of ccTracks) {
            if (ccTrack.language === track) {
                currentItem.selectClosedCaptionsTrack(ccTrack);
                return;
            }
        }

        this.logger.warn(`Closed caption track "${track}" not found.`);
    }

    public setCurrentTimeUpdateInterval(interval: number) {
        this.logger.trace("setCurrentTimeUpdateInterval: " + interval);
        // 0 disables, min accepted is 50
        if (interval === 0 || interval >= 50) {
            this.updateInterval = interval;
        }
    }

    public setDimensionsOfVideo(width: number, height: number) {
        this.logger.trace("setDimensionsOfVideo: " + width + "x" + height);
        this.view.setSize(width, height);
        this.player.view = this.view;
    }

    public setInitialBitrate(initialBitrate: number) {
        let currentParams: any;
        let newParams: any;
        this.logger.trace("setInitialBitrate: " + initialBitrate);
        currentParams = this.player.abrControlParameters;
        newParams = new AdobePSDK.ABRControlParameters(initialBitrate,
            currentParams.minBitRate,
            currentParams.maxBitRate,
            currentParams.abrPolicy);
        this.player.abrControlParameters = newParams;
    }

    public setPosition(msecs: number): void {
        this.logger.trace("setPosition: " + msecs);

        const curPos = this.getCurrentPosition();

        if (curPos === msecs) {
            return;
        }

        if (msecs < curPos) {
            this.shouldInterruptLive = false;
        }

        if (msecs < this.player.seekableRange.begin) {
            this.logger.info(`setPosition: requested position below range - seek to range begin: ${this.player.seekableRange.begin} msec`);
            this.player.seek(this.player.seekableRange.begin);
            return;
        }

        if (msecs >= this.player.seekableRange.end) {
            if (this._isLive()) {
                if ((this.player.seekableRange.end - this.getCurrentPosition() > this.maxFragmentDuration)) {
                    this.logger.info(`setPosition: requested position above range - seek to range end: ${this.player.seekableRange.end} msec`);
                    this.player.seek(AdobeRuntimeWrapper.SUPPORTS_ENTERING_LIVE() ? PSDKPlayer.LIVE_POINT : this.player.seekableRange.end);
                    this.shouldInterruptLive = true;
                } else {
                    this.logger.info("setPosition: requested position falls in the current loaded fragment");
                    this.sandbox.publish("xre:onenteringlive");
                }
            } else {
                this.logger.info(`setPosition: requested position above range - seek to range end: ${this.player.seekableRange.end} msec`);
                this.player.seek(this.player.seekableRange.end);
            }
            return;
        }
        this.logger.info(`setPosition: requested position is in range - seek to: ${msecs} msec`);
        this.player.seek(msecs);
    }

    public setPositionRelative(msecs: number) {
        this.logger.trace("setPositionRelative: " + msecs);
        const position = this.player.playbackMetrics.time + msecs;
        this.setPosition(position);
    }

    public setPreferredAudioLanguage(language: string) {
        let i: number;
        let len: number;
        let track: any;
        const currentItem = this._getCurrentItem();
        this.logger.trace("setPreferredAudioLanguage: " + language);

        len = currentItem ? currentItem.audioTracks.length : 0;
        this.logger.trace("setPreferredAudioLanguage: audioTracks.length=" + len);
        for (i = 0; i < len; i++) {
            track = currentItem.audioTracks[i];
            if (track.language === language) {
                this.logger.info("setPreferredAudioLanguage selected language: " + track.language + " name: " + track.name);
                currentItem.selectAudioTrack(track);

                // perform a seek to the current position. This forces the player to flush its buffers,
                // re-evaluate currentItem, and implement the language change without delay
                const pos = this.getCurrentPosition();
                if (pos) {
                    try {
                        // PSDK Throws ILLEGAL_STATE here because onMediaOpened hasn't fired yet.
                        // 2017 May 16 22:13:52.229988 pacexi3v2 Receiver[18164]:  Thread-18164 [JavaScript] - console [:0]: PSDKPlayer: willSeek:1500
                        // 2017 May 16 22:13:52.239359 pacexi3v2 Receiver[18164]:  Thread-18164 [JavaScript] - console [:0]: PSDKPlayer: ILLEGAL_STATE
                        // This doesn't appear to be causing an issue, it still seems to seek correctly.
                        this.player.seek(pos);
                    } catch (err) {
                        this.logger.error(err);
                    }
                }
                break;
            } else {
                this.logger.info("PSDKPlayer.setPreferredAudioLanguage skip[" + i + "] language: " + track.language);
                this.logger.info("PSDKPlayer.setPreferredAudioLanguage skip[" + i + "] name: " + track.name);
            }
        }
        if (i >= len) {
            this.logger.error("setPreferredAudioLanguage: no match found for " + language);
        }
    }

    public setPreferredZoomSetting(setting: string) {
        this.logger.trace("setPreferredZoomSetting");
        this.player.setPreferredZoomSetting(setting);
    }

    public _isSpeedChangeAtEOS(rateRequest: number): boolean {
        // This logic matches the fix in PSDK MediaPlayerPrivate
        // PSDK will set the rate to 1 and ignore this request.
        return (rateRequest > 1 && this._isLive() &&
            (this.getCurrentPosition() + rateRequest * this.updateInterval >= this.player.seekableRange.end));
    }

    public setSpeed(speed: number, overshootCorrection: number): void {
        this.logger.trace(`setSpeed: spd=${speed} ovr=${overshootCorrection}`);

        if (!isNaN(overshootCorrection)) {
            this.player.overshootCorrection = overshootCorrection;
            this.logger.info(`Setting overshootCorrection = ${overshootCorrection}`);
        }

        if (isNaN(speed)) {
            return;
        }

        if (speed === 1) {
            this.player.play();
        } else {
            if (speed <= 0) {
                this.shouldInterruptLive = false;
            }

            if (this._isSpeedChangeAtEOS(speed)) {
                const error = new PPError(events.MEDIA_WARNING_TRICKMODE_DISALLOWED, null, "speed change not allowed @ EOS");
                events.dispatchEvent(new events.MediaWarningEvent(error));
                return;
            } else if (speed > 1 && this._isLive() &&
                        (this.player.seekableRange.end - this.getCurrentPosition() <= this.maxFragmentDuration)) {
                this.sandbox.publish("xre:onenteringlive");
                return;
            }
            try {
                this.player.rate = speed;
            } catch (err) {
                this.logger.info(`error setting player.rate = ${speed} exception: ${err}`);
            }
        }
        this.logger.info("Setting rate = " + this.player.rate);
    }

    public setVolume(volume: number) {
        //PPJS:TID02 volume comes in as a float (.5 == 50%, 1 == 100%) psdk expects an int between 0 & 100
        this.logger.trace("setVolume: " + volume);
        this.player.volume = volume * 100;
    }

    public setInitialBufferTime(msec: number) {
        this.logger.trace("setInitialBufferTime: " + msec);
        this.initialBufferTime = msec;
        this.setBufferControlParameters(msec, this.player.bufferControlParameters.playBufferTime);
    }

    public setPlayingVODBufferTime(msec: number) {
        this.logger.trace("setPlayingVODBufferTime: " + msec);
        this.playingVODBufferTime = msec;
        this.setBufferControlParameters(this.player.bufferControlParameters.initialBufferTime, msec);
    }

    public setPlayingLinearBufferTime(msec: number) {
        this.logger.trace("setPlayingLinearBufferTime: " + msec);
        this.playingLinearBufferTime = msec;
        this.setBufferControlParameters(this.player.bufferControlParameters.initialBufferTime, msec);
    }

    public setScale(width: number, height: number) {
        this.logger.trace("setScale: " + width + "x" + height);
        this.view.setScale(width, height);
        this.player.view = this.view;
    }

    public setOffset(x: number, y: number) {
        this.logger.trace("setOffset: x=" + x + "y=" + y);
        this.view.setOffset(x, y);
        this.player.view = this.view;
    }

    public setTrickplayMaxFps(maxFps: number) {
        this.logger.trace("setTrickplayMaxFps: " + maxFps);
        this.playerTrickPlayFPSMaxAlreadySet = true;
        this.player.trickPlayMaxFps = maxFps;
    }

    public setAudioOnly(mute: boolean): void {
        this.logger.trace("setAudioOnly: " + mute);
        this.player.setVideoMute(mute);
    }

    /**
     * Set's the HTTPReader's timeout values.
     * @param {Number} startTransferTimeout in milliseconds
     * @param {Number} totalTimeout in milliseconds
     * @return {boolean} result if function exists on PSDK.
     */
    public setHTTPTimeouts(startTransferTimeout: number, totalTimeout: number): boolean {
        if (this.player.setHTTPTimeouts) {
            this.logger.info("MediaPlayer setHTTPTimeouts: " + startTransferTimeout + ", " + totalTimeout);
            this.player.setHTTPTimeouts(startTransferTimeout, totalTimeout);
            return true;
        }
        this.logger.warn("MediaPlayer setHTTPTimeouts not available in PSDK.");
        return false;
    }

    /**
     * PSDKPlayer Getters
     */

    public getAutoPlay() {
        //Autoplay is a state kept locally, not available as a PSDK API.
        this.logger.trace("getAutoPlay: " + this.autoPlay);
        return this.autoPlay;
    }

    public getAvailableAudioLanguages() {
        const languages: string[] = [];
        const currentItem = this._getCurrentItem();
        if (!currentItem || !currentItem.audioTracks) {
            return languages;
        }
        for (const track of currentItem.audioTracks) {
            languages.push(track.language);
        }
        return languages;
    }

    public getAvailableBitrates(): number[] {
        const bitRates: number[] = [];
        const currentItem: AdobePSDK.MediaPlayerItem = this._getCurrentItem();
        this.logger.trace("getAvailableBitrates");
        if (!currentItem) {
            return bitRates;
        }
        for (const profile of currentItem.profiles) {
            bitRates.push(profile.bitRate !== undefined ? profile.bitRate : (profile as any).bitrate); //TODO: bitrate doesn't seem to exist, verify if it is needed
        }
        return bitRates;
    }

    public getAvailableClosedCaptionTracks() {
        const currentItem = this._getCurrentItem();
        const tracks = (currentItem && currentItem.closedCaptionsTracks) || [];
        return tracks.map((track: any) => {
            return track.language;
        });
    }

    public getBitrateRange() {
        const currentParams = this.player.abrControlParameters;
        const range = [currentParams.minBitRate, currentParams.maxBitRate];
        this.logger.trace("getBitrateRange: " + range);
        return range;
    }

    public getBufferFilledLength(): number {
        const len = this.playbackInformation.bufferLength;
        this.logger.trace("getBufferFilledLength: " + len);
        return len;
    }

    public getBufferTime() {
        return this.playbackInformation.bufferTime;
    }

    public getClosedCaptionsStatus() {
        //TODO: Can we get this from XRE to keep them in sync??
        const stat = this.player.ccVisibility;
        this.logger.trace("getClosedCaptionsStatus: " + stat);
        return stat;
    }

    public getCurrentAudioLanguage() {
        const currentItem = this._getCurrentItem();
        if (!currentItem) {
            return undefined;
        }
        const lang = currentItem.selectedAudioTrack.language;
        this.logger.trace("getCurrentAudioLanguage: " + lang);
        return lang;
    }

    public getCurrentBitrate() {
        const br = this.playbackInformation.bitRate !== undefined ? this.playbackInformation.bitRate : this.playbackInformation.bitrate;
        this.logger.trace("getCurrentBitrate: " + br);
        return br;
    }

    public getCurrentClosedCaptionTrack() {
        this.logger.trace("getCurrentClosedCaptionTrack");
        const currentItem = this._getCurrentItem();
        if (!currentItem) {
            return "";
        }
        const trk = currentItem.selectedClosedCaptionsTrack;
        return trk ? trk.language : "";
    }

    public getCurrentPlaybackSpeed() {
        return this.player.rate;
    }

    public getCurrentPosition() {
        return this.player.currentTime;
    }

    public getDuration() {
        return this.player.playbackRange.duration;
    }

    public getEndPosition() {
        let endpos = this.player.playbackRange.end;
        if (this._isLive()) {
            endpos = this.getCurrentPosition() + this.getBufferTime();
        }
        return endpos;
    }

    /**
     * Non PlayerPlatformAPI's
     */
    public getInitialBitrate() {
        const br = this.player.abrControlParameters.initialBitRate;
        this.logger.trace("getInitialBitrate: " + br);
        return br;
    }

    // tslint:disable:cyclomatic-complexity
    // translates PSDK state into PlayerPlatformAPI status
    public mapStateToStatus(state: number): string {
        // PlayerPlatformAPI available status:
        //  complete, error, initialized, initializing
        //  paused, playing, ready, released
        // PSDK Player states:
        //  PLAYER_STATUS_IDLE, PLAYER_STATUS_INITIALIZING, PLAYER_STATUS_INITIALIZED,
        //  PLAYER_STATUS_PREPARING, PLAYER_STATUS_PREPARED, PLAYER_STATUS_PLAYING,
        //  PLAYER_STATUS_PAUSED, PLAYER_STATUS_SEEKING, PLAYER_STATUS_COMPLETE,
        //  PLAYER_STATUS_ERROR, PLAYER_STATUS_RELEASED
        let status = constants.STATUS_ERROR;
        if (this.bufferingFlag) {
            status = constants.STATUS_BUFFERING;
        } else {
            switch (state) {
                case AdobeRuntimeWrapper.STATUS_IDLE():
                    status = constants.STATUS_IDLE;
                    break;
                case AdobeRuntimeWrapper.STATUS_INITIALIZING():
                    status = constants.STATUS_INITIALIZING;
                    break;
                case AdobeRuntimeWrapper.STATUS_INITIALIZED():
                    status = constants.STATUS_INITIALIZED;
                    break;
                case AdobeRuntimeWrapper.STATUS_PREPARING():
                    status = constants.STATUS_PREPARING;
                    break;
                case AdobeRuntimeWrapper.STATUS_PREPARED():
                    status = constants.STATUS_READY;
                    break;
                case AdobeRuntimeWrapper.STATUS_PLAYING():
                    status = constants.STATUS_PLAYING;
                    break;
                case AdobeRuntimeWrapper.STATUS_SEEKING():
                    status = constants.STATUS_SEEKING;
                    break;
                case AdobeRuntimeWrapper.STATUS_PAUSED():
                    status = constants.STATUS_PAUSED;
                    break;
                case AdobeRuntimeWrapper.STATUS_COMPLETE():
                    status = constants.STATUS_COMPLETE;
                    break;
                case AdobeRuntimeWrapper.STATUS_ERROR():
                    status = constants.STATUS_ERROR;
                    break;
                case AdobeRuntimeWrapper.STATUS_RELEASED():
                    status = constants.STATUS_RELEASED;
                    break;
                // TODO: SUSPENDED ??
                default:
                    break;
            }
        }
        return status;
    }
    // tslint:enable:cyclomatic-complexity

    public getPlayerStatus() {
        return this.mapStateToStatus(this.player.status);
    }

    public getStartPosition() {
        let startpos = this.player.playbackRange.begin;
        if (this._isLive()) {
            startpos = this.sandbox.asset.resumePosition;
        }
        this.logger.trace("getStartPosition: " + startpos);
        return startpos;
    }

    public getSupportedPlaybackSpeeds() {
        const currentItem = this._getCurrentItem();
        if (!currentItem) {
            return [];
        }

        return currentItem.availablePlaybackRates;
    }

    public getVersion() {
        const version = "PSDK_PLAYER_VERSION=" + this.playerVersion;
        this.logger.trace("getVersion: " + version);
        return version;
    }

    public getVideoHeight() {
        //TODO: Assuming view not media..
        const h = this.view.height;
        this.logger.trace("getVideoHeight: " + h);
        return h;
    }

    public getVideoType(): "live" | "vod" {
        let typ: "live" | "vod" = "vod";
        if (this._isLive()) {
            typ = "live";
        }
        this.logger.trace("getVideoType: " + typ);
        return typ;
    }

    public getVideoWidth() {
        //TODO: Assuming View not media..
        const w = this.view.width;
        this.logger.trace("getVideoWidth: " + w);
        return w;
    }

    public hasCC() {
        this.logger.trace("hasCC");
        const currentItem = this._getCurrentItem();
        return currentItem && currentItem.hasClosedCaptions;
    }

    public hasDRM() {
        this.logger.trace("hasDRM");
        let drm = false;
        // note: old PSDK versions called this function "protected", which is a reserved word
        // and hence can't be called. Later it was changed to "isProtected". Try to call that.
        try {
            drm = this.player.currentItem.isProtected;
        } catch (error) {
            this.logger.error("Warning: error occurred while attempting to check current item for DRM: " + JSON.stringify(error) + "; assuming unprotected");
        }
        return drm;
    }

    // PSDKPlayer API's for PlayerPlatformPSDKExtensions

    public setBitratePolicy(policy: number): boolean {
        this.logger.trace("setBitratePolicy: " + policy);

        const currentParams = this.player.abrControlParameters;
        const min = AdobeRuntimeWrapper.ABR_MIN();
        const max = AdobeRuntimeWrapper.ABR_MAX();

        if (min <= policy && policy <= max) {
            this.logger.info("new AdobePSDK.ABRControlParameters: min=" + currentParams.minBitRate +
                " max=" + currentParams.maxBitRate + " policy=" + policy);
            this.player.abrControlParameters = new AdobePSDK.ABRControlParameters(
                currentParams.initialBitRate,
                currentParams.minBitRate,
                currentParams.maxBitRate,
                policy);
            return true;
        } else {
            this.logger.error("setBitratePolicy: invalid policy value: " + policy);
        }
        return false;
    }

    public getCurrentFPS() {
        return this.playbackInformation.frameRate;
    }

    public getCurrentDroppedFrames() {
        return this.playbackInformation.droppedFrameCount;
    }

    public getVolume() {
        return this.player.volume;
    }

    get playbackInformation() {
        return this.qosProvider.playbackInformation || this.qosProvider.getPlaybackInformation();
    }
}

registerModule("PSDKPlayer", PSDKPlayer, {
    children: [
        "CrossStreamPreventionHandler",
        "NetworkDownHandler",
        "VirtualStreamStitcherHandler",
        "PSDKPlayerEvents",
        "XRECCHandler"
    ]
});
