import { PPError } from "../../../PPError";
import { parseQueryParams } from "../../../util/JSUtil";
import { Logger } from "../../../util/Logger";

export enum OttPlaybackTypes {
    Live = "live",
    Vod = "vod"
}

export interface IOttLocatorProperties {
    partnerid: string;
    provider: string;
    mediaaccountname: string;
    mediaguid: string;
    playbacktype?: string;
    airingid?: string;
    simulcastairingid?: string;
    network?: string;
    title?: string;
    espncontenttype?: string;
    feedtype?: string;
    trackingid?: string;
    originalairdate?: string;
    sport?: string;
    genre?: string;
    league?: string;
    seriesid?: string;
}

// expected format partnerId:ott-stream:<provider>:<mediaAccountName>:<mediaGuid>?<queryParam>&<queryParam>...
export class OttLocatorParser {

    private logger: Logger = new Logger("OttLocatorParser");

    public parse(locator: string): IOttLocatorProperties {
        this.logger.trace("original locator: ", locator);
        locator = decodeURIComponent((locator + "").replace(/\+/g, " "));
        this.logger.trace("decoded locator: ", locator);
        return this.mapLocatorQueryParams(locator, this.mapLocatorParams(locator.split("?")[0]));
    }

    private mapLocatorParams(str: string): IOttLocatorProperties {
        const loc: string[] = str.split(":");
        this.validateLocator(loc);

        return {
            partnerid : loc[0],
            provider: loc[2],
            mediaaccountname: loc[3],
            mediaguid: loc [4],
            playbacktype: undefined,
            airingid: undefined,
            simulcastairingid: undefined,
            network: undefined,
            title: undefined,
            espncontenttype: undefined,
            feedtype: undefined,
            trackingid: undefined,
            originalairdate: undefined,
            sport: undefined,
            genre: undefined,
            league: undefined,
            seriesid: undefined
        };
    }

    /**
     * We require all four locator properties to be provided in the correct order, this validator
     * attempts to ensure that none of them are missing
     * @param arr {string[]}
     * @returns void
     */
    private validateLocator(arr: string[]): void {
        if (!arr || arr.length !== 5) {
            throw new PPError(7900, 7, `OTT locator is invalid ${arr}, ensure that the order is correct.`);
        }
        if (!arr[2] || !arr[2].length) {
            throw new PPError(7900, 7, `OTT locator has an invalid provider ${arr}.`);
        }
        if (!arr[3] || !arr[3].length) {
            throw new PPError(7900, 7, `OTT locator has an invalid media account name ${arr}.`);
        }
        if (!arr[4] || !arr[4].length) {
            throw new PPError(7900, 7, `OTT locator has an invalid media guid ${arr}.`);
        }
    }

    /**
     *
     * @param {string} locator
     * @param {locatorObj} locatorObj
     * @returns {IOttLocatorProperties}
     */
    private mapLocatorQueryParams(locator: string, locatorObj: IOttLocatorProperties): IOttLocatorProperties {
        const params = parseQueryParams(locator);
        const properties: string[] = Object.keys(locatorObj);

        for (const key of Object.keys(params)) {
            if (properties.indexOf(key.toLowerCase()) !== -1) {
                locatorObj[key.toLowerCase() as keyof IOttLocatorProperties] = params[key];
                this.logger.trace("set ", key.toLowerCase(), ":", params[key]);
            } else {
                this.logger.warn(key + " is an unknown locator property and was not mapped to anything.");
            }
        }

        if (!locatorObj.espncontenttype) {
            this.logger.warn("espncontenttype was not provided in the locator, using the default value: ", OttPlaybackTypes.Live);
            locatorObj.espncontenttype = OttPlaybackTypes.Live;
        }

        /** We may not always be getting a playbackType, so we are defaulting to live until this is resolved.
         * Sometiemes we are provided with a contentType, so we can try to use that instead as well.
         */
        if (!locatorObj.playbacktype) {
            this.logger.warn("playbacktype was not provided in the locator, using the espncontenttype instead: ", locatorObj.espncontenttype);
            locatorObj.playbacktype = locatorObj.espncontenttype;
        }

        if (locatorObj.playbacktype !== OttPlaybackTypes.Live && locatorObj.playbacktype !== OttPlaybackTypes.Vod) {
            this.logger.warn(`OTT locator playbackType is unrecognized: ${locatorObj.playbacktype}`);
        }

        return locatorObj;
    }
}
