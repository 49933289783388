import { IContentOptions } from "./ContentOptions";
import { BaseAsset, OTT } from "./BaseAsset";
import { OttAsset } from "../services/urlService/overTheTop/OttAsset";
import { VirtualStreamStitcherAsset } from "./VirtualStreamStitcherAsset";

/**
 * Factory class for creating assets
 */
export class AssetFactory {
    public static create(url: string, contentOptions: IContentOptions = {}): BaseAsset {
        let asset: BaseAsset;
        if (contentOptions.vss !== undefined) {
            asset = new VirtualStreamStitcherAsset(url, contentOptions);
        } else if (contentOptions.assetType === OTT) {
            asset = new OttAsset(url, contentOptions);
        } else {
            asset = new BaseAsset(url, contentOptions);
        }
        return asset;
    }
}

