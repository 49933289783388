import { X2JS } from "x2json";
import { PPError, MPX_ERROR } from "./PPError";
import { getNestedProperty } from "./util/JSUtil";
const xml2js = new X2JS();

/**
 * Parses an MPX SMIL response and returns the src URL. If no URL is found or any
 * exception is present, the `error` property will be populated with a `ClientError`.
 *
 * #### MPX suberror codes:
 * - 0: parse error
 * - 10: network error
 * - 20: unavailable error
 * - 21: out of country error
 * - 22: geo-location blocked error
 * - 23: expired error
 */
export class MpxParser {

    public error: PPError;
    private _js: any;

    /**
     * Add description
     * @param xml
     */
    public parse(xml: string): string {
        let url: string = "";
        const js = xml2js.xml_str2json(xml);
        this._js = js;

        const seq = getNestedProperty(js, "smil.body.seq");
        if (seq) {
            const video = seq.par ? seq.par.video : seq.video; // try for video tag, including <par>
            const tag = video || seq.ref; // use <ref> tag if video doesn't exist
            url = tag ? tag._src.replace(/\?.*/, "") : "";
        }

        this.error = this._parseError(url);

        if (this.error) {
            return null;
        }

        return url;
    }

    private _parseError(url: string): PPError {
        if (url.indexOf("/Untitled") !== -1) {
            return new PPError(MPX_ERROR, 21, "Out of country");
        } else if (url.indexOf("/Unavailable") !== -1) {
            return this._getErrorFromException();
        }
    }

    private _getErrorFromException(): PPError {
        const js = this._js;
        let params: any[] = getNestedProperty(js, "smil.body.seq.ref.param");
        if (params) {
            params = Array.isArray(params) ? params : [params];

            // grab first exception
            const exception = params.filter((param: any) => param._name === "exception")[0];

            switch (exception._value) {
                case "GeoLocationBlocked":
                    return new PPError(MPX_ERROR, 22, "Geo location blocked");
                case "Expired":
                    return new PPError(MPX_ERROR, 23, "Expired");
                default:
                    break;
            }
        }

        return new PPError(MPX_ERROR, 20, "MPX URL unavailable");
    }
}
