import { Logger } from "../../util/Logger";
import { IPPModule, IPPSandbox } from "../../PlayerPlatformApplication";
import { SessionManager } from "../../handlers/SessionManager";
import { registerModule } from "../../Application";
import { BaseAsset, AssetUrlType } from "./../../assets/BaseAsset";
import { TAG_IDENTITY_ADS, TAG_MESSAGE_REF } from "../../util/hls/HlsTagFactory";
import { MessageRefTag } from "../../util/hls/MessageRefTag";
import { IdentityAdsTag } from "../../util/hls/IdentityAdsTag";
import { PlacementStatusNotification } from "./PlacementStatusNotification";
import { IPSN, IPSNParams, IPSNDispatcherConfig, IQuartileMap, Quartile } from "./IPlacementStatusNotification";
import { AdEvent, AdStartEvent } from "./../../PlayerPlatformAPIEvents";
import { STATUS_IDLE, SET_ASSET, PRIORITY_MID } from "./../../PlayerPlatformConstants";
import { from, merge, empty, Observable, Subject, Subscription } from "rxjs";
import { ajax, AjaxResponse } from "rxjs/ajax";
import { VideoAd } from "../../ads/VideoAd";
import { HlsTag } from "../../util/hls/HlsTag";
import { filter, distinctUntilChanged, pairwise, take, concatAll, switchMap, map, skip, share, retry, catchError, takeUntil } from "rxjs/operators";

const quartileMap: IQuartileMap = { 25: Quartile.FIRST, 50: Quartile.MIDPOINT, 75: Quartile.THIRD };
const MPD_PSN_TAGS: string[] = ["#EXT-X-IDENTITY-ADS", "#EXT-X-MESSAGE-REF"];

/**
 * PSNDispatcher
 * @constructor
 */
export class PSNDispatcher implements IPPModule<PSNDispatcher> {

    private _logger: Logger = new Logger("PSNDispatcher");
    private _sandbox: IPPSandbox;
    private _acrURL: string;
    private _terminalAddress: string;
    private _messageRef: string;
    private _identityAds: string;

    private _sub: Subscription;
    private _requests: Subject<Observable<AjaxResponse>>;

    public init(sandbox: IPPSandbox) {
        this._sandbox = sandbox;
        const config: IPSNDispatcherConfig = sandbox.parent["cfg"];

        this._acrURL = config.acrURL || sandbox.config.placementStatusNotificationEndPoint;
        this._terminalAddress = config.terminalAddress;

        // setup listening to messageRef and identity tags
        sandbox.subscribe(SET_ASSET, this._onSetAsset, PRIORITY_MID, this);
        const tags: Observable<HlsTag> = sandbox.streams.tags.pipe(takeUntil(sandbox.destroyed), share());

        tags.pipe(filter((tag: HlsTag) => tag.name === TAG_IDENTITY_ADS))
        .subscribe((tag: IdentityAdsTag) => {
            this._identityAds = tag.identityAds;
        });

        tags.pipe(filter((tag: HlsTag) => tag.name === TAG_MESSAGE_REF))
        .subscribe((tag: MessageRefTag) => {
            this._messageRef = tag.messageRef;
         });

        // setup request subject
        this._requests = new Subject<Observable<AjaxResponse>>();

        // setup PSN xml stream
        this._sub = merge(
            this._stops(),
            this._startPlacements(),
            ...this._viewerEvents(),
            this._endPlacements(),
            this._quartiles(),
            this._endAlls()
        ).subscribe(psn => this._send(psn));

        // concat ajax requests and log results
        // requests use concatAll operator in order to send PSNs sequentially
        this._requests.pipe(concatAll()).subscribe(response => {
            this._logger.trace(`${response.status} - commit success`, response.request.body);
        }, err => {
            this._logger.error("PSN xml stream failure", err.message);
        });

        return this;
    }

    /**
     * destroy
     * make sure all previous PSN's are removed and not resent.
     */
    public destroy(sandbox: IPPSandbox) {
        sandbox.remove(SET_ASSET, this._onSetAsset);
        if (this._sub) {
            this._sub.unsubscribe();
        }
        if (this._requests) {
            this._requests.complete();
        }
    }

    private _onSetAsset(asset: BaseAsset) {
        this._identityAds = "";
        this._messageRef = "";

        if (asset.getUrlType() === AssetUrlType.URLTYPE_MPD) {
            for (const tag of MPD_PSN_TAGS) {
                asset.addSubscribedTag(tag);
            }
        }
    }

    /**
     * Create a PSN with a given `AdEvent`. The event is optional
     * in the case of endAll where tracking info is not needed.
     *
     * @private
     * @param {AdEvent} [event]
     * @returns
     */
    private _psn(event?: AdEvent): IPSN {
        let psnParams: IPSNParams = {
            sessionID: SessionManager.instance.playbackSessionId,
            terminalAddress: this._terminalAddress,
            messageRef: this._messageRef,
            identityAds: this._identityAds
        };

        const videoAd: VideoAd = event && event.videoAd;

        if (videoAd) {
            psnParams = {
                ...psnParams,
                tracking: videoAd.id,
                assetRef: videoAd.adAssetInfo
            };
        }

        return PlacementStatusNotification(psnParams);
    }

    /**
     * Creates a PSN startPlacement observable that maps `AdStartEvent`s to startPlacements.
     *
     * @private
     * @returns {Observable<string>}
     */
    private _startPlacements(): Observable<string> {
        return this._sandbox.streams.adStarts
            .pipe(map(event => this._psn(event).startPlacement(event.rate, event.position)));
    }

    /**
     * Creates a PSN endPlacement observable that maps both `AdCompleteEvent`s and
     * `AdExitedEvent`s to endPlacements
     *
     * @private
     * @returns {Observable<string>}
     */
    private _endPlacements(): Observable<string> {
        return merge(
            this._sandbox.streams.adCompletes,
            this._sandbox.streams.adExiteds
        )
            .pipe(
                map(event => this._psn(event).endPlacement(event.rate, event.position))
            );
    }

    /**
     * Creates an observable for the three PSN quartile events:
     *
     * - `firstQuartile` (25%)
     * - `midpoint` (50%)
     * - `thirdQuartile` (75%)
     *
     * These events are only fired during normal playback speed and ignored
     * during trickplay.
     *
     * @private
     * @returns {Observable<string>}
     */
    private _quartiles(): Observable<string> {
        return this._sandbox.streams.adProgresses
            .pipe(
                filter(event => event.rate === 1),      // don't send during trickplay
                pairwise(),                             // pair up new event with previous event
                filter(([event1, event2]) => event1.videoAd.id === event2.videoAd.id), // verify same ad
                switchMap(([event1, event2]) => {
                    return from(Object.keys(quartileMap).map(val => parseInt(val, 10)))
                        .pipe(
                            filter(num => num > event1.progress && num <= event2.progress), // did we pass a quartile?
                            map(num => this._psn(event2)[quartileMap[num]](1, event2.videoAd.duration * (num / 100))) // get appropriate quartile psn
                        );
                })
            );
    }

    /**
     * Crewates a PSN stop ViewerEvent observable. A stop ViewerEvent is created
     * when an `AdExitedEvent` is received when the player state is idle.
     *
     * @private
     * @returns {Observable<string>}
     */
    private _stops(): Observable<string> {
        return this._sandbox.streams.adExiteds
            .pipe(filter(() => this._sandbox.getPlayerStatus() === STATUS_IDLE),
            map(event => this._psn(event).stop(0, event.position)));
    }

    /**
     * Creates an observable for all PSN ViewerEvents except for stop
     * which is handled separately. ViewerEvents for pause, play, fastForward,
     * and rewind are created based on rate changes. The rate changes are tracked
     * by diffing progress events.
     *
     * @private
     * @returns {Observable<string>[]}
     */
    private _viewerEvents(): Observable<string>[] {
        // setup speed change observable that looks at progress events
        // this is switch-mapped to adStarts so rates aren't tracked across different ads
        const speedChanges: Observable<AdStartEvent> = this._sandbox.streams.adStarts
            .pipe(
                switchMap(() => this._sandbox.streams.adProgresses),
                distinctUntilChanged((prev, next) => prev.rate === next.rate),
                skip(1), share() // ignore first distinct event which causes "play" to fire during prerolls
            );

        const pauses = speedChanges.pipe(
            filter(event => event.rate === 0),
            map(event => this._psn(event).pause(event.rate, event.position))
        );

        const plays = speedChanges.pipe(
            filter(event => event.rate === 1),
            map(event => this._psn(event).play(event.rate, event.position))
        );

        const forwards = speedChanges.pipe(
            filter(event => event.rate > 1),
            map(event => this._psn(event).fastForward(event.rate, event.position))
        );

        const rewinds = speedChanges.pipe(
            filter(event => event.rate < 0),
            map(event => this._psn(event).rewind(event.rate, event.position))
        );

        return [pauses, plays, forwards, rewinds];
    }

    /**
     * Creates a PSN endAll observable that gets fired whenever a playback session
     * has ended. Ended is detrermined by a player IDLE state which gets
     * switch-mapped to media opened events to verify a stream actually started
     *
     * @private
     * @returns {Observable<string>}
     */
    private _endAlls(): Observable<string> {
        return this._sandbox.streams.mediaOpeneds
            .pipe(
                switchMap(() => merge(
                                    this._sandbox.streams.getPlayState(STATUS_IDLE),
                                    this._sandbox.streams.mediaEndeds
                                ).pipe(take(1))
                ),
                map(() => this._psn().endAll())
            );
    }

    /**
     * This just adds an ajax request to the request observable which is
     * activated in the `init` method.
     *
     * @private
     * @param {string} xmlStr
     */
    private _send(xmlStr: string): void {
        this._requests.next(
            ajax({
                url: this._acrURL,
                method: "POST",
                body: xmlStr,
                crossDomain: true,
                responseType: "text",
                headers: { "Content-Type": "text/xml" }
            })
            .pipe(
                retry(1),
                catchError(err => {
                    this._logger.error("PSN Request Failure.", err.message);
                    return empty();
                })
            )
        );
    }
}

registerModule("PSNDispatcher", PSNDispatcher);
