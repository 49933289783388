import { ITrackable } from "./ITrackable";
import { ProgressWindow } from "../../util/ProgressWindow";

/**
 * Available events for tracking on a timeline.
 */
export enum TrackerEventType {
    START,
    COMPLETE,
    ENTER,
    EXIT,
    PROGRESS,
    SKIPPED,
    INTERRUPT,
    UPCOMING
}

/**
 * This defines the event fired when a `TrackerEventType` is reached
 * on a timeline.
 */
export interface ITrackerEvent<T extends ITrackable> {
    type: TrackerEventType;
    progress: ProgressWindow;
    trackable: T;
}
