
import { IVideoAdAssetInfo } from "../VideoAd";

export interface IPSNParams {
    sessionID: string;
    terminalAddress: string;
    tracking?: string;
    messageRef?: string;
    identityAds?: string;
    assetRef?: IVideoAdAssetInfo;
}

export interface ISpotScopedEvent {
    Spot: ISpot;
    Events: IEvent;
}

export interface IPSNEvent {
    _time: string;
    _type: string;
    SpotNPT?: ISpotNPT;
    _messageRef?: string;
}

export interface IPlacementStatusNotificationJSON {
    _version: string;
    _xmlns: string;
    "_xmlns:core": string;
    _identity: string;
    _messageId: string;
    _system: string;
    PlayData: IPlayData;
}

export interface IEvent {
    ViewerEvent?: string;
    PlacementStatusEvent?: string;
}

export interface IPSN {
    createEvent: (type: string, scale: string, npt: string) => any;
    startPlacement: (rate: string | number, position: string | number) => any;
    endPlacement: (rate: string | number, position: string | number) => any;
    firstQuartile: (rate: string | number, position: string | number) => any;
    midpoint: (rate: string | number, position: string | number) => any;
    thirdQuartile: (rate: string | number, position: string | number) => any;
    fastForward: (rate: string | number, position: string | number) => any;
    rewind: (rate: string | number, position: string | number) => any;
    play: (rate: string | number, position: string | number) => any;
    pause: (rate: string | number, position: string | number) => any;
    stop: (start: string | number, position: string | number) => any;
    endAll: (type?: string) => any;

    [x: string]: (type: string, scale: string, npt: string) => any;
}

export interface IAvailableViewableEvents {
    fastForward: boolean;
    play: boolean;
    pause: boolean;
    rewind: boolean;
    stop: boolean;
}

export interface IPSNDispatcherConfig {
    terminalAddress: string;
    acrURL: string;
}

export interface IQuartileMap {
    [x: number]: Quartile;
}

export enum Quartile {
    FIRST = "firstQuartile",
    MIDPOINT = "midpoint",
    THIRD = "thirdQuartile"
}

interface ISpotNPT {
    _scale: string;
    __text: string;
}

interface IPlayData {
    _identityADS: string;
    SystemContext: ISystemContext;
    Client: IClient;
    Events?: any;
    SpotScopedEvents?: ISpotScopedEvent;
}

interface ISystemContext {
    Session: string;
}

interface IClient {
    TerminalAddress: ITerminalAddress;
}

interface ITerminalAddress {
    __text: string;
    _type: string;
}

interface ISpot {
    Content: IContent;
}

interface IContent {
    __prefix: string;
    Tracking?: ITracking;
    AssetRef?: IAssetRef;
}

interface ITracking {
    __prefix: string;
    __text: string;
}

interface IAssetRef {
    __prefix: string;
    _assetID: string;
    _providerID: string;
}




