import { AdobeOpportunityGenerator } from "../psdk/AdobeOpportunityGenerator";
import { AdobeContentFactory } from "../psdk/AdobeContentFactory";
import { ICustomAdConfig } from "../IAdConfig";
import { IPPSandbox } from "../../PlayerPlatformApplication";
import { Logger } from "../../util/Logger";

export class CustomAdOpportunityGenerator extends AdobeOpportunityGenerator {

    protected static _logger: Logger = new Logger("CustomAdOpportunityGenerator");

    constructor(_contentFactory: AdobeContentFactory, private cfg: ICustomAdConfig, _sandbox: IPPSandbox) {
        super(_contentFactory, _sandbox);
    }

    protected canResolve(timedMetadata: AdobePSDK.TimedMetadata): boolean {
        return this.cfg.tags.indexOf(timedMetadata.name) !== -1 && !timedMetadata.metadata.hasOwnProperty("ELAPSED");
    }

    protected getTagKey(timedMetadata: AdobePSDK.TimedMetadata): string {
        return timedMetadata.id;
    }

    protected getCUE(timedMetadata: AdobePSDK.TimedMetadata): string {
        return timedMetadata.metadata.CUE + timedMetadata.id;
    }
}
