/*jslint browser: true, plusplus: true, devel: true, sloppy: true, todo: true, white: true */

import { IPPSandbox } from "../../PlayerPlatformApplication";
import { BasePlayer } from "../base/BasePlayer";
import { registerModule } from "../../Application";
import { Logger } from "../../util/Logger";
import { STATUS_IDLE } from "../../PlayerPlatformConstants";

/**
 * NullPlayer Constructor
 */
export class NullPlayer extends BasePlayer {

    constructor() {
        super();
        this.logger = new Logger("NullPlayer");
    }

    public init(sandbox: IPPSandbox): NullPlayer {
        super.init(sandbox);
        this.setPlayerReady();
        return this;
    }

    public getPlayerStatus(): string {
        return STATUS_IDLE;
    }

}

registerModule("NullPlayer", NullPlayer);
