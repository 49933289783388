
const hasOwnProperty: any = Object.prototype.hasOwnProperty;

function has<T>(obj: T, prop: keyof T): boolean {
    return hasOwnProperty.call(obj, prop);
}

import { AdobePSDK } from "./AdobePSDK";

interface IMediaPlayerStatus23 {
    PLAYER_STATUS_IDLE: number;
    PLAYER_STATUS_INITIALIZING: number;
    PLAYER_STATUS_INITIALIZED: number;
    PLAYER_STATUS_PREPARING: number;
    PLAYER_STATUS_PREPARED: number;
    PLAYER_STATUS_PLAYING: number;
    PLAYER_STATUS_PAUSED: number;
    PLAYER_STATUS_SEEKING: number;
    PLAYER_STATUS_COMPLETE: number;
    PLAYER_STATUS_ERROR: number;
    PLAYER_STATUS_RELEASED: number;
}

interface IMediaResource23 {
    TYPE_HLS: number;
    TYPE_DASH: number;
    TYPE_ISOBMFF: number;
}

interface IABRControlParameters23 {
    ABR_POLICY_CONSERVATIVE: number;
    ABR_POLICY_AGGRESIVE: number;
}

interface IAdSignalingMode23 {
    MODE_MANIFEST_CUES: number;
}

interface IAdBreakPolicy23 {
    AD_BREAK_POLICY_PLAY: number;
}

interface IAdBreakWatchedPolicy23 {
    AD_BREAK_WATCHED_ON_END: number;
}

interface IAdPolicy23 {
    AD_POLICY_PLAY_FROM_AD_BREAK_BEGIN: number;
}

interface IPlacement23 {
    TYPE_PRE_ROLL: number;
    TYPE_MID_ROLL: number;
    MODE_INSERT: number;
    MODE_REPLACE: number;
}

interface IAd23 {
    ADTYPE_LINEAR: number;
}

const PSDKEventTypeClass = "PSDKEventType";


export class AdobeRuntimeWrapper {

    public static SUPPORTS_ENTERING_LIVE(): boolean {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) && AdobePSDK.PSDKEventType.CC_ENTERING_LIVE_EVENT !== undefined;
    }

    // MediaPlayerStatus
    public static STATUS_IDLE(): number {
        const key: keyof IMediaPlayerStatus23 = "PLAYER_STATUS_IDLE";
        return has<IMediaPlayerStatus23>(AdobePSDK.MediaPlayerStatus, key) ? AdobePSDK.MediaPlayerStatus[key] : AdobePSDK.MediaPlayerStatus.IDLE;
    }

    public static STATUS_INITIALIZING(): number {
        const key: keyof IMediaPlayerStatus23 = "PLAYER_STATUS_INITIALIZING";
        return has<IMediaPlayerStatus23>(AdobePSDK.MediaPlayerStatus, key) ? AdobePSDK.MediaPlayerStatus[key] : AdobePSDK.MediaPlayerStatus.INITIALIZING;
    }

    public static STATUS_INITIALIZED(): number {
        const key: keyof IMediaPlayerStatus23 = "PLAYER_STATUS_INITIALIZED";
        return has<IMediaPlayerStatus23>(AdobePSDK.MediaPlayerStatus, key) ? AdobePSDK.MediaPlayerStatus[key] : AdobePSDK.MediaPlayerStatus.INITIALIZED;
    }

    public static STATUS_PREPARING(): number {
        const key: keyof IMediaPlayerStatus23 = "PLAYER_STATUS_PREPARING";
        return has<IMediaPlayerStatus23>(AdobePSDK.MediaPlayerStatus, key) ? AdobePSDK.MediaPlayerStatus[key] : AdobePSDK.MediaPlayerStatus.PREPARING;
    }

    public static STATUS_PREPARED(): number {
        const key: keyof IMediaPlayerStatus23 = "PLAYER_STATUS_PREPARED";
        return has<IMediaPlayerStatus23>(AdobePSDK.MediaPlayerStatus, key) ? AdobePSDK.MediaPlayerStatus[key] : AdobePSDK.MediaPlayerStatus.PREPARED;
    }

    public static STATUS_PLAYING(): number {
        const key: keyof IMediaPlayerStatus23 = "PLAYER_STATUS_PLAYING";
        return has<IMediaPlayerStatus23>(AdobePSDK.MediaPlayerStatus, key) ? AdobePSDK.MediaPlayerStatus[key] : AdobePSDK.MediaPlayerStatus.PLAYING;
    }

    public static STATUS_PAUSED(): number {
        const key: keyof IMediaPlayerStatus23 = "PLAYER_STATUS_PAUSED";
        return has<IMediaPlayerStatus23>(AdobePSDK.MediaPlayerStatus, key) ? AdobePSDK.MediaPlayerStatus[key] : AdobePSDK.MediaPlayerStatus.PAUSED;
    }

    public static STATUS_SEEKING(): number {
        const key: keyof IMediaPlayerStatus23 = "PLAYER_STATUS_SEEKING";
        return has<IMediaPlayerStatus23>(AdobePSDK.MediaPlayerStatus, key) ? AdobePSDK.MediaPlayerStatus[key] : AdobePSDK.MediaPlayerStatus.SEEKING;
    }

    public static STATUS_COMPLETE(): number {
        const key: keyof IMediaPlayerStatus23 = "PLAYER_STATUS_COMPLETE";
        return has<IMediaPlayerStatus23>(AdobePSDK.MediaPlayerStatus, key) ? AdobePSDK.MediaPlayerStatus[key] : AdobePSDK.MediaPlayerStatus.COMPLETE;
    }

    public static STATUS_ERROR(): number {
        const key: keyof IMediaPlayerStatus23 = "PLAYER_STATUS_ERROR";
        return has<IMediaPlayerStatus23>(AdobePSDK.MediaPlayerStatus, key) ? AdobePSDK.MediaPlayerStatus[key] : AdobePSDK.MediaPlayerStatus.ERROR;
    }

    public static STATUS_RELEASED(): number {
        const key: keyof IMediaPlayerStatus23 = "PLAYER_STATUS_RELEASED";
        return has<IMediaPlayerStatus23>(AdobePSDK.MediaPlayerStatus, key) ? AdobePSDK.MediaPlayerStatus[key] : AdobePSDK.MediaPlayerStatus.RELEASED;
    }

    public static STATUS_SUSPENDED(): number {
        // TODO: Is this a new status?
        return AdobePSDK.MediaPlayerStatus.SUSPENDED || -1;
    }

    // MediaResource
    public static HLS(): number {
        const key: keyof IMediaResource23 = "TYPE_HLS";
        return has<IMediaResource23>(AdobePSDK.MediaResource, key) ? AdobePSDK.MediaResource[key] : AdobePSDK.MediaResourceType.HLS;
    }

    public static DASH(): number {
        const key: keyof IMediaResource23 = "TYPE_DASH";
        return has<IMediaResource23>(AdobePSDK.MediaResource, key) ? AdobePSDK.MediaResource[key] : AdobePSDK.MediaResourceType.DASH;
    }

    public static ISOBMFF(): number {
        const key: keyof IMediaResource23 = "TYPE_ISOBMFF";
        return has<IMediaResource23>(AdobePSDK.MediaResource, key) ? AdobePSDK.MediaResource[key] : AdobePSDK.MediaResourceType.ISOBMFF;
    }

    public static INVALID_ARGUMENT(): number {
        // TODO: Was this exposed in 2.3 ??
        return has.call(AdobePSDK.PSDKErrorCode, "kECInvalidArgument") ? AdobePSDK.PSDKErrorCode["kECInvalidArgument"] : AdobePSDK.PSDKErrorCode.INVALID_ARGUMENT;
    }

    // ABRPolicies
    public static ABR_MIN(): number {
        const key: keyof IABRControlParameters23 = "ABR_POLICY_CONSERVATIVE";
        return has<IABRControlParameters23>(AdobePSDK.ABRControlParameters, key) ? AdobePSDK.ABRControlParameters[key] : AdobePSDK.ABRControlParameters.CONSERVATIVE_POLICY;
    }

    public static ABR_MAX(): number {
        // NOTE(estobb200): Yes, Adobe misspelled AGGRESSIVE in 2.3. In 2.4 it was spelled correctly.
        const key: keyof IABRControlParameters23 = "ABR_POLICY_AGGRESIVE";
        return has<IABRControlParameters23>(AdobePSDK.ABRControlParameters, key) ? AdobePSDK.ABRControlParameters[key] : AdobePSDK.ABRControlParameters.AGGRESSIVE_POLICY;
    }

    // AdSignalingMode
    public static MANIFEST_CUES(): number {
        const key: keyof IAdSignalingMode23 = "MODE_MANIFEST_CUES";
        return has<IAdSignalingMode23>(AdobePSDK.AdSignalingMode, key) ? AdobePSDK.AdSignalingMode[key] : AdobePSDK.AdSignalingMode.MANIFEST_CUES;
    }

    // AdBreakPolicy
    public static AD_BREAK_POLICY_PLAY(): number {
        const key: keyof IAdBreakPolicy23 = "AD_BREAK_POLICY_PLAY";
        return has<IAdBreakPolicy23>(AdobePSDK.AdBreakPolicy, key) ? AdobePSDK.AdBreakPolicy[key] : AdobePSDK.AdBreakPolicy.PLAY;
    }

    // AdBreakWatchedPolicy
    public static AD_BREAK_WATCHED_ON_END(): number {
        const key: keyof IAdBreakWatchedPolicy23 = "AD_BREAK_WATCHED_ON_END";
        return has<IAdBreakWatchedPolicy23>(AdobePSDK.AdBreakWatchedPolicy, key) ? AdobePSDK.AdBreakWatchedPolicy[key] : AdobePSDK.AdBreakWatchedPolicy.WATCHED_ON_END;
    }

    // AdPolicy
    public static AD_POLICY_PLAY_FROM_AD_BREAK_BEGIN(): number {
        const key: keyof IAdPolicy23 = "AD_POLICY_PLAY_FROM_AD_BREAK_BEGIN";
        return has.call(AdobePSDK.AdPolicy, key) ? AdobePSDK.AdPolicy[key] : AdobePSDK.AdPolicy.PLAY_FROM_AD_BREAK_BEGIN;
    }

    // PlacementType
    public static PLACEMENT_PREROLL(): number {
        const key: keyof IPlacement23 = "TYPE_PRE_ROLL";
        return has<IPlacement23>(AdobePSDK.Placement, key) ? AdobePSDK.Placement[key] : AdobePSDK.PlacementType.PRE_ROLL;
    }

    public static PLACEMENT_MIDROLL(): number {
        const key: keyof IPlacement23 = "TYPE_MID_ROLL";
        return has<IPlacement23>(AdobePSDK.Placement, key) ? AdobePSDK.Placement[key] : AdobePSDK.PlacementType.MID_ROLL;
    }

    // PlacementMode
    public static PLACEMENT_MODE_INSERT(): number {
        const key: keyof IPlacement23 = "MODE_INSERT";
        return has<IPlacement23>(AdobePSDK.Placement, key) ? AdobePSDK.Placement[key] : AdobePSDK.PlacementMode.INSERT;
    }

    public static PLACEMENT_MODE_REPLACE(): number {
        const key: keyof IPlacement23 = "MODE_REPLACE";
        return has<IPlacement23>(AdobePSDK.Placement, key) ? AdobePSDK.Placement[key] : AdobePSDK.PlacementMode.REPLACE;
    }

    // AdType
    public static ADTYPE_LINEAR(): number {
        const key: keyof IAd23 = "ADTYPE_LINEAR";
        return has<IAd23>(AdobePSDK.Ad, key) ? AdobePSDK.Ad[key] : AdobePSDK.AdType.LINEAR;
    }

    // PSDKEventType Comcast Extensions
    public static CC_ENTERING_LIVE_EVENT(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.CC_ENTERING_LIVE_EVENT || "enteringlive" : "enteringlive";
    }

    public static CC_DECODER_AVAILABLE_EVENT(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.CC_DECODER_AVAILABLE_EVENT || "decoderAvailable" : "decoderAvailable";
    }

    // PSDKEventType
    public static FIRST_EVENT(): string {
        // Added in 2.4.1
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.FIRST_EVENT : "psdkFirstEvent";
    }

    public static AD_BREAK_STARTED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.AD_BREAK_STARTED : "adBreakStarted";
    }

    public static AD_BREAK_COMPLETED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.AD_BREAK_COMPLETED : "adBreakCompleted";
    }

    public static AD_BREAK_SKIPPED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.AD_BREAK_SKIPPED : "adBreakSkipped";
    }

    public static AD_CLICK(): string {
        // NOTE(estobb200): They renamed it from click to clicked.
        // It's sort of broken because the type when it's disptached is 'adClick', but to be able
        // to listen to it, it's 'adClicked' in callbacks.cpp.
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.AD_CLICK : "adClicked";
    }

    public static AD_STARTED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.AD_STARTED : "adStarted";
    }

    public static AD_PROGRESS(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.AD_PROGRESS : "adProgress";
    }

    public static AD_COMPLETED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.AD_COMPLETED : "adCompleted";
    }

    public static BUFFERING_BEGIN(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.BUFFERING_BEGIN : "bufferingBegin";
    }

    public static BUFFERING_END(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.BUFFERING_END : "bufferingEnd";
    }

    public static DRM_METADATA_INFO_AVAILABLE(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.DRM_METADATA_INFO_AVAILABLE : "drmMetadataInfoAvailable";
    }

    public static LOAD_INFORMATION_AVAILABLE(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.LOAD_INFORMATION_AVAILABLE : "loadInformationAvailable";
    }

    public static ITEM_CREATED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.ITEM_CREATED : "itemCreated";
    }

    public static CAPTIONS_UPDATED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.CAPTIONS_UPDATED : "captionUpdated";
    }

    public static AUDIO_UPDATED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.AUDIO_UPDATED : "audioUpdated";
    }

    public static PLAYBACK_RANGE_UPDATED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.PLAYBACK_RANGE_UPDATED : "playbackRangeUpdated";
    }

    public static MASTER_UPDATED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.MASTER_UPDATED : "masterUpdated";
    }

    public static STATUS_CHANGED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.STATUS_CHANGED : "statusChanged";
    }

    public static RATE_SELECTED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.RATE_SELECTED : "rateSelected";
    }

    public static RATE_PLAYING(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.RATE_PLAYING : "ratePlaying";
    }

    public static PROFILE_CHANGED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.PROFILE_CHANGED : "profileChanged";
    }

    public static RESERVATION_REACHED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.RESERVATION_REACHED : "reservationReached";
    }

    public static SEEK_POSITION_ADJUSTED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.SEEK_POSITION_ADJUSTED : "seekPositionAdjusted";
    }

    public static SEEK_BEGIN(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.SEEK_BEGIN : "seekBegin";
    }

    public static SEEK_END(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.SEEK_END : "seekEnd";
    }

    public static SIZE_AVAILABLE(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.SIZE_AVAILABLE : "sizeAvailable";
    }

    public static TIME_CHANGED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.TIME_CHANGED : "timeChanged";
    }

    public static TIMED_METADATA_AVAILABLE(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.TIMED_METADATA_AVAILABLE : "timedMetadataAvailable";
    }

    public static TIMELINE_UPDATED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.TIMELINE_UPDATED : "timelineUpdated";
    }

    public static PLAY_START(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.PLAY_START : "playStart";
    }

    public static OPERATION_FAILED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.OPERATION_FAILED : "operationFailed";
    }

    public static TIMED_METADATA_IN_BACKGROUND(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.TIMED_METADATA_IN_BACKGROUND : "timedMetadataInBackground";
    }

    public static TIMED_EVENT(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.TIMED_EVENT : "timedEvent";
    }

    public static BUFFER_PREPARED(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.BUFFER_PREPARED : "bufferPrepared";
    }

    public static AD_RESOLUTION_COMPLETE(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.AD_RESOLUTION_COMPLETE : "adResolutionComplete";
    }

    public static LAST_EVENT(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.LAST_EVENT : "psdkLastEvent";
    }

    public static FIRST_USER_EVENT(): string {
        return hasOwnProperty.call(AdobePSDK, PSDKEventTypeClass) ? AdobePSDK.PSDKEventType.FIRST_USER_EVENT : "psdkFirstUserEvent";
    }

}
