/**
 * This store uses localStorage when available, otherwise it just saves
 * messages in memory.
 */


import { Logger } from "../util/Logger";
import { BaseMessage } from "./Messages";

const KEY = "failedMessages";
const MAX_SIZE = 10000;
const BATCH_SIZE = 1000;
export const FAILED_BATCH_INTERVAL = 60000;

const logger = new Logger("FailedMessageStore");
let messageQueue = isLocalStorageAvailable() ? getSavedMessages() : [];


/**
 * Add messages to queue and write to localStorage if it's available.
 * This sorts messages by EVT.ETS value and then resizes array to be
 * of length <code>MAX_SIZE</code> (default 10000)
 *
 * @param messages
 */
export function addFailedMessages(messages: any) {

    logger.trace("Saving messages in failed message store.");

    if (Array.isArray(messages)) {
        messageQueue = messageQueue.concat(messages);
    } else {
        messageQueue.push(messages);
    }

    // sort by timestamp
    messageQueue.sort(sortByETS);

    // resize
    messageQueue.splice(0, messageQueue.length - MAX_SIZE);

    write();
}

/**
 * Removes <code>num</code> messages from the start of the queue and returns
 * an array. If no <code>num</code> parameter is given, it will return an array
 * of length <code>BATCH_SIZE</code> (default 1000). This method also removes those
 * messages from the queue.
 *
 * @param num - number of messages to retrieve
 * @returns {Array.<Object>|*}
 */
export function getFailedMessages(num: number = BATCH_SIZE) {

    const messages = messageQueue.splice(0, num);
    write();

    return messages;
}

function getSavedMessages() {
    return JSON.parse(localStorage.getItem(KEY)) || [];
}

function write() {
    if (isLocalStorageAvailable()) {
        localStorage.setItem(KEY, JSON.stringify(messageQueue));
    }
}

function isLocalStorageAvailable() {
    const test = "test";
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}

function sortByETS(msg1: BaseMessage, msg2: BaseMessage) {
    return parseInt(msg1.EVT.ETS, 10) - parseInt(msg2.EVT.ETS, 10);
}
