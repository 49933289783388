import { KeySystem } from "@viper/helio/dist/components/eme";
import { IAdConfig } from "../ads/IAdConfig";

// URL pattern of expected URL for FOG service on set top boxes with RDK
export const FOG_RE: RegExp = /tsb\?((clientId=[^&]*&recordedUrl=[^&]*)|(recordedUrl=[^&]*&clientId=[^&]*)){1}/;

// URL pattern of expected URL for manifest manipulator service
export const MM_RE: RegExp = /^(http|https):\/\/mm\./;

// URL pattern of expected URL for xtvapi service assets
export const XTV_RE: RegExp = /^(http|https):\/\/xtvapi\./;

// Stream type
export enum StreamFormat {
    DASH = "DASH",
    HLS = "HLS",
    NULL = "null"
}

/**
 * The IContentOptions are used to provide essential metadata information
 * used by PlayerPlatform when loading and playing an asset. Properties such as
 * `assetEngine` and `assetType` are used to determine which player engine to utilize, while other
 * properties are used to enable unique features for the designated player engine.
 *
 * Some of the properties in the IContentOptions object are also used to determine the mapping
 * for Analytics messages. Please note that the properties for an asset should only map to a
 * single asset type.
 *
 * | IContentOptions Property | Analytics Asset Type and Class |
 * | ----------------------- | ------------------------------ |
 * | `assetID`<br><br>`providerID`<br><br>`mediaGuid` | **PAID** Asset Type. <br>The Asset Class can be **VOD** or **Linear** based on `assetType`. <br>The default value is **VOD**. |
 * | `mediaID` <br><br>`platformID` | **MediaID** Asset Type. <br>The Asset Class can be **VOD** or **Linear** based on `assetType`. <br>The default value is **VOD**. |
 * | `streamId` | **StreamID** Asset Type. <br>The Asset Class is always **Linear**. |
 * | `recordingId` | **RecordingID** Asset Type. <br>The Asset Class is always **cDvr**. |
 * | `easPath` | **EAS_URI** Asset Type. <br>The Asset Class is always **EAS**. |
 *
 */
export interface IContentOptions extends IDeprecatedContentOptions {
    /**
     * Ad config is the structure that holds all the data to initialze an adManager from the
     * factory. Some AdManager implemetations extend the interface for additional initialization
     * data.
     *
     * Example
     * ```
     * let asset = pp.Asset.create(url, {
     *     adConfig: {
     *         type: "manifest",
     *         terminalAddress: "<address>",
     *         acrURL: "<url>"
     *     }
     * });
     * ```
     * See:
     * - [IManifestManipulatorAdConfig](./IManifestManipulatorAdConfig.html)
     * - [IAuditudeAdConfig](./IAuditudeAdConfig.html)
     * - [IC3AdConfig](./IC3AdConfig.html)
     * - [FreeWheelAdConfig](./FreeWheelAdConfig.html)
     */
    adConfig?: IAdConfig;

    /**
     * PlayerPlatform supports playing a variety of media types. The `assetEngine` property can be
     * used to determine which playback engine to utilize. Common values for `assetEngine` include
     * * dashmedia
     * * disney
     * * espn
     * * flash
     * * generic
     * * hulu
     * * nbcUni
     * * psdk
     * * helio
     * * helio_fusion
     *
     * If `assetEngine` is not specified or is set to *generic*, PlayerPlatform will make a "best guess" of the
     * playback engine based upon the file extension of the asset's url.
     *
     */
    assetEngine?: string;

    /**
     * `assetType` is used to determine both the asset class and
     * the regulatory class of the asset and is used in conjunction with other
     * IContentOptions properties when mapping to Analytics asset types.
     *
     * Legal values for `assetType` include
     * * CDVR
     * * DEFAULT
     * * LINEAR
     * * T6_LINEAR
     * * T6_VOD
     * * TVE_LINEAR
     * * TVE_VOD
     * * VOD
     * * OTT
     * * IVOD
     *
     */
    assetType?: string;

    /**
     * options for VSS / Simsub
     */
    vss?: IVirtualStreamStitcherOptions;

    /**
     * The identifier of the asset in the PAID format, which is 4 alpha characters followed by
     * 16 digits (e.g. "MSTR6897825815705097"). Used to specify a PAID asset.
     */
    assetId?: string;

    /**
     * The asset brand used to configure freewheel ads
     */
    brand?: string;

    /**
     * A string that identifies the provider of the asset (e.g. "xfinitylive.com").
     * Used to specify a PAID asset.
     */
    providerId?: string;

    /**
     * The MediaGUID associated with the asset. It is the combination of provider ID, asset ID and
     * content asset ID (e.g. "indemand.comINTL1114000006955675-INMV1114000006955675").
     * Used to specify a PAID asset.
     */
    mediaGuid?: string;

    mediaAccountName?: string;

    /**
     * A string that identifies the asset via a Media ID.
     * Used to specify a MediaID asset.
     */
    mediaId?: string;

    /**
     * A string that identifies the platform identifier for the asset.
     * Used to specify a MediaID asset.
     */
    platformId?: string;

    /**
     * A 19-digit string that identifies the stream identifier for the asset (e.g. "1638480768947242928").
     * Used to specify a StreamId asset.
     */
    streamId?: string;

    /**
     * Recording ID of the asset in the cDVR system (e.g. "2389746239875").
     * Used to specify a RecordingId asset.
     */
    recordingId?: string;

    /**
     * Path to the EAS alert media (e.g. "/tmp/ZCZC-11111-22222+33333/ZCZC-11111-22222+33333.ts").
     * Used to specify a EAS_URI asset.
     */
    easPath?: string;

    /**
     * Indicates the position in milliseconds to resume playing the designated asset.
     * This property is not applicable to all player engines.
     */
    resumePosition?: number;

    /**
     * Indicates the position in milliseconds to halt playing the designated asset.
     * This property is not applicable to all player engines.
     */
    endPosition?: number;

    /**
     * Provides channel name for Disney assets.
     */
    channel?: string;

    /**
     * Provides the authorization token for accessing the asset.
     */
    authToken?: string;

    /**
     * Result obtained from SecClient device authentication
     * see https://github.comcast.com/contentsecurity/spec/blob/master/SecClientApi.md#drm-license
     */
    deviceAuthenticationResult?: IDeviceAuthenticationResult;

    /**
     * Indicates whether to select Dolby Digital audio tracks for the designated asset.
     */
    surround?: boolean;

    /**
     * Designates use of the HTML5 Player engine for the asset.
     */
    html5?: boolean;

    /**
     * Designates whether to attempt using the FlashPlayer engine if the HTML5 Player engine  is unable to play the asset.
     */
    flashFallback?: boolean;

    /**
     * Provides the preferred aspect ratio for viewing this asset. Used by the Hulu Player Engine to select
     * Standard vs Wide Def.
     */
    aspectRatio?: number;

    /**
     * Provides the service location. Optional values include latitude and longitude.
     */
    serviceLocation?: any;

    /**
     * Provides the service zipcode location for the Player Engine.
     */
    serviceZip?: string;

    /**
     * Provides the encrypted service zipcode location for the Player Engine.
     */
    encryptedServiceZip?: string;

    drmKey?: string;

    /**
     * Provides the adId for auditude ad manager.
     */
    adId?: string;

    /**
     * Sets the key system for Helio Player to choose the EME Handler (ex: "com.microsoft.playready")
     */
     keySystem?: KeySystem;

    /**
     * Provides asset metadata used in analytics
     */
    metadata?: IAssetMetadata;

    /**
     * Callback fired when additional auth is required for OTT content
     */
    ottAuthCallback?: OttAuthCallback;

    /**
     * Sets the preference of which streaming format to be used.
     * Valid values: StreamFormat.DASH | StreamFormat.HLS
     *
     * If the perferred streaming format is set, PlayerPlatform will first attempt
     * to play the asset of that format.
     * If PlayerPlatform fails to the play the asset of the prefered format, then it
     * will throw a MediaRetryError with the error code 2.1
     */
    preferredStreamingFormat?: StreamFormat;

    /**
     * fallbackToOriginalStreamingFormat depends on preferredStreamingFormat being set.
     * In the case that the preferred format fails, PlayerPlatform will revert
     * back to the original format that was given.
     */
    fallbackToOriginalStreamingFormat?: boolean;
}

/**
 *  Interface for the function that player platform will call on the hosting app for authentication.  This
 *  function should be provided as ottAuthCallback in asset content options for OTT assets.
 *
 *  @param the asset locator
 *  @returns a promise that resolves to an object with authentication properties including the resolved asset url
 *
 *  example:
 *
 * let ottAsset = Asset.create(
 * "comcast:ott-stream:nbcsports:NBCSports: .... m3u8",
 * {
 *     assetType: "OTT",
 *     ottAuthCallback: (locator) => {
 *         return new Promise((resolve, reject) => {
 *             try {
 *                 let authResult = ....;
 *                 resolve(authResult);
 *             } catch(e) {
 *                 reject(e);
 *             }
 *         });
 *     }
 * }
 */
export type OttAuthCallback = (locator: string) => Promise<IOttAuthResult>;

/**
 *  Interface for object to be returned in promise from ottAuthCallback function.
 */
export interface IOttAuthResult {
    authType: string;
    authorized: boolean;
    caid?: string;
    playbackUrl?: string;
    authToken?: string;
    simulcastAiringId?: string;
    networkName?: string;
}

/**
 * showTitle: title of the show
 * networkName: network name
 * isLongForm: whether the asset is long form
 * series?: series name
 * season?: the number of the season e.g. 1, 3, 7
 * episode?: the number of the episode e.g. 1, 5
 * format?: e.g. "long form", "short form"
 * rating?: parental guideline or MPAA rating
 * ip?: client ip
 * parentCompany?: parent company, e.g. "FOX"
 */
export interface IAssetMetadata {
    showTitle: string;
    networkName: string;
    isLongForm: boolean;
    series?: string;
    season?: string;
    episode?: string;
    format?: string;
    rating?: string;
    aamContentId?: string;
    parentCompany?: string;
}

export function isEAS(options: IContentOptions): boolean {
    return Boolean(options.easPath);
}

/**
 * This will look like the following:
 * ^{ServiceZoneType}:(\d+)$
 */
export type ServiceZone = string;

/**
 *
 * examples:
 *   urn:comcast:location:geo:pc-fsa
 *   urn:comcast:location:geo:zipcode
 *   urn:comcast:location:service:pc-fsa
 *   urn:comcast:location:service:zipcode
 *   urn:comcast:location:vde-syscode
 */
export type ServiceZoneType = string;

export interface IVirtualStreamStitcherOptions {
    travelRightsEnabled?: boolean;
    initialServiceZone?: ServiceZone;

    /**
     * Callback to get a service zone from an application.
     * This is called when an initialServiceZone is not provided
     * or when an EXT-X-SZ-REQUIRED tag is observed in a manifest.
     * If the initialServiceZone is not provided, the vssDefaultServiceZoneType
     * is provided as the service zone type, otherwise the service zone type
     * provided with the EXT-X-SZ-REQUIRED tag is passed. The returned service zone will be appended to the manifest as the sz query string parameter.
     */
    serviceZoneCallback?: ServiceZoneCallback;
}

/**
 * A callback provided by an end user for use with the virtual stream stitcher service.
 * The callback is given the serviceZoneType which player platform believes we need a service zone for.
 * The application calls the callback with either an error or the most recent service zone. This is either called
 * initially when an initialServiceZone is not provided, or during playback when a EXT-X-SZ-REQUIRED tag is
 * observed which indicates a service zone change. If called initially, the defaultServiceZoneType is used.
 */
export type ServiceZoneCallback = (serviceZoneType: ServiceZoneType, resultCallback: (err: any, serviceZone: ServiceZone) => void) => void;

/**
 * Known asset types (global properties)
 */
export const AssetEngine: any = {
    GENERIC: "generic",
    FLASH: "flash",
    PSDK: "psdk",
    HULU: "hulu",
    ESPN: "espn",
    DISNEY: "disney",
    NBCUNI: "nbcUni",
    HELIO: "helio",
    HELIO_FUSION: "helio_fusion",
    AAMP: "aamp"
};

export interface IDeprecatedContentOptions {
    preventModify?: boolean;
    preventRedirect?: boolean;
    isRetry?: boolean;
    isContentPosition?: boolean;
}
