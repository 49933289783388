import { IXuaAsset } from "./Assets";
import { HomeState } from "./IAnalyticsProvider";
import { IPerformance } from "../../src/engines/helio/HelioPerformanceAggregator";

export enum BufferEventTypes {
    INITIAL = "initial",
    SEEK = "seek",
    TRICK_PLAY = "trickplay",
    UNDERFLOW = "underflow",
    REWIND = "rwd",
    PAUSE = "pause",
    PLAY = "play",
    FAST_FORWARD = "ffwd",
    UNKNOWN = "unknown"
}


export interface IApplicationErrorMessage {
    xuaAsset: IXuaAsset;
    errorCode: string;
    errorClass: string;
    errorDescription: string;
    warning: boolean;
    retryCount: number;
    adError: boolean;
    fallback: boolean;
    fallbackType: string;
    manifestUrl: string;
    playerStatus: string;
}

export interface IHeartbeatMessage {
    xuaAsset: IXuaAsset;
    currentPosition: number;
    bitrate: number;
    fps: number;
    cc: string;
    sap: string;
    envInfo: string;
    fragmentInfo?: IFragmentInfo;
}

export interface IEVT {
    ETS: string;
    NAME: string;
    ASSET: IXuaAsset;
    VALUE: IEVTValue;
    NET: INET;
    FRAGMENT?: IEVTValue;
    ENV?: string;
    EAS?: string;
}

export interface ISES {
    PSI?: string;
    PBI?: number;
}

export interface IFragmentInfo {
    fragmentUrl: string;
    count: number; //
    fragmentSize: number; //
    downloadDuration: number; //
    fragmentDuration: number; //
}

interface INET {
    IHS: HomeState;
    SECURITY?: string;
}

interface IEVTValue {
    POS?: number;
    XBR?: number;
    XFS?: number;
    CC?: string;
    SAP?: string;
    PLAYER_ID?: string;
    PLAYER_VER?: string;
    ORGANIZATION?: string;
    NATIVE?: boolean;
    SUPPORT_VOD?: boolean;
    SUPPORT_LINEAR?: boolean;
    SUPPORT_CDVR?: boolean;
    MANIF?: string;
    LAT?: number;
    NUMADS?: number;
    CODE?: number | string;
    DESCR?: string;
    AD_ERROR?: boolean;
    STATUS?: string;
    VAL?: string | number;
    AD_SERVICE?: string;
    AD_ID?: string;
    LATENCY_BREAKDOWN?: IPerformance;
    URL?: string;
    FSZ?: string | number;
    DLD?: string | number;
    DUR?: number;
    WARNING?: boolean;
    CLASS?: string;
    RETRY?: number;
    FALLBACK?: boolean;
    FALLBACK_TYPE?: string;
    ACT?: string;
    LANG?: string;
    URI?: string;
    TYPE?: BufferEventTypes;
    STATE?: string;
    START?: number;
    DURATION?: number;
    NOF?: number;
}

