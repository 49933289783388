import * as events from "../../PlayerPlatformAPIEvents";

import { IPPSandbox } from "../../PlayerPlatformApplication";
import { toObservable as sandboxObservable } from "../../Application";
import { Logger } from "../../util/Logger";
import { VideoAd } from "../../ads/VideoAd";
import { VideoAdBreak } from "../../ads/VideoAdBreak";
import { PPError } from "../../PPError";
import { IAdBreak } from "../IAdSlot";
import * as constants from "../../PlayerPlatformConstants";
import { takeUntil, withLatestFrom } from "rxjs/operators";

/**
 * AdobeContentTracker creates instances AdobePSDK.ContentTracker
 * and attaches it's functions as the callbacks.
 * It's purpose is to dispatch/emit AdEvent* objects back to
 * PlayerPlatformAPIEvents.
 */
export class AdobeContentTracker {

    private static _logger: Logger = new Logger("AdobeContentTracker");
    private _videoAds: VideoAd[] = [];
    private _adsById: { [x: string]: IAdAsset } = {};
    // A time value at which the Ad's should be inserted. Adobe uses this as
    // the timeline's hold time, it may be overriden when the AdBreak callback
    // fires.
    private _opportunityStartTime: number;

    constructor(psdkOpportunity: AdobePSDK.Opportunity, private _sandbox: IPPSandbox, adBreak: IAdBreak) {
        this._opportunityStartTime = psdkOpportunity.placement.time;

        let startTime: number = this._opportunityStartTime;
        for (const ad of adBreak.ads) {
            const adAsset: IAdAsset = {
                id: ad.id,
                url: ad.manifestUrl,
                duration: ad.duration
            };
            this._adsById[ad.id] = adAsset;
            this._videoAds.push(this._createVideoAd(adAsset, startTime));

            startTime += adAsset.duration;
        }
    }


    private _createVideoAd(ad: IAdAsset, startTime: number) {

        const adDuration = ad.duration !== undefined ? ad.duration : 0;
        const adStartTime = startTime !== undefined ? startTime : 0;
        return new VideoAd(
            ad.id,
            adStartTime,
            adDuration
        );
    }

    private _getVideoAdById(adobeAd: AdobePSDK.Ad): VideoAd {
        let videoAd = this._videoAds.filter(ad => ad.id === adobeAd.id).pop();
        if (videoAd === undefined) {
            AdobeContentTracker._logger.warn("Cannot find Video ad with ID =" + adobeAd.id + ". Generating new VideoAd.");
            videoAd = new VideoAd(
                adobeAd.id,
                0,
                0
            );
        }
        return videoAd;
    }

    private _getVideoAdBreak(adBreak: AdobePSDK.AdBreak): VideoAdBreak {
        const ads = adBreak.ads.map(this._getVideoAdById.bind(this))
                        .filter((ad: VideoAd) => this._adsById.hasOwnProperty(ad.id));

        if (ads.length !== adBreak.ads.length) {
            AdobeContentTracker._logger.warn("Ads did not map fully back from Adobe");
        }

        return new VideoAdBreak(ads as VideoAd[]);
    }

    private _getStartAdEvent(ad: AdobePSDK.Ad): events.AdStartEvent {
        const currentPosition = this._sandbox.getCurrentPosition();
        const videoAd = this._getVideoAdById(ad);

        return new events.AdStartEvent(videoAd, 0,
            this._sandbox.getCurrentPlaybackSpeed(),
            currentPosition - videoAd.startTime
        );
    }

    private _getStartAdBreakEvent(adBreak: AdobePSDK.AdBreak): events.AdBreakStartEvent {
        return new events.AdBreakStartEvent(
            this._getVideoAdBreak(adBreak)
        );
    }

    private _getCompleteAdBreakEvent(adBreak: AdobePSDK.AdBreak): events.AdBreakCompleteEvent {
        return new events.AdBreakCompleteEvent(
            this._getVideoAdBreak(adBreak)
        );
    }

    private _getCompleteAdEvent(ad: AdobePSDK.Ad): events.AdCompleteEvent {
        const currentPosition = this._sandbox.getCurrentPosition();
        const videoAd: VideoAd = this._getVideoAdById(ad);
        videoAd.incrementSeenCount();
        videoAd.watched = true;
        return new events.AdCompleteEvent(
            videoAd,
            100,
            this._sandbox.getCurrentPlaybackSpeed(),
            currentPosition - videoAd.startTime
        );
    }

    private _getProgressAdEvent(progress: number, ad: AdobePSDK.Ad): events.AdProgressEvent {
        const currentPosition = this._sandbox.getCurrentPosition();
        const videoAd: VideoAd = this._getVideoAdById(ad);
        return new events.AdProgressEvent(
            videoAd,
            progress,
            this._sandbox.getCurrentPlaybackSpeed(),
            currentPosition - videoAd.startTime
        );
    }

    private _onAdStart(ad: AdobePSDK.Ad): void {
        sandboxObservable(constants.STOP, { priority: 1 })
            .pipe(takeUntil(events.toObservable(events.AD_COMPLETE)))
            .subscribe(() => this._onAdComplete(ad));

        this._dispatchEvent(this._getStartAdEvent(ad));
    }

    private _onAdComplete(ad: AdobePSDK.Ad): void {
        this._dispatchEvent(this._getCompleteAdEvent(ad));
    }

    private _onAdProgress(ad: AdobePSDK.Ad, progress: number): void {
        this._dispatchEvent(this._getProgressAdEvent(progress, ad));
    }

    private _onAdClick(_: AdobePSDK.Ad): void {
        // This stub must exist or AdobePSDK will crash the set top box
        // if an ad is clicked
    }

    private _onAdBreakStart(adBreak: AdobePSDK.AdBreak): void {
        const currentPosition = this._sandbox.getCurrentPosition();

        events.toObservable(events.AD_COMPLETE)
              .pipe(
                  takeUntil(events.toObservable(events.AD_BREAK_COMPLETE)),
                  withLatestFrom(sandboxObservable(constants.STOP, { priority: 1 }),
                    (completeEvent, _stopEvent) => completeEvent)
              )
              .subscribe(() => this._onAdBreakComplete(adBreak));

        if (currentPosition > this._opportunityStartTime) {
            // Occasionally the opportunity.placement.time is used as a
            // placeholder for ad inseration, it's not clear from the PSDK
            // when the actual Ad start time occurs. If the current position
            // is beyond the opportunity.placement.time we use currentTime
            // instead.
            // This is also linked to us possibly resovling the opportunity
            // twice in the update callback, and is probably also linked to the
            // issue where we make a request for the same signal mutliple times.
            // See VPLAY-470 & VPLAY-604
            const delta = (currentPosition - this._opportunityStartTime) / 1000;
            AdobeContentTracker._logger.warn(`currentPosition: ${currentPosition} > this._opportunityStartTime: ${this._opportunityStartTime} delta: ${delta}`);
            this._opportunityStartTime = currentPosition;
        }
        this._dispatchEvent(this._getStartAdBreakEvent(adBreak));
    }

    private _onAdBreakComplete(adBreak: AdobePSDK.AdBreak): void {
        this._dispatchEvent(this._getCompleteAdBreakEvent(adBreak));
    }

    private _dispatchEvent(event: events.PlayerPlatformAPIEvent): void {
        events.emit(event);
    }

    private _onAdError(ad: AdobePSDK.Ad): void {
        const error = new PPError(7561, 0, `Loading of ad with id "${ad.id}" failed.`);
        this._dispatchEvent(new events.AdErrorEvent(this._getVideoAdById(ad), error));
    }

    public makeContentTracker(): AdobePSDK.ContentTracker {
        const contentTracker = new AdobePSDK.ContentTracker();
        contentTracker.onAdStartCallbackFunc = this._onAdStart.bind(this);
        contentTracker.onAdCompleteCallbackFunc = this._onAdComplete.bind(this);
        contentTracker.onAdErrorCallbackFunc = this._onAdError.bind(this);
        contentTracker.onAdProgressCallbackFunc = this._onAdProgress.bind(this);
        contentTracker.onAdClickCallbackFunc = this._onAdClick.bind(this);
        contentTracker.onAdBreakStartCallbackFunc = this._onAdBreakStart.bind(this);
        contentTracker.onAdBreakCompleteCallbackFunc = this._onAdBreakComplete.bind(this);
        return contentTracker;
    }

}
