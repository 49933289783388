import "./HomeNetworkAnalyticsHandler";
import { Logger } from "../util/Logger";
import { IPPModule, IPPSandbox } from "../PlayerPlatformApplication";
import * as events from "../PlayerPlatformAPIEvents";
import * as constants from "../PlayerPlatformConstants";
import * as messages from "../analytics/Messages";
import { BufferEventTypes, IFragmentInfo } from "../analytics/IMessages";
import { ConfigurationManager } from "../ConfigurationManager";
import { registerModule } from "../Application";
import { PPError } from "../PPError";
import { PlaybackStateMonitor } from "../analytics/PlaybackStateMonitor";
import { AnalyticsProvider } from "../analytics/AnalyticsProvider";
import { IHostInfo } from "../analytics/IAnalyticsProvider";
import { STATUS_IDLE, STATUS_ERROR } from "../PlayerPlatformConstants";
import { IPerformance } from "../../src/engines/helio/HelioPerformanceAggregator";
import { XuaAssetFactory } from "../analytics/XuaAssetFactory";
import { Observable, interval, from, fromEvent, merge } from "rxjs";
import { distinctUntilChanged, distinctUntilKeyChanged, tap, filter, map, mapTo, pairwise, share, takeUntil, withLatestFrom } from "rxjs/operators";
import { AdManagerTypes } from "../ads/IAdConfig";
import { FlashMediaWarningEvent } from "../engines/flash/FlashPlayerEvents";

class FragmentInfo {
    public downloadDuration: number;
    public fragmentSize: number;
    public fragmentDuration: number;
    public count: number;
    public fragmentUrl: string;

    constructor() {
        this.reset();
    }

    public reset() {
        this.downloadDuration = 0;
        this.fragmentSize = 0;
        this.fragmentDuration = 0;
        this.count = 0;
        this.fragmentUrl = "";
    }
}

interface ISeekInfoObject {
    seeking: boolean;
    position: number;
}

const AdServiceMap: { [AdManagerTypes.AUDITUDE]: string,
                      [AdManagerTypes.C3]: string,
                      [AdManagerTypes.MANIFEST]: string,
                      [AdManagerTypes.TVELINEAR]: string,
                      [AdManagerTypes.FREEWHEELVOD]: string } = {
    [AdManagerTypes.AUDITUDE]: "Auditude",
    [AdManagerTypes.C3]: "C3",
    [AdManagerTypes.MANIFEST]: "VEX",
    [AdManagerTypes.TVELINEAR]: "FreeWheel",
    [AdManagerTypes.FREEWHEELVOD]: "FreeWheel"
};

/**
 * Listens for events and creates appropriate analytics messages.
 */
export class AnalyticsHandler implements IPPModule<AnalyticsHandler> {

    private _logger: Logger = new Logger("AnalyticsHandler");
    private _sandbox: IPPSandbox;
    private _analyticsProvider: AnalyticsProvider;
    private _fragmentInfo = new FragmentInfo();
    private _lastFPS = 0;
    private _fpsThreshold = 2;
    private _quartileSent = -1;
    private _xuaAssetFactory: XuaAssetFactory;
    private _bufferStartStream: Observable<events.BufferStartEvent>;
    private _bufferCompleteStream: Observable<events.BufferCompleteEvent>;
    private _seekStartStream: Observable<events.SeekStartEvent>;
    private _seekCompleteStream: Observable<events.SeekCompleteEvent>;
    private static _instance: AnalyticsHandler;
    public hostInfo: IHostInfo;
    public _playbackStateMonitor: PlaybackStateMonitor;

    public static get instance(): AnalyticsHandler {
        return AnalyticsHandler._instance;
    }

    public get analyticsProvider(): AnalyticsProvider {
        return this._analyticsProvider;
    }

    public init(sandbox: IPPSandbox, hostInfo: IHostInfo, analyticsProvider: AnalyticsProvider, playbackStateMonitor: PlaybackStateMonitor) {
        this._sandbox = sandbox;
        this._analyticsProvider = analyticsProvider;
        this._playbackStateMonitor = playbackStateMonitor;
        this._bufferStartStream = fromEvent<[events.BufferStartEvent]>(events, events.BUFFER_START).pipe(map(([e]) => e));
        this._bufferCompleteStream = fromEvent<[events.BufferCompleteEvent]>(events, events.BUFFER_COMPLETE).pipe(map(([e]) => e));
        this._seekStartStream = fromEvent<[events.SeekStartEvent]>(events, events.SEEK_START).pipe(map(([e]) => e));
        this._seekCompleteStream = fromEvent<[events.SeekCompleteEvent]>(events, events.SEEK_COMPLETE).pipe(map(([e]) => e));
        this._xuaAssetFactory = new XuaAssetFactory();

        this.addEventListeners();
        sandbox.subscribe(constants.SET_ASSET, this.onOpeningMedia, constants.PRIORITY_DEFAULT, this);
        sandbox.subscribe(constants.MEDIA_INFO, this.onMediaInfo, constants.PRIORITY_DEFAULT, this);
        sandbox.subscribe(constants.ENGINE_SELECTED, this.onEngineSelected, constants.PRIORITY_DEFAULT, this);
        sandbox.subscribe(constants.PERFORMANCE_MESSAGE, this.onPerformance, constants.PRIORITY_DEFAULT, this);
        this._bufferSubscribe();

        hostInfo.appName = hostInfo.appName || sandbox.config[ConfigurationManager.ANALYTICS_DEVICE_TYPE];

        if (!hostInfo.appName) {
            this._logger.error("No app name provided for analytics.");
        }

        analyticsProvider.configureAnalytics(hostInfo, {
            analyticsProtocol: sandbox.config[ConfigurationManager.ANALYTICS_PROTOCOL],
            maxBatchSize: sandbox.config[ConfigurationManager.MAX_BATCH_SIZE],
            maxQueueSize: sandbox.config[ConfigurationManager.MAX_QUEUE_SIZE],
            batchInterval: sandbox.config[ConfigurationManager.BATCH_INTERVAL],
            analyticsUrl: sandbox.config[ConfigurationManager.ANALYTICS_END_POINT]
        });

        this._setupHeartbeat();

        this._playbackStateMonitor.init();

        this.hostInfo = hostInfo;

        AnalyticsHandler._instance = this;

        return this;
    }

    public destroy(sandbox: IPPSandbox) {
        this.removeEventListeners();
        sandbox.remove(constants.SET_ASSET, this.onOpeningMedia);
        sandbox.remove(constants.MEDIA_INFO, this.onMediaInfo);
        sandbox.remove(constants.ENGINE_SELECTED, this.onEngineSelected);
        sandbox.remove(constants.PERFORMANCE_MESSAGE, this.onPerformance);

        this._analyticsProvider.disable();
        this._playbackStateMonitor.destroy();
        AnalyticsHandler._instance = undefined;
    }

    private addEventListeners() {
        events.addEventListener(events.AD_START, this.onAdStart, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.AD_PROGRESS, this.onAdProgress, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.AD_COMPLETE, this.onAdComplete, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.AD_EXITED, this.onAdExited, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.AD_BREAK_START, this.onAdBreakStart, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.AD_BREAK_COMPLETE, this.onAdBreakComplete, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.AD_BREAK_EXITED, this.onAdBreakExited, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.BITRATE_CHANGED, this.onBitrateChanged, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.FPS_CHANGED, this.onFPSChanged, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.FRAGMENT_INFO, this.onFragmentInfo, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.MEDIA_FALLBACK, this.onMediaFallback, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.MEDIA_ENDED, this.onMediaEnded, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.MEDIA_FAILED, this.onMediaFailed, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.MEDIA_OPENED, this.onMediaOpened, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.MEDIA_RETRY, this.onMediaRetry, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.MEDIA_WARNING, this.onMediaWarning, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.PLAYBACK_SPEED_CHANGED, this.onPlaybackSpeedChanged, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.PLAYBACK_STARTED, this.onPlaybackStarted, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.PLAY_STATE_CHANGED, this.onPlayStateChanged, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.SEEK_COMPLETE, this.onSeekComplete, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.SEEK_START, this.onSeekStart, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.EMERGENCY_ALERT_IDENTIFIED, this.onEmergencyAlertIdentified, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.EMERGENCY_ALERT_STARTED, this.onEmergencyAlertStarted, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.EMERGENCY_ALERT_COMPLETE, this.onEmergencyAlertCompleted, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.EMERGENCY_ALERT_FAILURE, this.onEmergencyAlertFailed, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.EMERGENCY_ALERT_EXEMPTED, this.onEmergencyAlertExempted, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.EMERGENCY_ALERT_ERRORED, this.onEmergencyAlertErrored, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.STREAM_SWITCH, this.onStreamSwitch, constants.PRIORITY_DEFAULT, this);
    }

    private removeEventListeners() {
        events.removeEventListener(events.AD_START, this.onAdStart);
        events.removeEventListener(events.AD_PROGRESS, this.onAdProgress);
        events.removeEventListener(events.AD_COMPLETE, this.onAdComplete);
        events.removeEventListener(events.AD_EXITED, this.onAdExited);
        events.removeEventListener(events.AD_BREAK_START, this.onAdBreakStart);
        events.removeEventListener(events.AD_BREAK_COMPLETE, this.onAdBreakComplete);
        events.removeEventListener(events.AD_BREAK_EXITED, this.onAdBreakExited);
        events.removeEventListener(events.BITRATE_CHANGED, this.onBitrateChanged);
        events.removeEventListener(events.FPS_CHANGED, this.onFPSChanged);
        events.removeEventListener(events.FRAGMENT_INFO, this.onFragmentInfo);
        events.removeEventListener(events.MEDIA_FALLBACK, this.onMediaFallback);
        events.removeEventListener(events.MEDIA_ENDED, this.onMediaEnded);
        events.removeEventListener(events.MEDIA_FAILED, this.onMediaFailed);
        events.removeEventListener(events.MEDIA_OPENED, this.onMediaOpened);
        events.removeEventListener(events.MEDIA_RETRY, this.onMediaRetry);
        events.removeEventListener(events.MEDIA_WARNING, this.onMediaWarning);
        events.removeEventListener(events.PLAYBACK_SPEED_CHANGED, this.onPlaybackSpeedChanged);
        events.removeEventListener(events.PLAYBACK_STARTED, this.onPlaybackStarted);
        events.removeEventListener(events.PLAY_STATE_CHANGED, this.onPlayStateChanged);
        events.removeEventListener(events.SEEK_COMPLETE, this.onSeekComplete);
        events.removeEventListener(events.SEEK_START, this.onSeekStart);
        events.removeEventListener(events.EMERGENCY_ALERT_IDENTIFIED, this.onEmergencyAlertIdentified);
        events.removeEventListener(events.EMERGENCY_ALERT_STARTED, this.onEmergencyAlertStarted);
        events.removeEventListener(events.EMERGENCY_ALERT_COMPLETE, this.onEmergencyAlertCompleted);
        events.removeEventListener(events.EMERGENCY_ALERT_FAILURE, this.onEmergencyAlertFailed);
        events.removeEventListener(events.EMERGENCY_ALERT_EXEMPTED, this.onEmergencyAlertExempted);
        events.removeEventListener(events.EMERGENCY_ALERT_ERRORED, this.onEmergencyAlertErrored);
        events.removeEventListener(events.STREAM_SWITCH, this.onStreamSwitch);
    }

    private _setupHeartbeat() {
        interval(this._sandbox.config.heartbeatInterval)
            .pipe(
                filter(() => this._sandbox.getPlayerStatus() !== STATUS_IDLE && this._sandbox.getPlayerStatus() !== STATUS_ERROR),
                takeUntil(this._sandbox.destroyed)
            )
            .subscribe(
                () => this.onHeartBeat(),
                (err) => this._logger.error("error with heartbeat", err),
                () => this._logger.trace("completed heartbeat")
            );
    }

    /**
     * _bufferSubscribe creates a buffer event type stream and passes that type
     * into bufferStart bufferEnd events before dispatching.
     *
     * Points worth mentioning on the bufferTypeStream, any time media is opened
     * the value is "initial", until the playhead has moved, then it becomes "underflow".
     * Media opened can occur n times and will remain subscribed to return the proper buffer types.
     * If a seek occurred the playhead has to move from the seek complete position
     * to become "underflow" again.
     */
    private _bufferSubscribe(): void {

        // SeekStream is an observable of SeekInfoObject's, prior to a seek
        // firing we merge in {false, NEGATIVE_INFINITY} so nothing is stuck
        // waiting for a seek event.
        const seekStream: Observable<ISeekInfoObject> = merge(
            from([{ seeking: false, position: Number.NEGATIVE_INFINITY }]),
            this._seekStartStream
                .pipe(map((start: events.SeekStartEvent) => {
                    return {
                        seeking: true,
                        position: start.position
                    };
                })),
            this._seekCompleteStream
                .pipe(map((end: events.SeekCompleteEvent) => {
                    return {
                        seeking: false,
                        position: end.position
                    };
                }))
        );

        // bufferTypeStream is an observable of BufferEventType's defined in
        // `src/analytics/Messages`. These are distinctUtilChanged.
        const bufferTypeStream: Observable<BufferEventTypes> = merge<BufferEventTypes>(
            this._sandbox.streams.mediaOpeneds.pipe(mapTo<events.MediaOpenedEvent, BufferEventTypes>(BufferEventTypes.INITIAL)),
            this._sandbox.streams.mediaProgresses
                .pipe(
                    distinctUntilKeyChanged("position"),
                    pairwise(),
                    withLatestFrom(seekStream, (mediaProgressEvents: events.MediaProgressEvent[], seekInfo: ISeekInfoObject): BufferEventTypes => {
                        const { seeking, position } = seekInfo;
                        // It's still considered a seek BufferEventType until the position
                        // has changed from the seek event.
                        if (seeking || mediaProgressEvents[1].position === position) {
                            return BufferEventTypes.SEEK;
                        } else if (mediaProgressEvents[1].playbackSpeed < 0 || mediaProgressEvents[1].playbackSpeed > 1) {
                            return BufferEventTypes.TRICK_PLAY;
                        }
                        return BufferEventTypes.UNDERFLOW;
                    })
                ),
            this._seekStartStream.pipe(mapTo<events.SeekStartEvent, BufferEventTypes>(BufferEventTypes.SEEK))
        ).pipe(distinctUntilChanged());

        // bufferStartMessageStream is an Observale that merges bufferStart w/ bufferType
        // to return a bufferStartMessage. This message is used in the complete to extract
        // start values, and it's also shared with the final subscription to submit to
        // analytics provider.
        const bufferStartMessageStream: Observable<messages.BufferStartMessage> = this._bufferStartStream
            .pipe(withLatestFrom(bufferTypeStream, (event: events.BufferStartEvent, bufferEventType: BufferEventTypes) => {
                this._logger.trace("onBufferStart: " + JSON.stringify(event) + " type: " + bufferEventType);
                return new messages.BufferStartMessage(
                    this._xuaAssetFactory.create(this._sandbox.asset),
                    bufferEventType,
                    this._sandbox.getCurrentPosition());
            }), share());

        // The final start and complete messages, subscribed to and passed
        // the analyticsProvider.
        merge(
            bufferStartMessageStream,
            this._bufferCompleteStream
                .pipe(withLatestFrom(bufferStartMessageStream, (event: events.BufferCompleteEvent, startMessage: messages.BufferStartMessage) => {
                    this._logger.trace("onBufferEnd: " + JSON.stringify(event) + " type: " + startMessage.EVT.VALUE.TYPE);
                    return new messages.BufferCompleteMessage(
                        startMessage.EVT.ASSET,
                        startMessage.EVT.VALUE.TYPE,
                        startMessage.EVT.VALUE.START,
                        this._sandbox.getCurrentPosition());
                }))
        ).pipe(takeUntil(this._sandbox.destroyed.pipe(tap(() => this._logger.trace("Analytics Handler: onBuffer[Start|Complete] subscription destroyed")))))
            .subscribe((message: messages.BaseMessage) => {
                this._analyticsProvider.buildMessage(message);
            });
    }

    private onAdStart(event: events.AdStartEvent) {
        this._logger.trace("onAdStart: " + JSON.stringify(event));

        this._quartileSent = 0;
        const adProgressMessage = new messages.AdProgressMessage(
                this._xuaAssetFactory.create(this._sandbox.asset),
                0,
                this._sandbox.asset.adConfig ? AdServiceMap[this._sandbox.asset.adConfig.type] : undefined,
                event.videoAd ? event.videoAd.id : undefined);
        this._analyticsProvider.buildMessage(adProgressMessage);
    }

    // tslint:disable:cyclomatic-complexity
    private onAdProgress(event: events.AdProgressEvent) {

        const lastQuartile = this._quartileSent;

        if ((event.progress >= 0) && (this._quartileSent < 0)) {
            this._quartileSent = 0;
        }
        if ((event.progress >= 25) && (this._quartileSent < 1)) {
            this._quartileSent = 1;
        }
        if ((event.progress >= 50) && (this._quartileSent < 2)) {
            this._quartileSent = 2;
        }
        if ((event.progress >= 75) && (this._quartileSent < 3)) {
            this._quartileSent = 3;
        }
        if ((event.progress >= 100) && (this._quartileSent < 4)) {
            this._quartileSent = 4;
        }

        if (lastQuartile !== this._quartileSent) {
            this._logger.trace("onAdProgress: " + JSON.stringify(event));
            const adProgressMessage = new messages.AdProgressMessage(
                this._xuaAssetFactory.create(this._sandbox.asset),
                this._quartileSent * 25,
                this._sandbox.asset.adConfig ? AdServiceMap[this._sandbox.asset.adConfig.type] : undefined,
                event.videoAd ? event.videoAd.id : undefined);
            this._analyticsProvider.buildMessage(adProgressMessage);
        }
    }
    // tslint:enable:cyclomatic-complexity

    private onAdComplete(event: events.AdCompleteEvent) {
        this._logger.trace("onAdComplete: " + JSON.stringify(event));

        if (this._quartileSent < 4) {
            this._quartileSent = 4;
            const adProgressMessage = new messages.AdProgressMessage(
                this._xuaAssetFactory.create(this._sandbox.asset),
                this._quartileSent * 25,
                this._sandbox.asset.adConfig ? AdServiceMap[this._sandbox.asset.adConfig.type] : undefined,
                event.videoAd ? event.videoAd.id : undefined);
            this._analyticsProvider.buildMessage(adProgressMessage);
        }
    }

    private onAdExited(event: events.AdExitedEvent) {
        this._logger.trace("onAdExited: " + JSON.stringify(event));

        const adExitedMessage = new messages.AdExitedMessage(
            this._xuaAssetFactory.create(this._sandbox.asset));
        this._analyticsProvider.buildMessage(adExitedMessage);
    }

    private onAdBreakStart(event: events.AdBreakStartEvent) {
        this._logger.trace("onAdBreakStart: " + JSON.stringify(event));

        this._quartileSent = -1;
        const adBreakStartMessage = new messages.AdBreakStartMessage(
            this._xuaAssetFactory.create(this._sandbox.asset));
        this._analyticsProvider.buildMessage(adBreakStartMessage);
    }

    private onAdBreakComplete(event: events.AdBreakCompleteEvent) {
        this._logger.trace("onAdBreakComplete: " + JSON.stringify(event));

        if (this._quartileSent < 4) {
            this._quartileSent = 4;
            const adProgressMessage = new messages.AdProgressMessage(
                this._xuaAssetFactory.create(this._sandbox.asset),
                this._quartileSent * 25,
                this._sandbox.asset.adConfig ? AdServiceMap[this._sandbox.asset.adConfig.type] : undefined,
                (event.videoAdBreak.ads.length > 0) ? event.videoAdBreak.ads[event.videoAdBreak.ads.length - 1].id : undefined);
            this._analyticsProvider.buildMessage(adProgressMessage);
        }

        const adBreakCompleteMessage = new messages.AdBreakCompleteMessage(
            this._xuaAssetFactory.create(this._sandbox.asset));
        this._analyticsProvider.buildMessage(adBreakCompleteMessage);
    }

    private onAdBreakExited(event: events.AdBreakExitedEvent) {
        this._logger.trace("onAdBreakExited: " + JSON.stringify(event));

        this._analyticsProvider.buildMessage(new messages.AdBreakExitedMessage(
            this._xuaAssetFactory.create(this._sandbox.asset)
        ));
    }

    private onBitrateChanged(event: events.BitrateChangedEvent) {
        this._logger.trace("onBitrateChanged: " + JSON.stringify(event));

        const bitrateChangedMessage = new messages.BitrateChangedMessage(
            this._xuaAssetFactory.create(this._sandbox.asset),
            event.bitRate);
        this._analyticsProvider.buildMessage(bitrateChangedMessage);
    }

    private onFPSChanged(event: events.FPSChangedEvent) {
        if (Math.abs(event.fps - this._lastFPS) >= this._fpsThreshold) {
            this._logger.trace("onFPSChanged: " + JSON.stringify(event) + ": " + this._lastFPS);
            const fpsChangedMessage = new messages.FPSChangedMessage(
                this._xuaAssetFactory.create(this._sandbox.asset),
                event.fps);

            this._analyticsProvider.buildMessage(fpsChangedMessage);
        }

        this._lastFPS = event.fps;
    }

    private onEngineSelected() {
        // Clear out the player info from previously selected engine
        this.analyticsProvider.setPlayerInfo("", "");
    }

    private onFragmentInfo(event: events.FragmentInfoEvent) {
        const fragmentDuration = event.fragmentDuration >= 0 ? event.fragmentDuration : 0;

        // considered an warning. Only send if playing at normal speed.
        if (event.downloadDuration > fragmentDuration && this._sandbox.getCurrentPlaybackSpeed() === 1) {
            const data: IFragmentInfo = {
                downloadDuration: event.downloadDuration,
                fragmentDuration: fragmentDuration,
                fragmentSize: event.fragmentSize,
                fragmentUrl: event.fragmentUrl,
                count : undefined
            };
            const warningMessage = new messages.FragmentWarningMessage(
                this._xuaAssetFactory.create(this._sandbox.asset),
                data);

            this._analyticsProvider.buildMessage(warningMessage);
        }

        this._fragmentInfo.downloadDuration += event.downloadDuration;
        this._fragmentInfo.fragmentSize += event.fragmentSize;
        this._fragmentInfo.fragmentDuration += fragmentDuration;
        this._fragmentInfo.count++;
    }

    private onHeartBeat() {
        this._logger.trace("onHeartBeat");

        const heartbeatMessage = new messages.HeartbeatMessage({
            xuaAsset: this._xuaAssetFactory.create(this._sandbox.asset),
            currentPosition: this._sandbox.getCurrentPosition(),
            bitrate: this._sandbox.player.getCurrentBitrate(),
            fps: this._sandbox.player.getCurrentFPS(),
            cc: this._sandbox.player.getCurrentClosedCaptionTrack(),
            sap: this._sandbox.player.getCurrentAudioLanguage(),
            envInfo: null,
            fragmentInfo: this._fragmentInfo.count ? this._fragmentInfo : undefined
        });

        this._analyticsProvider.buildMessage(heartbeatMessage);

        this._fragmentInfo.reset();
    }

    private onMediaEnded(event: events.MediaEndedEvent) {
        this._logger.trace("onMediaEnded: " + JSON.stringify(event));
        const mediaEndedMessage = new messages.MediaEndedMessage(
            this._xuaAssetFactory.create(this._sandbox.asset));
        this._analyticsProvider.buildMessage(mediaEndedMessage);
    }

    private onMediaFailed(event: events.MediaFailedEvent) {
        this._logger.trace("onMediaFailed: " + JSON.stringify(event));
        const mediaFailedMessage = new messages.MediaFailedMessage(
            this._xuaAssetFactory.create(this._sandbox.asset),
            event.error.code,
            event.error.description,
            this._sandbox.getCurrentPosition(),
            this._sandbox.asset.url,
            this._playbackStateMonitor.isAdPlaying(),
            event.playerStatus
        );
        this._analyticsProvider.buildMessage(mediaFailedMessage);
        // use the force
        this._analyticsProvider.forceSendMessages();
    }

    private _buildApplicationErrorMessage(error: PPError, options: IApplicationErrorOptions = {}) {
        const errorMessage = new messages.ApplicationErrorMessage({
            xuaAsset: this._xuaAssetFactory.create(this._sandbox.asset),
            errorCode: error.code,
            errorClass: options.errorClass,
            errorDescription: error.description,
            warning: error.isWarning,
            retryCount: options.retryCount,
            adError: this._playbackStateMonitor.isAdPlaying(),
            fallback: options.fallback,
            fallbackType: options.fallbackType,
            manifestUrl: this._sandbox.asset.url,
            playerStatus: options.playerStatus
        });
        this._analyticsProvider.buildMessage(errorMessage);
    }

    private onMediaRetry(event: events.MediaRetryEvent) {
        this._logger.trace("onMediaRetry: " + JSON.stringify(event));
        this._buildApplicationErrorMessage(event.data.error, { errorClass: event.data.errorClass,
            retryCount: event.data.retryCount,
            playerStatus: event.data.playerStatus
        });
    }

    public onMediaWarning(event: events.MediaWarningEvent) {
        // Flash specific media warning should not be tracked
        if (!(event instanceof FlashMediaWarningEvent)) {
            this._logger.trace("onMediaWarning: " + JSON.stringify(event));
            this._buildApplicationErrorMessage(event.error);
        }
    }

    private onMediaFallback(event: events.MediaFallbackEvent) {
        this._logger.trace("onMediaFallback: " + JSON.stringify(event).replace("\n", ""));
        this._buildApplicationErrorMessage(event.data.error, {
            errorClass: event.data.errorClass,
            retryCount: event.data.retryCount,
            fallback: true,
            fallbackType: event.fallbackType
        });
    }

    public onMediaOpened(event: events.MediaOpenedEvent) {
        if (this._sandbox.asset.canSendMediaOpened()) {

            this._sandbox.asset.mediaOpenedCount += 1;

            this._logger.trace("onMediaOpened: " + JSON.stringify(event));
            const mediaOpenMessage = new messages.MediaOpenedMessage(
                this._xuaAssetFactory.create(this._sandbox.asset),
                event.openingLatency,
                this._sandbox.getCurrentPosition(),
                this._sandbox.asset.url,
                event.numAds
            );
            this._analyticsProvider.buildMessage(mediaOpenMessage);
        }
    }

    private onMediaInfo(description: string) {
        this._logger.trace("onMediaInfo: " + description);
        if (!this._sandbox.asset) {
            return;
        }
        // This happens when setPreferredAudioLanguage happens prior to setAsset
        /*
            XREPlayerPlatform:onCallMethod: setPreferredAudioLanguage
            XREPlayerPlatform:setPreferredAudioLanguage: eng
            PlayerPlatformAPI: setPreferredAudioLanguage: en
            AnalyticsHandler: onMediaInfo: SAP changed. en
        */
        const mediaInfoMessage = new messages.MediaInfoMessage(
            this._xuaAssetFactory.create(this._sandbox.asset),
            this._sandbox.getCurrentPosition(),
            description
        );

        this._analyticsProvider.buildMessage(mediaInfoMessage);
    }

    private onPerformance(performanceBreakdown: IPerformance) {
        this._logger.trace("onPerformance: " + JSON.stringify(performanceBreakdown));
        const performanceMessage = new messages.PerformanceMessage(
            this._xuaAssetFactory.create(this._sandbox.asset),
            performanceBreakdown
        );
        this._analyticsProvider.buildMessage(performanceMessage);
    }

    private onPlaybackSpeedChanged(event: events.PlaybackSpeedChangedEvent) {
        this._logger.trace("onPlaybackSpeedChanged: " + JSON.stringify(event));
        let trickPlayType: BufferEventTypes;
        if (event.playbackSpeed < 0) {
            trickPlayType = BufferEventTypes.REWIND;
        } else if (event.playbackSpeed === 0) {
            trickPlayType = BufferEventTypes.PAUSE;
        } else if (event.playbackSpeed === 1) {
            trickPlayType = BufferEventTypes.PLAY;
        } else if (event.playbackSpeed > 1) {
            trickPlayType = BufferEventTypes.FAST_FORWARD;
        } else {
            trickPlayType = BufferEventTypes.UNKNOWN;
        }
        const trickPlayMessage = new messages.TrickPlayMessage(
            this._xuaAssetFactory.create(this._sandbox.asset),
            this._sandbox.getCurrentPosition(),
            trickPlayType);
        this._analyticsProvider.buildMessage(trickPlayMessage);
    }

    private onPlaybackStarted() {
        this._logger.trace("onPlaybackStarted");
        const playbackStartedMessage = new messages.PlaybackStartedMessage(
            this._xuaAssetFactory.create(this._sandbox.asset));
        this._analyticsProvider.buildMessage(playbackStartedMessage);
    }

    private onPlayStateChanged(event: events.PlayStateChangedEvent) {
        if (event.playState === constants.STATUS_INITIALIZING) {
            const player = this._sandbox.player;
            this.analyticsProvider.setPlayerInfo(player.assetEngine, player.getVersion());
        }

        this._logger.trace("onPlayStateChanged: " + JSON.stringify(event));
        const playStateChangeMessage = new messages.PlayStateChangedMessage(
            this._xuaAssetFactory.create(this._sandbox.asset),
            event.playState);
        this._analyticsProvider.buildMessage(playStateChangeMessage);
    }

    private onSeekComplete(event: events.SeekCompleteEvent) {
        this._logger.trace("onSeekComplete: " + JSON.stringify(event));
        const scrubMessage = new messages.ScrubEndedMessage(
            this._sandbox.getCurrentPosition(),
            this._xuaAssetFactory.create(this._sandbox.asset));
        this._analyticsProvider.buildMessage(scrubMessage);
    }

    private onSeekStart(event: events.SeekStartEvent) {
        this._logger.trace("onSeekStart: " + JSON.stringify(event));
        const scrubMessage = new messages.ScrubStartedMessage(
            this._sandbox.preSeekPosition,
            this._xuaAssetFactory.create(this._sandbox.asset));
        this._analyticsProvider.buildMessage(scrubMessage);
    }

    private onOpeningMedia() {
        const openingMediaMessage = new messages.OpeningMediaMessage(
            this._xuaAssetFactory.create(this._sandbox.asset),
            this._sandbox.asset.url
        );
        this._analyticsProvider.buildMessage(openingMediaMessage);
    }

    private onEmergencyAlertIdentified(event: events.EmergencyAlertIdentifiedEvent) {
        this._logger.trace("onEmergencyAlertIdentified: " + JSON.stringify(event));
        const emergencyAlertIdentifiedMessage = new messages.EmergencyAlertMessage(
            "Identified",
            event.easLanguage,
            event.easURI
        );
        this._analyticsProvider.buildMessage(emergencyAlertIdentifiedMessage);
    }

    private onEmergencyAlertStarted(event: events.EmergencyAlertStartedEvent) {
        this._logger.trace("onEmergencyAlertStarted: " + JSON.stringify(event));
        const emergencyAlertStartedMessage = new messages.EmergencyAlertMessage(
            "Initiated",
            event.easLanguage,
            event.easUri
        );
        this._analyticsProvider.buildMessage(emergencyAlertStartedMessage);
    }

    private onEmergencyAlertCompleted(event: events.EmergencyAlertCompleteEvent) {
        this._logger.trace("onEmergencyAlertCompleted: " + JSON.stringify(event));
        const emergencyAlertCompletedMessage = new messages.EmergencyAlertMessage(
            "Completed",
            event.easLanguage,
            event.easUri
        );
        this._analyticsProvider.buildMessage(emergencyAlertCompletedMessage);
    }

    private onEmergencyAlertFailed(event: events.EmergencyAlertFailureEvent) {
        this._logger.trace("onEmergencyAlertFailed: " + JSON.stringify(event));
        const emergencyAlertFailedMessage = new messages.EmergencyAlertMessage(
            "Failed",
            event.easLanguage,
            event.easURI,
            event.easCode
        );
        this._analyticsProvider.buildMessage(emergencyAlertFailedMessage);
    }

    private onEmergencyAlertExempted(event: events.EmergencyAlertExemptedEvent) {
        this._logger.trace("onEmergencyAlertExempted: " + JSON.stringify(event));
        const emergencyAlertExemptedMessage = new messages.EmergencyAlertMessage(
            "Exempted",
            event.easLanguage,
            event.easURI
        );
        this._analyticsProvider.buildMessage(emergencyAlertExemptedMessage);
    }

    private onEmergencyAlertErrored(event: events.EmergencyAlertErroredEvent) {
        this._logger.trace("onEmergencyAlertErrored: " + JSON.stringify(event));
        const emergencyAlertErroredMessage = new messages.EmergencyAlertMessage(
            "Errored",
            event.easLanguage,
            event.easURI,
            event.easCode
        );
        this._analyticsProvider.buildMessage(emergencyAlertErroredMessage);
    }

    private onStreamSwitch(event: events.StreamSwitchEvent) {
        this._logger.trace("onVirtualStreamSwitch: " + JSON.stringify(event));
        const message = new messages.VStreamSwitchMessage(
            this._xuaAssetFactory.create(this._sandbox.asset)
        );
        this._analyticsProvider.buildMessage(message);
    }
}

interface IApplicationErrorOptions {
    errorClass?: string;
    retryCount?: number;
    fallback?: boolean;
    fallbackType?: string;
    playerStatus?: string;
}

registerModule("AnalyticsHandler", AnalyticsHandler, {
    children: [
        "HomeNetworkAnalyticsHandler"
    ]
});
