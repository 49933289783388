import { Logger } from "../util/Logger";
import { BaseAsset } from "../assets/BaseAsset";
import { IPPModule, IPPSandbox } from "../PlayerPlatformApplication";
import { registerModule } from "../Application";
import { AnalyticsHandler } from "./AnalyticsHandler";
import { ConfigurationManager } from "../ConfigurationManager";
import * as messages from "../analytics/Messages";
import { Observable, Subscription, from, of, fromEvent } from "rxjs";
import { ajax } from "rxjs/ajax";
import { bufferCount, catchError, concatMap, map, takeUntil } from "rxjs/operators";

import * as events from "../PlayerPlatformAPIEvents";

export interface IDiagnosticTestResult {
    test: IDiagnosticTest;
    success: boolean;
    duration: number;
    reason?: string;
}

export interface IDiagnosticTest {
    name: "GATEWAY_DOWN" | "CDN_DOWN" | "INTERNET_DOWN";
    url: string;
}

const DEFAULT_HNA_TIMEOUT: number = 5000;

/**
 * Represents a handler with the responsibility of logic regarding the home
 * network analytics `xuaDiagnosticEvent` message. This module should run the
 * tests needed and build messages for the `xuaDiagnosticEvent`.
 */
export class HomeNetworkAnalyticsHandler implements IPPModule<HomeNetworkAnalyticsHandler> {

    private _logger: Logger = new Logger("HomeNetworkAnalyticsHandler");
    private _sandbox: IPPSandbox;

    private diagnosticTestTimeout: number = DEFAULT_HNA_TIMEOUT;

    private testRunner?: Subscription;

    public init(sandbox: IPPSandbox): HomeNetworkAnalyticsHandler {
        this._sandbox = sandbox;

        sandbox.streams.setAssets
            .pipe(takeUntil(sandbox.destroyed))
            .subscribe((asset: BaseAsset) => this.onSetAsset(asset));

        if (this._sandbox.asset) {
            this.onSetAsset(this._sandbox.asset);
        }

        return this;
    }

    private onSetAsset(asset: BaseAsset) {
        const testsNeeded = this.getTestsNeeded(asset);

        // stop any currently running runner
        // for previous assets
        this.stopTestRunner();

        if (testsNeeded.length > 0) {
            this.testRunner = this.getTestRunner(testsNeeded);
        } else {
            this._logger.trace("No tests needed for asset");
        }

        this.diagnosticTestTimeout = this.getDiagnosticTestTimeout(asset);

    }

    private stopTestRunner(): void {
        if (this.testRunner) {
            this.testRunner.unsubscribe();
            this.testRunner = undefined;
        }
    }

    private getDiagnosticTestTimeout(asset: BaseAsset): number {
        const configMgr: ConfigurationManager = ConfigurationManager.getInstance();
        const assetType: string = configMgr.getAssetType(asset);
        return configMgr.getByAssetType(assetType, ConfigurationManager.HNA_TIMEOUT, DEFAULT_HNA_TIMEOUT);
    }

    private getTestsNeeded(asset: BaseAsset): IDiagnosticTest[] {
        const testsNeeded: IDiagnosticTest[] = [];
        const configMgr: ConfigurationManager = ConfigurationManager.getInstance();
        const assetType: string = configMgr.getAssetType(asset);
        const hnaEnabled: boolean = configMgr.getByAssetType(assetType, ConfigurationManager.HNA_ENABLED, false);

        if (hnaEnabled) {
            const hnaCdnEndpoint: string = this.getCDNTestUrl(
                asset.url,
                configMgr.getByAssetType(assetType, ConfigurationManager.HNA_CDN_ENDPOINT, "")
            );
            const hnaAwsEndpoint: string = configMgr.getByAssetType(assetType, ConfigurationManager.HNA_AWS_ENDPOINT, "");

            if (hnaCdnEndpoint) {
                testsNeeded.push({
                    name: "CDN_DOWN",
                    url: hnaCdnEndpoint
                });
            }

            if (hnaAwsEndpoint) {
                testsNeeded.push({
                    name: "INTERNET_DOWN",
                    url: hnaAwsEndpoint
                });
            }
        }

        return testsNeeded;
    }

    /**
     * For CDN tests we append the configured end point to the asset
     * base URL.
     *
     * ex.
     *    with an asset url of http://www.assets.com/my/asset/foobar.m3u8 and
     *    a cdn enpoint off FOO.test we want to run the test on
     *    http://www.assets.com/FOO.test
     */
    private getCDNTestUrl(assetUrl: string, cdnEndpoint: string): string {

        const urlCheck: RegExp = /^(https?:\/\/[^\/]+\/)(.*)$/i;

        // If we have a URL we cna work with
        if (urlCheck.test(assetUrl)) {
            return assetUrl.replace(urlCheck, "$1" + cdnEndpoint);
        }

    }

    private runDiagnosticTests(testsNeeded: IDiagnosticTest[]): void {
        this._logger.trace("Running diagnostic tests");
        from(testsNeeded)
            .pipe(
                concatMap((diagnosticTest: IDiagnosticTest): Observable<IDiagnosticTestResult> => {
                    this._logger.trace("Requesting " + diagnosticTest.url);
                    const requestTime = new Date().getTime();
                    return ajax({
                        method: "GET",
                        timeout: this.diagnosticTestTimeout,
                        crossDomain: true,
                        url: diagnosticTest.url
                    })
                        .pipe(
                            takeUntil(this._sandbox.destroyed),
                            map((response): boolean => response.status === 200),
                            catchError(() => of(false)),
                            map((success: boolean) => ({
                                test: diagnosticTest,
                                success,
                                duration: (new Date()).getTime() - requestTime
                            }))
                        );
                }),
                bufferCount(testsNeeded.length),
                takeUntil(this._sandbox.destroyed)
            )
            .subscribe((results: IDiagnosticTestResult[]) => {
                AnalyticsHandler.instance.analyticsProvider.buildMessage(
                    new messages.DiagnosticEventMessage(results)
                );
            });
    }

    private getTestRunner(testsNeeded: IDiagnosticTest[]): Subscription {
        return fromEvent(events, events.MEDIA_FAILED)
                .pipe(
                    takeUntil(this._sandbox.destroyed)
                )
            .subscribe(() => this.runDiagnosticTests(testsNeeded));
    }

    public destroy(_sandbox: IPPSandbox): void {
        this.stopTestRunner();
    }

}

registerModule("HomeNetworkAnalyticsHandler", HomeNetworkAnalyticsHandler);
