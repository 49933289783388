import "./ads/AdManagerFactory";
import * as app from "./Application";
import { detect } from "detect-browser";
import * as constants from "./PlayerPlatformConstants";
import * as events from "./PlayerPlatformAPIEvents";
import { BaseAsset, getUrlExtension, AssetExtension } from "./assets/BaseAsset";
import { AdManager } from "./ads/AdManager";
import { IAdConfig } from "./ads/IAdConfig";
import { AnalyticsProvider } from "./analytics/AnalyticsProvider";
import { IHostInfo } from "./analytics/IAnalyticsProvider";
import { PlaybackStateMonitor } from "./analytics/PlaybackStateMonitor";
import { EASPoller } from "./eas/EASPoller";
import { IEASSettings } from "./eas/EmergencyAlertProvider";
import { EmergencyAlert } from "./eas/EmergencyAlert";
import { MediaSegment } from "./MediaSegment";
import { ConfigurationManager, IConfigOptions } from "./ConfigurationManager";
import { VideoAdBreak } from "./ads/VideoAdBreak";
import { parse as parseXsct } from "./util/XSCTToken";
import { Logger } from "./util/Logger";
import { BasePlayer } from "./engines/base/BasePlayer";
import { IPlayerCCStyle } from "./PlayerPlatformCCStyle";
import { PubSub as Mediator } from "publicious";
import { PlayerPlatformStreams } from "./PlayerPlatformStreams";
import { XREPlayerPlatform } from "./xre/XREPlayerPlatform";
import { throwError, empty, from, Subscription } from "rxjs";
import { filter, take, catchError, map, takeUntil } from "rxjs/operators";
import { PPError } from "./PPError";
import * as urlService from "./services/urlService/URLService";
import { SecClient } from "@ces/secclient-js-core";
import { SessionManager } from "./handlers/SessionManager";

const browser = detect() || {
    name: "unknown",
    version: "unknown"
};

/**
 * Main PlayerPlatformAPI constructor. This should be called after config values have been loaded through
 * `ConfigurationManager`.
 *
 * #### Example:
 * ```
 * const playerPlatform;
 * const configManager = pp.ConfigurationManager.getInstance();
 *
 * configManager.onSuccess(function() {
 *      playerPlatform = new pp.PlayerPlatformAPI({
 *          videoElement: document.getElementById("video"),
 *          configurationManager: configManager
 *      });
 * });
 *
 * configManager.loadConfiguration("http://myconfigurl/config.json");
 *
 * // or pass in a configuration object
 *
 * playerPlatform = new pp.PlayerPlatformAPI({
 *      videoElement: document.getElementById("video"),
 *      configuration: {
 *          retryOnMediaFailed: "true",
 *          defaultAsset: {
 *              initialBitrate: 0,
 *              initialBufferTime: 2000
 *          }
 *      }
 * });
 * ```
 */
export class PlayerPlatformAPI {

    @app.exposeProp
    public player: BasePlayer = null;

    @app.exposeProp
    public adManager: AdManager | null = null;

    @app.exposeProp
    public asset: BaseAsset = null;

    @app.exposeProp
    /**
     * The last known position we have seen from an underlying
     * engine. If this value is not undefined it is the time from
     * the last progress event emitted. A value of undefined represents
     * there has been no progress made so the position is unknown.
     */
    protected lastKnownPosition?: number;

    @app.exposeProp
    protected preSeekPosition: number = 0;

    @app.exposeProp
    private streams: PlayerPlatformStreams = new PlayerPlatformStreams();

    private logger: Logger;
    private mediator: Mediator;
    private configMgr: ConfigurationManager;
    private videoElement: HTMLElement;
    private currentFPS: number = 0;
    private currentDroppedFrames: number = 0;
    private currentDuration: number = 0;
    private closedCaptionsEnabled: boolean = false;
    private closedCaptionsStyle?: IPlayerCCStyle;
    private playerTypes: string[] = [];
    private pendingPlaybackStart: Subscription;
    private resolveUrlSub: Subscription;
    private boundClickHandler: EventListenerObject;
    private mute: boolean;
    private currentVolume: number;

    constructor(parameters: IPlayerPlatformAPIParams) {

        this.logger = parameters.logger || new Logger("PlayerPlatformAPI");
        this.logger.trace("new");

        validateParameters(parameters, this.logger);

        this.configMgr = parameters.configurationManager || ConfigurationManager.getInstance();

        this.videoElement = parameters.videoElement;

        // load configuration object if necessary
        if (!this.configMgr.isReady()) {
            this.configMgr.loadConfiguration(parameters.configuration || {});
        } else if (parameters.configuration && Object.keys(parameters.configuration).length) {
            this.logger.warn("Configuration has been loaded, parameters.configuration is ignored.");
        }

        this.boundClickHandler = this._clickHandler.bind(this);
        this.videoElement.addEventListener("click", this.boundClickHandler);

        function validateParameters(params: IPlayerPlatformAPIParams, logger: Logger) {
            if (!params) {
                logger.error("PlayerPlatformAPI requires a parameters object");
                throw new Error("PlayerPlatformAPI requires a parameters object");
            }

            if (!params.videoElement) {
                logger.error("Params must contain the id of an HTMLElement to be used as the video canvas");
                throw new Error("Params must contain the id of an HTMLElement to be used as the video canvas");
            }
        }

        events.addEventListener(events.MEDIA_PROGRESS, this.updatePlaybackMetrics, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.MEDIA_OPENED, this.onMediaOpened, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.PLAY_STATE_CHANGED, this.onPlayStateChanged, constants.PRIORITY_DEFAULT, this);

        app.inject({
            config: this.configMgr.values,
            params: parameters
        });
        app.init(this);

        this.mediator.subscribe(constants.SET_ASSET, this.onSetAsset, { priority: constants.PRIORITY_TOP }, this);
        this.mediator.subscribe(constants.ENGINE_SELECTED, this.engineChange, { priority: constants.PRIORITY_DEFAULT }, this);
        this.mediator.subscribe(constants.AD_MANAGER_SELECTED, this.adManagerChange, { priority: constants.PRIORITY_TOP }, this);
        this.mediator.subscribe(constants.UPDATE_PLAYER_CONFIG, this.setPlayerConfig, { priority: constants.PRIORITY_DEFAULT }, this);
        this.mediator.subscribe(constants.PLAYERS_AVAILABLE, this.playersAvailable, { priority: constants.PRIORITY_DEFAULT }, this);
        this.mediator.subscribe(constants.SWAP_ASSET, this.swapAsset, { priority: constants.PRIORITY_DEFAULT }, this);
        this.mediator.publish(constants.PLAYER_CHECK);

    }

    private onSetAsset(asset: BaseAsset): void {

        if (!asset.isRetry) {
            this.stop();
        }

        this.configMgr.extendConfiguration(ConfigurationManager.DEFAULT_ASSET, asset.assetType);

        this.asset = asset;

        const adManagerOpts = this.asset.adConfig || {
            type: "none"
        };

        this.mediator.publish("ads:configureAds", adManagerOpts.type, adManagerOpts);

        //propagate volume settings between asset changes, this is necessary due to the timing of the tear-down and
        //instantiation between the various video engines (especially flash). What ends up happening is that the volume will get
        //propagated to the previous engine and will not make it to the new engine; this ensures that the volume setting is preserved
        //and gets passed to the new engine between asset swaps.
        if (typeof this.currentVolume === "number") {
            this.setVolume(this.currentVolume);
        }
        if (typeof this.mute === "boolean") {
            this.setAudioOnly(this.mute);
        }

    }

    private swapAsset(asset: BaseAsset): void {
        this.asset = asset;
        this.logger.trace("swapAsset: " + JSON.stringify(this.asset));
    }

    private engineChange(engine: BasePlayer): void {
        this.player = engine;
        this.player.onPlayerReady(() => {
            this.setInitialPlayerConfig();
        });
    }

    private playersAvailable(assetTypes: string[]): void {
        this.playerTypes = assetTypes;
        this.logger.trace("playersAvailable: " + JSON.stringify(this.playerTypes));
    }

    private adManagerChange(manager: AdManager | null): void {
        this.adManager = manager;
    }

    /**
     * Add a specified event listener.
     * @param {String} type
     * @param {Function} listener
     * @param {Object} context
     */
    public addEventListener(type: string, listener: any, context: any): void {
        this.logger.trace("addEventListener: Adding event for type: " + type);
        events.addEventListener(type, listener, constants.PRIORITY_DEFAULT, context);
    }


    /**
     * Add a specified event listener.
     * @param {String} type
     * @param {Function} listener
     * @param {Object} context
     */
    public on(type: string, listener: any, context: any): void {
        this.addEventListener(type, listener, context);
    }

    /**
     * Dispatch an event
     *
     * @param {Object} event
     *
     * @private
     */
    public dispatchEvent(event: events.PlayerPlatformAPIEvent): void {
        events.dispatchEvent(event);
    }

    public removeEventListener(type: string, listener: any): void {
        events.removeEventListener(type, listener);
    }

    /**
     * Remove an existing event listener with an event type
     *
     * @param {String} type
     * @param {Function} listener - callback function to execute
     */
    public off(type: string, listener: any): void {
        this.removeEventListener(type, listener);
    }

    /**
     * Methods PlayerPlatformAPI -------------------------------------------------
     */

    /**
     * Play the current Asset using the set playback speed.
     */
    public play(): void {
        this.logger.info("play");
        this.mediator.publish(constants.PLAY);
    }

    /**
     * Pauses the current Asset. The Asset can be resumed by calling play().
     * @see play
     */
    public pause(): void {
        this.logger.info("pause");
        this.mediator.publish(constants.PAUSE);
    }

    /**
     * Stops the current Asset and resets the player. In order to play the Asset again, setAsset() must be called.
     * @see setAsset
     */
    public stop(): void {
        this.logger.info("stop");
        this.mediator.publish(constants.STOP);
        this.lastKnownPosition = undefined;
    }

    /**
     * Sets the play head to the current live point.
     * This will not be actual live as the player keeps a configurable distance away from live.
     */
    public seekToLive(): void {
        this.logger.info("seekToLive");
        this.preSeekPosition = this.getCurrentPosition();
        this.mediator.publish(constants.SEEK_TO_LIVE);
    }

    /**
     * Destroy the player object and release all data the object has to be recreated in order to be used again.
     */
    public destroy(): void {
        this.logger.trace("destroy");

        if (this.pendingPlaybackStart) {
            this.pendingPlaybackStart.unsubscribe();
        }

        this.videoElement.removeEventListener("click", this.boundClickHandler);

        this.mediator.remove(constants.SWAP_ASSET, this.swapAsset);
        this.mediator.remove(constants.SET_ASSET, this.onSetAsset);
        this.mediator.remove(constants.ENGINE_SELECTED, this.engineChange);
        this.mediator.remove(constants.AD_MANAGER_SELECTED, this.adManagerChange);
        this.mediator.remove(constants.UPDATE_PLAYER_CONFIG, this.setPlayerConfig);
        this.mediator.remove(constants.PLAYERS_AVAILABLE, this.playersAvailable);
        events.removeEventListener(events.MEDIA_PROGRESS, this.updatePlaybackMetrics);
        events.removeEventListener(events.MEDIA_OPENED, this.onMediaOpened);
        events.removeEventListener(events.PLAY_STATE_CHANGED, this.onPlayStateChanged);

        this.mediator.publish(constants.DESTROY);

        app.stopModules();
        this.videoElement = undefined;

    }

    /**
     * Configure analytics plugin using an object containing all required fields.
     * See HostInfo API docs.
     *
     * @param hostInfo - HostInfo object containing required fields
     */
    public configureAnalytics(hostInfo: IHostInfo): void;

    /**
     * Configure analytics using XSCT token string, app version.
     *
     * @param xsct - XTVAPI session response
     * @param appVersion - application version number
     */
    public configureAnalytics(xsct: IXsctResponse, appVersion: string): void;

    /**
     * Combined method to cover method overload.
     *
     * @param hostInfoOrXsct
     * @param appVersion
     */
    public configureAnalytics(hostInfoOrXsct: IHostInfo | IXsctResponse, appVersion?: string): void {

        this.logger.trace("configureAnalytics: " + JSON.stringify(hostInfoOrXsct));
        let hostInfo = hostInfoOrXsct;

        // if object contains a tokenSummary property, assume an XsctResponse is being passed in
        if ((hostInfoOrXsct as IXsctResponse).tokenSummary && appVersion) {
            const xsctObj = hostInfoOrXsct as IXsctResponse;
            hostInfo = {
                appName: this.configMgr.get(ConfigurationManager.ANALYTICS_DEVICE_TYPE),
                appVersion: appVersion,
                deviceName: browser.name,
                deviceVersion: browser.version,
                // TODO(estobb200): Is this ok for both DEV.PHYSICAL_DEVICE_ID & DEV.DEV_ID ??
                deviceId: xsctObj.tokenSummary.deviceId,
                physicalId: xsctObj.tokenSummary.deviceId,
                accountId: xsctObj.tokenSummary.xboAccountId,
                xsctPartnerId: xsctObj.tokenSummary.partnerId,
                inHomeState: () => xsctObj.tokenSummary.inHomeStatus === "in-home" ? "inHome" : "outOfHome"
            };
        }

        app.startModule("AnalyticsHandler", hostInfo, new AnalyticsProvider(), new PlaybackStateMonitor());
    }

    public configureEmergencyAlerts(xsctToken: string): void {

        if (this.isXRE()) {
            this.logger.warn("EAS not available on XRE platforms.");
            return;
        }

        const zipToFipsUrl = this.configMgr.get(ConfigurationManager.ZIPS_TO_FIPS_END_POINT);
        const alertUrl = this.configMgr.get(ConfigurationManager.ALERT_SERVICE_END_POINT);

        const easSettings: IEASSettings = {
            pollingInterval: parseInt(this.configMgr.get(ConfigurationManager.EAS_UPDATE_INTERVAL), 10),
            timeout: parseInt(this.configMgr.get(ConfigurationManager.EAS_NETWORK_REQUEST_TIMEOUT), 10),
            repeat: parseInt(this.configMgr.get(ConfigurationManager.EAS_ALERT_REPEAT), 10),
            font: this.configMgr.get(ConfigurationManager.EAS_ALERT_FONT),
            fontSize: parseFloat(this.configMgr.get(ConfigurationManager.EAS_ALERT_FONT_SIZE))
        };

        const zip = parseXsct(xsctToken).zipCode;
        const easPoller = new EASPoller({
            alertUrl,
            zipToFipsUrl,
            zip,
            interval: easSettings.pollingInterval,
            preferredLanguage: this.configMgr.get(ConfigurationManager.EAS_LANGUAGE),
            mimeType: this.getMimeTypeForEASPoller()
        });
        app.startModule("EmergencyAlertProvider", easPoller, easSettings);
    }
    public shutDownEmergencyAlerts() {
        app.stopModule("EmergencyAlertProvider");
    }

    /**
     * Setters PlayerPlatformAPI -------------------------------------------------
     */

    @app.expose
    public setAsset(asset: BaseAsset): void {

        this.logger.trace("setAsset: resumePosition=" + asset.resumePosition + " asset=" + JSON.stringify(asset));

        if (!asset.isRetry && !asset.isRollback) {
            SessionManager.instance.incrementPlaybackCount();
        }

        if (this.resolveUrlSub) {
            this.resolveUrlSub.unsubscribe();
        }

        this.resolveUrlSub = from(
            urlService.getURLPlaylistForAsset(asset.originalUrl, asset, asset.isRetry)
        )
            .pipe(
                catchError((err: any) => {
                    this.logger.error("Error resolving Urls", err);
                    this.asset = asset;
                    if (err instanceof PPError) {
                        this.dispatchEvent(new events.MediaFailedEvent(err, false));
                        return empty();
                    }
                    return throwError(err);
                }),
                map((urls) => {
                    if (urls.length === 0) {
                        this.logger.warn("Got no URLs, trying original URL");
                        return [asset.originalUrl];
                    }
                    return urls;
                })
            )
            .subscribe((urls) => {
                this.logger.trace("Resolved urls", urls.toString());
                asset.urls = urls;
                if (!asset.isRetry && !asset.isRollback) {
                    asset.initializeMediaOpenedCounts();
                }
                asset.url = asset.urls.shift();
                this.mediator.publish(constants.SET_ASSET, asset);
            });
    }

    /**
     * Sets the play head position in milliseconds
     * @param target Desired play head position in milliseconds.
     * @param ignoreAds - ignore c3 ads
     */
    @app.expose
    public setPosition(target: number, ignoreAds?: boolean): void {
        this.logger.info("setPosition - target=" + target + " ignoreAds=" + ignoreAds);
        this.preSeekPosition = this.getCurrentPosition();
        this.mediator.publish(constants.SET_POSITION, target, ignoreAds);
    }

    /**
     * Sets the play head position relative to the current play head position.
     * @param {Number} msec Desired play head position in milliseconds relative to the current play head position.
     * @param {boolean} ignoreAds - ignore c3 ads
     * (newPosition = currentPosition + msec)
     *
     */
    public setPositionRelative(msec: number, ignoreAds?: boolean): void {
        this.logger.info("setPositionRelative: " + msec);

        this.setPosition(this.getCurrentPosition() + msec, ignoreAds);
    }

    /**
     * Set the playback volume
     * @param {Number} volume The desired volume. The value can be between 0 and 1
     */
    public setVolume(volume: number): void {

        volume = volume > 1 ? 1 : volume;
        volume = volume < 0 ? 0 : volume;

        this.logger.trace("setVolume: " + volume);
        this.currentVolume = volume;

        this.mediator.publish(constants.SET_VOLUME, volume);
    }

    /**
     * Set playback rate. Note some assets will not support trick-play.
     * @param {Number} speed A positive/negative value of the desired playback speed.
     * @param {Number} overshootCorrection Correction value to be applied during trickplay
     */
    public setSpeed(speed: number, overshootCorrection?: number): void {
        this.logger.info(`setSpeed: spd=${speed}`);
        this.mediator.publish(constants.SET_SPEED, speed, overshootCorrection);
    }

    /**
     * Returns if closed captions is enabled
     * @returns true if closed captions is enabled, false otherwise
     */
    public getClosedCaptionsEnabled(): boolean {
        return this.closedCaptionsEnabled;
    }

    /**
     * This method allows closed captioning to be enabled.  Closed captioning display policy is determined by the player and is
     * rendered by the player when this is enabled.  Closed captioning is disabled by default in the player.
     * @param {Boolean} flag Identifiying if CC is enabled or disabled.
     */
    public setClosedCaptionsEnabled(flag: boolean): void {
        this.logger.trace("setClosedCaptionsEnabled: " + flag);
        this.closedCaptionsEnabled = flag;
        this.mediator.publish(constants.SET_CC_ENABLED, flag);

        this.mediator.publish(constants.MEDIA_INFO, "CC " + (flag ? "enabled" : "disabled"));
    }

    /**
     * The desired bitrate (in bits per second) at which playback should start.
     *
     * You should set this value prior to assigning the item to a MediaPlayer to ensure that it is considered.
     * @param {Number} initialBitrate Initial requested bitrate (in bits/sec). The player will find the closest value to the specified value.
     */
    public setInitialBitrate(initialBitrate: number): void {
        this.logger.trace("setInitialBitrate: " + initialBitrate);
        this.configMgr.update({ initialBitrate });
        this.mediator.publish(constants.SET_INITIAL_BITRATE, initialBitrate);
    }

    /**
     * The size (expressed in milliseconds) of the streaming buffer to use initially
     *
     * You should set this value prior to assigning the item to a MediaPlayer to ensure that it is considered.
     * @param {Number} initialBufferTime Initial buffer time (in msec).
     */
    public setInitialBufferTime(initialBufferTime: number): void {
        this.logger.trace("setInitialBufferTime: " + initialBufferTime);
        this.configMgr.update({ initialBufferTime });
        this.mediator.publish(constants.SET_INITIAL_BUFFER_TIME, initialBufferTime);
    }

    /**
     * The delay (expressed in milliseconds) delay between completion of one segment load and starting the next.
     *
     * You should set this value prior to assigning the item to a MediaPlayer to ensure that it is considered.
     *
     * NOTE: This setting is only used with the helio_js engine.
     * @param {Number} intersegmentDelay Initial buffer time (in msec).
     */
    public setIntersegmentDelay(intersegmentDelay: number) {
        this.logger.trace("setIntersegmentDelay: " + intersegmentDelay);
        this.configMgr.update({ intersegmentDelay });
        this.mediator.publish(constants.SET_INTERSEGMENT_DELAY, intersegmentDelay);
    }

    /**
     * The size (expressed in milliseconds) of the streaming buffer to use during VOD playback
     *
     * You should set this value prior to assigning the item to a MediaPlayer to ensure that it is considered.
     * @param {Number} playingVODBufferTime VOD buffer time when in playing state (in msec).
     */
    public setPlayingVODBufferTime(playingVODBufferTime: number): void {
        this.logger.trace("setPlayingVODBufferTime: " + playingVODBufferTime);
        this.configMgr.update({ playingVODBufferTime });
        this.mediator.publish(constants.SET_PLAYING_VOD_BUFFER_TIME, playingVODBufferTime);
    }

    /**
     * The size (expressed in milliseconds) of the streaming buffer to use during linear playback
     *
     * You should set this value prior to assigning the item to a MediaPlayer to ensure that it is considered.
     * @param {Number} playingLinearBufferTime Linear buffer time when in playing state (in msec).
     */
    public setPlayingLinearBufferTime(playingLinearBufferTime: number): void {
        this.logger.trace("setPlayingLinearBufferTime: " + playingLinearBufferTime);
        this.configMgr.update({ playingLinearBufferTime });
        this.mediator.publish(constants.SET_PLAYING_LINEAR_BUFFER_TIME, playingLinearBufferTime);
    }

    /**
     * Blocks the video/audio from rendering used in Parental Control cases.
     * @param {Boolean} flag If true the video and audio will be hiden from the user.
     */
    public setBlock(flag: boolean): void {
        this.logger.trace("setBlock: " + flag);
        this.mediator.publish(constants.SET_BLOCK, flag);
    }

    /**
     * This method specifies the preferred zoom setting for the video.  If set to Full the video should be stretched to completely
     * fill the player’s view size and should not necessarily preserve the original aspect ratio of the video.  If set to None
     * the video should be stretched to fit the player’s view as much as possible while still maintaining the video’s aspect
     * ratio (Black pillars should be displayed where appropriate).  A default value of None is assumed.
     * @param {String} zoom - Value can be either: None || Full
     */
    public setPreferredZoomSetting(zoom: string): void {

        if (!zoom) {
            return;
        }

        this.logger.trace("setPreferredZoomSetting: " + zoom);
        this.configMgr.update({ zoom });
        this.mediator.publish(constants.SET_PREFERRED_ZOOM_SETTING, zoom);
    }

    /**
     * Set 608 closed caption track. There are four tracks CC1, CC2, CC3 and CC4
     * @param {String} track String representation of desired track.
     */
    public setClosedCaptionsTrack(track: string): void {
        this.logger.trace("setClosedCaptionsTrack: " + track);
        this.mediator.publish(constants.SET_CC_TRACK, track);

        this.mediator.publish(constants.MEDIA_INFO, "CC changed. " + track);
    }

    /**
     * The bitrate interval allowed for playback (expressed in bits per second).
     * @param {Number} minimumBitrate The minimum number in the range.
     * @param {Number} maximumBitrate The maximum number in the range.
     */
    public setBitrateRange(minimumBitrate: number, maximumBitrate: number): void {
        this.logger.trace("setBitrateRange: " + minimumBitrate + "-" + maximumBitrate);
        this.configMgr.update({ minimumBitrate, maximumBitrate });
        this.mediator.publish(constants.SET_BITRATE_RANGE, minimumBitrate, maximumBitrate);
    }

    /**
     * set the player's bitrate policy
     * @param {Number} initialPolicy  The new policy value.
     */
    public setBitratePolicy(initialPolicy: number): void {
        this.logger.trace("setBitratePolicy: " + initialPolicy);
        this.configMgr.update({ initialPolicy });
        this.mediator.publish(constants.SET_BITRATE_POLICY, initialPolicy);
    }

    /**
     * Set the preferred audio langauge to use
     * @param {String} audioLanguage
     * @see getAvailableAudioLanguages
     * to get the acceptable values.
     */
    public setPreferredAudioLanguage(audioLanguage: string): void {
        if (!audioLanguage) {
            return;
        }

        this.logger.trace("setPreferredAudioLanguage: " + audioLanguage);
        this.configMgr.update({ audioLanguage });
        this.mediator.publish(constants.SET_PREFERRED_AUDIO_LANGUAGE, audioLanguage);

        this.mediator.publish(constants.MEDIA_INFO, "SAP changed. " + audioLanguage);
    }

    /**
     * Size of the video. Once the playback starts, the client can use this property to set the desired rendered video dimensions.
     * @param {Number} width The media width in pixels.
     * @param {Number} height The media height in pixels.
     */
    public setDimensionsOfVideo(width: number, height: number): void {
        this.logger.trace("setDimensionsOfVideo: " + width + "x" + height);
        this.mediator.publish(constants.SET_VIDEO_DIMENSIONS, width, height);
    }

    /**
     * Flag indicating if the player will automatically start playing the media stream once all the data is available. Defaults to false.
     * @param {Boolean} autoplay
     */
    public setAutoPlay(autoplay: boolean): void {
        this.logger.trace("setAutoPlay: " + autoplay);
        this.configMgr.update({ autoplay });
        this.mediator.publish(constants.SET_AUTOPLAY, autoplay);
    }

    /**
     * Interval between the dispatch of change events for the current time in milliseconds.
     * The default is 250 milliseconds. A zero value disables the dispatch of the change events.
     * The minimum accepted value (except zero) is 50 milliseconds, due to performance concerns. Attempting to set
     * the interval to a lower value will result in it being set to 50
     * @param {Number} interval (in milliseconds)
     */
    public setCurrentTimeUpdateInterval(interval: number): void {
        this.logger.trace("setCurrentTimeUpdateInterval: " + interval);
        if ((interval < 50) && (interval !== 0)) {
            interval = 50;
        }
        this.mediator.publish(constants.SET_CURRENT_TIME_UPDATE_INTERVAL, interval);
    }

    /**
     * @param {Number} width
     * @param {Number} height
     */
    public setScale(width: number, height: number): void {
        this.logger.trace("setScale: " + width + "x" + height);
        this.mediator.publish(constants.SET_SCALE, width, height);
    }

    /**
     * @param {Number} x
     * @param {Number} y
     */
    public setOffset(x: number, y: number): void {
        this.logger.trace("setOffset: x=" + x + " y=" + y);
        this.mediator.publish(constants.SET_OFFSET, x, y);
    }

    /**
     * Shows/Hides Video Playback
     * @param {boolean} mute
     */
    public setAudioOnly(mute: boolean): void {
        this.logger.trace("setAudioOnly: " + mute);
        this.mute = mute;
        this.mediator.publish(constants.SET_AUDIO_ONLY, mute);
    }

    /**
     * Getters PlayerPlatformAPI -------------------------------------------------
     */

    /**
     * Returns the type of the current asset.
     * @returns {String} value of assetType when the asset was created.
     */
    public getAssetType(): string {
        this.logger.trace("getAssetType");
        if (!!this.asset) {
            return this.asset.assetType;
        } else {
            return null;
        }
    }

    /**
     * Returns the type of the current asset engine.
     * @returns {String} value of assetEngine when the asset was created.
     */
    public getAssetEngineType(): string {
        this.logger.trace("getAssetEngineType");
        return this.player.getAssetEngineType();
    }

    /**
     * Returns the end position of the asset in milliseconds.
     * @returns {Number} End position of the asset in milliseconds.
     */
    public getEndPosition(): number {
        this.logger.trace("getEndPosition");
        return this.player.getEndPosition();
    }

    /**
     * Returns the start position of the asset in milliseconds.
     * @returns {Number} Start position of the asset in milliseconds.
     */
    public getStartPosition(): number {
        this.logger.trace("getStartPosition");
        return this.player.getStartPosition();
    }

    /**
     * Returns the configured initial bitrate that the video will start at.
     * @returns {Number} Initial bitrate. Will be Null if it was never set.
     * Note - PSDKPlayer returns default ABRControlParam initial bitrate
     */
    public getInitialBitrate(): number {
        this.logger.trace("getInitialbitrate");
        return this.player.getInitialBitrate();
    }

    /**
     * Returns the play head position of the player in milliseconds.
     * @returns {Number} Play head position of player in milliseconds.
     */
    @app.expose
    public getCurrentPosition(): number {
        return this.player.getCurrentPosition();
    }

    /**
     * Returns the play head position of the player in milliseconds, offset
     * by the duration of watched ads.
     * @returns {Number} Play head position of player in milliseconds within
     * the content.
     */
    @app.expose
    public getContentPosition(): number {
        if (!this.adManager) {
            return this.getCurrentPosition();
        }
        return this.adManager.getResumePositionNoAds(this.getCurrentPosition());
    }

    /**
     * Returns the last known position of the player in millisecons, offset
     * by the duration of watched ads.
     */
    @app.expose
    public getContentLastKnownPosition(): number {
        if (!this.adManager) {
            return this.lastKnownPosition;
        }
        return this.adManager.getResumePositionNoAds(this.lastKnownPosition);
    }

    /**
     * Returns the duration of the asset in milliseconds.
     * @returns {Number} Duration of the media in milliseconds.
     *
     */
    @app.expose
    public getDuration(): number {
        return this.player.getDuration();
    }

    /**
     * Returns all supported playback speeds for the asset.
     * @returns {Number[]} Array of numbers that represent currently supported playback speeds.
     */
    @app.expose
    public getSupportedPlaybackSpeeds(): number[] {
        return this.player.getSupportedPlaybackSpeeds() || [];
    }

    /**
     * Returns the current speed of the player.
     * @returns {Number} Current speed of the player.
     */
    @app.expose
    public getCurrentPlaybackSpeed(): number {
        return this.player.getCurrentPlaybackSpeed();
    }

    /**
     * Returns the current player status.
     * @returns {String} Possible values are:
     * idle,
     * complete,
     * error,
     * initialized,
     * initializing,
     * preparing,
     * seeking,
     * paused,
     * playing,
     * ready,
     * released
     */
    @app.expose
    public getPlayerStatus(): string {
        return this.player.getPlayerStatus();
    }

    /**
     * Returns a boolean indicating the status of CC.
     * @returns {Boolean} True if CC is on.
     */
    public getClosedCaptionsStatus(): boolean {
        this.logger.trace("getClosedCaptionsStatus");
        return this.player.getClosedCaptionsStatus();
    }

    /**
     * Returns the range of allowed profiles for video playback. The player stays with the range.
     * @returns {Number[]} containing the range [0] = min value, [1] = max value. (Array used to support JS bridge)
     * Array will be null if the range was never set.
     */
    public getBitrateRange(): number[] {
        this.logger.trace("getBitrateRange");
        return this.player.getBitrateRange();
    }

    /**
     * Returns the array of all available bitrates for a specific asset.
     * @returns {Number[]} containing the bitrate value for all available bitrates for the asset.
     * This is only available after MediaOpened event.
     */
    public getAvailableBitrates(): number[] {
        this.logger.trace("getAvailableBitrates");
        return this.player.getAvailableBitrates();
    }

    /**
     * Returns the bitrate of the currently playing profile.
     * @returns {Number} Current bitrate in Kbits/sec.
     */
    @app.expose
    public getCurrentBitrate(): number {
        this.logger.trace("getCurrentBitrate");
        return this.player.getCurrentBitrate();
    }

    /**
     * Returns the total buffer time of the player.
     * @returns {Number} Total buffer time in seconds.
     */
    public getBufferTime(): number {
        this.logger.trace("getBufferTime");
        return this.player.getBufferTime();
    }

    /**
     * Returns the filled buffer time.
     * @returns {Number} Time filled in the buffer in seconds.
     */
    public getBufferFilledLength(): number {
        this.logger.trace("getBufferFilledLength");
        return this.player.getBufferFilledLength();
    }

    /**
     * Returns the current audio language (track) selected
     * @returns {String} Audio track information
     */
    public getCurrentAudioLanguage(): string {
        this.logger.trace("getCurrentAudioLanguage");
        return this.player.getCurrentAudioLanguage();
    }

    /**
     * Returns the current closed caption track selected
     * @returns {String} Closed caption track information
     */
    public getCurrentClosedCaptionTrack(): string {
        this.logger.trace("getCurrentClosedCaptionTrack");
        return this.player.getCurrentClosedCaptionTrack();
    }

    /**
     * Return array of available closed caption tracks that can be rendered.
     * @returns {Object[]} Array of available CC tracks
     */
    public getAvailableClosedCaptionTracks(): string[] {
        return this.player.getAvailableClosedCaptionTracks();
    }

    /**
     * Return array of available player engine types.
     * @returns {Object[]} Array of available player types
     */
    public getAvailablePlayerTypes(): string[] {
        return this.playerTypes;
    }

    /**
     * Flag indicating if the player will automatically start playing the media stream once all the data is available. Defaults to false.
     * @returns {Boolean} True if the player will start automatically
     */
    @app.expose
    public getAutoPlay(): boolean {
        this.logger.trace("getAutoPlay");
        return this.player.getAutoPlay();
    }

    /**
     * Returns the current video height.
     * @returns {Number} Video height in pixels
     */
    public getVideoHeight(): number {
        this.logger.trace("getVideoHeight");
        return this.player.getVideoHeight();
    }

    /**
     * Returns the current video width.
     * @returns {Number} Video width in pixels
     */
    public getVideoWidth(): number {
        this.logger.trace("getVideoWidth");
        return this.player.getVideoWidth();
    }

    /**
     * Returns the current video type (can be live or vod).
     * @returns {String} Video type - either live or vod.
     */
    @app.expose
    public getVideoType(): string {
        this.logger.trace("getVideoType");
        return this.player.getVideoType();
    }

    /**
     * Indicates that the stream has CC available.
     * @returns {Boolean} True if the stream includes closed captions.
     */
    public hasCC(): boolean {
        this.logger.trace("hasCC");
        return this.player.hasCC();
    }

    /**
     *
     * Indicates the version of the Player
     * @returns {String} Concatenated version info for all components.
     */
    public getVersion(): string {
        // add other component version strings here as they become pertinent
        // TODO: remove EXPERIMENTAL HULU VERSION
        return `BUILD_TYPE=${__buildtype} VIPER_VERSION=${__viperversion} ${this.player ? this.player.getVersion() : "PLAYER NOT DEFINED"}`;
    }

    /**
     *
     * Indicates if current stream is protected by DRM
     * @returns {Boolean} True if the stream supports DRM.
     */
    public hasDRM(): boolean {
        this.logger.trace("hasDRM");
        return this.player.hasDRM();
    }

    /**
     * Returns an array of the audio languages available in the current asset
     * @returns {Object[]} Array of audio track information
     */
    public getAvailableAudioLanguages(): string[] {
        return this.player.getAvailableAudioLanguages();
    }

    /**
     * Returns the current frame rate in FPS
     * @default -1
     * @returns {Number} Current frame rate in frames/sec.
     */
    /*tslint:disable:no-accessor-field-mismatch*/
    @app.expose
    public getCurrentFPS(): number {
        return this.player.getCurrentFPS();
    }
    /*tslint:enable:no-accessor-field-mismatch*/

    /**
     * Returns the current count of dropped frames
     * @default -1
     * @returns {Number}
     */
    /*tslint:disable:no-accessor-field-mismatch*/
    public getCurrentDroppedFrames(): number {
        return this.player.getCurrentDroppedFrames();
    }
    /*tslint:enable:no-accessor-field-mismatch*/

    /**
     * Returns an array of ad markers
     * @returns {Object[]} Array of ad markers
     */
    @app.expose
    public getTimeline(): VideoAdBreak[] {
        this.logger.trace("getTimeline");

        // this will be overridden by an AdManager
        return [];
    }

    /**
     * returns current volume setting for player
     *
     * @returns {number}
     */
    public getVolume(): number {
        return this.player.getVolume();
    }

    /**
     * Configure an AdManager specified by the `type` parameter. Allowed types:
     *
     *  - manifest - Manifest manipulator ad manager
     *  - auditude - Auditude ad manager
     *  - freewheel - Freewheel ad manager
     *  - c3 - C3 ad manager
     *  - none - Use this if you want to stop using a previously configured ad manager.
     *
     * Refer to [full documentation](http://player.xcal.tv/docs/js/master/ads/#configuring-ads)
     * for details on the required configuration properties for each type of ad manager.
     *
     * @param {string} type     - type of ad manager to configuyre
     * @param {IAdConfig} [cfg]    - configuration object (may be null)
     * @deprecated("Ad manager now is configured via setAsset contentOption")
     */
    public configureAds(type: string, cfg: IAdConfig): void {
        this.logger.trace("configureAds: type=" + type);

        this.mediator.publish("ads:configureAds", type, cfg);
    }

    /**
     * generate a list of media segments representing the current asset and any ads it contains
     * @returns {Array}
     */
    public getMediaSegments(): MediaSegment[] {
        // this will be overridden by ad manager
        return [];
    }

    /**
     * Returns the asset extension if available, undefined otherwise
     * AssetExtensions are one of:
     * - "m3u8"
     * - "mpd"
     * - "f4m"
     * - "mp4"
     */
    public getVideoFormat(): AssetExtension | undefined {
        if (this.asset && this.asset.url) {
            return getUrlExtension(this.asset.url);
        } else {
            return undefined;
        }
    }

    /**
     * This should update on each media progress event. Various player properties are checked and compared to previous
     * values in order to dispatch appropriate events.
     *
     * @private
     */
    private updatePlaybackMetrics(event: events.MediaProgressEvent): void {
        this.lastKnownPosition = event.position;

        const fps = Math.round(this.getCurrentFPS());
        if (fps !== this.currentFPS) {
            this.currentFPS = fps;
            events.dispatchEvent(new events.FPSChangedEvent(fps));
        }

        const droppedFrames = this.getCurrentDroppedFrames();
        if (droppedFrames !== this.currentDroppedFrames) {
            this.currentDroppedFrames = droppedFrames;
            events.dispatchEvent(new events.DroppedFPSChangedEvent(droppedFrames));
        }

        const duration = event.endposition;
        if (duration !== this.currentDuration) {
            this.currentDuration = duration;
            events.dispatchEvent(new events.DurationChangedEvent(duration));
        }
    }

    /**
     * This is used to transfer previously set properties to a new player type.
     *
     * @private
     */
    private setPlayerConfig(): void {
        this.mediator.publish(constants.SET_BITRATE_POLICY, this.configMgr.get(ConfigurationManager.PLAYING_POLICY));

        if (this.getVideoType().toLowerCase() === "live") {
            this.mediator.publish(constants.SET_PLAYING_LINEAR_BUFFER_TIME, this.configMgr.get(ConfigurationManager.PLAYING_LINEAR_BUFFER_TIME));
        } else {
            this.mediator.publish(constants.SET_PLAYING_VOD_BUFFER_TIME, this.configMgr.get(ConfigurationManager.PLAYING_VOD_BUFFER_TIME));
        }
    }

    private setInitialPlayerConfig(): void {
        this.mediator.publish(constants.SET_INITIAL_BITRATE, this.configMgr.get(ConfigurationManager.INITIAL_BITRATE));
        this.mediator.publish(constants.SET_BITRATE_RANGE, this.configMgr.get(ConfigurationManager.MINIMUM_BITRATE), this.configMgr.get(ConfigurationManager.MAXIMUM_BITRATE));
        this.mediator.publish(constants.SET_BITRATE_POLICY, this.configMgr.get(ConfigurationManager.INITIAL_POLICY));
        this.mediator.publish(constants.SET_INITIAL_BUFFER_TIME, this.configMgr.get(ConfigurationManager.INITIAL_BUFFER_TIME));
        this.mediator.publish(constants.SET_INTERSEGMENT_DELAY, this.configMgr.get(ConfigurationManager.INTERSEGMENT_DELAY));
        this.mediator.publish(constants.SET_AUTOPLAY, this.configMgr.get(ConfigurationManager.AUTOPLAY));
    }

    /**
     * Set SAP and CC when receiving a media opened event.
     *
     * @private
     */
    private onMediaOpened(): void {

        try {
            this.setPreferredAudioLanguage(this.configMgr.get(ConfigurationManager.AUDIO_LANGUAGE));
        } catch (error) {
            this.logger.error("Error restoring SAP state: " + error);
        }

        try {
            this.setClosedCaptionsEnabled(this.closedCaptionsEnabled);
        } catch (error) {
            this.logger.error("Error restoring CC state: " + error);
        }

        if (this.pendingPlaybackStart) {
            this.pendingPlaybackStart.unsubscribe();
            this.pendingPlaybackStart = null;
        }

        // need to filter out payback rates of 0. searching around while autoplay
        // is disabled produces progress event with a rate of 0.
        this.pendingPlaybackStart = this.streams.mediaProgresses
            .pipe(
                filter((evt: events.MediaProgressEvent) => evt.playbackSpeed !== 0),
                take(1),
                takeUntil(app.toObservable(constants.DESTROY))
            )
            .subscribe(() => this.dispatchEvent(new events.PlaybackStartedEvent()));
    }

    private onPlayStateChanged(event: events.PlayStateChangedEvent): void {
        // don't do this in XRE environment since media opened is fired too early
        if (event.playState === constants.STATUS_PLAYING && !this.isXRE()) {
            this.setPlayerConfig();
        }
    }

    /**
     * Returns the Mime type based on the enginetype used in playing EAS
     */
    private getMimeTypeForEASPoller(): string {
        let mimeType: string = EmergencyAlert.MIME_HLS;
        if (this.configMgr.get(ConfigurationManager.HELIO_EAS, false) ||
            this.configMgr.get(ConfigurationManager.HELIOFUSION_EAS, false)) {
            mimeType = EmergencyAlert.MIME_DASH;
        }
        return mimeType;
    }

    /**
     * Determine if platform is running in an XRE environment.
     *
     * @returns {boolean}
     * @private
     */
    @app.expose
    public isXRE(): boolean {
        return ((typeof AdobePSDK !== "undefined" || typeof AAMP !== "undefined") && typeof _xrePlayerPlatform !== "undefined");
    }

    /**
     * Closed Caption Boolean. Legal values are "true" | "false".
     * @typedef {string} ClosedCaptionBoolean
     */

    /**
     * Closed Caption Colors. Legal values include "0x000000" - "0xFFFFFF" | "red" | "green" | "blue" |
     *     "cyan" | "magenta" | "yellow" | "white" | "black".
     * @typedef {string} ClosedCaptionColor
     */

    /**
     * Closed Caption Edges. Legal values include "none" | "raised" | "depressed" | "uniform" |
     *     "drop_shadow_left" | "drop_shadow_right".
     * @typedef {string} ClosedCaptionEdge
     */

    /**
     * Closed Caption Font Style. Legal values include "default" | "monospaced_serif" | "proportional_serif" |
     *     "monospaced_sanserif" | "proportional_sanserif" | "casual" | "cursive" | "smallcaps".
     * @typedef {string} ClosedCaptionFontStyle
     */

    /**
     * Closed Caption Opacity. Legal values include "solid" | "flash" | "translucent" | "transparent".
     * @typedef {string} ClosedCaptionOpacity
     */

    /**
     * Closed Caption Pen Size. Legal values include "small" | "standard" | "large".
     * @typedef {string} ClosedCaptionPenSize
     */

    /**
     * Closed Caption Style Object
     * @typedef {Object} ClosedCaptionStyle
     * @property {ClosedCaptionFontStyle} fontStyle
     * @property {ClosedCaptionColor} textEdgeColor
     * @property {ClosedCaptionEdge} textEdgeStyle
     * @property {ClosedCaptionColor} textForegroundColor
     * @property {ClosedCaptionOpacity} textForegroundOpacity
     * @property {ClosedCaptionBoolean} penItalicized
     * @property {ClosedCaptionBoolean} penUnderline
     * @property {ClosedCaptionPenSize} penSize
     * @property {ClosedCaptionColor} windowBorderEdgeColor
     * @property {ClosedCaptionEdge} windowBorderEdgeStyle
     * @property {ClosedCaptionColor} windowFillColor
     * @property {ClosedCaptionOpacity} windowFillOpacity
     */

    /**
     * Closed Caption Style Options
     * @typedef {Object} ClosedCaptionStyleOptions
     * @property {ClosedCaptionFontStyle[]} fontStyle
     * @property {ClosedCaptionColor[]} textEdgeColor
     * @property {ClosedCaptionEdge[]} textEdgeStyle
     * @property {ClosedCaptionColor[]} textForegroundColor
     * @property {ClosedCaptionOpacity[]} textForegroundOpacity
     * @property {ClosedCaptionBoolean[]} penItalicized
     * @property {ClosedCaptionBoolean[]} penUnderline
     * @property {ClosedCaptionPenSize[]} penSize
     * @property {ClosedCaptionColor[]} windowBorderEdgeColor
     * @property {ClosedCaptionEdge[]} windowBorderEdgeStyle
     * @property {ClosedCaptionColor[]} windowFillColor
     * @property {ClosedCaptionOpacity[]} windowFillOpacity
     */

    /**
     * Object containing arrays of available closed captions rendering options that can be modified based on user settings.
     * @returns {ClosedCaptionStyleOptions} Arrays of CC rendering options
     */
    public getSupportedClosedCaptionsOptions(): any {
        this.logger.trace("getSupportedClosedCaptionsOptions");
        return this.player.getSupportedClosedCaptionsOptions();
    }

    /**
     * Returns an object containing the current closed caption style settings.
     * @deprecated should use getClosedCaptionsStyle instead
     * @returns {ClosedCaptionStyle}
     */
    public getCurrentClosedCaptionsStyle(): IPlayerCCStyle {
        this.logger.trace("getCurrentClosedCaptionsStyle");
        return this.player.getCurrentClosedCaptionsStyle();
    }

    /**
     * Returns an object containing the current closed caption style settings.
     * @returns the current closed captions style
     */
    public getClosedCaptionsStyle(): IPlayerCCStyle | undefined {
        return this.closedCaptionsStyle;
    }

    /**
     * Sets the desired closed caption style.
     * @param {ClosedCaptionStyle} ccStyle
     */
    public setClosedCaptionsStyle(ccStyle: IPlayerCCStyle): void {
        this.logger.trace("setClosedCaptionsStyle");
        this.mediator.publish(
            constants.SET_CC_STYLE,
            this.closedCaptionsStyle = ccStyle
        );
    }

    public notifyClick(): void {
        this.logger.trace("notifyClick");
        this.mediator.publish(constants.NOTIFY_CLICK);
    }

    private _clickHandler(): void {
        if (this.configMgr.get(ConfigurationManager.HANDLE_CLICKS)) {
            this.notifyClick();
        }
    }
}

export interface IPlayerPlatformAPIParams {
    configurationManager?: ConfigurationManager;
    configuration?: IConfigOptions;
    logger?: Logger;
    videoElement: HTMLElement;
    secClient?: SecClient;
}

export interface IXsctTokenSummary {
    isCDVREntitled?: boolean;
    isVODEntitled?: boolean;
    isLinearEntitled?: boolean;
    userType?: string;
    inHomeStatus: string;
    currentRestrictions?: any[];
    isCloudServiceAvailable?: boolean;
    notOnOrAfter?: number;
    notBefore?: number;
    deviceId: string;
    partnerId?: string;
    mso?: string;
    entitlements?: string[];
    xboAccountId: string;
}

/**
 * This interface mirrors the JSON response received from the
 * XTVAPI create-session endpoint.
 */
export interface IXsctResponse {
    /**
     * Base64 encoded XSCT token
     */
    xsct: string;

    /**
     * Token summary returned from XTVAPI
     */
    tokenSummary: IXsctTokenSummary;
}

/**
 * @hidden
 */
declare const AdobePSDK: any;
declare const AAMP: any;

/**
 * @hidden
 */
declare const __viperversion: string;

/**
 * @hidden
 */
declare const __buildtype: string;

/**
 * @hidden
 */
declare const _xrePlayerPlatform: XREPlayerPlatform;
