import { IPPModule, IPPSandbox } from "../PlayerPlatformApplication";
import { registerModule } from "../Application";
import { TAG_SOURCE_STREAM, TAG_SERVICE_ZONE_REQUIRED } from "../util/hls/HlsTagFactory";
import { HlsSourceStreamTag } from "../util/hls/HlsSourceStreamTag";
import { Logger } from "../util/Logger";
import { emit, StreamSwitchEvent, ServiceZoneEvent } from "../PlayerPlatformAPIEvents";
import { VirtualStreamStitcherAsset } from "../assets/VirtualStreamStitcherAsset";
import { map, switchMap, filter, takeUntil } from "rxjs/operators";


export class VirtualStreamStitcherHandler implements IPPModule<VirtualStreamStitcherHandler> {

    private readonly logger: Logger = new Logger("VirtualStreamStitcherHandler");
    private lastEmittedEventKey: string;
    private initialVssTagKey: string;
    private count: number;
    private key: string;

    public init(sandbox: IPPSandbox) {
        this.initStreams(sandbox);
        return this;
    }

    private initStreams(sandbox: IPPSandbox): void {

        sandbox.streams.setAssets
        .pipe(
            switchMap(() => sandbox.streams.tags),
            filter( tag =>
                tag.name === TAG_SERVICE_ZONE_REQUIRED &&
                (Boolean)(tag.content)
            ),
            takeUntil(sandbox.destroyed)
        )
        .subscribe(tag => {
            emit(new ServiceZoneEvent(tag.content));
        });

        sandbox.streams.setAssets
            .pipe(
                switchMap(
                    (asset) => sandbox.streams.tags
                                .pipe(map((tag) => ({ asset: asset as VirtualStreamStitcherAsset, tag: tag as HlsSourceStreamTag })))
                ),
                filter(assetTag =>
                    assetTag.asset instanceof VirtualStreamStitcherAsset &&
                    assetTag.asset.contentOptions.vss !== undefined &&
                    assetTag.tag.name === TAG_SOURCE_STREAM &&
                    (Boolean)(assetTag.tag.signal)
                ),
                takeUntil(sandbox.destroyed)
            )
            .subscribe(assetTag => {
                this.processVssAssetTag(assetTag);
            });
    }

    private processVssAssetTag(assetTag: {asset: VirtualStreamStitcherAsset, tag: HlsSourceStreamTag }): void {

        assetTag.asset.sourceStreamId = this.parseStreamIdFromAssetTagId(assetTag.tag.id);
        assetTag.asset.signalId = assetTag.tag.signal || assetTag.asset.sourceStreamId;

        const currentKey: string = assetTag.asset.signalId + assetTag.asset.sourceStreamId;

        if (this.initialVssTagKey === undefined && this.lastEmittedEventKey === undefined) {
             this.initialVssTagKey = currentKey;
        }

        if (currentKey !== this.key) {
            this.key = currentKey;
            this.count = 1;
        } else if (currentKey === this.key) {
            this.count = this.count + 1;
        }

        if (this.count === 3 && currentKey !== this.lastEmittedEventKey && currentKey !== this.initialVssTagKey) {
            emit(new StreamSwitchEvent(assetTag.asset.sourceStreamId, assetTag.asset.signalId));
            this.lastEmittedEventKey = this.key;
            this.key = "";
            this.initialVssTagKey = null;
            this.count = 0;
        }

    }

    private parseStreamIdFromAssetTagId(merlinUrn: string): string {
        if (!merlinUrn) {
            return "";
        }
        const matches = merlinUrn.match(/^[0-9]{19}$/);
        if (!matches) {
            this.logger.warn(`could not parse stream id from urn ${merlinUrn}`);
            return "";
        }
        return matches[0];
    }

    public destroy(_sandbox: IPPSandbox): void {
        this.lastEmittedEventKey = null;
        this.initialVssTagKey = undefined;
    }
}

registerModule("VirtualStreamStitcherHandler", VirtualStreamStitcherHandler, { autostart: true });
