import { ProgressWindow } from "../../util/ProgressWindow";
import { ITrackable } from "./ITrackable";
import { TrackerEventType, ITrackerEvent } from "./TrackerEvent";
import { predicates } from "./TrackerPredicates";
import { from, ConnectableObservable, Observable, Subject } from "rxjs";
import { flatMap, switchMap, multicast } from "rxjs/operators";

const DEFAULT_TYPES = [
    TrackerEventType.COMPLETE,
    TrackerEventType.EXIT,
    TrackerEventType.START,
    TrackerEventType.ENTER,
    TrackerEventType.PROGRESS,
    TrackerEventType.SKIPPED,
    TrackerEventType.INTERRUPT
];

/**
 * Provides the ability to track certain events on a timeline given a `ITrackable` object.
 * These are tracked using an Observable returned from one of these methods. The stream
 * will fire `ITrackerEvent`s when the appropriate event is reached on the timeline.
 */
export class TimelineTracker {

    private _window: Subject<ProgressWindow> = new Subject<ProgressWindow>();

    constructor(startObs: Observable<any>, progressObs: Observable<ProgressWindow>) {
        // use switchMap to start the progress stream over when a new item in the start stream is received
        (startObs.pipe(switchMap(() => progressObs), multicast(this._window)) as ConnectableObservable<ProgressWindow>).connect();
    }

    /**
     * Tears down ProgressWindow subject by firing the complete event.
     */
    public destroy() {
        this._window.complete();
    }

    /**
     * Determines what `ITrackerEvent`s should be fired for `trackable` given the location contained in the
     * given `progress` window.
     */
    private _trackProgress<T extends ITrackable>(progress: ProgressWindow, trackable: T, types: TrackerEventType[]): ITrackerEvent<T>[] {
        return types
                   .filter(type => predicates[type](progress, trackable))
                   .map(type => ({ type, progress, trackable }));
    }

    /**
     * Tracks a given `trackable` object by monitoring the timeline and firing `ITrackerEvents` on the returned
     * observable stream. On each progress event, all event types given in the `types` array will be checked.
     */
    public track<T extends ITrackable>(trackable: T, types: TrackerEventType[] = DEFAULT_TYPES): Observable<ITrackerEvent<T>> {
        return this._window.pipe(flatMap(progress =>
            from(this._trackProgress(progress, trackable, types)))
        );
    }

    /**
     * Tracks an Observable of `ITrackable` objects returned from the given callback by monitoring the timeline and
     * firing `ITrackerEvents` on the returned observable stream. On each progress event, all event types given in
     * the `types` array will be checked for every `ITrackable` in the Observable returned by the callback.
     */
    public trackCb<T extends ITrackable>(cb: (progress: ProgressWindow) => Observable<ITrackable>, types: TrackerEventType[] = DEFAULT_TYPES): Observable<ITrackerEvent<T>> {
        return this._window.pipe(flatMap(progress =>
            cb(progress).pipe(flatMap((trackable: T) =>
                from(this._trackProgress(progress, trackable, types))))));
    }
}
