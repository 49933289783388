/* tslint:disable:no-console */

declare const global: any;
declare const videoDiv: HTMLElement;
declare const XREReceiver: any;
declare const AdobePSDK: any;
declare const _playerPlatformAPI: PlayerPlatformAPI;
declare const _xrePlayerPlatform: XREPlayerPlatform;
declare const __configPath: string;

/**
 * Partner config regex.
 * NOTE: if a new partner config is required, it **must** be added to the regex below.
 */
const PARTNER_CONFIG_RE = /^(comcast|cox|rogers|shaw|videotron)/;
require("./config/default.json");
require("./config/comcast.json");
require("./config/cox.json");
require("./config/cox-dev.json");
require("./config/shaw.json");
require("./config/shaw-dev.json");
require("./config/rogers.json");
require("./config/rogers-dev.json");
require("./config/videotron.json");
require("./config/videotron-dev.json");
require("polyfill-function-prototype-bind");

import { Logger } from "../util/Logger";
import { XREPlayerPlatform } from "./XREPlayerPlatform";
import { ConfigurationManager } from "../ConfigurationManager";
import { PlayerPlatformAPI } from "../PlayerPlatformAPI";
import { parseQueryParams } from "../util/JSUtil";
import * as app from "../Application";
import "./XREEventManager";

const logger = new Logger("XREMain");
const configMgr = ConfigurationManager.getInstance();


// global variables
global._xrePlayerPlatform = null;
global._xreEventManager = null;
global._playerPlatformAPI = null;

/**
 * URL query parameters
 *
 * @property deviceType
 * @property estbMacAddress
 * @property partnerId
 * @property receiverVersion
 * @property receiverPlatform
 * @type {*|Object}
 */
const query = parseQueryParams(location.search);

const partnerId = parsePartnerId(query.partnerId);
const deviceVersion = parseDeviceVersion(query.receiverPlatform);
const deviceID = query.estbMacAddress;

const analyticsAppNames: any = {
    "xg1": "Xg1",
    "xg1v3": "Xg1v3",
    "xg2": "Xg2",
    "xi3": "Xi3",
    "xi4": "Xi4"
};

let rectNeedsUpdate: boolean = false;

// setup deprecated global functions
Object.getOwnPropertyNames(XREPlayerPlatform.prototype).forEach(createGlobalFunction);

global.onXREReady = function() {
    console.log("onXREReady");

    if (typeof XREReceiver === "undefined") {
        throw new Error("XREReceiver not available");
    }

    global._xrePlayerPlatform = new XREPlayerPlatform({
        configurationManager: configMgr,
        deviceID: deviceID
    });

    try {
        // Configuration setup
        configMgr.onSuccess(onConfigLoaded);
        configMgr.onFailure(onConfigFailed);

        const partnerConfig = PARTNER_CONFIG_RE.test(partnerId) ? partnerId : "default";

        const configUrl = `${__configPath}/${partnerConfig.toLowerCase()}.json${location.search}`;
        configMgr.loadConfiguration(configUrl);
    } catch (error) {
        onReadyFailed(`Couldn't create the PlayerPlatformAPI: ${error.toString()}`);
    }
};

function onReadyFailed(description: string) {
    const debug = { partnerId, deviceVersion, query };

    console.error("XREReceiver.onReadyFailed");
    console.error(`${description} --- ${JSON.stringify(debug, null, 2)}`);

    XREReceiver.onEvent("onReadyFailed", { description, debug });
}

function onConfigLoaded(values: any) {
    logger.info("Configuration loaded: " + JSON.stringify(values, null, 2));
    const device = deviceType(query.receiverPlatform) || "";

    configMgr.extendConfiguration(device.toLowerCase(), ConfigurationManager.DEFAULT_ASSET);
    configMgr.update(ConfigurationManager.DRM_ENABLED, false);
    configMgr.update(ConfigurationManager.PARTNER_ID, values.partnerId || partnerId);

    // Initialize the Player and Analytics
    const params = {
        configurationManager: configMgr,
        videoElement: videoDiv,
        deviceID
    };

    global._playerPlatformAPI = new PlayerPlatformAPI(params);
    global._xreEventManager = app.startModule("XREEventManager", _playerPlatformAPI, query.deviceType);

    logVersion();

    if (shouldEnableAds()) {
        console.log("enabling dynamic ad insertion");
        _xrePlayerPlatform.enableAds = true;
    } else {
        console.log("dynamic ad insertion disabled");
    }

    if (rectNeedsUpdate) {
        rectNeedsUpdate = false;
        UpdateVideoRect();
    }

    console.log("XREReceiver.onReady");
    XREReceiver.onEvent("onReady", {});

    const deviceName = analyticsAppNames[device.toLowerCase()] || device.toUpperCase();
    const appName = `${configMgr.get(ConfigurationManager.ANALYTICS_DEVICE_TYPE)}-${deviceName}`;

    // Enable Analytics as part of player setup
    _playerPlatformAPI.configureAnalytics({
        appName: appName,
        appVersion: query.receiverVersion,
        deviceId: query.estbMacAddress,
        deviceVersion: parseDeviceVersion(query.receiverPlatform),
        deviceName: query.deviceType,
        xsctPartnerId: parsePartnerId(query.partnerId),
        inHomeState: function() { return "inHome"; },
        messageOptions: _xrePlayerPlatform.messageOptions
    });
}

function logVersion(): void {
    try {
        console.log(`PlayerPlatformAPI: ${_playerPlatformAPI.getVersion()}`);
        console.log(`AdobePSDK: ${AdobePSDK.version.description}`);
    } catch (error) {
        console.log("Error retrieving version info.");
    }
}

function parsePartnerId(id: string): string {
    // Default to comcast for any falsy value
    return (id || "comcast").toLowerCase();
}

function shouldEnableAds() {
    return configMgr.get(ConfigurationManager.ENABLE_ADS) && configMgr.get(ConfigurationManager.ENABLE_ADS) !== "false";
}

function onConfigFailed(error: string, url: string) {
    onReadyFailed(`Config loading failed with error: ${error} -- URL: ${url}`);

}

function deviceType(receiverPlatform: string) {
    if (typeof receiverPlatform === "string") {
        return receiverPlatform.split("_")[1];
    }
}

function parseDeviceVersion(receiverPlatform: string) {

    if (query.receiverVersion) {
        return query.receiverVersion;
    }

    if (typeof receiverPlatform === "string") {
        return receiverPlatform.split("_")[2];
    }
}

/**
 * sets a function with the given name on the global object that
 * forwards to _xrePlayerPlatform
 *
 * @param name - name of function
 */
function createGlobalFunction(name: string) {

    // don't create globals for these methods
    if (name === "constructor" || name === "onCallMethod") {
        return;
    }

    global[name] = function() {
        logger.warn("WARNING! DEPRECATED! Please use _xrePlayerPlatform");
        (_xrePlayerPlatform as any)[name].apply(_xrePlayerPlatform, arguments);
    };
}

/**
 * Local function to handle resize events from browser
 */
global.onBodyResize = function() {
    logger.info("onBodyResize calling UpdateVideoRect");
    UpdateVideoRect();
};

function UpdateVideoRect() {
    const vidElement = document.getElementById("videoDiv");
    if (vidElement) {
        const rect = vidElement.getBoundingClientRect();
        logger.info("UpdateVideoRect videoDiv = " + rect.left + ", " + rect.top + " - " + rect.width + " x " + rect.height);
        if (!_playerPlatformAPI) {
            rectNeedsUpdate = true;
            return;
        }
        _playerPlatformAPI.setDimensionsOfVideo(rect.width, rect.height);
    }
}
