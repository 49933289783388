import "../psn/PSNDispatcher";
import "../../engines/flash/FlashEventToEventMapper";

import { IPPSandbox } from "../../PlayerPlatformApplication";
import { AdManager } from "../AdManager";
import { IAdBreak, IManifestTag } from "../IAdSlot";
import { registerPlugin } from "../../Application";
import { AdManagerTypes, ICustomAdConfig } from "../IAdConfig";
import { CustomAdContentFactory } from "./CustomAdContentFactory";
import * as constants from "../../PlayerPlatformConstants";

export class CustomAdManager extends AdManager {
    public cfg: ICustomAdConfig;

    public readonly type: AdManagerTypes = AdManagerTypes.CUSTOM;

    public init(sandbox: IPPSandbox, cfg: ICustomAdConfig): AdManager {
        super.init(sandbox, cfg, false);
        this.cfg = cfg;

        sandbox.subscribe("ads:resolve", this._onResolve, constants.PRIORITY_DEFAULT, this);

        this.subscribedTags = this.cfg.tags;

        return this;
    }

    public destroy(sandbox: IPPSandbox): void {
        super.destroy(sandbox);
        sandbox.remove("ads:resolve", this._onResolve);
    }

    protected updateResumePosition(): void {
         /* */
    }

    public hasContentFactory(): boolean {
        return true;
    }

    public getContentFactory(): AdobePSDK.ContentFactory {
        return new CustomAdContentFactory(this.sandbox, this.cfg).makeContentFactory();
    }

    public getMetadata() {
        return {
            adType: this.type,
            subscribedTags: this.subscribedTags
        };
    }

    private _onPlacementResolve(opportunityId: string, placement: IAdBreak) {
        this.sandbox.publish("ads:timelineResolved", opportunityId, [placement]);
    }

    /**
     * Called by the FLASH code via the sandbox mediator
     * to resolve an opportunity.
     */
    private _onResolve(opportunity: AdobePSDK.Opportunity): void {
        const tag: IManifestTag = {
            tagName: opportunity.customParameters.name,
            tagParams: opportunity.customParameters.content
        };

        this.cfg.resolve(tag)
            .then((adBreak: IAdBreak) => {
                this._onPlacementResolve(opportunity.id, adBreak);
            });
    }
}

registerPlugin("CustomAdManager", CustomAdManager, {
    children: ["FlashEventToEventMapper"]
});
