import { IPPModule, IPPSandbox } from "../../PlayerPlatformApplication";
import * as events from "../../PlayerPlatformAPIEvents";
import { registerModule } from "../../Application";
import { VideoAd } from "../../ads/VideoAd";
import { VideoAdBreak } from "../../ads/VideoAdBreak";
import { AD_BREAK_COMPLETE, AD_BREAK_START, AD_START, AD_PROGRESS, AD_COMPLETE } from "../../engines/flash/FlashPlayerEvents";
import * as constants from "../../PlayerPlatformConstants";
import { toObservable } from "../../Application";

import { merge } from "rxjs";
import { filter, mapTo, distinctUntilChanged, withLatestFrom, tap, map, takeUntil } from "rxjs/operators";

interface IFlashAd {
    id: string;
    duration: number;
    vpaid: boolean;
    watched: boolean;
    clickUrl: string;
    companionAds: any[];
}

interface IFlashAdEvent {
    ad: IFlashAd;
    time: number;
    progress: number;
    playbackSpeed: number;
    position: number;
}

interface IFlashAdBreakEvent {
    adBreak: IFlashAdBreak;
}

interface IFlashAdBreak {
    ads: IFlashAd[];
    id: string;
    duration: number;
}

interface IAdBreakAndAd {
    adBreak: IFlashAdBreakEvent;
    ad: IFlashAdEvent;
}

export class FlashEventToEventMapper implements IPPModule<FlashEventToEventMapper> {

    private adStartTime: number = 0;
    private sandbox: IPPSandbox;

    public init(sandbox: IPPSandbox): FlashEventToEventMapper {

        this.sandbox = sandbox;


        toObservable<IFlashAdEvent>(AD_START)
            .pipe(
                tap((event: IFlashAdEvent) => this.adStartTime = event.time),
                map((event: IFlashAdEvent) => this._toAdStartEvent(event)),
                takeUntil(this.sandbox.destroyed)
            )
            .subscribe((event: events.AdStartEvent) => events.emit(event));

        toObservable<IFlashAdEvent>(AD_COMPLETE)
            .pipe(
                map((event: IFlashAdEvent) => this._toAdCompleteEvent(event)),
                takeUntil(this.sandbox.destroyed)
            )
            .subscribe((event: events.AdCompleteEvent) => events.emit(event));

        /**
         *  SWF based flash ads will emit progress
         *  events even when playback is paused.
         *  The distinctUntilChanged captures only
         *  when progress is actually happening.
         */
        toObservable<IFlashAdEvent>(AD_PROGRESS)
            .pipe(
                distinctUntilChanged((event1: IFlashAdEvent, event2: IFlashAdEvent) => event1.position === event2.position),
                map((event: IFlashAdEvent) => this._toAdProgressEvent(event)),
                takeUntil(this.sandbox.destroyed)
            )
            .subscribe((event: events.AdProgressEvent) => events.emit(event));

        toObservable<IFlashAdBreakEvent>(AD_BREAK_START)
            .pipe(
                map((event: IFlashAdBreakEvent) => this._toAdBreakStartEvent(event)),
                takeUntil(this.sandbox.destroyed)
            )
            .subscribe((event: events.AdBreakStartEvent) => events.emit(event));

        toObservable<IFlashAdBreakEvent>(AD_BREAK_COMPLETE)
            .pipe(
                map((event: IFlashAdBreakEvent) => this._toAdBreakCompleteEvent(event)),
                takeUntil(this.sandbox.destroyed)
            )
            .subscribe((event: events.AdBreakCompleteEvent) => events.emit(event));

        /**
         * Flash does not provide us with an ad exited or an ad break
         * exited event. This observable chain listens for an `api:stop`
         * and then determines if we are currently displaying a flash ad.
         * If we are displaying a flash ad then a PlayerPlatformAPI
         * AdBreakExited and AdExited event are emitted
         */
        toObservable(constants.STOP)
            .pipe(
                withLatestFrom(
                    merge(
                        toObservable<IFlashAdEvent>(AD_START),
                        toObservable<IFlashAdEvent>(AD_PROGRESS),
                        toObservable(AD_COMPLETE).pipe(mapTo(undefined))
                    ),
                    merge(
                        toObservable<IFlashAdBreakEvent>(AD_BREAK_START),
                        toObservable(AD_BREAK_COMPLETE).pipe(mapTo(undefined))
                    ),
                    (_, adEvent: IFlashAdEvent, adBreakEvent: IFlashAdBreakEvent): IAdBreakAndAd => {
                        return {
                            adBreak: adBreakEvent,
                            ad: adEvent
                        };
                    }
                ),
                filter((last: IAdBreakAndAd) => last.adBreak !== undefined && last.ad !== undefined),
                map((last: IAdBreakAndAd): events.PlayerPlatformAPIEvent[] => {
                    return [
                        this._toAdExitedEvent(last.ad),
                        this._toAdBreakExitedEvent(last.adBreak)
                    ];
                }),
                takeUntil(this.sandbox.destroyed)
            )
            .subscribe((mappedEvents: events.PlayerPlatformAPIEvent[]) => {
                mappedEvents.forEach((event: events.PlayerPlatformAPIEvent) => events.emit(event));
            });

        return this;
    }

    public destroy(): void {
        /* */
    }

    private _toAdBreakStartEvent(event: IFlashAdBreakEvent): events.AdBreakStartEvent {
        return new events.AdBreakStartEvent(this._toVideoAdBreak(event));
    }

    private _toAdBreakCompleteEvent(event: IFlashAdBreakEvent): events.AdBreakCompleteEvent {
        return new events.AdBreakCompleteEvent(this._toVideoAdBreak(event));
    }

    private _toAdBreakExitedEvent(event: IFlashAdBreakEvent): events.AdBreakExitedEvent {
        return new events.AdBreakExitedEvent(this._toVideoAdBreak(event));
    }

    private _toVideoAdBreak(event: IFlashAdBreakEvent): VideoAdBreak {
        const ads: VideoAd [] = [];
        let startTime: number = 0;
        // Actionscript will give us an array like object and
        // not an actual array.
        for (let i = 0, length = event.adBreak.ads.length; i < length; i++) {
            const ad = event.adBreak.ads[i];
            ads.push(this._toVideoAd(ad, startTime));
            startTime += ad.duration;
        }

        return new VideoAdBreak(ads);
    }

    private _toAdProgressEvent(event: IFlashAdEvent): events.AdProgressEvent {
        return new events.AdProgressEvent(
            this._toVideoAd(event.ad, this.adStartTime),
            event.progress,
            event.playbackSpeed,
            event.position - this.adStartTime
        );
    }

    private _toAdCompleteEvent(event: IFlashAdEvent): events.AdCompleteEvent {
        return new events.AdCompleteEvent(
            this._toVideoAd(event.ad, this.adStartTime),
            event.progress,
            event.playbackSpeed,
            event.position - this.adStartTime
        );
    }

    private _toAdExitedEvent(event: IFlashAdEvent): events.AdExitedEvent {
        return new events.AdExitedEvent(
            this._toVideoAd(event.ad, this.adStartTime),
            event.progress,
            event.playbackSpeed,
            event.position - this.adStartTime
        );
    }

    private _toAdStartEvent(event: IFlashAdEvent): events.AdStartEvent {
        return new events.AdStartEvent(
            this._toVideoAd(event.ad, this.adStartTime),
            event.progress,
            event.playbackSpeed,
            event.position - this.adStartTime
        );
    }

    private _toVideoAd(ad: IFlashAd, startTime: number): VideoAd {
        const videoAd: VideoAd = new VideoAd(
            ad.id,
            startTime,
            ad.duration, {
                vpaid: ad.vpaid,
                clickThrough: ad.clickUrl,
                companionAds: ad.companionAds
            }
        );
        videoAd.watched = ad.watched;

        return videoAd;
    }
}

registerModule("FlashEventToEventMapper", FlashEventToEventMapper);
