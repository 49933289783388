import { MPX_ERROR, PPError, VIRTUAL_STREAM_STITCHER_ERROR, VirtualStreamStitcherErrorCodes } from "../../PPError";
import { MpxParser } from "../../MpxParser";
import { VirtualStreamStitcherAsset } from "../../assets/VirtualStreamStitcherAsset";
import { AnalyticsHandler } from "../../handlers/AnalyticsHandler";
import { formatLocalDateTimeStamp, parseQueryParams, replaceQueryParams } from "../../util/JSUtil";
import { IAdConfig } from "../../ads/IAdConfig";
import { OttAsset } from "./overTheTop/OttAsset";
import { Logger } from "../../util/Logger";
import { ConfigurationManager } from "../../ConfigurationManager";
import { AssetEngine, IContentOptions, ServiceZone } from "../../assets/ContentOptions";
import { SessionManager } from "../../handlers/SessionManager";
import { AdManagerKeys } from "../../ads/AdManagerFactory";
import { BaseAsset } from "../../assets/BaseAsset";
import { StreamFormat, IVirtualStreamStitcherOptions } from "../../assets/ContentOptions";
import { IManifestManipulatorAdConfig } from "../../ads/IAdConfig";
import { XuaAssetFactory } from "../../analytics/XuaAssetFactory";
import { NbcConditionedStreamApi, INbcConditionedStreamConfig } from "../../assets/NbcConditionedStreamApi";
import { getOttUrl } from "./overTheTop/Ott";
import { ajax } from "rxjs/ajax";

declare const global: any;

export const MPX_RE: RegExp = /^(https?):\/\/link\.theplatform\.com\//;
export const MM_RE: RegExp = /^(https?):\/\/mm\./;
export const CCR_RE: RegExp = /^(https?):\/\/ccr\./;
export const TR_RE: RegExp = /^(https?):\/\/tr\./;
export const FOG_RE: RegExp = /tsb\?((clientId=[^&]*&recordedUrl=[^&]*)|(recordedUrl=[^&]*&clientId=[^&]*)){1}/;

/**
 * FOG provides the URL in a query string parameter.
 * This is they key of that query string parameter.
 */
const FOG_URL_KEY: string = "recordedUrl";

const logger: Logger = new Logger("URLService");

/**
 * The goals of this URLService are to provide additional information on URLs which
 * player platform is provided.
 *
 * These functions should remain stateless. The goal is that one day these functions could reside
 * as a micro service on another server.
 *
 * Anytime we manipulate the URL we are provided we should provide that manipulation through one of the
 * end point functions belows.
 *
 * All of these functions should be the equivalent of some restful service end point.
 */

/**
 * Determines if a provided asset is for use with a manifest manipulator server.
 * This check currently relies on what is provided in the ad config.
 * @param adConfig the ad confuration to check
 * @returns true iff the ad config is of MANIFEST type
 */
function isManifestManipulator(adConfig: IAdConfig): boolean {
    return adConfig && adConfig.type === AdManagerKeys.MANIFEST;
}

/**
 * Writes a property to an object only if the value is not undefined.
 * @param baseObject the base object to update with the property
 * @param propertyKey the property key to add to the base object
 * @param value the value to add if not undefined
 */
function coalesceProperty(baseObject: { [x: string]: string | number | undefined }, propertyKey: string, value: string | number | undefined): void {
    if (value !== undefined) {
        baseObject[propertyKey] = value;
    }
}

/**
 * The manifest manipulator server relies on player platform to provide it data about the
 * client which is playing it. Some of this data is needed just prior to playing back an
 * asset. This function provides a url which will provide the data the manifest manipulator
 * server requires. It is intended to be used just prior to passing a url to an underlying player.
 * @param url the url which we plan to provide to an underlying player
 * @param asset the asset associated with the url
 * @param adConfig the ad config associated with the asset and url
 * @returns fully resolved url which is used for manifest manipulator playback
 */
function prepareManifestManipulator(url: string, asset: BaseAsset, adConfig: IManifestManipulatorAdConfig): string {
    logger.trace("prepareManifestManipulator", url);
    const params = parseQueryParams(url);
    params.StreamType = params.StreamType || "VOD_T6";
    params.AssetId = params.AssetId || asset.assetId;
    params.ProviderId = params.ProviderId || asset.providerId;
    params.DeviceId = adConfig.deviceID || adConfig.terminalAddress;
    params.sid = SessionManager.instance.playbackSessionId;
    params.dtz = formatLocalDateTimeStamp();
    params.PartnerId = `private:${params.partnerId || ConfigurationManager.getInstance().get(ConfigurationManager.PARTNER_ID)}`;

    // Append settings for freewheel, these may not exist
    coalesceProperty(params, "nw", adConfig.networkId);
    coalesceProperty(params, "prof", adConfig.playerProfile);
    coalesceProperty(params, "csid", adConfig.siteSectionId);
    coalesceProperty(params, "caid", adConfig.assetId);
    coalesceProperty(params, "_fw_h_x_country", adConfig.countryCode);
    coalesceProperty(params, "_fw_h_x_postal_code", adConfig.postalCode);
    coalesceProperty(params, "host", adConfig.serverUrl);
    coalesceProperty(params, "vdur", adConfig.vdur);

    return replaceQueryParams(url, params);
}

/**
 * The FOG server relies on player platform to provide it data about the client which is playing it.
 * Some of this data is needed just prior to playing back an asset. This function provides a url which will provide
 * the data the FOG server requires. It is intended to be used just prior to passing a url to an underlying player.
 * @param url the url which we plan to provide to an underlying player
 * @param asset the asset associated with the url
 * @returns fully resolved url which is used for FOG playback
 */
function prepareFOGUrl(url: string, asset: BaseAsset): string {
    logger.trace("prepareFOGUrl", url);
    const params = parseQueryParams(url);
    const hostInfo = AnalyticsHandler.instance.hostInfo;
    params["analyticsUrl"] = ConfigurationManager.getInstance().get(ConfigurationManager.FOG_ANALYTICS_END_POINT);
    params["money"] = JSON.stringify({
        PARENT_ID: SessionManager.instance.moneyTrace.spanId,
        TRACE_ID: SessionManager.instance.moneyTrace.traceId
    });
    params["ses"] = JSON.stringify({
        PSI: SessionManager.instance.sessionId,
        PBI: SessionManager.instance.playbackCount
    });
    params["asset"] = JSON.stringify(new XuaAssetFactory().create(asset));
    params["appName"] = hostInfo.appName;
    params["appVer"] = hostInfo.appVersion;
    params["acId"] = global._xrePlayerPlatform.contentOptions.accountID;
    params["devName"] = hostInfo.deviceName;
    params["devID"] = hostInfo.deviceId;
    params["phyID"] = global._xrePlayerPlatform.contentOptions.deviceID;
    params[FOG_URL_KEY] = params[FOG_URL_KEY].replace(/^https:\/\//, "http://");
    return replaceQueryParams(url, params);
}

/**
 * Prior to playing certain URLs we have to append additional information.
 * We currently do this for the following situations:
 * * MANIFEST MANIPULATOR
 * * FOG
 * These services rely on player platform to provide them information. This function
 * provides that information to these services just before we pass the URL to an underlying
 * player.
 * @param url the url as player platform is about to provide it to an underlying player
 * @param asset associated content options with the provided url
 * @returns url which should be passed to the underlying player
 */
export function getURLForPlayback(url: string, asset: BaseAsset): string {

    logger.trace("getURLForPlayback", url);

    if (isManifestManipulator(asset.adConfig)) {
        return prepareManifestManipulator(url, asset, asset.adConfig as IManifestManipulatorAdConfig);
    }

    if (FOG_RE.test(url)) {
        return prepareFOGUrl(url, asset);
    }

    return url;

}

/**
 * For linear redundant urls we make a request to a Comcast CDN with
 * a query string parameter of trred, with this included parameter the CDN
 * provides a list of locations it would like player platform to play through.
 * @param url the original CDN location
 * @param queryParams query paramaters to be merged with the redundancy request paramaters
 * @returns list of URLs from linear redundant CDN endpoint
 */
function getLinearRedundantUrls(url: string, queryParams?: any): Promise<string[]> {
    logger.trace("getLinearRedundantUrls", url);

    queryParams = (queryParams) ? queryParams : {};
    queryParams.trred = false;

    return new Promise((resolve, _reject) => {
        try {
            ajax({
                url: replaceQueryParams(url, queryParams),
                method: "GET",
                crossDomain: true,
                responseType: "json",
                timeout: ConfigurationManager.getInstance().get(ConfigurationManager.PLAYER_NETWORK_REQUEST_TIMEOUT)
            })
                .subscribe((ajaxResponse) => {
                    if (ajaxResponse.response && Array.isArray(ajaxResponse.response.locations)) {
                        resolve(ajaxResponse.response["locations"]);
                    } else {
                        resolve([url]);
                    }
                }, (err) => {
                    // In case of any error, return the original URL
                    logger.error("Error with linear redundancy request", err);
                    resolve([url]);
                });
        } catch (err) {
            logger.error("Unable to make request", err);
            resolve([url]);
        }
    });

}

/**
 * FOG assets need all of the typical modifications but need them on the provided recordedUrl query string parameter.
 * This method recursively calls back to make modifications on the recordedUrl and then maps them back to FOG fully
 * resolved URLs.
 * @param fogUrl fully resolved FOG url as provided to player platform
 * @param recordedUrl the recordedUrl within the provided FOG url
 * @param asset the associated asset provided with the FOG url
 * @param isRetry whether we are querying due to a retry
 * @returns list of fully resolved URLs for use with a FOG server from player platform
 */
function getURLPlaylistForFOGAsset(fogUrl: string, recordedUrl: string, asset: BaseAsset, isRetry: boolean): Promise<string[]> {
    return getURLPlaylistForAsset(recordedUrl, asset, isRetry)
        .then((urls) => urls.map((url) => {
            const params = parseQueryParams(fogUrl);
            params.recordedUrl = decodeURIComponent(url);
            return replaceQueryParams(fogUrl, params);
        }));
}

/* tslint:disable max-line-length */
/*
 * For DAI RGB assets player platform has to modify the host and URL in a specific way.
 * Player platform gets provided URLs similar to:
 *
 * http://dai-aim.g.comcast.net/TVE/HLS/TBSHD_HD_TVE_12817_0_7062422138357055163/index.m3u8?vservv=live&ProviderId=xfinitytv.com&AssetId=MSTR7062422138357055&DeviceId=x
 *
 * It then modifies this URL to
 * http://dai-aim.g.comcast.net/TBSHD_HD_TVE_12817_0_7062422138357055163.m3u8
 *
 */
/* tslint:enable max-line-length */
function modifyUrlForRgb(rewriteHost: string, url: string): string {
    // Match the host at index 1 and the new asset name at index 3
    const RGB_RE: RegExp = /^(https?):\/\/dai-aim[^/]*\/TVE\/(HLS|HDS)\/([^/]*)\//;
    const matches = RGB_RE.exec(url);
    // If we found a match but it doesn't seem right
    // return the original url
    if (!matches || matches.length !== 4) {
        return url;
    }
    return `${matches[1]}://${rewriteHost}/${matches[3]}.m3u8`;
}

export function getURLPlaylistForManifestManipulator(url: string, asset: BaseAsset): Promise<string[]> {
    let playlist: string[] = [url];

    // use the partner host prefix if applicable, otherwise default to CCR
    const reg: RegExp = ConfigurationManager.getInstance().get(ConfigurationManager.USE_TR_HOST_PREFIX) ? TR_RE : CCR_RE;
    const match = reg.exec(url);

    if (match && match.length > 1 && !asset.isFallback) {

        const mmRetries = ConfigurationManager.getInstance().get(ConfigurationManager.MANIFEST_MANIPULATOR_RETRIES);
        let mmUrl: string;

        if (ConfigurationManager.getInstance().get(ConfigurationManager.ENABLE_MULTISITE_VOD_DAI)) {
            mmUrl = url.replace(reg, match[0] + "mm-");
            playlist.unshift(mmUrl);
        } else {
            mmUrl = url.replace(reg, match[1] + "://mm.");
            playlist.unshift(mmUrl);
        }

        playlist = appendCcrUrlsBasedOnRetryCount(mmRetries, mmUrl, playlist);

    }

    return Promise.resolve(playlist);
}

function appendCcrUrlsBasedOnRetryCount(mmRetries: number, url: string, playlist: string[]): string[] {

    if (mmRetries && mmRetries > 0) {

        for (let mmRertryIndex = 0; mmRertryIndex < mmRetries; mmRertryIndex++) {
            playlist.unshift(url);
        }
    }

    return playlist;
}

function getURLPlaylistForNBC(url: string, asset: BaseAsset): Promise<string[]> {
    return new Promise((resolve, reject) => {
        if (!asset.serviceZip) {
            return reject(new PPError(7550, 5, "Service zip required for NBC assets"));
        }
        new NbcConditionedStreamApi(
            ConfigurationManager.getInstance().get(ConfigurationManager.NBC_CONDITIONED_STREAM_API) as INbcConditionedStreamConfig).getConditionedAssetUrl(url, asset.serviceZip)
            .subscribe(
                (nbcUrl: string) => resolve([nbcUrl]),
                (err) => reject(err)
            );
    });
}

function getURLPlaylistForMPX(asset: BaseAsset): Promise<string[]> {
    logger.trace("getURLPlaylistForMPX", asset.url);
    const params = parseQueryParams(asset.url);
    if (!params.format || params.format.toLowerCase() !== "smil") {
        params.format = "smil";
    }
    const formatSmilUrl = replaceQueryParams(asset.url, params);

    logger.trace(`Requesting CCR url for MPX locator URL: ${formatSmilUrl}`);

    return new Promise((resolve, reject) => {

        ajax({
            url: formatSmilUrl,
            method: "get",
            responseType: "text",
            crossDomain: true
        })
            .subscribe((res) => {
                try {
                    const parser = new MpxParser();
                    let newUrl = parser.parse(res.response);

                    newUrl = modifyAssetUrlForDashAssets(asset, newUrl);

                    if (ConfigurationManager.getInstance().getByAssetType(String(asset.assetType), ConfigurationManager.FORCE_HTTPS, false)) {
                        newUrl = newUrl.replace(/^http:/, "https:");
                    }

                    if (parser.error) {
                        throw parser.error;
                    } else if (!newUrl) {
                        reject(new PPError(MPX_ERROR, 0, "MPX parse error, no URL found"));
                    }
                    resolve([newUrl]);
                } catch (err) {
                    logger.error(`Error parsing MPX response: ${err}`);
                    reject(err);
                }
            }, (err) => {
                logger.error(`Error retrieving URL: ${err}`);
                reject(new PPError(MPX_ERROR, 10, "Error retrieving MPX URL"));
            });
    });
}

function getURLPlaylistForVirtualStreamStitcher(url: string, asset: VirtualStreamStitcherAsset, vssOptions: IVirtualStreamStitcherOptions = {}): Promise<string[]> {

    const initialServiceZone = vssOptions.initialServiceZone;

    if (initialServiceZone !== undefined) {
        return new Promise((resolve, _reject) => {
            logger.trace(`VSS initial service zone is ${initialServiceZone}`);
            resolveVssUrl(url, asset, initialServiceZone, resolve);
        });
    }

    const serviceZoneCallback = vssOptions.serviceZoneCallback;

    if (serviceZoneCallback === undefined) {
        return Promise.reject(new PPError(VIRTUAL_STREAM_STITCHER_ERROR, VirtualStreamStitcherErrorCodes.NO_CALLBACK_PROVIDED, "VSS service zone callback was not supplied in content options."));
    }

    if (typeof serviceZoneCallback !== "function") {
        return Promise.reject(new PPError(VIRTUAL_STREAM_STITCHER_ERROR, VirtualStreamStitcherErrorCodes.CALLBACK_NOT_A_FUNCTION, "VSS service zone callback must be a function."));
    }

    return new Promise((resolve, reject) => {
        let timedOut: boolean = false;
        const serviceZoneType = asset.serviceZoneType || ConfigurationManager.getInstance().get(ConfigurationManager.VSS_DEFAULT_SERVICE_ZONE_TYPE);
        if (!serviceZoneType) {
            reject(new PPError(VIRTUAL_STREAM_STITCHER_ERROR, VirtualStreamStitcherErrorCodes.NO_SERVICE_ZONE_TYPE_AVAILABLE, "VSS service zone type not known"));
            return;
        }
        const vssCbTimeout = setTimeout(() => {
            timedOut = true;
            reject(new PPError(VIRTUAL_STREAM_STITCHER_ERROR, VirtualStreamStitcherErrorCodes.CALLBACK_TIMED_OUT, "VSS service zone callback timed out"));
        }, ConfigurationManager.getInstance().get(ConfigurationManager.VSS_SERVICE_ZONE_CALLBACK_TIMEOUT) || 10000);
        try {
            serviceZoneCallback(serviceZoneType, (err: any, serviceZone: string) => {
                if (timedOut) {
                    logger.warn("Ignoring VSS response since timed out");
                    return;
                }
                if (err) {
                    logger.error("Error returned for service zone callback", String(err));
                    reject(new PPError(VIRTUAL_STREAM_STITCHER_ERROR, VirtualStreamStitcherErrorCodes.CALLBACK_ERROR, "VSS service zone callback errored"));
                    return;
                }
                clearTimeout(vssCbTimeout);
                logger.trace(`VSS service zone callback returned service zone ${serviceZone}`);
                resolveVssUrl(url, asset, serviceZone, resolve);
            });
        } catch (err) {
            clearTimeout(vssCbTimeout);
            logger.error(`VSS service zone callback threw error: ${err}, name: ${err.name}, message: ${err.message}, stack: ${err.stack}`);
            reject(new PPError(VIRTUAL_STREAM_STITCHER_ERROR, VirtualStreamStitcherErrorCodes.CALLBACK_THREW_ERROR, "VSS service zone callback threw an error"));
        }
    });
}

function resolveVssUrl(url: string, asset: VirtualStreamStitcherAsset, serviceZone: ServiceZone, resolve: (value?: any) => void): void {
    const queryParams = parseQueryParams(url);
    asset.serviceZone = queryParams["sz"] = serviceZone;

    // reset the initialServiceZone for subsequent service zone callbacks
    if (asset.contentOptions.vss && asset.contentOptions.vss.serviceZoneCallback) {
        asset.contentOptions.vss.initialServiceZone = undefined;
    }

    if (isLinearRedundant(asset)) {
        resolve(getLinearRedundantUrls(replaceQueryParams(url, queryParams), queryParams));
    } else {
        resolve([replaceQueryParams(url, queryParams)]);
    }
}

/**
 * Certain modifications are made up front and modify the URL. After these changes
 * we don't modify the URL again. These modifications are included below.
 * They are currently used for:
 *  * Forcing a URL to be HTTPS
 *  * Appending an -eac3.m3u8 suffix for certain super8 servers for DD+
 *  * Modifying host for RGB, working for dynamic ad insertion servers.
 * @param assetType the type of asset
 * @param url the url to modify
 * @returns fully modified initial url
 */
function makeInitialModifications(asset: IContentOptions, url: string): string {

    // If this asset always wants https
    if (ConfigurationManager.getInstance().getByAssetType(String(asset.assetType), ConfigurationManager.FORCE_HTTPS, false)) {
        url = url.replace(/^http:/, "https:");
        logger.trace(`changed url for ${ConfigurationManager.FORCE_HTTPS} ${url}`, url);
    }

    // If this asset needs DD+ modification
    if (ConfigurationManager.getInstance().getByAssetType(String(asset.assetType), ConfigurationManager.DD_PLUS, false) || asset.surround) {
        url = url.replace(/\.m3u8$/, "-eac3.m3u8");
        logger.trace(`changed url for ${ConfigurationManager.DD_PLUS} ${url}`, url);
    }

    const rgbUrlRewriteHost: string = ConfigurationManager.getInstance().getByAssetType(String(asset.assetType), ConfigurationManager.RGB_URL_REWRITE_HOST, "");

    // If RGB URL, change for RGB
    if (rgbUrlRewriteHost) {
        url = modifyUrlForRgb(rgbUrlRewriteHost, url);
        logger.trace(`changed url for ${ConfigurationManager.RGB_URL_REWRITE_HOST} ${url}`);
    }

    return url;

}

function getPlaylist(url: string, asset: BaseAsset, isRetry: boolean): Promise<string[]> {

    logger.trace("getPlaylist", url, isRetry);

    // Get URLs for manifest manipulator service
    if (isManifestManipulator(asset.adConfig)) {
        return getURLPlaylistForManifestManipulator(url, asset);
    }

    // Get URLs for NBC Universal Asset
    if (asset.assetEngine === AssetEngine.NBCUNI) {
        return getURLPlaylistForNBC(url, asset);
    }

    // Get URLs for VSS Asset
    if (asset instanceof VirtualStreamStitcherAsset) {
        return getURLPlaylistForVirtualStreamStitcher(url, asset, asset.contentOptions.vss);
    }

    // Get URLs for Over-the-top OTT asset
    if (asset instanceof OttAsset) {
        return getOttUrl(url, asset);
    }

    // Get URLs for a linear redundant CDN asset with apache traffic control
    if (isLinearRedundant(asset)) {
        return getLinearRedundantUrls(url);
    }

    // Get URLs for MPX (mpx.theplatform.com) assets
    if (!asset.preventRedirect && MPX_RE.test(url)) {
        return getURLPlaylistForMPX(asset);
    }

    logger.trace("No modifications needed");

    // Return original URL if no modifications detected
    return Promise.resolve([url]);

}

function isLinearRedundant(asset: BaseAsset): boolean {
    return ConfigurationManager.getInstance().getByAssetType(asset.assetType, ConfigurationManager.CDN_REDUNDANT, false);
}

/**
 * Often times in player platform we are given a url which we have to do more work on to get a final list of URLs which
 * we attempt to play through. The result is a queue of arrays which we attempt playback on in a FIFO order.
 * @param url the url as provided to player platform by a user
 * @param asset associated content options with the provided url
 * @param isRetry whether we are querying this as the result of retrying
 * @returns queue of URLs to work through while playing back
 */
export function getURLPlaylistForAsset(url: string, asset: BaseAsset, isRetry: boolean = false): Promise<string[]> {

    logger.trace("getURLPlaylistForAsset", url, isRetry);

    // During retry, if the asset is Linear Redundant fallback urls should be resolved again.
    // else retry the same asset
    if (isRetry && !isLinearRedundant(asset)) {
        return Promise.resolve([asset.url]);
    }

    // Modify the url based on the preferred streaming format
    url = modifyAssetUrlForDashAssets(asset, url);

    // If FOG asset, get URLS for the recordedUrl
    // Recursively exit after this and pull URLs for the
    // recordedUrl query string.
    if (FOG_RE.test(url)) {
        return getURLPlaylistForFOGAsset(url, parseQueryParams(url).recordedUrl, asset, isRetry);
    }

    // Make initial modifications that modify the URL
    url = makeInitialModifications(asset, url);
    return getPlaylist(url, asset, isRetry);

}

function modifyAssetUrlForDashAssets(asset: BaseAsset, url: string): string {

    if (asset.contentOptions.preferredStreamingFormat === StreamFormat.DASH) {

        if (!asset.isFallback) {
            url = url.replace(/\.m3u8/, ".mpd");
        } else {
            url = url.replace(/\.mpd/, ".m3u8");
        }
    }

    return url;
}
