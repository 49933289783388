import { MILLISECONDS_PER_SECOND } from "../../PlayerPlatformConstants";
import { X2JS } from "x2json";
import { createGUID, formatLocalDateTimeStamp } from "../../util/JSUtil";
import { IPSNParams, IPlacementStatusNotificationJSON, IPSNEvent, IEvent, ISpotScopedEvent, IPSN, IAvailableViewableEvents } from "./IPlacementStatusNotification";

export function PlacementStatusNotification(params: IPSNParams): IPSN {

    const x2js: X2JS = new X2JS({ escapeMode: false });
    const prefixCore: string = "core";
    const version: string = "1.1";
    const xmlnsAdm: string = "http://www.scte.org/schemas/130-3/2008a/adm";
    const xmlnsCore: string = "http://www.scte.org/schemas/130-2/2008a/core";
    const identity: string = "86CF2E98-AEBA-4C3A-A3D4-616CF7D74A79";
    const system: string = "IP_Player";

    const availableViewerEvents: IAvailableViewableEvents = {
        fastForward: true,
        play: true,
        pause: true,
        rewind: true,
        stop: true
    };

    const json: IPlacementStatusNotificationJSON = {
        _version: version,
        _xmlns: xmlnsAdm,
        "_xmlns:core": xmlnsCore,
        _identity: identity,
        _messageId: createGUID().toUpperCase(),
        _system: system,
        PlayData: {
            _identityADS: params.identityAds || "",
            SystemContext: {
                Session: params.sessionID
            },
            Client: {
                TerminalAddress: {
                    __text: params.terminalAddress,
                    _type: "DEVICEID"
                }
            }
        }
    };

    function toXML(): string {
        return x2js.json2xml_str({ PlacementStatusNotification: json });
    }

    /**
     * createEvents wraps a PSNEvent in an object with an EventType of
     * either ViewerEvent || PlacementStatusEvent
     */
    function createEvents(event: IPSNEvent): IEvent {
        const obj: IEvent = {};
        const eventType: string = isViewerEvent(event) ? "ViewerEvent" : "PlacementStatusEvent";
        obj[eventType] = event;
        return obj;
    }

    function createSpotScopedEvents(event: IPSNEvent): ISpotScopedEvent {
        const spotScopedEvents: ISpotScopedEvent = {
            Spot: {
                Content: {
                    __prefix: prefixCore
                }
            },
            Events: createEvents(event)
        };
        if (params.assetRef) {
            spotScopedEvents.Spot.Content.AssetRef = {
                __prefix: prefixCore,
                _assetID: params.assetRef.assetId || "",
                _providerID: params.assetRef.providerId || ""
            };
        }
        spotScopedEvents.Spot.Content.Tracking = {
            __prefix: prefixCore,
            __text: params.tracking
        };

        return spotScopedEvents;
    }

    function createEvent(type: string, scale: string, npt: string) {
        // create message ID
        json._messageId = createGUID().toUpperCase();

        // remove other events
        delete json.PlayData.Events;
        delete json.PlayData.SpotScopedEvents;

        //noinspection TypeScriptValidateTypes
        const event: IPSNEvent = {
            _time: formatLocalDateTimeStamp(),
            _type: type
        };
        if (params.messageRef) {
            event._messageRef = params.messageRef;
        }

        if (scale !== undefined && npt !== undefined) {
            const millis: number = (parseFloat(npt) / MILLISECONDS_PER_SECOND);
            event.SpotNPT = {
                _scale: parseFloat(scale).toFixed(1),
                __text: millis % 1 ? millis.toFixed(3) : millis.toFixed()
            };

            json.PlayData.SpotScopedEvents = createSpotScopedEvents(event);
        } else {
            json.PlayData.Events = createEvents(event);
        }

        return toXML();
    }

    function isViewerEvent(event: IPSNEvent) {
        return Object.prototype.hasOwnProperty.call(availableViewerEvents, event._type);
    }

    function curryEvent(type: string): any {
        return function(scale: string, npt: string) {
            return createEvent(type, scale, npt);
        };
    }

    return {
        createEvent: createEvent,
        startPlacement: curryEvent("startPlacement"),
        endPlacement: curryEvent("endPlacement"),
        firstQuartile: curryEvent("private:firstQuartile"),
        midpoint: curryEvent("private:midpoint"),
        thirdQuartile: curryEvent("private:thirdQuartile"),
        fastForward: curryEvent("fastForward"),
        rewind: curryEvent("rewind"),
        play: curryEvent("play"),
        pause: curryEvent("pause"),
        stop: curryEvent("stop"),
        endAll: curryEvent("endAll")
    };
}
