import { HlsTag } from "./HlsTag";
import { HlsCueTag } from "./HlsCueTag";
import { HlsTrickmodeRestrictionTag } from "./HlsTrickmodeRestrictionTag";
import { HlsSourceStreamTag } from "./HlsSourceStreamTag";
import { HlsServiceZoneRequiredTag } from "./HlsServiceZoneRequiredTag";
import { AssetIdTag } from "./AssetIdTag";
import { IdentityAdsTag } from "./IdentityAdsTag";
import { MessageRefTag } from "./MessageRefTag";

export const TAG_CUE: string = "#EXT-X-CUE";
export const TAG_TRICKMODE_RESTRICTION: string = "#EXT-X-TRICKMODE-RESTRICTION";
export const TAG_SOURCE_STREAM: string = "#EXT-X-SOURCE-STREAM";
export const TAG_SERVICE_ZONE_REQUIRED: string = "#EXT-X-SZ-REQUIRED";
export const TAG_CONTENT_IDENTIFIER: string = "#EXT-X-CONTENT-IDENTIFIER";
export const TAG_ASSET_ID: string = "#EXT-X-ASSET-ID";
export const TAG_IDENTITY_ADS: string = "#EXT-X-IDENTITY-ADS";
export const TAG_MESSAGE_REF: string = "#EXT-X-MESSAGE-REF";

export const TAG_CONSTRUCTORS: { [x: string]: IHlsTagConstructor } = {
    [TAG_CUE]: HlsCueTag,
    [TAG_TRICKMODE_RESTRICTION]: HlsTrickmodeRestrictionTag,
    [TAG_SOURCE_STREAM]: HlsSourceStreamTag,
    [TAG_SERVICE_ZONE_REQUIRED]: HlsServiceZoneRequiredTag,
    [TAG_ASSET_ID]: AssetIdTag,
    [TAG_IDENTITY_ADS]: IdentityAdsTag,
    [TAG_MESSAGE_REF]: MessageRefTag
};

export function create(name: string, time: number, content: string = ""): HlsTag {
    const ctor: IHlsTagConstructor = TAG_CONSTRUCTORS[name];

    if (ctor) {
        return new ctor(time, content);
    }

    return new HlsTag(name, time, content);
}

type IHlsTagConstructor = new (time: number, content?: string) => HlsTag;
