import { BaseAsset } from "./assets/BaseAsset";
import { HlsTag } from "./util/hls/HlsTag";
import { toObservable as fromSandboxEvent } from "./Application";
import {
    AD_BREAK_COMPLETE,
    AD_BREAK_START,
    AD_COMPLETE,
    AD_ERROR,
    AD_EXITED,
    AD_PROGRESS,
    AD_START,
    ADS_SKIPPED,
    BITRATE_CHANGED,
    MEDIA_ENDED,
    MEDIA_OPENED,
    MEDIA_PROGRESS,
    PLAYBACK_SPEED_CHANGED,
    PLAYBACK_STARTED,
    PLAY_STATE_CHANGED,
    AdBreakCompleteEvent,
    AdBreakStartEvent,
    AdCompleteEvent,
    AdErrorEvent,
    AdExitedEvent,
    AdProgressEvent,
    AdStartEvent,
    AdsSkippedEvent,
    BitrateChangedEvent,
    MediaEndedEvent,
    MediaOpenedEvent,
    MediaProgressEvent,
    PlaybackSpeedChangedEvent,
    PlaybackStartedEvent,
    PlayStateChangedEvent,
    toObservable as fromEvent,
    AdBreakExitedEvent,
    AD_BREAK_EXITED,
    StreamSwitchEvent,
    STREAM_SWITCH
} from "./PlayerPlatformAPIEvents";
import * as constants from "./PlayerPlatformConstants";
import { Observable, BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";

export class PlayerPlatformStreams {
    public setXacts: Observable<any> = new BehaviorSubject<any>(null);
    public setAssets: Observable<BaseAsset> = fromSandboxEvent<BaseAsset>(constants.SET_ASSET);
    public manifests: Observable<Document> = fromSandboxEvent<Document>("player:manifestLoaded");
    public tags: Observable<HlsTag> = fromSandboxEvent<HlsTag>("player:tag");
    public playbackStarts: Observable<PlaybackStartedEvent> = fromEvent<PlaybackStartedEvent>(PLAYBACK_STARTED);
    public mediaOpeneds: Observable<MediaOpenedEvent> = fromEvent<MediaOpenedEvent>(MEDIA_OPENED);
    public mediaProgresses: Observable<MediaProgressEvent> = fromEvent<MediaProgressEvent>(MEDIA_PROGRESS);
    public mediaEndeds: Observable<MediaEndedEvent> = fromEvent<MediaEndedEvent>(MEDIA_ENDED);
    public playStates: Observable<PlayStateChangedEvent> = fromEvent<PlayStateChangedEvent>(PLAY_STATE_CHANGED);
    public speeds: Observable<PlaybackSpeedChangedEvent> = fromEvent<PlaybackSpeedChangedEvent>(PLAYBACK_SPEED_CHANGED);
    public bitrates: Observable<BitrateChangedEvent> = fromEvent<BitrateChangedEvent>(BITRATE_CHANGED);
    public adCompletes: Observable<AdCompleteEvent> = fromEvent<AdCompleteEvent>(AD_COMPLETE);
    public adErrors: Observable<AdErrorEvent> = fromEvent<AdErrorEvent>(AD_ERROR);
    public adExiteds: Observable<AdExitedEvent> = fromEvent<AdExitedEvent>(AD_EXITED);
    public adProgresses: Observable<AdProgressEvent> = fromEvent<AdProgressEvent>(AD_PROGRESS);
    public adStarts: Observable<AdStartEvent> = fromEvent<AdStartEvent>(AD_START);
    public adBreakStarts: Observable<AdBreakStartEvent> = fromEvent<AdBreakStartEvent>(AD_BREAK_START);
    public adBreakCompletes: Observable<AdBreakCompleteEvent> = fromEvent<AdBreakCompleteEvent>(AD_BREAK_COMPLETE);
    public adBreakExiteds: Observable<AdBreakExitedEvent> = fromEvent<AdBreakExitedEvent>(AD_BREAK_EXITED);
    public adsSkippeds: Observable<AdsSkippedEvent> = fromEvent<AdsSkippedEvent>(ADS_SKIPPED);
    public streamSwitches: Observable<StreamSwitchEvent> = fromEvent<StreamSwitchEvent>(STREAM_SWITCH);

    constructor() {
        fromSandboxEvent<any>(constants.SET_XACT).subscribe(this.setXacts as BehaviorSubject<any>);
    }

    public getPlayState(state: string): Observable<PlayStateChangedEvent> {
        return this.playStates.pipe(filter(evt => evt.playState === state));
    }
}
