import { MILLISECONDS_PER_SECOND } from "../../PlayerPlatformConstants";
import { ProgressWindow } from "../../util/ProgressWindow";
import { TrackerEventType } from "./TrackerEvent";
import { ITrackable } from "./ITrackable";

export type PredicateSignature = (progress: ProgressWindow, trackable: ITrackable) => boolean;

export const predicates: { [x: number]: PredicateSignature } = {

    /**
     * When a progress event goes from a position outside of a trackable to
     * a position inside of a trackable during playback/trickplay (NOT seeking).
     */
    [TrackerEventType.START](progress: ProgressWindow, trackable: ITrackable): boolean {
        return !progress.seeking && intoTrackable(progress, trackable);
    },

    /**
     * When a progress event goes from a position inside of a trackable to
     * a position inside of a trackable during playback/trickplay (NOT seeking).
     */
    [TrackerEventType.COMPLETE](progress: ProgressWindow, trackable: ITrackable): boolean {
        return !progress.seeking && outOfTrackable(progress, trackable);
    },

    /**
     * When a progress event goes from a position outside of a trackable to
     * a position inside of a trackable due to a seek.
     */
    [TrackerEventType.ENTER](progress: ProgressWindow, trackable: ITrackable): boolean {
        return progress.seeking && intoTrackable(progress, trackable);
    },

    /**
     * When a progress event goes from a position inside of a trackable to
     * a position inside of a trackable due to a seek.
     */
    [TrackerEventType.EXIT](progress: ProgressWindow, trackable: ITrackable): boolean {
        return progress.seeking && outOfTrackable(progress, trackable);
    },

    /**
     * When a progress event occurs while inside of a trackable.
     */
    [TrackerEventType.PROGRESS](progress: ProgressWindow, trackable: ITrackable): boolean {
        return !progress.stopped && trackable.coversLocation(progress.next);
    },

    /**
     * When a progress event goes from a position before the trackable startTime to a position
     * after the trackable endTime. OR when a progress event goes from a position after the
     * trackable endTime to a position before the trackable startTime. Essentially this should
     * determine if a trackable has been skipped or jumped over, likely due to trickplay.
     */
    [TrackerEventType.SKIPPED](progress: ProgressWindow, trackable: ITrackable): boolean {
        return skipped(progress, trackable);
    },

    /**
     * When the progress window is stopped while inside a trackable.
     */
    [TrackerEventType.INTERRUPT](progress: ProgressWindow, trackable: ITrackable): boolean {
        return progress.stopped && trackable.coversLocation(progress.prev);
    },

    /**
     * This determines if a trackable is coming up in the future. The amount of time to look
     * ahead into the future is calculated by multiplying the playback rate by 1000 (ms/sec).
     * This will only return true if the playback rate is greater than 1.
     */
    [TrackerEventType.UPCOMING](progress: ProgressWindow, trackable: ITrackable): boolean {
        if (progress.nextRate > 1) {
            const lookahead = progress.nextRate * MILLISECONDS_PER_SECOND;
            return trackable.isInRange(progress.next, progress.next + lookahead);
        }

        return false;
    }
};

function intoTrackable(progress: ProgressWindow, trackable: ITrackable): boolean {
    return !trackable.coversLocation(progress.prev) && trackable.coversLocation(progress.next);
}

function outOfTrackable(progress: ProgressWindow, trackable: ITrackable): boolean {
    return trackable.coversLocation(progress.prev) && !trackable.coversLocation(progress.next);
}

function skipped(progress: ProgressWindow, trackable: ITrackable): boolean {
    return ( !progress.seeking &&  progress.duringTrickPlay() )
        && ((trackable.startTime > progress.prev && trackable.endTime <= progress.next) ||
        (trackable.endTime <= progress.prev && trackable.startTime > progress.next));
}
