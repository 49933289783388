import { HlsTag } from "./HlsTag";
import { MILLISECONDS_PER_SECOND } from "../../PlayerPlatformConstants";

const REQUIRED_FIELDS: string[] = ["ID", "PROVIDER", "DURATION"];

export class AssetIdTag extends HlsTag {

    public assetId: string;
    public providerId: string;
    public duration: number;

    constructor(time: number, content: string) {
        super("#EXT-X-ASSET-ID", time, content, REQUIRED_FIELDS);

        this.assetId = this.metadata["ID"];
        this.providerId = this.metadata["PROVIDER"];
        this.duration = parseFloat(this.metadata["DURATION"]) * MILLISECONDS_PER_SECOND;
    }
}
