import { Logger } from "../util/Logger";
import { ITrackable } from "./tracking/ITrackable";
import { VideoAd } from "./VideoAd";

/**
 * VideoAdBreak
 * @constructor
 */
export class VideoAdBreak implements ITrackable {

    private static logger: Logger = new Logger("VideoAdBreak");

    public ads: VideoAd[] = [];

    constructor(ads: VideoAd[] = []) {
        ads.forEach(this.addAd.bind(this));
    }

    get startTime() {
        return this.ads[0] ? this.ads[0].startTime : 0;
    }

    get duration() {
        return this.ads.reduce((l, r) => l + r.duration, 0);
    }

    get endTime() {
        return this.startTime + this.duration;
    }

    get watched() {
        for (const ad of this.ads) {
            if (!ad.watched) {
                return false;
            }
        }
        return true;
    }

    set watched(watched: boolean) {
        for (const ad of this.ads) {
            if (!watched) {
                ad.seenCount = 0;
                ad.watched = false;
            } else if (watched && !ad.watched) {
                ad.seenCount = 1;
                ad.watched = true;
            }
        }
    }

    get adCount() {
        return this.ads.length;
    }

    /**
     * add a VideoAd to this ad break
     * @param {object} ad               - VideoAd object to add to this break
     * @returns {boolean}               - true if ad was added to this break; false otherwise
     */
    public addAd(ad: VideoAd): boolean {

        VideoAdBreak.logger.trace("addAd");
        if (!this.ads.length || ad.startTime === this.endTime || this.startTime === ad.endTime) {
            this.ads.push(ad);
            this.ads.sort((ad1, ad2) => ad1.startTime - ad2.startTime);
            return true;
        }
        return false;
    }

    /**
     * @param {object} ad to look for
     * @returns {boolean}
     */
    public containsAd(ad: VideoAd) {

        let res = false;

        VideoAdBreak.logger.trace("containsAd");

        for (const each of this.ads) {
            if (each === ad) {
                res = true;
                VideoAdBreak.logger.trace("containsAd: returning true!");
                break;
            }
        }

        return res;
    }

    /**
     * indicate if the ad break includes the specified time
     * note that the start time of the break is considered to be included in the break, but the end time is not
     * @param {number} location (msec)
     * @returns {boolean}
     */
    public coversLocation(location: number) {

        let res = false;

        if (this.startTime !== -1 && ((this.startTime <= location) && (location < this.endTime))) {
            res = true;
        }

        return res;
    }

    /**
     * Determines if the ad start time falls in the given range.
     *
     * @param begin
     * @param end
     * @returns {boolean}
     */
    public isInRange(begin: number, end: number) {
        return begin <= this.startTime && this.startTime <= end;
    }

    /**
     * return the ad in this break that includes the designated time, or null if not found
     * note that the start time of the ad is considered to be included in the ad, but the end time is not
     * @param {number} time - stream time in milliseconds
     * @returns {object}
     */
    public getAdByTime(time: number) {
        let res: VideoAd;
        let i: number;

        VideoAdBreak.logger.trace("getAdByTime: " + time);

        for (i = 0; i < this.ads.length; ++i) {
            if (this.ads[i].coversLocation(time)) {
                res = this.ads[i];
                break;
            }
        }

        return res;
    }

    /**
     * return the ad in this break that matches designated id, or null if not found
     * @param {string} id - identifier being sought
     * @returns {object}
     */
    public getAdById(id: string) {
        let res: VideoAd;
        let i: number;

        VideoAdBreak.logger.trace("getAdById: " + id);

        for (i = 0; i < this.ads.length; ++i) {
            if (this.ads[i].id === id) {
                res = this.ads[i];
                break;
            }
        }

        return res;
    }

    /**
     * This checks all ads to see if `speed` is allowed. If `speed` is not allowed for
     * one ad in this break then the entire break is not allowed.
     *
     * @param speed
     * @returns {boolean}
     */
    public speedIsAllowed(speed: number): boolean {
        for (const ad of this.ads) {
            if (!ad.speedIsAllowed(speed)) {
                VideoAdBreak.logger.trace(`speedIsAllowed: false -- speed=${speed}`);
                return false;
            }
        }

        VideoAdBreak.logger.trace(`speedIsAllowed: true -- speed=${speed}`);
        return true;
    }

    /**
     * this checks all ads to see if `target` can be seeked to. If `target` is not allowed for
     * one ad in this break then the entire break is not allowed.
     *
     * @param start
     * @param target
     * @returns {boolean}
     */
    public seekIsAllowed(start: number, target: number): boolean {
        for (const ad of this.ads) {
            if (!ad.seekIsAllowed(start, target)) {
                VideoAdBreak.logger.trace(`seekIsAllowed: false -- target=${target}`);
                return false;
            }
        }

        VideoAdBreak.logger.trace(`seekIsAllowed: true -- target=${target}`);
        return true;
    }

    /**
     * Given a `target` position, check ads to see if it's allowed. If so return `target`, otherwise
     * return `start` if this break is currently in progress or return this break's `startTime`.
     *
     * @param start
     * @param target
     * @returns {number}
     */
    public getSeekLimit(start: number, target: number): number {
        const allowed = this.seekIsAllowed(start, target);

        if (allowed) {
            return target;
        } else if (this.coversLocation(start)) {
            return start;
        } else {
            return this.startTime;
        }
    }
}
