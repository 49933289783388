/**
 * This overlay is used as a diagnostic modal with XRE.
 * To show and hide this modal XRE directly calls out to the DOM and assumes there
 * is an element with an id of overlayModal. The display state of that modal is then toggled
 * between none and block. We start this overlay with display none and assume XRE will manually
 * call to show the modal on their end.
 *
 * XRE code to interact with this modal:
 *
 * QString evalOverlayDisplayStr = QString("var modal = document.getElementById('overlayModal');if (modal) {if (modal.style.display == 'block') {modal.style.display = 'none';} else if ((modal.style.display == 'none') && (document.getElementById('anomalyModal').style.display == 'none')) {modal.style.display = 'block';}document.getElementById('anomalyModal').style.display == 'none';} null;");
 */

import { h, app } from "hyperapp";

const MANIFEST_TYPES = {
  m3u8: "HLS",
  mpd: "DASH",
};

/**
 * actions that manipulate state of the DOM
 */
export interface IOverlayActions {
  setUrl: (url: string) => void;
  setViperVersion: (url: string) => void;
  setViperURL: (url: string) => void;
  setManifestType: (type: string) => void;
  setDrmType: (type: string) => void;
  setContentType: (type: string) => void;
  setBitrates: (bitrates: number[]) => void;
  setCurrentBitrate: (bitrate: number) => void;
}

/**
 * common set of styles
 */
const STYLES = {
  modal: {
    position: "absolute",
    border: "1px solid #2B363D",
    backgroundColor: "rgba(0,0,0,0.5)",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
    color: "#fff",
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    WebkitAnimationName: "animatetop",
    WebkitAnimationDuration: "0.4s"
  }
};

/**
 * Display playback metrics such as bitrate and buffer health
 */
const Metrics = ({
  manifestType,
  drmType,
  contentType,
  bitrates = [],
  currentBitrate
}) => {
  const outerStyle = {
    ...STYLES.modal,
    width: "auto",
    height: "10%",
    top: "15%",
    left: "60px",
    padding: "0px 15px 15px 15px",
    font: "13px arial, sans-serif"
  };

  const bitrateList = {
    display: "inline",
    listStyle: "none",
    padding: 0
  };

  const bitrateItemCurrent = {
    border: "2px solid #FFFB55",
    borderRadius: "5px",
    display: "inline",
    padding: "5px",
    marginLeft: "5px"
  };

  const bitrateItem = {
    display: "inline",
    marginLeft: "5px"
  };

  return (
    <div style={outerStyle}>
      <h4>
        AAMP|{contentType}|{manifestType}|{drmType}
      </h4>
      <div>
        Bitrates(Mbps):
        <ul style={bitrateList}>
          {bitrates.map(bitrate => (
            <li
              style={
                bitrate === currentBitrate ? bitrateItemCurrent : bitrateItem
              }
            >
              {bitrate}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

/**
 * Displays a URL at the top of the window
 */
const URL = ({ 
  url,
  viperVersion,
  viperURL
 }) => {
  const outerStyle = {
    ...STYLES.modal,
    width: "90%",
    height: "9%",
    top: "10px",
    left: 0,
    right: 0,
    margin: "0 auto",
    paddingTop: "2px",
    textAlign: "center",
    font: "14px arial, sans-serif"
  };

  return (
    <div style={outerStyle}>
      VIPER {viperVersion} | {viperURL} | CONTENT URL: {url}
    </div>
  );
};

/**
 * the entire view - a collection of modals/overlays
 */
const view = state => {
  return (
    <div>
      <Metrics
        manifestType={state.manifestType}
        drmType={state.drmType}
        contentType={state.contentType}
        bitrates={state.bitrates}
        currentBitrate={state.currentBitrate}
      />
      <URL
       url={state.url}
       viperVersion={state.viperVersion}
       viperURL={state.viperURL}
      />
    </div>
  );
};

/**
 * helper function to convert to Mbps
 */
const convertToMbps = bitrate => (bitrate / 1000000).toFixed(1);

/**
 * defines actions that manipulate state
 */
const actions: IOverlayActions = {
  setUrl: url => ({ url }),
  setViperVersion: viperVersion => ({ viperVersion }),
  setViperURL: viperURL => ({ viperURL }),
  setManifestType: urlType => ({ manifestType: MANIFEST_TYPES[urlType] || "UNKNOWN" }),
  setDrmType: drmType => ({ drmType }),
  setContentType: contentType => ({ contentType }),
  setBitrates: bitrates => ({ bitrates: bitrates.map(convertToMbps) }),
  setCurrentBitrate: bitrate => ({ currentBitrate: convertToMbps(bitrate) })
};

/**
 * attaches a hyperapp app to a DOM element and returns the actions
 */
export function attach(element): IOverlayActions {
  return app({}, actions, view, element) as IOverlayActions;
}
