
import { registerModule } from "../Application";
import { IPPModule, IPPSandbox } from "../PlayerPlatformApplication";
import * as events from "../PlayerPlatformAPIEvents";
import { Logger } from "../util/Logger";
import * as constants from "../PlayerPlatformConstants";
import { PPError, STALLED_ERROR } from "../PPError";
import { ConfigurationManager } from "../ConfigurationManager";
import { never } from "rxjs";
import { mapTo, merge, tap, switchMap, takeUntil } from "rxjs/operators";

/**
 * Validates that playback is still continuing and hasn't gotten stalled.
 *
 * There are various situations where we may not be notified of media failures from
 * Adobe or a third party player and playback appears to freeze. This module
 * will attempt to find these moments and emit a MediaFailedEvent when these situations
 * are detected. This MediaFailedEvent will notify people that we have determined playback
 * has stalled for some reason.
 *
 * The timeout period defaults to 10000 but can be configured through our configuration manager.
 */
export class PlaybackStalledHandler implements IPPModule<PlaybackStalledHandler> {

    private _logger: Logger = new Logger("PlaybackStalledHandler");
    private _timeoutID: number;
    private _stalledTimeout: number;
    private _sandbox: IPPSandbox;

    /**
     * The default stalled timeout period (in ms)
     */
    public static readonly DEFAULT_STALLED_TIMEOUT: number = 30000;

    /**
     * The set of statuses that we should emit a media failed
     * for if we determine the player has been stuck in this
     * status for too long.
     */
    private static readonly SHOULD_BE_PLAYING_STATUS: {[x: string]: number} = {
        [constants.STATUS_PLAYING]: 1,
        [constants.STATUS_SEEKING]: 1,
        [constants.STATUS_BUFFERING]: 1
    };

    public init(sandbox: IPPSandbox): PlaybackStalledHandler {
        this._sandbox = sandbox;
        this._stalledTimeout = this._sandbox.config.stalledTimeout || PlaybackStalledHandler.DEFAULT_STALLED_TIMEOUT;
        this._logger.trace(`Initializing PlaybackStalledHandler with timeout: ${this._stalledTimeout}`);
        this._setupValidator();
        return this;
    }

    public destroy(): void {
        window.clearTimeout(this._timeoutID);
    }

    private _setupValidator(): void {

        this._sandbox.streams.setAssets
            .pipe(
                tap(() => window.clearTimeout(this._timeoutID)), // clear previous handler
                switchMap((asset) => this._sandbox.streams.mediaOpeneds.pipe(mapTo(asset))), // wait until media is opened
                switchMap((asset) => {
                    if (ConfigurationManager.getInstance().getByAssetType(ConfigurationManager.getInstance().getAssetType(asset),
                            ConfigurationManager.PLAYBACKSTALLED_ENABLED)) {
                        // listen for progress to be made
                        return this._sandbox.streams.playStates
                            .pipe(
                                merge(this._sandbox.streams.mediaProgresses),
                                merge(this._sandbox.streams.adProgresses),
                                takeUntil(this._sandbox.streams.setAssets)
                            );
                    } else {
                        // don't do anything if not enabled
                        return never();
                    }
                }),
                takeUntil(this._sandbox.destroyed)
            )
            .subscribe(() => {
                window.clearTimeout(this._timeoutID);
                this._setWindowTimeout();
            });

    }

    private _setWindowTimeout(): void {
        if (PlaybackStalledHandler.SHOULD_BE_PLAYING_STATUS.hasOwnProperty(this._sandbox.getPlayerStatus())) {
            this._timeoutID = window.setTimeout(() => this._sendError(this._stalledTimeout, this._sandbox.getPlayerStatus()), this._stalledTimeout);
        }
    }

    private _sendError(stalledTimeout: number, playerStatus: string): void {
        const message: string = `Playback has been stalled for more than ${stalledTimeout}ms`;
        this._logger.warn(message);
        events.emit(new events.MediaFailedEvent(new PPError(STALLED_ERROR, 0, message, false), true, playerStatus));
    }

}

registerModule("PlaybackStalledHandler", PlaybackStalledHandler, { autostart: true });
