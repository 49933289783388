import { BaseAsset } from "./assets/BaseAsset";
import { VideoAdBreak } from "./ads/VideoAdBreak";
import { VideoAd } from "./ads/VideoAd";
import { ITrackerEvent } from "./ads/tracking/TrackerEvent";
import { Logger } from "./util/Logger";
import { PPError } from "./PPError";
import { fromEventPattern, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PubSub as Mediator } from "publicious";
import { EmergencyAlertType } from "./eas/EmergencyAlert";
import * as constants from "./PlayerPlatformConstants";

/**
 * @hidden
 */
const logger = new Logger("PlayerPlatformAPIEvent");

/**
 * @hidden
 */
const mediator = new Mediator();

export const AD_BREAK_COMPLETE = "AdBreakComplete";
export const AD_BREAK_EXITED = "AdBreakExited";
export const AD_BREAK_START = "AdBreakStart";
export const AD_BREAK_UPCOMING = "AdBreakUpcoming";
export const AD_COMPLETE = "AdComplete";
export const AD_ERROR = "AdError";
export const AD_EXITED = "AdExited";
export const AD_PROGRESS = "AdProgress";
export const AD_SEEK = "AdSeek";
export const AD_START = "AdStart";
export const AD_SKIPPED = "AdSkipped";
export const ADS_SKIPPED = "AdsSkipped";
export const BITRATE_CHANGED = "BitrateChanged";
export const BUFFER_COMPLETE = "BufferComplete";
export const BUFFER_START = "BufferStart";
export const DROPPED_FPS_CHANGED = "DroppedFPSChanged";
export const DURATION_CHANGED = "DurationChanged";
export const FPS_CHANGED = "FPSChanged";
export const FRAGMENT_INFO = "FragmentInfo";
export const MEDIA_FALLBACK = "MediaFallback";
export const MEDIA_ENDED = "MediaEnded";
export const MEDIA_FAILED = "MediaFailed";
export const MEDIA_OPENED = "MediaOpened";
export const MEDIA_RETRY = "MediaRetry";
export const MEDIA_PROGRESS = "MediaProgress";
export const MEDIA_WARNING = "MediaWarning";
export const MISSING_DRM_TOKEN = "MissingDRMToken";
export const NUMBER_OF_ALTERNATIVE_AUDIO_STREAMS_CHANGED = "NumberOfAlternativeAudioStreamsChanged";
export const NUMBER_OF_CLOSED_CAPTIONS_STREAMS_CHANGED = "NumberOfClosedCaptionsStreamsChanged";
export const PLAYBACK_SPEED_CHANGED = "PlaybackSpeedChanged";
export const PLAYBACK_SPEEDS_CHANGED = "PlaybackSpeedsChanged";
export const PLAYBACK_STARTED = "PlaybackStarted";
export const PLAY_STATE_CHANGED = "PlayStateChanged";
export const SEEK_COMPLETE = "SeekComplete";
export const SEEK_START = "SeekStart";
export const DRM_METADATA = "DRMMetadata";
export const EMERGENCY_ALERT_IDENTIFIED = "EmergencyAlertIdentified";
export const EMERGENCY_ALERT_EXEMPTED = "EmergencyAlertExempted";
export const EMERGENCY_ALERT_STARTED = "EmergencyAlertStarted";
export const EMERGENCY_ALERT_COMPLETE = "EmergencyAlertComplete";
export const EMERGENCY_ALERT_FAILURE = "EmergencyAlertFailure";
export const EMERGENCY_ALERT_ERRORED = "EmergencyAlertErrored";
export const VPAID_AD_EVENT = "VPAIDAdEvent";
export const STREAM_SWITCH = "StreamSwitch";
export const SERVICE_ZONE = "ServiceZone";

// codes for MEDIA_WARNING event
export const MEDIA_WARNING_TRICKMODE_DISALLOWED = 70;

// codes for PLAYBACK_SPEED_CHANGED event
export const SPEED_CHANGE_USER_REQUESTED = 0;
export const SPEED_CHANGE_PROGRAM_BOUNDARY = 1;
export const SPEED_CHANGE_TSB_BOUNDARY = 2;
export const SPEED_CHANGE_AD_BOUNDARY = 3;

// A threshold to move the position in the AdStart & AdComplete events to a
// boundary point. See AdStart & AdComplete events for it's usage.
export const AD_RANGE_POSITION_TOLERANCE = 250;

export interface IMediaOpenedEvent {
    mediaType: string;
    playbackSpeeds: number[];
    availableAudioLanguages: string[];
    width: number;
    height: number;
    openingLatency: number;
    hasDRM: boolean;
    hasCC: boolean;
}

export type VPAIDAdEventType = "AdStopped" | "AdLoading" | "AdLoaded" | "AdError" | "AdProgress" | "AdPaused" | "AdClickThru" | "AdPlaying" | "AdStarted";

/**
 * Base Event Constructor.
 *
 * @param {String} type event type
 * @returns {object}
 *
 */
export class PlayerPlatformAPIEvent {
    constructor(public type: string) { }
}

/**
 * All ads in an ad break have completed.
 *
 * @event AdBreakComplete
 * @param {VideoAdBreak} videoAdBreak
 *
 */
export class AdBreakCompleteEvent extends PlayerPlatformAPIEvent {

    public videoAdBreak: VideoAdBreak;

    constructor(videoAdBreak: VideoAdBreak) {
        super(AD_BREAK_COMPLETE);
        this.videoAdBreak = videoAdBreak;

        logger.trace("new AdBreakCompleteEvent");
    }

    public static fromTrackerEvent(event: ITrackerEvent<VideoAdBreak>): AdBreakCompleteEvent {
        logger.info("Tracking: AdBreakCompleteEvent", JSON.stringify(event.progress));

        return new AdBreakCompleteEvent(event.trackable);
    }
}

/**
 *  Ad break was stopped before playing all ad breaks.
 *
 * @event AdExited
 * @param {VideoAdBreak} videoAdBreak
 */
export class AdBreakExitedEvent extends PlayerPlatformAPIEvent {

    public videoAdBreak: VideoAdBreak;

    constructor(videoAdBreak: VideoAdBreak) {
        logger.trace("new AdBreakExitedEvent");
        super(AD_BREAK_EXITED);
        this.videoAdBreak = videoAdBreak;
    }

    public static fromTrackerEvent(event: ITrackerEvent<VideoAdBreak>): AdBreakExitedEvent {
        logger.info("Tracking: AdBreakExitedEvent", JSON.stringify(event.progress));
        return new AdBreakExitedEvent(event.trackable);
    }
}

/**
 * Ad break has started and the first ad in the break should start.
 *
 * @event AdBreakStart
 * @param {VideoAdBreak} videoadBreak
 *
 */
export class AdBreakStartEvent extends PlayerPlatformAPIEvent {

    constructor(public videoAdBreak: VideoAdBreak) {
        super(AD_BREAK_START);
        this.videoAdBreak = videoAdBreak;

        logger.trace("new AdBreakStartEvent");
    }

    public static fromTrackerEvent(event: ITrackerEvent<VideoAdBreak>): AdBreakStartEvent {
        logger.info("Tracking: AdBreakStartEvent", JSON.stringify(event.progress));
        return new AdBreakStartEvent(event.trackable);
    }
}

/**
 * This event is fired when an ad break is about to be reached on the timeline
 * during trickplay.
 *
 * @event AdBreakUpcoming
 * @param {VideoAdBreak} videoadBreak
 *
 */
export class AdBreakUpcomingEvent extends PlayerPlatformAPIEvent {

    constructor(public videoAdBreak: VideoAdBreak) {
        super(AD_BREAK_UPCOMING);
        logger.trace("new AdBreakUpcomingEvent");
    }

    public static fromTrackerEvent(event: ITrackerEvent<VideoAdBreak>): AdBreakUpcomingEvent {
        logger.info("Tracking: AdBreakUpcomingEvent", JSON.stringify(event.progress));
        return new AdBreakUpcomingEvent(event.trackable);
    }
}

/**
 * An error was generated due to ad resolution or ad playback
 *
 * @event AdError
 */
export class AdErrorEvent extends PlayerPlatformAPIEvent {
    public videoAd: VideoAd;
    public error: PPError;

    constructor(videoAd: VideoAd, error: PPError) {
        super(AD_ERROR);
        logger.trace("new AdErrorEvent");
        this.videoAd = videoAd;
        this.error = error;
    }
}

export class FreeWheelAdErrorEvent extends AdErrorEvent {
    constructor(public videoAd: VideoAd, public error: PPError, public slot: tv.freewheel.SDK.Slot) {
        super(videoAd, error);
    }
}

/**
 * AdEvent base type
 *
 * @param {VideoAd} videoAd
 * @param {Number} progress in percent done (0 to 100)
 * @param {Number} rate playback speed
 * @param {Number} position playback position (msec) within the ad content
 *
 */
export class AdEvent extends PlayerPlatformAPIEvent {
    constructor(adType: string, public videoAd: VideoAd, public progress: number, public rate: number, public position: number) {
        super(adType);
    }
}

/**
 * Ad has finished playing, this does not necessarily mean that the position
 * of the playhead reached the position of the adStart + durtion of the ad. If
 * leaving an ad early is supported then the AdCompleteEvent will show that in
 * the position property.
 *
 * @event AdComplete
 *
 */
export class AdCompleteEvent extends AdEvent {

    constructor(videoAd: VideoAd, progress: number, rate: number, position: number) {
        logger.trace("new AdCompleteEvent");
        super(AD_COMPLETE, videoAd, progress, rate, position);
        // Cap the position to <= to duration of the ad, if the ad's duration is known
        // If we're within the AD_RANGE_POSITION_TOLERANCE of the duration of the ad
        // we use the duration as the position.
        if (videoAd && (this.position > videoAd.duration || this.position + AD_RANGE_POSITION_TOLERANCE > videoAd.duration)) {
            logger.trace(`AdCompleteEvent.position ${this.position} beyond or within ${AD_RANGE_POSITION_TOLERANCE} of duration, resetting to ${videoAd.duration}`);
            this.position = videoAd.duration;
        }
    }

    public static fromTrackerEvent(event: ITrackerEvent<VideoAd>): AdCompleteEvent {
        const { progress, trackable } = event;
        const position = progress.prev < progress.next ? trackable.duration : 0;

        logger.info("Tracking: AdCompleteEvent", JSON.stringify(event.progress));

        return new AdCompleteEvent(trackable, trackable.getPercentageComplete(progress.next), progress.nextRate, position);
    }
}

/**
 *  Ad was stopped before reaching the end of the ad.
 *
 * This event is deprecated in favor of AdCompleteEvent. Only one event will
 * occur when finishing an ad.
 *
 * TODO: When removing this event, the fromTrackerEvent methods will need to be
 * consolidated.
 *
 * @event AdExited
 */
export class AdExitedEvent extends AdEvent {

    constructor(videoAd: VideoAd, progress: number, rate: number, position: number) {
        logger.trace("new AdExitedEvent");
        logger.warn("AdExitedEvent is DEPRECATED: AdCompleteEvent should be used instead");
        super(AD_EXITED, videoAd, progress, rate, position);
    }

    public static fromTrackerEvent(event: ITrackerEvent<VideoAd>): AdExitedEvent {
        const { progress, trackable } = event;
        const position = progress.prev - trackable.startTime;

        logger.info("Tracking: AdExitedEvent", JSON.stringify(event.progress));

        return new AdExitedEvent(trackable, trackable.getPercentageComplete(progress.prev), progress.nextRate, position);
    }
}

/**
 * Fired on each progress event during ad playback.
 *
 * @event AdProgress
 *
 */
export class AdProgressEvent extends AdEvent {

    constructor(videoAd: VideoAd, progress: number, rate: number, position: number) {
        super(AD_PROGRESS, videoAd, progress, rate, position);
    }

    public static fromTrackerEvent(event: ITrackerEvent<VideoAd>): AdProgressEvent {
        const { progress, trackable } = event;
        const position = progress.next - trackable.startTime;
        return new AdProgressEvent(trackable, trackable.getPercentageComplete(progress.next), progress.nextRate, position);
    }
}

/**
 * Ad has begun playback
 *
 * @event AdStart
 *
 */
export class AdStartEvent extends AdEvent {

    constructor(videoAd: VideoAd, progress: number, rate: number, position: number) {
        logger.trace("new AdStartEvent");
        super(AD_START, videoAd, progress, rate, position);
        // If you're within 250 ms of 0 assume you started at 0
        // This allows a slight tolerence in creating the AdStartEvent
        // due to a callback, and also querying the player's current position.
        // Also makes sure that we don't report a negative start postion
        if (this.position <= AD_RANGE_POSITION_TOLERANCE) {
            logger.trace(`AdStartEvent.position ${this.position} less than ${AD_RANGE_POSITION_TOLERANCE}, resetting to 0`);
            this.position = 0;
        }
    }

    public static fromTrackerEvent(event: ITrackerEvent<VideoAd>): AdStartEvent {
        const { progress, trackable } = event;
        const position = progress.seeking ?
            progress.next - trackable.startTime :
            progress.prev < progress.next ? 0 : trackable.duration;

        logger.info("Tracking: AdStartEvent", JSON.stringify(event.progress));

        return new AdStartEvent(trackable, trackable.getPercentageComplete(progress.next), progress.nextRate, position);
    }
}

/**
 * Type of the event dispatched when ad is being seeked over.
 *
 * @hidden
 * @param {VideoAd} videoAd
 *
 */
export class AdSeekEvent extends PlayerPlatformAPIEvent {

    public videoAd: VideoAd;

    constructor(videoAd: VideoAd) {
        logger.trace("new AdSeekEvent");
        super(AD_SEEK);
        this.videoAd = videoAd;
    }
}

/**
 * Ad that has been skipped due to trick-play speed
 *
 * @event AdsSkipped
 * @param {VideoAd[]} videoAds - array of ads skipped
 * @paran {number} rate - playback speed
 */
export class AdSkippedEvent extends PlayerPlatformAPIEvent {
    public videoAd: VideoAd;
    public rate: number;

    constructor(videoAd: VideoAd, rate: number) {
        super(AD_SKIPPED);
        this.videoAd = videoAd;
        this.rate = rate;
    }

    public static fromTrackerEvent(event: ITrackerEvent<VideoAd>): AdSkippedEvent {
        return new AdSkippedEvent(event.trackable, event.progress.nextRate);
    }
}

/**
 * Ads have been skipped due to trick-play speed
 *
 * @event AdsSkipped
 * @param {VideoAd[]} videoAds - array of ads skipped
 * @paran {number} rate - playback speed
 */
export class AdsSkippedEvent extends PlayerPlatformAPIEvent {
    public videoAds: VideoAd[];
    public rate: number;

    constructor(videoAds: VideoAd[], rate: number) {
        super(ADS_SKIPPED);
        this.videoAds = videoAds;
        this.rate = rate;
    }
}

/**
 * Bitrate has changed
 *
 * @event BitrateChanged
 * @param {Number} bitRate
 * @param {String} changeReason
 * @param {Number} width
 * @param {Number} height
 *
 */
export class BitrateChangedEvent extends PlayerPlatformAPIEvent {

    public bitRate: number;
    public changeReason: string;
    public height: number;
    public width: number;

    constructor(bitRate: number, changeReason: string, width: number, height: number) {
        logger.trace("new BitrateChangedEvent");
        super(BITRATE_CHANGED);
        this.bitRate = isNaN(bitRate) ? 0 : bitRate;
        this.changeReason = changeReason;
        this.height = height;
        this.width = width;
    }
}

/**
 * Buffering for an asset has completed.
 *
 * @event BufferComplete
 */
export class BufferCompleteEvent extends PlayerPlatformAPIEvent {
    constructor() {
        logger.trace("new BufferCompleteEvent");
        super(BUFFER_COMPLETE);
    }
}

/**
 * Buffering for an asset has started.
 * @event BufferStart
 */
export class BufferStartEvent extends PlayerPlatformAPIEvent {
    constructor() {
        logger.trace("new BufferStartEvent");
        super(BUFFER_START);
    }
}

/**
 * Number of dropped frames changes.
 *
 * @event DroppedFPSChanged
 * @param {Number} droppedfps
 */
export class DroppedFPSChangedEvent extends PlayerPlatformAPIEvent {

    public droppedfps: number;

    constructor(droppedfps: number) {
        super(DROPPED_FPS_CHANGED);
        this.droppedfps = droppedfps;
    }
}

/**
 * Duration of the asset changes.
 *
 * @event DurationChanged
 * @param {Number} duration (msec)
 */
export class DurationChangedEvent extends PlayerPlatformAPIEvent {

    public duration: number;

    constructor(duration: number) {
        super(DURATION_CHANGED);
        this.duration = duration;
    }
}

/**
 * Number of rendered frames per second changes.
 *
 * @event FPSChanged
 * @param {Number} fps
 */
export class FPSChangedEvent extends PlayerPlatformAPIEvent {
    public fps: number;

    constructor(fps: number) {
        super(FPS_CHANGED);
        this.fps = fps;
    }
}

/**
 * A fragment has been successfully downloaded
 *
 * @event FragmentInfo
 * @param {Number} downloadDuration
 * @param {Number} fragmentSize
 * @param {String} fragmentUrl
 * @param {Number} fragmentDuration
 */
export class FragmentInfoEvent extends PlayerPlatformAPIEvent {

    public downloadDuration: number;
    public fragmentSize: number;
    public fragmentUrl: string;
    public fragmentDuration: number;

    constructor(downloadDuration: number, fragmentSize: number, fragmentUrl: string, fragmentDuration: number) {
        super(FRAGMENT_INFO);
        this.downloadDuration = downloadDuration;
        this.fragmentSize = fragmentSize;
        this.fragmentUrl = fragmentUrl;
        this.fragmentDuration = fragmentDuration;
    }
}

/**
 * Media has ended (the play head reached the duration of the asset).
 *
 * @event MediaEnded
 */
export class MediaEndedEvent extends PlayerPlatformAPIEvent {
    constructor() {
        logger.trace("new MediaEndedEvent");
        super(MEDIA_ENDED);
    }
}

/**
 * Media has encountered a failure that prevented or broke playback.
 *
 * @event MediaFailed
 */
export class MediaFailedEvent extends PlayerPlatformAPIEvent {

    public retryCount: number;
    public errorClass: string;

    /**
     * @param {PPError} error The error associated with the media failure
     * @param {boolean} retry Whether this failure can be retried
     */
    constructor(public error: PPError, public retry: boolean = true, public playerStatus: string = "" ) {
        super(MEDIA_FAILED);
        logger.trace("new MediaFailedEvent");
    }
}

/**
 * Media failure results in a retry operation
 *
 * @event MediaRetry
 * @param {object} data - contains retry count
 */
export class MediaRetryEvent extends PlayerPlatformAPIEvent {

    public data: MediaFailedEvent;
    public retryCount: number;

    constructor(data: MediaFailedEvent) {
        logger.trace("new MediaRetryEvent");
        super(MEDIA_RETRY);
        this.data = data;
        this.retryCount = data.retryCount;
    }
}

export type FallbackType = "dai" | "cdn";

/**
 * Media failure results in a DAI Fallback operation
 *
 * @event MediaDaiFallback
 * @param {object} data
 */
export class MediaFallbackEvent extends PlayerPlatformAPIEvent {

    public data: MediaFailedEvent;
    public fallbackType: FallbackType;

    constructor(data: MediaFailedEvent, type: FallbackType) {
        logger.trace("new MediaFallbackEvent");
        super(MEDIA_FALLBACK);
        this.data = data;
        this.fallbackType = type;
    }
}

/**
 * The media is ready for playback including DRM acqusition and ad insertion.
 *
 * @event MediaOpened
 * @param {String} mediaType
 * @param {Number[]} playbackSpeeds
 * @param {Object[]} availableAudioLanguages
 * @param {Number} width
 * @param {Number} height
 * @param {Number} openingLatency
 * @param {Boolean} hasDRM
 * @param {Boolean} hasCC
 * @param {Array} mediaSegments
 *
 */
export class MediaOpenedEvent extends PlayerPlatformAPIEvent {

    public mediaType: string;
    public playbackSpeeds: number[];
    public availableAudioLanguages: string[];
    public width: number;
    public height: number;
    public openingLatency: number;
    public hasDRM: boolean;
    public hasCC: boolean;
    public numAds: number;

    constructor(config: IMediaOpenedEvent) {
        logger.trace("new MediaOpenedEvent");
        super(MEDIA_OPENED);
        this.mediaType = config.mediaType;
        this.playbackSpeeds = config.playbackSpeeds;
        this.availableAudioLanguages = config.availableAudioLanguages;
        this.width = config.width;
        this.height = config.height;
        this.openingLatency = config.openingLatency;
        this.hasDRM = config.hasDRM;
        this.hasCC = config.hasCC;
    }
}

/**
 * Media playhead has changed position.
 *
 * @event MediaProgress
 * @param {Number} position (msec)
 * @param {Number} playbackSpeed
 * @param {Number} startposition (msec)
 * @param {Number} endposition (msec)
 * @param {Number} updateinterval (msec)
 *
 */
export class MediaProgressEvent extends PlayerPlatformAPIEvent {

    public position: number;
    public playbackSpeed: number;
    public startposition: number;
    public endposition: number;
    public updateinterval: number;

    constructor(position: number, playbackSpeed: number, startposition: number, endposition: number, updateinterval: number) {
        super(MEDIA_PROGRESS);
        this.position = position;
        this.playbackSpeed = playbackSpeed;
        this.startposition = startposition;
        this.endposition = endposition;
        this.updateinterval = updateinterval;
    }
}

/**
 * Media has encountered a warning that might impact video playback.
 *
 * @event MediaWarning
 *
 */
export class MediaWarningEvent extends PlayerPlatformAPIEvent {

    public error: PPError;

    /**
     * @param {PPError} error The error associated with this warning
     */
    constructor(error: PPError) {
        logger.trace("new MediaWarningEvent");
        super(MEDIA_WARNING);
        error.isWarning = true;
        this.error = error;
    }
}

/**
 * An authentication token is missing and is required to get DRM license.
 *
 * @event MissingDRMToken
 * @param {BaseAsset} asset
 *
 */
export class MissingDRMTokenEvent extends PlayerPlatformAPIEvent {

    public asset: BaseAsset;

    constructor(asset: BaseAsset) {
        logger.trace("new MissingDRMTokenEvent");
        super(MISSING_DRM_TOKEN);
        this.asset = asset;
    }
}

/**
 * The number of available audio languages changes.
 *
 * @event NumberOfAlternativeAudioStreamsChanged
 * @param {Number} numberofAlternativeAudioStreams
 *
 */
export class NumberOfAlternativeAudioStreamsChangedEvent extends PlayerPlatformAPIEvent {

    public numberOfAlternativeAudioStreams: number;

    constructor(numberofAlternativeAudioStreams: number) {
        logger.trace("new NumberOfAlternativeAudioStreamsChangedEvent");
        super(NUMBER_OF_ALTERNATIVE_AUDIO_STREAMS_CHANGED);
        this.numberOfAlternativeAudioStreams = numberofAlternativeAudioStreams;
    }
}

/**
 * The number of available closed captions changes.
 *
 * @event NumberOfClosedCaptionsStreamsChanged
 * @param {Number} numberofClosedCaptionsStreams
 *
 */
export class NumberOfClosedCaptionsStreamsChanged extends PlayerPlatformAPIEvent {

    public numberOfClosedCaptionsStreams: number;

    constructor(numberofClosedCaptionsStreams: number) {
        logger.trace("new NumberOfClosedCaptionsStreamsChanged");
        super(NUMBER_OF_CLOSED_CAPTIONS_STREAMS_CHANGED);
        this.numberOfClosedCaptionsStreams = numberofClosedCaptionsStreams;
    }
}

/**
 * The playback speed of the asset changes.
 *
 * @event PlaybackSpeedChanged
 * @param {Number} playbackSpeed
 * @param {Number} [reason] - defaults to USER_REQUESTED if not provided
 */
export class PlaybackSpeedChangedEvent extends PlayerPlatformAPIEvent {

    public playbackSpeed: number;
    public reason: number;

    constructor(playbackSpeed: number, reason?: number) {
        logger.trace("new PlaybackSpeedChangedEvent");
        super(PLAYBACK_SPEED_CHANGED);
        this.playbackSpeed = playbackSpeed;
        this.reason = reason || SPEED_CHANGE_USER_REQUESTED;
    }
}

/**
 * The set of currently supported playback speeds change.
 *
 * @event PlaybackSpeedsChanged
 * @param {Array} playbackSpeeds
 *
 */
export class PlaybackSpeedsChangedEvent extends PlayerPlatformAPIEvent {

    public playbackSpeeds: number[];

    constructor(playbackSpeeds: number[]) {
        logger.trace("new PlaybackSpeedsChangedEvent: " + JSON.stringify(playbackSpeeds));
        super(PLAYBACK_SPEEDS_CHANGED);
        this.playbackSpeeds = playbackSpeeds;
    }
}

/**
 * Initial playback of the asset began.
 *
 * @event PlaybackStarted
 *
 */
export class PlaybackStartedEvent extends PlayerPlatformAPIEvent {

    constructor() {
        logger.trace("new PlaybackStarted");
        super(PLAYBACK_STARTED);
    }
}

/**
 * The state of the media player changes.
 *
 * @event PlayStateChanged
 * @param {String} playState
 *
 */
export class PlayStateChangedEvent extends PlayerPlatformAPIEvent {

    public playState: string;

    constructor(playState: string) {
        logger.trace("new PlayStateChangedEvent");
        super(PLAY_STATE_CHANGED);
        this.playState = playState;
    }
}

/**
 * Seeking for an asset ends.
 *
 * @event SeekComplete
 */
export class SeekCompleteEvent extends PlayerPlatformAPIEvent {

    public position: number;

    constructor(position: number) {
        logger.trace("new SeekCompleteEvent");
        super(SEEK_COMPLETE);
        this.position = position;
    }
}

/**
 * Seeking for an asset starts.
 *
 * @event SeekStart
 */
export class SeekStartEvent extends PlayerPlatformAPIEvent {

    public position: number;

    constructor(position: number) {
        logger.trace("new SeekStartEvent");
        super(SEEK_START);
        this.position = position;
    }
}

/**
 * DRM metadata has been retrieved
 *
 * @event DRMMetadata
 */
export class DRMMetadataEvent extends PlayerPlatformAPIEvent {

    constructor(public drmMetadataInfo?: any) {
        super(DRM_METADATA);
        logger.trace("new DRMMetadataEvent");
    }
}

/**
 * An emergency alert has been found in the client's region
 *
 * @event EmergencyAlertIdentified
 * @param {String} easLanguage - the ISO-639 code corresponding to the langauge of the EAS media
 * @param {String} easUri - the URI specifying the location of the EAS event's media or stream
 */
export class EmergencyAlertIdentifiedEvent extends PlayerPlatformAPIEvent {

    public easLanguage: string;
    public easURI: string;

    constructor(easLanguage: string, easUri: string) {
        logger.trace("new EmergencyAlertIdentifiedEvent");
        super(EMERGENCY_ALERT_IDENTIFIED);
        this.easLanguage = easLanguage;
        this.easURI = easUri;
    }
}

/**
 * The client is exempted from playing back the alert.
 *
 * @event EmergencyAlertExempted
 * @param {String} easLanguage - the ISO-639 code corresponding to the langauge of the EAS media
 * @param {String} easUri - the URI specifying the location of the EAS event's media or stream
 */
export class EmergencyAlertExemptedEvent extends PlayerPlatformAPIEvent {

    public easLanguage: string;
    public easURI: string;

    constructor(easLanguage: string, easUri: string) {
        logger.trace("new EmergencyAlertExemptedEvent");
        super(EMERGENCY_ALERT_EXEMPTED);
        this.easLanguage = easLanguage;
        this.easURI = easUri;
    }
}

/**
 * An emergency alert has started playback or started scrolling
 *
 * @event EmergencyAlertStarted
 * @param {String} easLanguage - the ISO-639 code corresponding to the langauge of the EAS media
 * @param {String} easUri - the URI specifying the location of the EAS event's media or stream
 */
export class EmergencyAlertStartedEvent extends PlayerPlatformAPIEvent {

    constructor(public alertType: EmergencyAlertType, public easLanguage: string, public easUri: string) {
        super(EMERGENCY_ALERT_STARTED);
        logger.trace("new EmergencyAlertStartedEvent");
    }
}

/**
 * An emergency alert has completed playback or finished scrolling
 *
 * @event EmergencyAlertComplete
 * @param {String} easLanguage - the ISO-639 code corresponding to the langauge of the EAS media
 * @param {String} easUri - the URI specifying the location of the EAS event's media or stream
 */
export class EmergencyAlertCompleteEvent extends PlayerPlatformAPIEvent {

    constructor(public alertType: EmergencyAlertType, public easLanguage: string, public easUri: string) {
        super(EMERGENCY_ALERT_COMPLETE);
        logger.trace("new EmergencyAlertCompleteEvent");
    }
}

/**
 * The client has exhausted its retries and is stopping playback of the emergency alert
 *
 * @event EmergencyAlertFailure
 * @param {String} easCode - the error code that resulted in the failure
 * @param {String} easLanguage - the ISO-639 code corresponding to the langauge of the EAS media
 * @param {String} easUri - the URI specifying the location of the EAS event's media or stream
 */
export class EmergencyAlertFailureEvent extends PlayerPlatformAPIEvent {

    public easCode: string;
    public easLanguage: string;
    public easURI: string;

    constructor(easCode: string, easLanguage: string, easUri: string) {
        logger.trace("new EmergencyAlertFailureEvent");
        super(EMERGENCY_ALERT_FAILURE);
        this.easCode = easCode;
        this.easLanguage = easLanguage;
        this.easURI = easUri;
    }
}

/**
 * A non-fatal exception has resulted in the client retrying one or more actions during the playback of an emergency alert.
 *
 * @event EmergencyAlertErrored
 * @param {String} easCode - the error code that resulted in the failure
 * @param {String} easLanguage - the ISO-639 code corresponding to the langauge of the EAS media
 * @param {String} easUri - the URI specifying the location of the EAS event's media or stream
 */
export class EmergencyAlertErroredEvent extends PlayerPlatformAPIEvent {

    public easCode: string;
    public easLanguage: string;
    public easURI: string;

    constructor(easCode: string, easLanguage: string, easUri: string) {
        logger.trace("new EmergencyAlertErroredEvent");
        super(EMERGENCY_ALERT_ERRORED);
        this.easCode = easCode;
        this.easLanguage = easLanguage;
        this.easURI = easUri;
    }
}

/**
 * A VPAID ad events has occured. These are playback and loading events
 * related to a VPAID ad which is usually dynamic content that we run on
 * the DOM which can be a SWF or JS code.
 * @param {VPAIDAdEventType} type - the type of event
 */
export class VPAIDAdEvent extends PlayerPlatformAPIEvent {

    constructor(public vpaidType: VPAIDAdEventType) {
        super(VPAID_AD_EVENT);
        logger.trace(`new VPAIDAdEvent(${vpaidType})`);
    }

}

/**
 * A VSS stream switch occurred
 *
 * @event StreamSwitch
 * @param {Asset} asset: the asset on which the stream switch occurred
 * (will contain latest values for sourceStreamId, signalId, and serviceZone properties)
 */
export class StreamSwitchEvent extends PlayerPlatformAPIEvent {

    constructor(public sourceStreamId: string, public signalId: string) {
        super(STREAM_SWITCH);
        logger.trace("new StreamSwitch event");
    }
}

/**
 * A VSS stream has reached the end of the manifest and now requires the viewers location
 * in order to retrive the service zone based manifest.
 *
 * @event ServiceZoneRequirement
 * @param {string} serviceZoneType: the serviceZoneType is the type of location
 * we are requesting from the user (ex: zipcode, geolocation, uurn)
 */
export class ServiceZoneEvent extends PlayerPlatformAPIEvent {

    constructor(public serviceZoneType: string) {
        super(SERVICE_ZONE);
        logger.trace("new ServiceZone event");
    }
}

/*
 * Add a listener callback for the given <code>type</code>
 * THIS FUNCTION IS REQUIRED FOR fromEvent to work with RXJS
 * PROVIDING PRIORITY AND CONTEXT SHOULD BE REQUIRED
 */
export function addEventListener(type: string, listener: (...evt: any[]) => void, priority: number = constants.PRIORITY_DEFAULT, context: any = null): void {
    mediator.on(type, listener, { priority: priority }, context);
}

/**
 * Remove a listener callback for the given <code>type</code>
 *
 * @hidden
 * @param type
 * @param listener
 */
export function removeEventListener(type: string, listener: (...evt: any[]) => void): void {
    mediator.remove(type, listener);
}

/**
 * Publish the instance of <code>PlayerPlatformAPIEvent</code>
 *
 * @hidden
 * @param event
 */
export function dispatchEvent(event: PlayerPlatformAPIEvent): void {
    if (!event) {
        // creating an error captures the stack, which will be useful for figuring out
        // where the undefined event was passed from
        logger.error("dispatchEvent was called with undefined event");
        return;
    }
    // Mediator no longer throws, if the observable subscription does not handle
    // the error, mediator will catch it and log it so that it can continue publishing
    mediator.publish(event.type, event);
}

/**
 * @hidden
 */
export const on = addEventListener;

/**
 * @hidden
 */
export const off = removeEventListener;

/**
 * @hidden
 */
export const emit = dispatchEvent;

export function toObservable<T>(name: string): Observable<T> {
    return fromEventPattern<T | T[]>(
        handler => mediator.subscribe(name, handler, { priority: constants.PRIORITY_DEFAULT }),
        handler => mediator.remove(name, handler)
    ).pipe(map((t) => Array.isArray(t) ? t[0] : t));
}
