/**
 * ## Application
 *
 * ### module
 * A module is an object or a function that returns an object that contains
 * an `init` and `destroy` method. These functions will be injected with a sandbox
 * containing read-only access to properties and functions exposed via `expose`
 * and `exposeProp`.
 *
 * ### children
 * A module can have child modules. Children will be initialized _after_
 * the parent module is initialized and will contain a reference to the parent
 * module via `parent`
 *
 * ### dependencies
 * A module can have dependencies. Dependencies will be initialized _before_
 * the parent module and the instances will be injected into the parent module's
 * `init` function.
 */

import "./Polyfills";
import { SubResposne as SubResponse, PubSub as Mediator } from "publicious";
import { Logger } from "./util/Logger";
import { extend } from "./util/JSUtil";
import { bindCallback, fromEventPattern, Observable } from "rxjs";
import { map } from "rxjs/operators";

let modules: IApplicationModuleMap = {};

let apiInstance: any;

let sandboxProps: string[] = [];
let sandboxDi: any = {};

let mediator = new Mediator();
const logger = new Logger("Application");

export function init(instance: object): void {

    apiInstance = instance;

    // add mediator to api instance
    apiInstance.mediator = mediator;

    for (const mod in modules) {
        if (modules.hasOwnProperty(mod) && modules[mod].options.autostart) {
            startModule(mod);
        }
    }
}

export function destroy(): void {
    stopModules();

    apiInstance = null;
    sandboxProps = null;
    modules = {};
    sandboxDi = {};
    mediator = null;
}

export function inject(diObj: object): void {
    extend(sandboxDi, diObj);
}

export function contains(name: string): boolean {
    return !!modules[name];
}

export function registerModule<T>(name: string, mod: IModuleConstructor<T> | IModule<T>, options?: IApplicationOptions): void {
    if (typeof mod === "function") {
        const isConstructor = name[0] === name[0].toUpperCase();
        mod = isConstructor ? new mod() : (mod as any)();
    }

    if (!validate(name, mod)) {
        return;
    }

    modules[name] = {
        instance: mod,
        apis: [],
        options: options || {},
        destroyCbs: [],
        onDestroy: function(cb: () => void) {
            this.destroyCbs.push(cb);
        }
    };
}

export function appMediator(): Mediator {
    return mediator;
}

export function registerPlugin<T>(name: string, plugin: IModuleConstructor<T>, options?: IApplicationOptions): void {

    options = options || {};
    options.plugin = true;

    registerModule(name, plugin, options);
}

export function startModule(moduleName: string, ..._argsArray: any[]): any | null {

    const mod = modules[moduleName];

    if (!mod) {
        logger.error("Cannot start module " + moduleName + ". No module registered by that name.");
        return;
    }


    const sandbox: Sandbox = new Sandbox(apiInstance, mod);
    let args: any[] = [sandbox].concat(Array.prototype.slice.call(arguments, 1));
    args = args.concat(startDependencies(mod));
    const instance = mod.instance;

    if (!mod.sandbox) {
        mod.sandbox = sandbox;
        if (!instance.init.apply(instance, args)) {
            logger.warn(moduleName + " returned null, it has not been started.");
            return null;
        }
    }

    logger.info(moduleName + " started.");

    startChildren(mod);

    return instance;
}

export function stopModule(moduleName: string): void {
    const mod = modules[moduleName];

    if (!mod) {
        logger.error("Cannot stop module " + moduleName + ". No module registered by that name.");
        return;
    }

    stopChildren(mod);
    stopDependencies(mod);
    removeAPIs(mod);

    const modObj = mod;
    // It's possible that a module may try to be stopped before ever starting
    if (modObj.sandbox) {
        modObj.instance.destroy(modObj.sandbox);
        if (modObj.destroyCbs.length) {
            modObj.destroyCbs.forEach(cb => cb());
        }
        modObj.sandbox = null;
    }

    logger.info(moduleName + " stopped.");
}

export function stopModules(): void {
    for (const mod in modules) {
        if (modules.hasOwnProperty(mod) && modules[mod].options.autostart) {
            stopModule(mod);
        }
    }
}

export function register(options: IApplicationOptions = {}): (target: any) => void {
    return (target: any) => registerModule(target["name"], target, options);
}

export function expose(_target: object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>): TypedPropertyDescriptor<any> {
    sandboxProps.push(propertyKey);
    return descriptor;
}

export function exposeProp(_target: object, propertyKey: string): void {
    sandboxProps.push(propertyKey);
}

function startChildren(mod: any): void {
    if (!mod.options.children) {
        return;
    }

    mod.options.children.forEach(function(child: string) {
        modules[child].parent = mod.instance;
        startModule(child);
    });
}

function stopChildren(mod: any): void {
    if (!mod.options.children) {
        return;
    }

    mod.options.children.forEach(function(child: string) {
        stopModule(child);
    });
}

function startDependencies(mod: any): any[] {
    return (mod.options.dependencies || []).map(dep => {
        modules[dep].parent = mod.instance;
        return startModule(dep);
    });

}

function stopDependencies(mod: any): void {
    (mod.options.dependencies || []).forEach(stopModule);
}

function removeAPIs(mod: any) {
    mod.apis.forEach(function(api: string) {
        delete apiInstance[api];
    });
}

function validate(name: string, constructor: any) {
    if (!constructor.init || !constructor.destroy) {
        logger.error(name + ": both an init and destroy function must be defined.");
        return false;
    }

    if (typeof constructor.init !== "function" || typeof constructor.destroy !== "function") {
        logger.error(name + ": init and destroy properties must be functions.");
        return false;
    }

    return true;
}

export class Sandbox implements ISandbox {

    private _api: any;
    private _mod: IApplicationModule;
    private _destroyed: Observable<any>;

    [x: string]: any;

    constructor(api: any, mod: IApplicationModule) {
        this._api = api;
        this._mod = mod;

        if (this._mod) {
            const onDestroy = bindCallback(this._mod.onDestroy.bind(this._mod));
            this._destroyed = onDestroy();
        }


        this._init(this._api);
    }

    private _init(api: any) {

        extend(this, sandboxDi);

        const props = sandboxProps || [];

        // set exposed methods and properties on sandbox
        props.forEach((prop: string) => {
            if (typeof api[prop] === "function") {
                this._setFunction(prop);
            } else {
                this._setProperty(prop);
            }

        });
    }

    private _setFunction(func: string) {
        this[func] = function() {
            return this.api[func].apply(this._api, arguments);
        };
    }

    private _setProperty(prop: string) {
        Object.defineProperty(this, prop, {
            get() {
                return this._api[prop];
            }
        });
    }

    public publish(_channelName: string, ..._items: any[]): void {
        mediator.publish.apply(mediator, arguments);
    }

    public subscribe(channelName: string, func: (...args: any[]) => void, priority: number, context: any): SubResponse {
        return mediator.subscribe(channelName, func, { priority: priority }, context);
    }

    public remove(channelName: string, func: (...args: any[]) => void): void {
        mediator.remove(channelName, func);
    }

    public addAPI(name: string, prop: any) {
        if (this._mod.options.plugin) {
            apiInstance[name] = prop;
            this._mod.apis.push(name);
        } else {
            logger.warn("Must register as a plugin to add an API");
        }

    }

    get parent() {
        return this._mod.parent;
    }

    get api() {
        return apiInstance;
    }

    get destroyed(): Observable<any> {
        return this._destroyed;
    }
}

export interface IApplicationModule {
    [key: string]: any;
    instance: any;
    sandbox?: any;
    destroyCb?: () => void;
    destroyCbs: (() => void)[];
    onDestroy: (cb: () => void) => void;
    options?: IApplicationOptions;
    parent?: IApplicationModule;
    apis?: string[];
}

export interface IApplicationModuleMap {
    [x: string]: IApplicationModule;
}

export interface IApplicationOptions {
    autostart?: boolean;
    plugin?: boolean;
    children?: string[];
    dependencies?: string[];
}

export interface IModule<T> {
    init(sandbox: ISandbox, ...args: any[]): T;
    destroy(sandbox: ISandbox): void;
}

export type IModuleConstructor<T> = new () => IModule<T>;

export interface ISandbox {
    parent: any;
    api: any;
    destroyed: Observable<any>;

    publish(name: string, ...args: any[]): void;
    subscribe(name: string, callback: (...args: any[]) => void, priority: number, context: any): SubResponse;
    remove(name: string, callback: (...args: any[]) => void): void;
    addAPI(name: string, prop: any): void;
}

export function toObservable<T>(name: string, priority: any = { priority: 4 }): Observable<T> {
    return fromEventPattern<T | T[]>(
        handler => mediator.subscribe(name, handler, priority, {}),
        handler => mediator.remove(name, handler)
    ).pipe(map((t) => Array.isArray(t) ? t[0] : t));
}
