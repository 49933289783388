
import { IPPSandbox } from "../../PlayerPlatformApplication";
import { AdobeContentResolver } from "./AdobeContentResolver";
import { AdobeOpportunityGenerator } from "./AdobeOpportunityGenerator";
import { AdobeAdPolicySelector } from "./AdobeAdPolicySelector";
import { IRequestResolver } from "../../ads/IResolvableOpportunity";

export class AdobeContentFactory {

    private _adPolicySelector: AdobePSDK.AdPolicySelector = null;
    private _resolvers: AdobePSDK.ContentResolver[] = null;
    private _generators: AdobePSDK.OpportunityGenerator[] = null;

    public hasPreRollAd: boolean = false;

    constructor(protected _sandbox: IPPSandbox, private _requestResolver?: IRequestResolver) {}

    private _retrieveAdPolicySelector(_item: AdobePSDK.MediaPlayerItem): AdobePSDK.AdPolicySelector {

        if (this._adPolicySelector === null) {
            this._adPolicySelector = (new AdobeAdPolicySelector()).makeAdPolicySelector();
        }
        return this._adPolicySelector;
    }

    protected _retrieveResolvers(_item: AdobePSDK.MediaPlayerItem): AdobePSDK.ContentResolver[] {

        if (this._resolvers === null) {
            const resolver: AdobeContentResolver = this.makeAdResolver();
            if (this._requestResolver) {
                resolver.requestResolver = this._requestResolver;
            }
            this._resolvers = [
                resolver.makeContentResolver()
            ];
        }
        return this._resolvers;
    }

    private _retrieveOpportunityGenerators(_item: AdobePSDK.MediaPlayerItem): AdobePSDK.OpportunityGenerator[] {

        if (this._generators === null) {
            this._generators = [
                this.makeOpportunityInstance().makeOpportunityGenerator()
            ];
        }
        return this._generators;
    }

    protected makeOpportunityInstance(): AdobeOpportunityGenerator {
        return new AdobeOpportunityGenerator(this, this._sandbox);
    }

    protected makeAdResolver(): AdobeContentResolver {
        return new AdobeContentResolver(this._sandbox);
    }

    public makeContentFactory(): AdobePSDK.ContentFactory {
        const contentFactory = new AdobePSDK.ContentFactory();
        contentFactory.retrieveAdPolicySelectorCallbackFunc = this._retrieveAdPolicySelector.bind(this);
        contentFactory.retrieveResolversCallbackFunc = this._retrieveResolvers.bind(this);
        contentFactory.retrieveOpportunityGeneratorsCallbackFunc = this._retrieveOpportunityGenerators.bind(this);
        return contentFactory;
    }

}
