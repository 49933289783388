import { HlsTag } from "./HlsTag";

const REQUIRED_FIELDS: string[] = [];

export class HlsSourceStreamTag extends HlsTag {

    public id: string;
    public signal: string;

    constructor(time: number, content: string) {
        super("#EXT-X-SOURCE-STREAM", time, content, REQUIRED_FIELDS);

        this.id = this.metadata["ID"];
        this.signal = this.metadata["SIGNAL"];
    }
}
