import { IAdConfig } from "../ads/IAdConfig";
import { AssetEngine, IContentOptions, IAssetMetadata, XTV_RE, isEAS } from "./ContentOptions";
import { Logger } from "../util/Logger";
import { ConfigurationManager } from "../ConfigurationManager";

/**
 * Represents all fields which will be logged when JSON.Stringify is called on Asset object
 */
export interface IAssetJSON {
    url: string;
    assetEngine: string;
    assetType?: string;
    assetId?: string;
    providerId?: string;
    recordingId?: string;
    streamId?: string;
    surround?: boolean;
    isRetry?: boolean;
    isRollback?: boolean;
    mediaGuid?: string;
    mediaId?: string;
}

export const RESUME_FROM_LIVE_POINT: number = -1;
export const RESUME_FROM_START: number = 0;

export type AssetExtension = "m3u8" | "mpd" | "f4m" | "mp4";

/**
 * Asset url formats
 */
export const AssetUrlType: { [x: string]: AssetExtension } = {
    URLTYPE_M3U: "m3u8",
    URLTYPE_MPD: "mpd",
    URLTYPE_F4M: "f4m",
    URLTYPE_MP4: "mp4"
};

export const OTT = "OTT";

/**
 *
 * Asset type mappings
 */
export const AssetTypeMapping: {
    [x: string]: string,
    CDVR: string,
    DEFAULT: string,
    LINEAR: string,
    T6_LINEAR: string,
    T6_VOD: string,
    TVE_LINEAR: string,
    TVE_VOD: string,
    VOD: string,
    OTT: string,
    IVOD: string
} = {
    CDVR: "cDvr",
    DEFAULT: "defaultAsset",
    LINEAR: "Linear",
    T6_LINEAR: "title6Linear",
    T6_VOD: "title6Vod",
    TVE_LINEAR: "tveLinear",
    TVE_VOD: "tveVod",
    VOD: "Vod",
    OTT: "ott",
    IVOD: "iVod"
};

export const VodAssetTypes = {
    [AssetTypeMapping.T6_VOD]: true,
    [AssetTypeMapping.TVE_VOD]: true,
    [AssetTypeMapping.VOD]: true
};

export function getUrlExtension(url: string): AssetExtension {
    const keys = Object.keys(AssetUrlType);

    url = url.toLowerCase();
    let urlExtension: AssetExtension;
    let idx: number;
    let prev: string;
    let next: string;
    const exp = /[^0-9A-Za-z]/;
    const length = keys.length;

    for (let i = 0; i < length; i++) {
        const key: string = keys[i];
        const len = AssetUrlType[key].length;
        idx = url.lastIndexOf(AssetUrlType[keys[i]]);
        if ((idx !== -1) && (idx > 0)) {
            prev = url.charAt(idx - 1);
            next = url.charAt(idx + len);
            if (exp.test(prev) && ((next === "") || (exp.test(next)))) {
                urlExtension = AssetUrlType[keys[i]];
                break;
            }
        }
    }
    return urlExtension;
}

export class BaseAsset implements IContentOptions {

    public adMetadata: any;
    private _url: string;
    private _urls: string[] = [];
    private _subscribedTags: string[] = [];

    // contentOptions
    public adId: string;
    public aspectRatio: number;
    public assetEngine: string;
    public assetId: string;
    public assetType: string;
    public brand: string;
    public channel: string;
    public drmKey: string;
    public easPath: string;
    public endPosition: number;
    public mediaGuid: string;
    public mediaAccountName: string;
    public mediaId: string;
    public metadata: IAssetMetadata;
    public platformId: string;
    public providerId: string;
    public recordingId: string;
    public resumePosition: number;
    public serviceZip: string;
    public encryptedServiceZip: string;
    public streamId: string;
    public surround: boolean;

    // deprecated content options
    // do NOT rely on these
    public isContentPosition: boolean = true;
    public isRetry: boolean = false;
    public preventModify: boolean = false;
    public preventRedirect: boolean = false;

    public isRollback: boolean = false;
    public isFallback: boolean = false;

    // limit MediaOpened
    public mediaOpenedCount: number = 0;
    private maxMediaOpenedCount: number = 1;

    protected readonly _logger: Logger = new Logger("Asset");

    constructor(public readonly originalUrl: string, public readonly contentOptions: IContentOptions = {}) {

        this._logger.trace(`new Asset: originalUrl=${originalUrl} contentOptions=${JSON.stringify(contentOptions, null, 2)}`);

        this._url = originalUrl;

        this.adMetadata = {};

        // add deprecated props
        this.isRetry = contentOptions.isRetry;
        this.preventModify = contentOptions.preventModify;
        this.preventRedirect = contentOptions.preventRedirect;
        this.isContentPosition = contentOptions.isContentPosition;

        // set all options from contentOptions:
        this.assetEngine = contentOptions.assetEngine || AssetEngine.GENERIC;
        this.assetType = this._mapAssetType(contentOptions.assetType);
        this.resumePosition = isNaN(contentOptions.resumePosition) ? 0 :  contentOptions.resumePosition * 1;
        this.adId = contentOptions.adId;
        this.assetId = contentOptions.assetId;
        this.providerId = contentOptions.providerId;
        this.streamId = contentOptions.streamId;
        this.recordingId = contentOptions.recordingId;
        this.metadata = contentOptions.metadata;
        this.mediaId = contentOptions.mediaId;
        this.platformId = contentOptions.platformId;
        this.easPath = contentOptions.easPath;
        this.drmKey = contentOptions.drmKey;
        this.endPosition = contentOptions.endPosition;
        this.brand = contentOptions.brand;

        this.surround = contentOptions.surround;
        this.mediaGuid = contentOptions.mediaGuid;
        this.mediaAccountName = contentOptions.mediaAccountName;
        this.aspectRatio = contentOptions.aspectRatio;
        this.serviceZip = contentOptions.serviceZip;
        this.encryptedServiceZip = contentOptions.encryptedServiceZip;

        if (this.missingAssetIdentifier()) {
            this._logger.warn("Warning! No asset identifiers provided in content options");
        }
    }

    public addSubscribedTag(tag: string): void {
        if (this._subscribedTags.indexOf(tag) === -1) {
            this._subscribedTags.push(tag);
        }
    }

    get subscribedTags(): string[] {
        return this._subscribedTags;
    }

    public get adConfig(): IAdConfig {
        return this.contentOptions.adConfig;
    }

    public get authToken(): string {
        return this.contentOptions.authToken;
    }

    public get deviceAuthenticationResult(): IDeviceAuthenticationResult {
        return this.contentOptions.deviceAuthenticationResult;
    }

    /**
     * Determines if we were provided some way to identify
     * the asset within our analytics systems.
     */
    private missingAssetIdentifier(): boolean {
        return !(this.providerId && this.assetId) &&
            !this.mediaGuid &&
            !this.recordingId &&
            !this.streamId &&
            !(this.mediaId && this.platformId) &&
            !this.easPath;
    }

    /**
     * Returns JSON object with Asset Details
     * @returns {{url: string, assetEngine: string, assetType: string, assetId: string, providerId: string, recordingId: string,
     * streamId: string, surround: boolean, isRetry: boolean, isRollback: boolean, mediaGuid: string, mediaId: string}}
     */
    public toJSON(): IAssetJSON {
        return { url: this.url,
            assetEngine: this.assetEngine,
            assetType: this.assetType,
            assetId: this.assetId,
            providerId: this.providerId,
            recordingId: this.recordingId,
            streamId: this.streamId,
            surround: this.surround,
            isRetry: this.isRetry,
            isRollback: this.isRollback,
            mediaGuid: this.mediaGuid,
            mediaId: this.mediaId
        };
    }

    public get url(): string {
        return this._url;
    }

    public set url(url: string) {
        this._url = url;
    }

    public get urls(): string[] {
        return this._urls;
    }

    public set urls(urls: string[]) {
        this._urls = urls;
    }

    get id(): string {
        return this.assetId || this.streamId || this.recordingId;
    }

    public getUrlType(): string {
        return getUrlExtension(this.url);
    }

    /**
     * Determines whether the asset is a VOD asset by checking all asset IDs
     *
     * @returns {boolean}
     */
    public isVOD(): boolean {
        return (this.assetId !== undefined) && (this.providerId !== undefined) && (!this.streamId) && (!this.recordingId);
    }

    public assetTypeIsVod(): boolean {
        return (Boolean)(VodAssetTypes[this.assetType]);
    }

    public isCDVR(): boolean {
        return this.recordingId !== undefined && this.recordingId !== null;
    }

    public isT6VODAsset(): boolean {
        return this.assetType === AssetTypeMapping.T6_VOD;
    }

    public isIVODAsset(): boolean {
        return this.assetType === AssetTypeMapping.IVOD;
    }

    public shouldRetry(): boolean {
        return !XTV_RE.test(this.url) && !this.recordingId;
    }

    public shouldStartFromLivePoint(videoType: "live" | "vod"): boolean {
        if (videoType !== "live") {
            return false;
        } else if (isEAS(this.contentOptions)) {
            return this.resumePosition === RESUME_FROM_LIVE_POINT;
        } else  {
            return !this.isCDVR() && !this.isVOD();
        }
    }

    private _mapAssetType(assetType: string) {
        return AssetTypeMapping[assetType] || assetType;
    }

    /**
     * Per session, MediaOpened messages sent to analytics, is limited to retry count + number of total urls URLService resolves
     */
    public initializeMediaOpenedCounts(): void {
        this.mediaOpenedCount = 0;
        if (ConfigurationManager.getInstance().get(ConfigurationManager.RETRY_ON_MEDIA_FAILED)) {
            this.maxMediaOpenedCount = this.urls.length + ConfigurationManager.getInstance().getByAssetType(this.assetType, ConfigurationManager.MAXIMUM_RETRIES);
        } else {
            this.maxMediaOpenedCount = 1;
        }
    }

    /**
     * Returns true if xuaMediaOpened limit is not reached
     * @returns {boolean}
     */
    public canSendMediaOpened(): boolean {
        return this.mediaOpenedCount < this.maxMediaOpenedCount;
    }

}
