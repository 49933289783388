
import "../tracking/AdTracker";
import "../psn/PSNDispatcher";
import { Logger } from "../../util/Logger";
import { IPPSandbox } from "../../PlayerPlatformApplication";
import { BaseAsset, AssetUrlType } from "../../assets/BaseAsset";
import { registerPlugin } from "../../Application";
import { MM_RE } from "../../assets/ContentOptions";
import { AdManager } from "../AdManager";
import { AdManagerTypes, IManifestManipulatorAdConfig } from "../IAdConfig";
import * as events from "../../PlayerPlatformAPIEvents";
import TrickModeRestriction from "../TrickModeRestriction";
import { VideoAd } from "../VideoAd";
import * as constants from "../../PlayerPlatformConstants";
import { PPError } from "../../PPError";
import { HlsTag } from "../../util/hls/HlsTag";
import { TAG_CUE, TAG_TRICKMODE_RESTRICTION, TAG_ASSET_ID } from "../../util/hls/HlsTagFactory";
import { HlsCueTag } from "../../util/hls/HlsCueTag";
import { HlsTrickmodeRestrictionTag } from "../../util/hls/HlsTrickmodeRestrictionTag";
import { AssetIdTag } from "../../util/hls/AssetIdTag";
import { timer, Observable } from "rxjs";
import { flatMap, filter, share, takeUntil } from "rxjs/operators";
import { MediatorChannel } from "publicious";


/**
 * ManifestManipulatorAdManager
 * @constructor
 */
export class ManifestManipulatorAdManager extends AdManager {
    public cfg: IManifestManipulatorAdConfig;

    public readonly type: AdManagerTypes = AdManagerTypes.MANIFEST;
    private _restrictions: TrickModeRestriction[] = [];
    private _adAssetIdTags: AssetIdTag[] = [];

    constructor() {
        super();
        this.logger = new Logger("ManifestManipulatorAdManager");
        this.subscribedTags = ["#EXT-X-CUE", "#EXT-X-TRICKMODE-RESTRICTION"];
    }

    public init(sandbox: IPPSandbox, cfg: IManifestManipulatorAdConfig): any {
        super.init(sandbox, cfg, true);

        events.addEventListener(events.AD_BREAK_START, this.onAdBreakStarted, constants.PRIORITY_HIGH, this);

        const tags: Observable<HlsTag> = sandbox.streams.tags.pipe(takeUntil(sandbox.destroyed), share());

        tags.pipe(filter((tag: HlsTag) => tag.name === TAG_CUE))
            .subscribe((tag: HlsCueTag) => {
                this.addAd(tag.id, tag.time, tag.duration);
            });

        tags.pipe(filter((tag: HlsTag) => tag.name === TAG_TRICKMODE_RESTRICTION))
            .subscribe((tag: HlsTrickmodeRestrictionTag) => this.addRestriction(tag.id, tag.mode, tag.scale, tag.limit));

        // Ad Asset ID info
        tags.pipe(filter((tag: HlsTag) => tag.name === TAG_ASSET_ID))
            .subscribe((tag: AssetIdTag) => {
                this._adAssetIdTags.push(tag);
             });

        this._startTimeoutStream();

        return this;
    }

    /**
     * destroys admanager object and removes all event listeners
     * @param sandbox
     */
    public destroy(sandbox: IPPSandbox): void {
        super.destroy(sandbox);
        events.removeEventListener(events.AD_BREAK_START, this.onAdBreakStarted);
    }

    public onSetAsset(asset: BaseAsset): void {
        if ( asset.getUrlType() === AssetUrlType.URLTYPE_MPD) {
            this.subscribedTags.push(TAG_ASSET_ID);
        }
        super.onSetAsset(asset);
    }

    /**
     * add an entry to the list of trick mode restrictions
     * @method addRestriction
     * @param {string} id        - identifier of ad to which this restriction applies
     * @param {string} mode      - trick play mode that restriction applies to ("fastForward", "rewind", or "pause")
     * @param {string} scale     - trick play speed that restriction applies to (-1 = all)
     * @param {string} limit     - number of times ad must be viewed before restriction is relaxed (-1 = always)
     */
    public addRestriction(id: string, mode: string, scale: string = "-1", limit: string = "0") {
        this.logger.trace("addRestriction: id=" + id + " mode=" + mode + " scale=" + scale + " limit=" + limit);

        if (!mode) {
            this.logger.warn("No mode provided in trickmode restriction, ignoring");
            return;
        }

        const numScale = scale === TrickModeRestriction.SCALE_ALL ? -1 : parseInt(scale, 10);
        const numLimit = limit === TrickModeRestriction.LIMIT_ALWAYS ? -1 : parseInt(limit, 10);

        this._restrictions.push(new TrickModeRestriction(id, mode, numScale, numLimit));
    }

    /**
     * Handler for AdbreakStart event
     * @param {events.AdBreakStartEvent} event - videoAdBreak data
     */
    protected  onAdBreakStarted(event: events.AdBreakStartEvent) {
        if (this.sandbox.getCurrentPlaybackSpeed() > 1 && !event.videoAdBreak.speedIsAllowed(this.sandbox.getCurrentPlaybackSpeed())) {
            const channel: MediatorChannel = arguments[arguments.length - 1];
            this.logger.trace("Stopped AdBreakStart event propagation since playback speed is >1 and it is a ffd disabled AdBreak");
            channel.stopPropagation();
            this.sandbox.publish(constants.SET_POSITION, event.videoAdBreak.startTime);
        }
    }

    public onAdComplete(event: events.AdCompleteEvent) {
        if (event.rate === 1) {
            event.videoAd.incrementSeenCount();
        }
    }

    public getNewSeekPosition(start: number, target: number): number {
        const current = this.getCurrentAdBreak();
        if (current && !current.seekIsAllowed(start, target)) {
            return current.getSeekLimit(start, target);
        }

        const closest = this.getClosestAdBreak(target);
        if (closest && closest.startTime > start && !closest.seekIsAllowed(start, target)) {
            return closest.getSeekLimit(start, target);
        }

        return target;
    }

    private attachRestrictions() {
        this.ads.forEach((ad: VideoAd) => {
            ad.restrictions = this._restrictions.filter((restriction => restriction.id === ad.id));
        });
    }

    private attachAdAssetInfo() {
        for (const ad of this.ads) {
            for (const adAssetIdTag of this._adAssetIdTags) {
                if ((Math.abs(adAssetIdTag.time - ad.startTime) < Number.EPSILON) &&
                    (Math.abs(adAssetIdTag.duration - ad.duration) < Number.EPSILON)) {
                    ad.adAssetInfo = { assetId: adAssetIdTag.assetId, providerId: adAssetIdTag.providerId };
                }
            }
        }
    }

    /**
     * EventListener to PLAY_STATE_CHANGED event
     * @param evt
     * @protected
     */
    protected onPlayStateChanged(evt: events.PlayStateChangedEvent): void {
        if (evt.playState === constants.STATUS_INITIALIZED) {
            this.attachRestrictions();

            // Update video ads with corresponding ad asset id and provider id
            this.attachAdAssetInfo();
            this._resetAdsAssetInfo();

            this.adBreaks = AdManager.sortIntoAdBreaks(this.ads);
            this.updateResumePosition();
        }
    }

    private _startTimeoutStream(): void {

        const states = this.sandbox.streams.playStates.pipe(takeUntil(this.sandbox.destroyed));
        const initializings = states.pipe(filter((evt: events.PlayStateChangedEvent) => evt.playState === constants.STATUS_INITIALIZING));
        const notInitializings = states.pipe(filter((evt: events.PlayStateChangedEvent) => evt.playState !== constants.STATUS_INITIALIZING));

        initializings
            .pipe(
                filter(() => MM_RE.test(this.sandbox.asset.url)),
                flatMap(() => timer(this.sandbox.config.manifestManipulatorTimeout)
                                .pipe(takeUntil(notInitializings))),
                takeUntil(this.sandbox.destroyed)
            )
            .subscribe(() => events.emit(new events.MediaFailedEvent(new PPError(7555, 504, "VEX timeout"))));
    }

    private _resetAdsAssetInfo(): void {
        this._adAssetIdTags = [];
    }

    public reset() {
        this._restrictions = [];
        this._resetAdsAssetInfo();
        super.reset();
    }
}

registerPlugin("ManifestManipulatorAdManager", ManifestManipulatorAdManager, {
    children: ["PSNDispatcher", "AdTracker"]
});
