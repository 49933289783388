import { registerModule } from "../Application";
import { FOG_RE } from "../assets/ContentOptions";
import { IPPModule, IPPSandbox } from "../PlayerPlatformApplication";
import { XREError, getByErrorCode } from "../engines/psdk/XREErrors";
import { XREPlayerPlatform } from "./XREPlayerPlatform";
import { PPError } from "../PPError";
declare const global: any;
declare const XREReceiver: any;
declare const _xrePlayerPlatform: XREPlayerPlatform;

import * as events from "../PlayerPlatformAPIEvents";
import * as constants from "../PlayerPlatformConstants";
import { PlayerPlatformAPI } from "../PlayerPlatformAPI";
import { Logger } from "../util/Logger";
import { convert2to3 } from "../util/LanguageUtil";
import { AssetTypeMapping } from "../assets/BaseAsset";

type XREMediaType = "live" | "liveTSB" | "recorded";

/**
 * Initialize the XRE event system and register listeners
 * @constructor
 */
export class XREEventManager implements IPPModule<XREEventManager> {

    public static TYPICAL_IFRAME_INTERVAL_MILLISECONDS = 2000;
    public static LIVE_DELAY_MILLISECONDS = 4000;
    public static PLAYBACK_SPEEDS: number[] = [-64, -32, -16, -4, -1, 0, 1, 4, 16, 32, 64];

    /**
     * 10
     * NETWORK ERROR
     * Not able to access content due to a network error
     */
    public static NETWORK_ERROR = 10;

    /**
     * 20
     * RESOURCE UNAVAILABLE
     * The resource was not found at the URL provided (HTTP 404)
     */
    public static RESOURCE_UNAVAILABLE = 20;

    /**
     * 30
     * UNSUPPORTED CONTENT
     * The resource is not recognized as a format that the player supports
     */
    public static UNSUPPORTED_CONTENT = 30;

    /**
     * 40
     * CONTENT UNAUTHORIZED
     * User is unauthorized to play content; content hasn't been paid for
     */
    public static CONTENT_UNAUTHORIZED = 40;

    /**
     * 50
     * DRM FAILURE
     * Unable to get rights to play back content
     */
    public static DRM_FAILURE = 50;

    /**
     * 60
     * CONTENT CANCELLED
     * A request to destroy the video resource is made prior to video loading (before onVideoMetaData or onStreamPlaying would be sent)
     */
    public static CONTENT_CANCELLED = 60;

    /**
     * 70
     * PLAYBACK FAILURE
     * Unable to start playing content
     */
    public static PLAYBACK_FAILURE = 70;

    /**
     * 80
     * PLAYBACK TERMINATED
     * Error occurred during playback
     */
    public static PLAYBACK_TERMINATED = 80;

    /**
     * 90
     * INVALID PARAMS
     * Errors that relate to the state of the player such as invalid playback speed, audio language, etc.
     */
    public static INVALID_PARAMS = 90;

    /**
     * 100
     * OTHER
     */
    public static OTHER = 100;

    /**
     * 110
     * MEDIA_ERROR_TUNE_ERROR
     * Failure to tune to the frequency
     */
    public static TUNE_ERROR = 110;

    /**
     * 120
     * PROGRAM_DATA_UNAVAILABLE
     * Failure to get the PAT/PMT/PID and other program data
     */
    public static PROGRAM_DATA_UNAVAILABLE = 120;

    /**
     * 130
     * VOD_PLAYBACK_FAILURE
     * Failure in playing VOD asset
     */
    public static VOD_PLAYBACK_FAILURE = 130;


    private logger = new Logger("XREEventManager");
    private player?: PlayerPlatformAPI;
    private deviceType?: string;
    private sandbox?: IPPSandbox;

    /**
     * @param player - an instance of the PlayerPlatformAPI that XREEventManager
     *                  will attach all it's listeners to.
     * @param deviceType
     */
    public init(sandbox: IPPSandbox, player: PlayerPlatformAPI, deviceType: string) {
        this.player = player;
        this.deviceType = deviceType;
        this.sandbox = sandbox;
        events.on("BitrateChanged", this.onBitrateChanged, constants.PRIORITY_DEFAULT, this);
        events.on("BufferComplete", this.onBufferComplete, constants.PRIORITY_DEFAULT, this);
        events.on("BufferStart", this.onBufferStart, constants.PRIORITY_DEFAULT, this);
        events.on("MediaEnded", this.onMediaEnded, constants.PRIORITY_DEFAULT, this);
        events.on("MediaFailed", this.onMediaFailed, constants.PRIORITY_DEFAULT, this);
        events.on("MediaOpened", this.onMediaOpened, constants.PRIORITY_DEFAULT, this);
        events.on("MediaProgress", this.onMediaProgress, constants.PRIORITY_DEFAULT, this);
        events.on("MediaWarning", this.onMediaWarning, constants.PRIORITY_DEFAULT, this);
        events.on("PlaybackSpeedChanged", this.onPlaybackSpeedChanged, constants.PRIORITY_DEFAULT, this);
        events.on("PlaybackSpeedsChanged", this.onPlaybackSpeedsChanged, constants.PRIORITY_DEFAULT, this);
        events.on("PlayStateChanged", this.onPlayStateChanged, constants.PRIORITY_DEFAULT, this);
        events.on("DRMMetadata", this.onDRMMetadata, constants.PRIORITY_DEFAULT, this);
        events.on("MediaRetry", this.onMediaRetry, constants.PRIORITY_DEFAULT, this);

        sandbox.subscribe("xre:onenteringlive", this.onEnteringLive, constants.PRIORITY_DEFAULT, this);

        return this;
    }

    public destroy(sandbox: IPPSandbox) {
        events.off("BitrateChanged", this.onBitrateChanged);
        events.off("BufferComplete", this.onBufferComplete);
        events.off("BufferStart", this.onBufferStart);
        events.off("MediaEnded", this.onMediaEnded);
        events.off("MediaFailed", this.onMediaFailed);
        events.off("MediaOpened", this.onMediaOpened);
        events.off("MediaProgress", this.onMediaProgress);
        events.off("MediaWarning", this.onMediaWarning);
        events.off("PlaybackSpeedChanged", this.onPlaybackSpeedChanged);
        events.off("PlaybackSpeedsChanged", this.onPlaybackSpeedsChanged);
        events.off("PlayStateChanged", this.onPlayStateChanged);
        events.off("DRMMetadata", this.onDRMMetadata);
        events.off("MediaRetry", this.onMediaRetry);

        sandbox.remove("xre:onenteringlive", this.onEnteringLive);
    }

    private onBitrateChanged(event: events.BitrateChangedEvent) {
        this.logger.trace("onBitrateChanged");
        if (global.XREReceiver) {
            global.XREReceiver.onEvent("onDynamicStreamsChange",
                {
                    bitRate: event.bitRate,
                    changeReason: event.changeReason
                });
        }
    }

    private onBufferComplete() {
        this.logger.trace("onBufferComplete");
        _xrePlayerPlatform.isBuffering = false;
    }

    private onBufferStart() {
        this.logger.trace("onBufferStart");
        _xrePlayerPlatform.isBuffering = true;
    }

    private onMediaEnded() {
        this.logger.info("onMediaEnded: Stop");
        if (this.player) {
            this.player.stop();
        }
        if (global.XREReceiver) {
            global.XREReceiver.onEvent("onMediaEnded", {});
        }
    }

    private onMediaFailed(event: events.MediaFailedEvent) {
        this.logger.trace("onMediaFailed");

        if (this.player) {
            this.player.stop();
        }

        const [ errorCode, errorDesc ] = this.getErrorCode(event.error);

        if (global.XREReceiver) {
            XREReceiver.onEvent("onMediaFailed", {
                code: errorCode,
                description: errorDesc,
                playerRecoveryEnabled: false
            });
        }
    }

    private onMediaOpened() {
        this.logger.trace("onMediaOpened");

        if (!this.player) {
            this.logger.warn("got a media open without a player configured");
            return;
        }

        // onMediaOpened never makes it the Receiver for some liveTSB tunes
        // https://ccp.sys.comcast.net/browse/ARRISXI6-596
        try {
            if (global.XREReceiver) {
                this.logger.info("onMediaOpened send onMediaOpened");
                //convert audio languages..
                const languagesISO639: any[] = [];
                const availableLanguages = this.player.getAvailableAudioLanguages();
                for (const lang of availableLanguages) {
                    languagesISO639.push(convert2to3(lang));
                }

                const playbackSpeeds = XREEventManager.PLAYBACK_SPEEDS.filter(num => {
                    if (this.player.adManager) {
                        return this.player.adManager.getSupportedPlaybackSpeeds(true).indexOf(num) !== -1;
                    } else {
                        return this.player.getSupportedPlaybackSpeeds().indexOf(num) !== -1;
                    }
                });

                global.XREReceiver.onEvent("onMediaOpened",
                    {
                        mediaType: this.getMediaType(),
                        width: this.player.getVideoWidth(),
                        height: this.player.getVideoHeight(),
                        endPosition: this.player.getDuration(),
                        availableAudioLanguages: languagesISO639,
                        playbackSpeeds: playbackSpeeds,
                        mediaSegments: this.player.getMediaSegments().map(segment => segment.toObject()),
                        availableClosedCaptionTracks: this.player.getAvailableClosedCaptionTracks(),
                        customProps: {
                            engineType: this.player.getAssetEngineType()
                        }
                    });
            }

            if (this.player.getAutoPlay()) {
                this.logger.info("XREEventManager.onMediaOpened calling play()");
                this.player.play();
            }
        } catch (e) {
            this.logger.info(e);
        }
    }

    private onMediaProgress(event: events.MediaProgressEvent) {
        if (global.XREReceiver) {
            global.XREReceiver.onEvent("onMediaProgress",
                { position: event.position, startPosition: event.startposition, endPosition: event.endposition, playbackSpeed: event.playbackSpeed });
        } else {
            this.logger.error("onMediaProgress: no XREReceiver!");
        }
    }

    private onMediaRetry(event: events.MediaRetryEvent) {
        this.logger.trace("onMediaRetry");

        const [ errorCode, errorDesc ] = this.getErrorCode(event.data.error);

        if (global.XREReceiver) {
            if (_xrePlayerPlatform.contentOptions.enableSelfHeal) {
                XREReceiver.onEvent("onMediaFailed", {
                    code: errorCode,
                    description: errorDesc,
                    playerRecoveryEnabled: true
                });
            } else {
                XREReceiver.onEvent("onMediaWarning", {
                    code: errorCode,
                    description: errorDesc
                });
            }
        }
    }

    private onMediaWarning(event: events.MediaWarningEvent) {
        this.logger.trace("onMediaWarning");
        if (global.XREReceiver) {
            global.XREReceiver.onEvent("onMediaWarning", { code: event.error.code, description: event.error.description });
        }
    }

    private onPlaybackSpeedChanged(event: events.PlaybackSpeedChangedEvent) {
        this.logger.trace("onPlaybackSpeedChanged");
        if (global.XREReceiver) {
            global.XREReceiver.onEvent("onPlaybackSpeedChanged", {
                speed: event.playbackSpeed,
                reason: event.reason
            });
        }
    }

    private onPlaybackSpeedsChanged(event: events.PlaybackSpeedsChangedEvent) {
        // need to make sure we account for allowed XRE speeds
        const newSpeeds = XREEventManager.PLAYBACK_SPEEDS.filter(num => {
            return event.playbackSpeeds.indexOf(num) !== -1;
        });

        this.logger.trace(`onPlaybackSpeedsChanged: ${JSON.stringify(newSpeeds)}`);

        if (global.XREReceiver) {
            global.XREReceiver.onEvent("onPlaybackSpeedsChanged", { playbackSpeeds: newSpeeds });
        }
    }

    private onPlayStateChanged(event: events.PlayStateChangedEvent) {
        this.logger.trace("onPlayStateChanged: state = " + event.playState);

        // These are the only 3 states that XREReceiver checks for :(
        if ((event.playState === "playing" || // Playing removes the undercard
            event.playState === "paused" ||
            (this.shouldSendInitializing() && event.playState === "initializing")) && global.XREReceiver) {
                global.XREReceiver.onEvent("onPlayStateChanged", { state: event.playState });
        }
    }

    private onDRMMetadata(event: events.DRMMetadataEvent) {
        this.logger.trace("onDRMMetadata");
        if (global.XREReceiver) {
            global.XREReceiver.onEvent("onDRMMetadata", { drmMetadataInfo: event.drmMetadataInfo });
        }
    }

    private onEnteringLive() {
        this.logger.trace("OnEnteringLive");
        if (global.XREReceiver) {
            global.XREReceiver.onEvent("onEnteringLive", {});
        }
    }

    public onAdditionalAuthRequired(locator: string, eventId: string): void {
        this.logger.trace("onAdditionalAuthRequired");
        if (global.XREReceiver) {
            XREReceiver.onEvent("onAdditionalAuthRequired", { locator, eventId });
        }
    }

    private getMediaType(): XREMediaType {

        let xreMediaType: XREMediaType = mediaTypeMap[String(this.player && this.player.getVideoType())];

        if (!xreMediaType) {
            throw new Error("cannot determine xre media type");
        }

        if (xreMediaType === "live" && FOG_RE.test(String(this.player && this.player.asset && this.player.asset.url))) {
            xreMediaType = "liveTSB";
        }

        this.logger.info(`XRE media type: ${xreMediaType}`);

        return xreMediaType;
    }

    private shouldSendInitializing(): boolean {
        // RNG150 doesn't handle "initializing" correctly. Don't send if STB is RNG150
        if (this.deviceType && this.deviceType.toLowerCase().indexOf("rng150") >= 0) {
            return false;
        }

        // we need retry logic to work for OTT content because cookie auth could fail which
        // would require a retry to obtain new cookies
        if (this.sandbox && this.sandbox.asset && this.sandbox.asset.assetType === AssetTypeMapping.OTT) {
            return false;
        }

        return true;
    }

    private getErrorCode(error: PPError): [ number, string ] {
        let errorCode: number = 0;
        let errorDesc: string = "";

        if (this.player && this.player.getAssetEngineType() === "aamp") {
            errorCode = parseInt(error.code, 10);
            errorDesc = error.description;
        } else {
            const xreError: XREError = getByErrorCode(error.code);
            errorCode = xreError.code;
            errorDesc = xreError.description;
        }

        return [errorCode, errorDesc];
    }
}

const mediaTypeMap: { [x: string]: XREMediaType } = {
    live: "live",
    vod: "recorded"
};

registerModule("XREEventManager", XREEventManager);
