
import { IPPSandbox } from "../../PlayerPlatformApplication";
import { AdobeContentTracker } from "./AdobeContentTracker";
import { Logger } from "../../util/Logger";
import { AdobeRuntimeWrapper } from "../../engines/psdk/AdobeRuntimeWrapper";
import { IRequestResolver } from "../../ads/IResolvableOpportunity";
import { IAdBreak } from "../IAdSlot";
import * as constants from "../../PlayerPlatformConstants";

const RESOLVE_TIMEOUT: number = 10000;

export class AdobeContentResolver {

    public requestResolver: IRequestResolver;

    protected _client: AdobePSDK.ContentResolverClient;

    protected static _logger: Logger = new Logger("AdobeContentResolver");

    private _resolvingOpportunities = {};
    private _resolvingTimeouts = {};

    constructor(private _sandbox: IPPSandbox) {}

    public configure(_item: AdobePSDK.MediaPlayerItem, client: AdobePSDK.ContentResolverClient): void {
        this._client = client;
        this._sandbox.subscribe("ads:timelineResolved", this.onTimelineResolved, constants.PRIORITY_DEFAULT, this);
    }

    public cleanup(): void {
        this._client = null;
        this._resolvingOpportunities = {};
        this._resolvingTimeouts = {};
        this._sandbox.remove("ads:timelineResolved", this.onTimelineResolved);
    }

    protected onTimelineResolved(opportunityId: string, adBreaks: IAdBreak[]): void {
        AdobeContentResolver._logger.info("onTimelineResolved", opportunityId, JSON.stringify(adBreaks));
        if (this._resolvingOpportunities[opportunityId]) {
            this._processOpportunity(
                this._resolvingOpportunities[opportunityId],
                adBreaks
            );
        } else {
            AdobeContentResolver._logger.info("Opportunity not found");
        }
    }

    public canResolve(_opportunity: AdobePSDK.Opportunity): boolean {
        return true;
    }

    private completeOpportunity(opportunity: AdobePSDK.Opportunity): void {
        if (this._client) {
            AdobeContentResolver._logger.info("notifyCompleted");
            this._client.notifyCompleted(opportunity);
        } else {
            AdobeContentResolver._logger.warn("Client has not been configured");
        }
        clearTimeout(this._resolvingTimeouts[opportunity.id]);
        delete this._resolvingOpportunities[opportunity.id];
        delete this._resolvingTimeouts[opportunity.id];
    }

    private _processOpportunity(opportunity: AdobePSDK.Opportunity, adBreaks: IAdBreak[]): void {

        AdobeContentResolver._logger.info("_processOpportunity", opportunity.id, adBreaks.length);

        if (this._client && adBreaks.length > 0) {
            AdobeContentResolver._logger.info("process");
            this._client.process(this._getTimelineOperations(opportunity, adBreaks));
        } else {
            AdobeContentResolver._logger.warn("Nothing to process");
        }

        this.completeOpportunity(opportunity);
    }

    private _getTimelineOperations(opportunity: AdobePSDK.Opportunity, adBreaks: IAdBreak[]): AdobePSDK.AdBreakPlacement[] {

        const timelineOperations: AdobePSDK.AdBreakPlacement[] = [];

        for (const adBreak of adBreaks) {
            timelineOperations.push(this.getAdBreakPlacement(opportunity, adBreak));
        }

        return timelineOperations;

    }

    private getAdBreakPlacement(opportunity: AdobePSDK.Opportunity, adBreak: IAdBreak): AdobePSDK.AdBreakPlacement {
        const contentTracker: AdobeContentTracker = new AdobeContentTracker(opportunity, this._sandbox, adBreak);
        const psdkTracker: AdobePSDK.ContentTracker = contentTracker.makeContentTracker();
        const placement = adBreak.type === "insert" ? this.createInsertionPlacement(opportunity.placement) : opportunity.placement;

        return new AdobePSDK.AdBreakPlacement(
            new AdobePSDK.AdBreak(
                this.getAds(adBreak, psdkTracker),
                psdkTracker,
                AdobePSDK.AdInsertionType.CLIENT_INSERTED
            ),
            placement
        );
    }

    private getAds(adBreak: IAdBreak, tracker: AdobePSDK.ContentTracker): AdobePSDK.Ad[] {
        return adBreak.ads.map(ad => {

            const resource = new AdobePSDK.MediaResource();
            resource.url = ad.manifestUrl;
            resource.type = AdobeRuntimeWrapper.HLS();

            const adAsset = new AdobePSDK.AdAsset(ad.id, ad.duration, resource);

            return new AdobePSDK.Ad(
                ad.id, AdobeRuntimeWrapper.ADTYPE_LINEAR(), ad.duration,
                false, adAsset, [], tracker, AdobePSDK.AdInsertionType.CLIENT_INSERTED
            );

        });
    }

    /*
    Adobe placements are immutable and a new one needs to be returned in order to switch the placement mode
     */
    private createInsertionPlacement(placement: AdobePSDK.Placement): AdobePSDK.Placement {
        return new AdobePSDK.Placement(
            placement.type,
            placement.time,
            placement.duration,
            AdobeRuntimeWrapper.PLACEMENT_MODE_INSERT()
        );
    }

    /**
     * Place holder, called by AdobePSDK
     */
    protected resolveHandler(opportunity: AdobePSDK.Opportunity): void {
        AdobeContentResolver._logger.info("WE ARE TRYING TO RESOLVE");
        try {
            AdobeContentResolver._logger.info("resolve", opportunity.id);
            this._resolvingTimeouts[opportunity.id] = setTimeout(() => {
                AdobeContentResolver._logger.warn("Timing our opportunity " + opportunity.id);
                this.completeOpportunity(opportunity);
            }, RESOLVE_TIMEOUT);
            this._resolvingOpportunities[opportunity.id] = opportunity;
        } catch (e) {
            AdobeContentResolver._logger.info("WE COULD NOT RESOLVE");
        }
    }

    public makeContentResolver(): AdobePSDK.ContentResolver {
        const adResolver = new AdobePSDK.ContentResolver();
        adResolver.configureCallbackFunc = this.configure.bind(this);
        adResolver.canResolveCallbackFunc = this.canResolve.bind(this);
        adResolver.resolveCallbackFunc = this.resolveHandler.bind(this);
        adResolver.cleanupCallbackFunc = this.cleanup.bind(this);
        return adResolver;
    }

}
