import { Observable, of, throwError } from "rxjs";
import { AjaxResponse } from "rxjs/ajax";
import { Logger } from "../util/Logger";
import { PPError } from "../PPError";
import { ajax } from "rxjs/ajax";
import { switchMap, catchError } from "rxjs/operators";

export interface INbcConditionedStreamRequestBody {
    adobeMvpdId: string;
    device: string;
    serviceZip: string;
}

export interface INbcConditionedStreamConfig {
    serviceUrl: string;
    adobeMvpdId: string;
    channels: INbcConditionedStreamChannelsConfig;
}

export interface INbcConditionedStreamChannelsConfig {
    [index: string]: INbcConditionedStreamChannelConfig;
}

export interface INbcConditionedStreamChannelConfig {
    apiKey: string;
}

export const NBCU_API_ERROR_CODE = 8100;
export const TIMEOUT_ERROR_CODE = 1;
export const UNRECOGNIZED_RESPONSE_FORMAT = 2;
export const CHANNEL_CONFIGURATION_ERROR = 3;

export class NbcConditionedStreamApi {

    private static _logger = new Logger("NBCConditionedStreamApi");

    constructor(private config: INbcConditionedStreamConfig) {
    }

    public getConditionedAssetUrl(channel: string, serviceZip: string): Observable<string> {
        channel = channel ? channel.toLowerCase() : "";
        if (!this.config.channels || !this.config.channels[channel] || !this.config.channels[channel].apiKey) {
            return throwError(new PPError(NBCU_API_ERROR_CODE, CHANNEL_CONFIGURATION_ERROR, ""));
        }

        return this.sendRequest(channel, serviceZip)
            .pipe(
                switchMap((ajaxResponse: AjaxResponse) =>
                    this.succeeded(ajaxResponse) ?
                        of(ajaxResponse.response.streamUrl) :
                        throwError(this.buildValidationPPError(ajaxResponse)))
            );
    }

    private succeeded(ajaxResponse: AjaxResponse): boolean {
        return Boolean(ajaxResponse && ajaxResponse.status === 200 && ajaxResponse.response && !ajaxResponse.response.restricted && ajaxResponse.response.streamUrl);
    }

    private sendRequest(channel: string, serviceZip: string): Observable<AjaxResponse> {

        return ajax({
            url: `${this.config.serviceUrl}/${channel}`,
            method: "POST",
            body: JSON.stringify(this.buildRequestBody(serviceZip)),
            crossDomain: true,
            responseType: "json",
            headers: {
                Authorization: `NBC-Referrer key=${this.config.channels[channel].apiKey}, version=3.0, type=cpc`,
                "Content-Type": "application/json; charset=UTF-8"
            }
        })
        // it's possible we can get a standard javascript error
        // in that case let it go up the stack
            .pipe(catchError((err: any) => throwError(err.xhr ? this.buildAjaxPPError(err) : err)));
    }

    private buildValidationPPError(ajaxResponse: AjaxResponse): PPError {
        NbcConditionedStreamApi._logger.warn(`API returned erroneous response: ${JSON.stringify(ajaxResponse)}`);
        if (!ajaxResponse.response || !ajaxResponse.response.restrictionDetails || !ajaxResponse.response.restrictionDetails.code) {
            return new PPError(
                NBCU_API_ERROR_CODE,
                UNRECOGNIZED_RESPONSE_FORMAT,
                "NBC api response format not recognized");
        }
        const subCode = ajaxResponse.response.restrictionDetails.code;
        const message = ajaxResponse.response.restrictionDetails.description;
        return new PPError(NBCU_API_ERROR_CODE, subCode, message);
    }

    private buildAjaxPPError(err: any): PPError {
        const logInfo = JSON.stringify({
                status: err.status,
                request: err.request,
                response: (err.xhr && err.xhr.response) || ""
            }
        );
        NbcConditionedStreamApi._logger.warn(`API request caused an error: ${JSON.stringify(logInfo)}`);

        return err.status === 0 ? this.buildTimeoutAjaxPPError() : this.buildAjaxWithStatusPPError(err);
    }

    private buildTimeoutAjaxPPError(): PPError {
        return new PPError(NBCU_API_ERROR_CODE, TIMEOUT_ERROR_CODE, "");
    }

    private buildAjaxWithStatusPPError(err: any): PPError {
        const subCode = (err.xhr && err.xhr.response && err.xhr.response.code) || "";
        const statusCode = err.status || "";
        const message = (err.xhr && err.xhr.response && err.xhr.response.message) || "";
        return new PPError(NBCU_API_ERROR_CODE, subCode, `${statusCode} ${message}`);
    }

    private buildRequestBody(serviceZip: string): INbcConditionedStreamRequestBody {
        return {
            serviceZip,
            adobeMvpdId: this.config.adobeMvpdId,
            device: "web"
        };
    }
}
