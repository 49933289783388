import { HlsTag } from "./HlsTag";
import { MILLISECONDS_PER_SECOND } from "../../PlayerPlatformConstants";

const REQUIRED_FIELDS: string[] = ["ID", "DURATION"];

export class HlsCueTag extends HlsTag {

    public id: string;
    public duration: number;
    public psn: boolean;

    constructor(time: number, content: string) {
        super("#EXT-X-CUE", time, content, REQUIRED_FIELDS);

        this.id = this.metadata["ID"];
        this.duration = parseFloat(this.metadata["DURATION"]) * MILLISECONDS_PER_SECOND;
        this.psn = this.metadata["PSN"] === "true";
    }
}
