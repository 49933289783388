import { BaseMessage } from "./Messages";

export interface IAnalyticsMoneyTrace {
    TRACE_ID: string;
    PARENT_ID: string;
    SPAN_ID: string;
}

export interface IDeviceInfo {
    DEV_NAME?: string;
    DEV_VER?: string;
    DEV_ID?: string;
    PHYSICAL_DEVICE_ID?: string;
}

export interface IApplicationInfo {
    APP_NAME?: string;
    APP_VER?: string;
    PLAYER_NAME?: string;
    PLAYER_VER?: string;
    PLUGIN_NAME?: string;
    PLUGIN_VER?: string;
    SERVICE_ACCOUNT_ID?: string;
    xsctPartnerId?: string;
}

export interface IHostInfo {
    appName: string;
    appVersion: string;
    deviceName: string;
    deviceVersion: string;
    deviceId: string;
    physicalId?: string;
    accountId?: string;
    xsctPartnerId?: string;
    inHomeState: () => string;
    messageOptions?: IMessageOptions;
}

export interface IAnalyticsConfig {
    maxBatchSize: number;
    maxQueueSize: number;
    analyticsUrl: string;
    analyticsProtocol: number;
    batchInterval: number;
}

// A message options arguments allows hook points into the message dispatching
// in the buildMessage analytics call.
export interface IMessageOptions {
    // preDispatch is not necessarily called before the message is sent;
    // but instead more likely called prior to a message being queued up in a batch.
    preDispatch?: (message: BaseMessage) => void;
}

export enum HomeState {
    UNKNOWN = "unknown",
    IN_HOME = "inHome",
    OUT_OF_HOME = "outOfHome"
}
