import { AdobeContentFactory } from "../psdk/AdobeContentFactory";
import { CustomAdOpportunityGenerator } from "./CustomAdOpportunityGenerator";
import { AdobeOpportunityGenerator } from "../psdk/AdobeOpportunityGenerator";
import { AdobeContentResolver } from "../psdk/AdobeContentResolver";
import { CustomAdResolver } from "./CustomAdResolver";
import { IPPSandbox } from "../../PlayerPlatformApplication";
import { ICustomAdConfig } from "../IAdConfig";

export class CustomAdContentFactory extends AdobeContentFactory {
    constructor(_sandbox: IPPSandbox, private cfg: ICustomAdConfig) {
        super(_sandbox);
    }

    protected makeOpportunityInstance(): AdobeOpportunityGenerator {
        return new CustomAdOpportunityGenerator(this, this.cfg, this._sandbox);
    }

    protected makeAdResolver(): AdobeContentResolver {
        return new CustomAdResolver(this._sandbox, this.cfg);
    }
}
