export default class TrickModeRestriction {

    public static MODE_FFWD = "fastForward";
    public static MODE_PAUSE = "pause";
    public static MODE_REW = "rewind";
    public static SCALE_ALL = "All";
    public static LIMIT_ALWAYS = "Always";

    public id: string;
    public mode: string;
    public scale: number;
    public limit: number;

    constructor(id: string, mode: string, scale: number, limit: number) {
        this.id = id;
        this.mode = mode;
        this.scale = scale;
        this.limit = limit;
    }
}

export class RestrictionLimits {
    public fastForward: number;
    public rewind: number;
    public pause: number;

    [x: string]: number;

    constructor(ff: number = 0, rew: number = 0, pause: number = 0) {
        this.fastForward = ff;
        this.rewind = rew;
        this.pause = pause;
    }
}
