type RegulatoryClass = "T6" | "NONE";

const T6_RE = /^(title6|cDvr)/;
const LINEAR_RE = /Linear$/;
const VOD_RE = /Vod$/;
const CDVR_RE = /cDvr/;

export interface IXuaAsset {
    ID_TYPE: string;
    CLASS: string;
    PLAYBACK_TYPE: string;
    RegulatoryClass: RegulatoryClass;
    IDS: IAssetIDs;
    INFO: object;
    SECURITY: string;
    VSTRM?: IVStream;
}

export interface IVStream {
    SRCID?: string;
    SIGID?: string;
    SRVZONE?: string;
}

function toRegulatoryClass(assetType: string): RegulatoryClass {
    let regClass: RegulatoryClass = "NONE";
    if (T6_RE.test(assetType)) {
        regClass = "T6";
    }
    return regClass;
}

function toAssetClass(assetType: string): string {
    let assetClass: string = null;
    if (LINEAR_RE.test(assetType)) {
        assetClass = "Linear";
    } else if (VOD_RE.test(assetType)) {
        assetClass = "VOD";
    } else if (CDVR_RE.test(assetType)) {
        assetClass = "cDVR";
    }
    return assetClass;
}

export class PAIDAsset implements IXuaAsset {
    public ID_TYPE: string = "PAID";
    public CLASS: string;
    public PLAYBACK_TYPE: string = "STREAM";
    public RegulatoryClass: RegulatoryClass;
    public IDS: IAssetIDs;
    public INFO: object = {};
    public SECURITY: string = "FALSE";
    constructor(providerId: string, assetId: string, assetType: string, mediaGuid?: string) {
        this.CLASS = toAssetClass(assetType) || "VOD";
        this.RegulatoryClass = toRegulatoryClass(assetType);
        this.IDS = {
            PID: providerId,
            AID: assetId,
            MediaGUID: mediaGuid
        };
        this.INFO = {};
    }
}

export class GenericAsset implements IXuaAsset {
    public ID_TYPE: string = "GenericId";
    public CLASS: string;
    public PLAYBACK_TYPE: string = "STREAM";
    public RegulatoryClass: RegulatoryClass;
    public IDS: IAssetIDs;
    public INFO: object = {};
    public SECURITY: string = "FALSE";
    constructor(mediaGuid: string, assetType: string, mediaId: string) {
        this.CLASS = toAssetClass(assetType) || "VOD";
        this.RegulatoryClass = toRegulatoryClass(assetType);
        this.IDS = {
            NS: "CIM",
            MediaGUID: mediaGuid,
            MEDIA_ID: mediaId
        };
    }
}

export class STRIDAsset implements IXuaAsset {
    public ID_TYPE: string = "StreamId";
    public CLASS: string = "Linear";
    public PLAYBACK_TYPE: string = "STREAM";
    public RegulatoryClass: RegulatoryClass;
    public IDS: IAssetIDs;
    public INFO: object = {};
    public SECURITY: string = "FALSE";
    public VSTRM: IVStream;
    constructor(streamId: string, assetType: string, vstrm: IVStream) {
        this.RegulatoryClass = toRegulatoryClass(assetType);
        this.IDS = {
            STRID: streamId
        };
        this.VSTRM = vstrm || {};
    }
}

export class RIDAsset implements IXuaAsset {
    public ID_TYPE: string = "RecordingId";
    public CLASS: string = "cDVR";
    public PLAYBACK_TYPE: string = "STREAM";
    public RegulatoryClass: RegulatoryClass;
    public IDS: IAssetIDs;
    public INFO: object = {};
    public SECURITY: string = "FALSE";
    constructor(recordingId: string, assetType: string) {
        this.RegulatoryClass = toRegulatoryClass(assetType);
        this.IDS = {
            RECID: recordingId
        };
    }
}

export class EASAsset implements IXuaAsset {
    public ID_TYPE: string = "EAS_URI";
    public CLASS: string = "EAS";
    public PLAYBACK_TYPE: string = "STREAM";
    public RegulatoryClass: RegulatoryClass = "NONE";
    public IDS: IAssetIDs;
    public INFO: object = {};
    public SECURITY: string = "FALSE";
    constructor(easPath: string) {
        this.IDS = {
            EAS_URI: easPath
        };
    }
}

interface IAssetIDs {
    PID?: string;
    AID?: string;
    MediaGUID?: string;
    MEDIA_ID?: string;
    TID?: string;
    STRID?: string;
    RECID?: string;
    EAS_URI?: string;
    NS?: string;
}
