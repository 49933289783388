import { Logger } from "../Logger";

const NON_QUOTED_COMMA = /,(?=(?:[^"]|"[^"]*")*$)/;

export class HlsTag {

    public metadata: { [x: string]: string } = {};

    private _logger: Logger = new Logger("HlsTag");

    constructor(public name: string, public time: number, public content: string = "", private _requiredFields: string[] = []) {
        this._parseContent();
        this._logContentWarnings();
    }

    protected _parseContent() {
        if (!this.content) {
            return;
        }

        const prefix = `${this.name}:`;
        if (this.content.indexOf(prefix) === 0) {
            this.content = this.content.substr(this.content.indexOf(":") + 1);
        }

        // this splits the attribute list using a comma delimiter into an `AttributeName=AttributeValue` array
        // a RegExp is used to split on the comma to exclude any commas contained in double-quotes
        const keyValues: string[] = this.content.split(NON_QUOTED_COMMA);

        keyValues.forEach(kv => {
            // parse a key/value pair out of a string of key=value
            // since value can contain = characters, we look for the first = to be the kvp separator
            // any remaining = characters are considered to be part of the value
            const delim = kv.indexOf("=");
            if (delim > 0) {
                const key = kv.substring(0, delim);
                const value = kv.substring(delim + 1);
                this.metadata[key] = value ?
                    // remove wrapping quotes if they exist
                    value.replace(/^"([^"]*)"$/, "$1") :
                    value;
            }
        });
    }

    private _logContentWarnings() {
        const keys = Object.keys(this.metadata);
        this._requiredFields
            .filter(field => keys.indexOf(field) === -1)
            .forEach(field => this._logger.warn(`Required field ${field} missing from ${this.name} tag`));
    }
}
