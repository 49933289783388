/**
 * Handles dispatching of analytics messages
 * @module
 */

declare let __viperversion: string;

import { ajax } from "rxjs/ajax";
import { Logger } from "../util/Logger";
import { BaseMessage, PluginInitializedMessage } from "./Messages";
import { FAILED_BATCH_INTERVAL, getFailedMessages, addFailedMessages } from "./FailedMessageStore";
import { SessionManager } from "../handlers/SessionManager";
import { IAnalyticsMoneyTrace, IDeviceInfo, IApplicationInfo, IAnalyticsConfig, IMessageOptions, IHostInfo, HomeState } from "./IAnalyticsProvider";

const logger: Logger = new Logger("AnalyticsProvider");
const protocol: string = "2.0";
const pluginName: string = "PlayerPlatformJS";
const messages: BaseMessage[] = [];
const events: object = {};
const messageSeperator: string = "\u001E";

let isConfigured: boolean;
let isEnabled: boolean;
let deviceInfo: IDeviceInfo;
let applicationInfo: IApplicationInfo;
let timerId: number;
let failedTimerId: number;
let easUriPath: string;

export class AnalyticsProvider {

    private _config: IAnalyticsConfig;
    private _messageOptions: IMessageOptions;

    /**
     * @param devInfo
     * @param config
     */
    public configureAnalytics(devInfo: IHostInfo, config: IAnalyticsConfig) {

        this._config = config;
        this._messageOptions = devInfo.messageOptions;

        deviceInfo = {
            DEV_NAME: devInfo.deviceName,
            DEV_VER: devInfo.deviceVersion,
            DEV_ID: devInfo.deviceId,
            PHYSICAL_DEVICE_ID: devInfo.physicalId
        };

        applicationInfo = {
            APP_NAME: devInfo.appName,
            APP_VER: devInfo.appVersion,
            PLAYER_NAME: "", // Player name and version aren't known until asset has been set
            PLAYER_VER: "",
            PLUGIN_NAME: pluginName,
            PLUGIN_VER: __viperversion,
            SERVICE_ACCOUNT_ID: devInfo.accountId,
            xsctPartnerId: devInfo.xsctPartnerId
        };

        BaseMessage.inHomeStateCallback = this.createInHomeStateCallback(devInfo.inHomeState);

        isConfigured = true;
        isEnabled = true;
        easUriPath = undefined;

        if (isEnabled) {
            this.startTimers();
        }

        // Section 4.1.1 : xuaPluginInitialized sent any time configuration changes or plugin initialized.
        const pluginInitMessage: PluginInitializedMessage = new PluginInitializedMessage();
        this.buildMessage(pluginInitMessage);
    }

    /**
     * Enable analytics. Analytics must also be configured to be used.
     */
    public enable(): void {
        isEnabled = true;
        if (isConfigured) {
            this.startTimers();
        } else {
            logger.error("Analytics must be configured to send messages.s");
        }
    }

    /**
     * Disable analytics to prevent any messages from being sent.
     */
    public disable(): void {
        clearInterval(timerId);
        clearInterval(failedTimerId);
        isEnabled = false;
    }

    private _getMONEY(): IAnalyticsMoneyTrace {
        return {
            TRACE_ID: SessionManager.instance.moneyTrace.traceId,
            PARENT_ID: SessionManager.instance.moneyTrace.parentId,
            SPAN_ID: SessionManager.instance.moneyTrace.spanId
        };
    }

    public setPlayerInfo(name: string, version: string): void {
        applicationInfo.PLAYER_NAME = name;
        applicationInfo.PLAYER_VER = version;
    }

    /**
     * buildMessage will automtically queue a message to be sent.
     * @param {BaseMessage} message
     * @returns void
     */
    public buildMessage(message: BaseMessage): void {
        if (!isEnabled || !isConfigured) {
            return;
        }

        message.APV = protocol;
        message.APP = applicationInfo;
        message.DEV = deviceInfo;
        message.SES.PSI = SessionManager.instance.sessionId;
        message.SES.PBI = SessionManager.instance.playbackCount;

        //message xuaEAS sets/clears easUriPath for future messages
        this.toggleXuaEAS(message);

        if (messages.length >= this._config.maxQueueSize) {
            messages.shift();
        }

        // Some assets may have overrideing values for accountId & deviceId
        // for the message. Expect preDispatch to be the last place for mutation
        // of the message object to occur.
        if (this._messageOptions && typeof this._messageOptions.preDispatch === "function") {
            this._messageOptions.preDispatch(message);
        }

        // plugin initialized is sent without delay and is not batched
        if (message.EVT.NAME === "xuaPluginInitialized") {
            this.sendMessages([message]);
        } else {
            messages.push(message);
            if ((this._config.maxBatchSize <= messages.length) || (message.EVT.NAME === "xuaHeartBeat")) {
                this.sendMessages(messages.splice(0));
            }
        }
    }

    /**
     * Either sets or clears the easUriPath by xuaEAS
     * @param {BaseMessage} message
     * @returns void
     */
    public toggleXuaEAS(message: BaseMessage): void {
        let lastEasMessage: boolean = false;
        if (message.EVT.NAME === "xuaEAS") {
            if (message.EVT.VALUE.ACT === "Identified" || message.EVT.VALUE.ACT === "Initiated") {
                easUriPath = this.getPathnameFromUrl(message.EVT.VALUE.URI);
            }
            if (message.EVT.VALUE.ACT === "Completed" ||
                message.EVT.VALUE.ACT === "Exempted" ||
                message.EVT.VALUE.ACT === "Failed") {
                lastEasMessage = true;
            }
        }

        // Stuff EAS into message if within an EAS
        // Reset if this is the end of the EAS
        if (easUriPath) {
            message.EVT.EAS = easUriPath;
            if (lastEasMessage) {
                easUriPath = undefined;
            }
        }
    }

    /**
     * Parses the USVString containing the initial '/' followed by the path of the URL or the empty string if there is not path
     * Example http://xfinity.com/viper returns '/viper'
     * @param {string} url
     * @returns string
     */
    private getPathnameFromUrl(url: string): string {
        if (!url) {
            return "";
        }
        const parser: HTMLAnchorElement = document.createElement("a");
        parser.href = url;
        return parser.pathname;
    }

    private startTimers(): void {
        clearInterval(timerId);
        clearInterval(failedTimerId);
        timerId = window.setInterval(this.forceSendMessages.bind(this), this._config.batchInterval);
        failedTimerId = window.setInterval(this.sendFailedMessages.bind(this), FAILED_BATCH_INTERVAL);
    }

    public addListener(eventName: string, callback: () => void): void {
        if (!events[eventName]) {
            events[eventName] = [];
        }
        const callbacks = events[eventName];
        callbacks.push(callback);
    }

    private raiseEvent(eventName: string, args: any[]): void {
        const callbacks = events[eventName];

        if (!callbacks) {
            return;
        }

        for (let i = 0, l = callbacks.length; i < l; i++) {
            callbacks[i].apply(null, args);
        }
    }

    public forceSendMessages(): void {
        if (isEnabled && isConfigured) {
            this.sendMessages(messages.splice(0));
        }
    }

    private sendFailedMessages(): void {
        if (isEnabled && isConfigured) {
            this.sendMessages(getFailedMessages());
        }
    }

    private handleErrorResponse(statusCode: number, failedMessages: any): void {
        if (statusCode && statusCode !== 403) {
            addFailedMessages(failedMessages);
        }
        this.raiseEvent("analyticsFailure", [statusCode, failedMessages]);
    }

    private handleSuccessResponse(statusCode: number): void {
        this.raiseEvent("analyticsSuccess", [statusCode]);
    }

    private _appendMONEY(message: BaseMessage): BaseMessage {

        if (message.EVT.NAME !== "xuaPluginInitialized") {
            message.MONEY = this._getMONEY();
            SessionManager.instance.moneyTrace.createTraceMessage();
        }

        return message;

    }

    /**
     * Concatenate messages with message separator and send the resulting string to
     * the analytics endpoint.
     *
     * @param messageArr - Array of message objects
     */
    private sendMessages(messageArr: BaseMessage[]): void {

        if (!messageArr.length) {
            return;
        }

        // stringify each element and join with message separator
        const jsonToSend = messageArr
            .map(this._appendMONEY.bind(this))
            .map((value: {}): string => JSON.stringify(value))
            .join(messageSeperator);

        ajax({
            url: this._config.analyticsUrl,
            headers: { "X-Date" : Date.now().toString() },
            body: jsonToSend,
            method: "POST"
        })
            .subscribe(() => this.handleSuccessResponse(200),
                error => this.handleErrorResponse(error ? error.status : null, messageArr)
        );
    }

    /**
     * Creates a function using a given callback and returns another
     * function with the correct format for an "in home" state string.
     * The default state is "unknown"
     *
     * @param { () => string } inHomeState - callback that returns in home state
     * @returns { () => string }
     */
    private createInHomeStateCallback(inHomeState: () => string): () => HomeState {

        if ( typeof inHomeState !== "function" ) {
            return () => HomeState.UNKNOWN;
        }

        return function() {
            switch (inHomeState().toLowerCase()) {
                case "inhome":
                    return HomeState.IN_HOME;
                case "outofhome":
                    return HomeState.OUT_OF_HOME;
                default:
                    logger.warn("Invalid in home state (" + inHomeState().toLowerCase() + "), defaulting to 'unknown'");
                    return HomeState.UNKNOWN;
            }
        };
    }
}
