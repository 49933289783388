
import { AdobeRuntimeWrapper } from "../../engines/psdk/AdobeRuntimeWrapper";

export class AdobeAdPolicySelector {

    private _selectPolicyForAdBreak(_adPolicyInfo: AdobePSDK.AdPolicyInfo): number {
        return AdobeRuntimeWrapper.AD_BREAK_POLICY_PLAY();
    }

    private _selectAdBreaksToPlay(adPolicyInfo: AdobePSDK.AdPolicyInfo): AdobePSDK.TimelineItem[] {
        return adPolicyInfo.adBreakTimelineItems.filter((adBreakTimelineItem: AdobePSDK.AdBreakTimelineItem) => {
            const preroll = AdobeRuntimeWrapper.PLACEMENT_PREROLL();
            return adBreakTimelineItem.placementType === preroll && !adBreakTimelineItem.watched;
        });
    }

    private _selectPolicyForSeekIntoAd(_adPolicyInfo: AdobePSDK.AdPolicyInfo): number {
        return AdobeRuntimeWrapper.AD_POLICY_PLAY_FROM_AD_BREAK_BEGIN();
    }

    private _selectWatchedPolicyForAdBreak(_adPolicyInfo: AdobePSDK.AdPolicyInfo): number {
        return AdobeRuntimeWrapper.AD_BREAK_WATCHED_ON_END();
    }

    public makeAdPolicySelector(): AdobePSDK.AdPolicySelector {
        const adPolicySelector = new AdobePSDK.AdPolicySelector();
        adPolicySelector.selectPolicyForAdBreakCallbackFunc = this._selectPolicyForAdBreak.bind(this);
        adPolicySelector.selectAdBreaksToPlayCallbackFunc = this._selectAdBreaksToPlay.bind(this);
        adPolicySelector.selectPolicyForSeekIntoAdCallbackFunc = this._selectPolicyForSeekIntoAd.bind(this);
        adPolicySelector.selectWatchedPolicyForAdBreakCallbackFunc = this._selectWatchedPolicyForAdBreak.bind(this);
        return adPolicySelector;
    }

}
