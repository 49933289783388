import { HlsTag } from "./HlsTag";

const REQUIRED_FIELDS: string[] = [];

export class IdentityAdsTag extends HlsTag {

    public identityAds: string;

    constructor(time: number, content: string) {
        super("#EXT-X-IDENTITY-ADS", time, content, REQUIRED_FIELDS);
    }

    protected _parseContent() {
        if (!this.content) {
            return;
        }
        this.identityAds  = this.content.substr(this.content.lastIndexOf(":") + 1);
    }
}
