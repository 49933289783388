import { AdobeContentResolver } from "../psdk/AdobeContentResolver";
import { IPPSandbox } from "../../PlayerPlatformApplication";
import { ICustomAdConfig } from "../IAdConfig";
import { IManifestTag, IAdBreak } from "../IAdSlot";
import { Logger } from "../../util/Logger";

export class CustomAdResolver extends AdobeContentResolver {

    protected static _logger: Logger = new Logger("CustomAdResolver");

    constructor(_sandbox: IPPSandbox, private cfg: ICustomAdConfig) {
        super(_sandbox);
    }

    private resolveOpportunity(opportunity: AdobePSDK.Opportunity) {
        const tag: IManifestTag = {
            tagName: opportunity.customParameters.name,
            tagParams: opportunity.customParameters.content
        };

        this.cfg.resolve(tag)
            .then((adBreak: IAdBreak) => {
                this.onTimelineResolved(opportunity.id, [adBreak]);
            });
    }

    protected resolveHandler(opportunity: AdobePSDK.Opportunity): void {
        try {
            super.resolveHandler(opportunity);
            this.resolveOpportunity(opportunity);
        } catch (err) {
            CustomAdResolver._logger.error("Couldn't resolve", err);
        }
    }
}
