
import { IPPSandbox, IPPModule } from "../PlayerPlatformApplication";
import * as app from "../Application";
import { MoneyTrace, generateUUID } from "../util/MoneyTrace";

export class SessionManager implements IPPModule<SessionManager> {

    private static _instance: SessionManager;
    private _playbackCount: number = 0;
    public moneyTrace: MoneyTrace;
    private _uuid: string = generateUUID();
    private _customTraceId: boolean = false;

    public static get instance(): SessionManager {
        if (SessionManager._instance === undefined) {
            SessionManager._instance = new SessionManager();
        }
        return SessionManager._instance;
    }

    constructor() {
        if (SessionManager._instance !== undefined) {
            throw new Error("Use this class as a singleton");
        }
        this.moneyTrace = new MoneyTrace({
            traceId: this.playbackSessionId,
            parentId: "0"
        });
        SessionManager._instance = this;
    }

    public init(_sandbox: IPPSandbox) {
        return this;
    }

    public destroy(_sandbox: IPPSandbox) {
         /* */
    }

    /**
     * Indicates if a custom trace id is being used.
     * If this is true we don't update trace_id with PSI-PBI.
     * If this is false we must update trace_id with new PBI.
     */
    public setCustomTraceId(customTrace: boolean): void {
        this._customTraceId = customTrace;
    }

    public incrementPlaybackCount(): void {
        this._playbackCount++;
        if (!this._customTraceId) {
            this.moneyTrace.traceId = this.playbackSessionId;
        }
    }

    public get playbackCount(): number {
        return this._playbackCount;
    }

    public get sessionId(): string {
        return this._uuid;
    }

    public get playbackSessionId(): string {
        return `${this._uuid}-${this._playbackCount}`;
    }

}

app.registerModule("SessionManager", SessionManager, { autostart: true });
