
import { AdobeContentFactory } from "./AdobeContentFactory";
import { IPPSandbox } from "../../PlayerPlatformApplication";
import { AdobeRuntimeWrapper } from "../../engines/psdk/AdobeRuntimeWrapper";
import { Logger } from "../../util/Logger";

/**
 * AdobeOpportunityGenerator creates instances AdobePSDK.OpportunityGenerator
 * and attaches it's functions as the callbacks.
 * It's purpose is only to report back the client it was configured with
 * when it detects an opportunity in the item's timed metadata. See the README
 * in this directory for additional details.
 */
export class AdobeOpportunityGenerator {

    public static readonly PRE_ROLLER_PLACEHOLDER_ID: string = "psdkInitialOpportunity";

    /**
     * This is a magic number that's required for Adobe to
     * properly insert midrolls. Without a preroll duration,
     * midroll insertion/replace shows unexpected behavior.
     */
    public static readonly PREROLL_DURATION: number = 30000;

    private _client: AdobePSDK.OpportunityGeneratorClient = null;
    private _item: AdobePSDK.MediaPlayerItem = null;

    private _resolvedTags: { [x: string]: boolean } = {};
    private _resolvedCUES: { [x: string]: boolean } = {};

    private _batchedOpportunities: AdobePSDK.Opportunity[] = [];

    protected static NEXT_ID: number = 0;

    protected static _logger: Logger = new Logger("AdobeOpportunityGenerator");

    constructor(
        private _contentFactory: AdobeContentFactory,
        private _sandbox: IPPSandbox
    ) {
    }

    private _cleanup(): void {
        this._resolvedTags = {};
        this._resolvedCUES = {};
        this._client = null;
        this._item = null;
    }

    /**
     * Gets the type of ad placement to use for the provided timed metadata.
     * INSERT will insert the ad and adjust the timeline
     * REPLACE will play the ad over the existing timeline
     * @returns INSERT
     */
    protected getAdPlacementMode(_metadata: AdobePSDK.TimedMetadata): number {
        return AdobeRuntimeWrapper.PLACEMENT_MODE_INSERT();
    }

    protected _getOpportunity(metadata: AdobePSDK.TimedMetadata): AdobePSDK.Opportunity {
        AdobeOpportunityGenerator._logger.info("_getOpportunity", metadata.time, metadata.name, metadata.content);
        const type = metadata.time === 0 ? AdobePSDK.PlacementType.PRE_ROLL : AdobePSDK.PlacementType.MID_ROLL;
        const id = `${metadata.id}`;
        const duration = metadata.metadata.DURATION;
        const placement = new AdobePSDK.Placement(type, metadata.time, duration, AdobeRuntimeWrapper.PLACEMENT_MODE_REPLACE());
        const customParams = {
            name: metadata.name,
            content: metadata.content,
            id: metadata.metadata.ID,
            duration: metadata.metadata.DURATION
        };
        const opportunity = new AdobePSDK.Opportunity(id.toString(), placement, metadata.metadata, customParams);
        AdobeOpportunityGenerator.NEXT_ID++;
        return opportunity;
    }

    protected getTagKey(timedMetadata: AdobePSDK.TimedMetadata): string {
        return timedMetadata.metadata.TIME;
    }

    protected getCUE(timedMetadata: AdobePSDK.TimedMetadata): string {
        return timedMetadata.metadata.CUE;
    }

    protected canResolve(timedMetadata: AdobePSDK.TimedMetadata): boolean {
        return timedMetadata.name === "#EXT-X-CUE";
    }

    private _haveResolved(timedMetadata: AdobePSDK.TimedMetadata): boolean {
        return this._resolvedTags.hasOwnProperty(this.getTagKey(timedMetadata)) ||
                    this._resolvedCUES.hasOwnProperty(this.getCUE(timedMetadata));
    }

    private _markResolved(timedMetadata: AdobePSDK.TimedMetadata): void {
        this._resolvedTags[this.getTagKey(timedMetadata)] = true;
        this._resolvedCUES[this.getCUE(timedMetadata)] = true;
    }

    private _processUpdatedMetadata() {
        const timedMetadata: AdobePSDK.TimedMetadata[] = this._item.timedMetadata;

        if (!Array.isArray(timedMetadata) || timedMetadata.length === 0) {
            AdobeOpportunityGenerator._logger.info("No metadata to process");
            return;
        }

        for (const tag of timedMetadata) {
            if (this.canResolve(tag) && !this._haveResolved(tag)) {
                this._batchedOpportunities.push(this._getOpportunity(tag));
                this._markResolved(tag);
            }
        }

    }

    private _resolveBatch(): void {
        for (const opportunity of this._batchedOpportunities) {
            AdobeOpportunityGenerator._logger.info("Calling resolve for " + opportunity.id);
            this._client.resolve(opportunity);
        }
        this._sandbox.publish("ads:opportunitiesSent", this._batchedOpportunities);
        this._batchedOpportunities = [];
    }

    /**
     * Called by AdobePSDK when an item is first loaded.
     * Gets the initial item and the client which can be used to resolve ads
     */
    private _configure(item: AdobePSDK.MediaPlayerItem, client: AdobePSDK.OpportunityGeneratorClient) {
        this._client = client;
        this._item = item;

        // Used to prep PSDK for a server-mapped pre-roll ad
        if (this._contentFactory.hasPreRollAd) {
            const duration = item.live ? AdobeOpportunityGenerator.PREROLL_DURATION : 0;
            this._batchedOpportunities.push(
                new AdobePSDK.Opportunity(
                    AdobeOpportunityGenerator.PRE_ROLLER_PLACEHOLDER_ID,
                    new AdobePSDK.Placement(AdobeRuntimeWrapper.PLACEMENT_PREROLL(), 0, duration, AdobeRuntimeWrapper.PLACEMENT_MODE_REPLACE())
                )
            );
        }

        this._processUpdatedMetadata();
        this._resolveBatch();
    }

    private _update(_playhead: number, _playbackRange: AdobePSDK.TimeRange): void {
        this._processUpdatedMetadata();
        this._resolveBatch();
    }

    public makeOpportunityGenerator(): AdobePSDK.OpportunityGenerator {
        const opportunityGenerator = new AdobePSDK.OpportunityGenerator();
        opportunityGenerator.configureCallbackFunc = this._configure.bind(this);
        opportunityGenerator.updateCallbackFunc = this._update.bind(this);
        opportunityGenerator.cleanupCallbackFunc = this._cleanup.bind(this);
        return opportunityGenerator;
    }

}
