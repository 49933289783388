import { Logger } from "../util/Logger";
import { PubSub as Mediator } from "publicious";
import { EmergencyAlert } from "./EmergencyAlert";
import { getNestedProperty } from "../util/JSUtil";
import { X2JS } from "x2json";
import { MoneyTrace } from "../util/MoneyTrace";
import { ajax } from "rxjs/ajax";

const xml2js = new X2JS();

export interface IEASPollerOptions {
    alertUrl: string;
    zipToFipsUrl: string;
    zip: string;
    interval: number;
    moneyTrace?: any;
    preferredLanguage: string;
    mimeType: string;
}

export class EASPoller {

    public static ALERT_RECEIVED = "alertReceived";
    public static ALERTS_AVAILABLE = "alertsAvailable";
    public static ALERTS_FAILURE_CHECK = "alertsFailureCheck";
    public static EAN_START = "eanStart";
    public static EAN_COMPLETE = "eanComplete";

    private logger = new Logger("EASPoller");
    private _fips: string;
    private _timer: any;
    private _mediator = new Mediator();
    private _alerts: EmergencyAlert[] = [];
    private _alertsAdded: { [x: string]: boolean } = {};
    private _currentEan: EmergencyAlert;
    private _options: IEASPollerOptions;

    constructor(options: IEASPollerOptions) {
        this._options = { ...options };
        this._options.moneyTrace = this._options.moneyTrace || new MoneyTrace();
    }

    public start(): void {
        if (this._fips) {
            this._startTimer();
        } else {
            this._getFips((err: Error, fips: string) => {
                if (err) {
                    this.logger.error(`Error retrieving fips code. EAS polling will not start: ${err.toString()}`);
                    return;
                }
                this._fips = fips;
                this._startTimer();
            });
        }
    }

    public stop(): void {
        clearInterval(this._timer);
    }

    public add(alert: any) {
        this._alerts.push(alert);
    }

    public onAlertReceived(fn: () => void) {
        this._mediator.on(EASPoller.ALERT_RECEIVED, fn);
    }

    public onAlertsAvailable(fn: () => void) {
        this._mediator.on(EASPoller.ALERTS_AVAILABLE, fn);
    }

    public onAlertsFailureCheck(fn: () => void) {
        this._mediator.on(EASPoller.ALERTS_FAILURE_CHECK, fn);
    }

    public onEanStart(fn: () => void) {
        this._mediator.on(EASPoller.EAN_START, fn);
    }

    public onEanComplete(fn: () => void) {
        this._mediator.on(EASPoller.EAN_COMPLETE, fn);
    }

    private _startTimer() {
        clearInterval(this._timer);
        this._timer = setInterval(this._onTimerFired.bind(this), this._options.interval);
    }

    private _onTimerFired() {
        ajax({
            url: this._options.alertUrl + this._fips,
            headers: { "X-MoneyTrace": this._options.moneyTrace.createTraceMessage() },
            method: "get",
            responseType: "text",
            crossDomain: true
        })
            .subscribe(res => {
                    this._parseAlertResponse(res.response);
                    if (this._alerts.length) {
                        this._mediator.emit(EASPoller.ALERTS_AVAILABLE, this._alerts);
                    } else {
                        this.logger.trace("EAS Timer Fired with no alerts");
                    }
                },
                err => {
                    this.logger.error(`Error retrieving EAS alerts: ${err.toString()}`);
                    return;
                });
    }

    private _parseAlertResponse(data: string) {
        const parser: (xmlDocStr: XMLDocument | string) => any = typeof data === "string" ? xml2js.xml_str2json : xml2js.xml2json;
        const easData = parser.call(xml2js, data);

        let alerts = getNestedProperty(easData, "alerts.alert") || [];
        alerts = Array.isArray(alerts) ? alerts : [alerts];

        const easAlerts: EmergencyAlert[] =
            alerts.map((alert: any) => new EmergencyAlert(alert, this._options.preferredLanguage, this._options.mimeType));
        easAlerts.filter(alert => !alert.isEan()).forEach(this._processAlert.bind(this));

        this._checkEan(easAlerts);
    }

    private _processAlert(alert: EmergencyAlert) {
        if (!this._alertsAdded[alert.id] && alert.uri) {
            this._alertsAdded[alert.id] = true;
            this._alerts.push(alert);
            this._mediator.emit(EASPoller.ALERT_RECEIVED, alert);
        } else if (!alert.uri) {
            this.logger.warn("Encountered EAS alert with no attached media. " + JSON.stringify(alert));
        }
    }

    private _isEanAlertWithUri(alert: EmergencyAlert): boolean {
        return alert.isEan() && alert.uri >= "";
    }

    private _checkEan(alerts: EmergencyAlert[]): void {

        const ean = alerts.filter(this._isEanAlertWithUri.bind(this))[0];

        if (this._currentEan && !ean) {
            this._mediator.publish(EASPoller.EAN_COMPLETE, this._currentEan);
            this._currentEan = null;
        } else if (!this._currentEan && ean) {
            this._currentEan = ean;
            this._mediator.emit(EASPoller.ALERT_RECEIVED, ean);
            this._mediator.publish(EASPoller.EAN_START, ean);
        }

    }

    private _getFips(cb: (err: Error, resp?: string) => void): void {
        ajax({
            url: this._options.zipToFipsUrl + this._options.zip,
            headers: { "X-MoneyTrace": this._options.moneyTrace.createTraceMessage() },
            method: "get",
            responseType: "text",
            crossDomain: true
        })
            .subscribe(res => {
                    cb(null, this._parseFipsResponse(res.response || "000000"));
                },
                err => cb(err));
    }

    private _parseFipsResponse(data: string) {
        const parser: (xmlDoc: XMLDocument | string) => any = typeof data === "string" ? xml2js.xml_str2json : xml2js.xml2json;
        const easData = parser.call(xml2js, data);
        const fips = getNestedProperty(easData, "zips.zip.countyFips") || getNestedProperty(easData, "zips.zip[0].countyFips");
        this.logger.trace(`received fips code ${fips}`);
        return fips;
    }
}
