import { HlsTag } from "./HlsTag";

const REQUIRED_FIELDS: string[] = [];

export class MessageRefTag extends HlsTag {

    public messageRef: string;

    constructor(time: number, content: string) {
        super("#EXT-X-MESSAGE-REF", time, content, REQUIRED_FIELDS);
    }

    protected _parseContent() {
        if (!this.content) {
            return;
        }
        this.messageRef  = this.content.substr(this.content.lastIndexOf(":") + 1);
    }
}
