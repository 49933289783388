import { IContentOptions, ServiceZone, ServiceZoneType } from "./ContentOptions";
import { TAG_SOURCE_STREAM, TAG_SERVICE_ZONE_REQUIRED } from "../util/hls/HlsTagFactory";
import { BaseAsset } from "./BaseAsset";

/**
 * An asset for use with the virtual stream stitcher service.
 * The virtual stream stitcher service is a service which provides
 * a live manifest which has rotating license keys and two unique tags.
 *
 * EXT-X-SOURCE-STREAM
 *   - used to provide analytics information about changes to the content of the underlying stream
 * EXT-X-SZ-REQUIRED
 *   - used to indicate when a `ServiceZoneType` change has occured
 * These tags are custom and only observed with the virtual stream stitcher service.
 */
export class VirtualStreamStitcherAsset extends BaseAsset {

    /**
     * The service zone being used for this asset.
     * This is appended to the manifest as the sz query string
     * parameter.
     */
    public serviceZone: ServiceZone;

    /**
     * The source stream id of the manifest,
     * this is used for analytics purposes
     * and pouplated by tags from the manifest
     * once playback begins
     */
    public sourceStreamId?: string;

    /**
     * The signal id of the manifest,
     * this is used for analytics purposes
     * and populated by tags from the manifest
     * once playback begins
     */
    public signalId?: string;

    /**
     * The service zone type of the manifest
     * This could change during asset playback
     * This represents the current service zone
     * type. This will either be the default or
     * a servize zone type we have observed in
     * a manifest
     */
    public serviceZoneType?: ServiceZoneType;

    constructor(url: string, contentOptions: IContentOptions = {}) {
        super(url, contentOptions);
        this.addSubscribedTag(TAG_SOURCE_STREAM);
        this.addSubscribedTag(TAG_SERVICE_ZONE_REQUIRED);
    }
}
