
export const DefaultConfigOptions = {
  "productType": "cdvr",
  "drmNetworkRequestTimeout": 10000,
  "cimaEndPoint": "https://consecservice-stage.ccp.xcal.tv/cima/login",
  "metadataEndPoint": "https://consecservice-stage.ccp.xcal.tv/flashaccess/resource/metadata?env=stage",

  "easNetworkRequestTimeout": 10000,
  "zipToFipsEndPoint": "https://easip-client-po.ccp.xcal.tv/easws/api/fips/zip/",
  "alertServiceEndPoint": "https://easip-client-po.ccp.xcal.tv/easws/api/alert/active/fipscode/",
  "easUpdateInterval": 15000,
  "easAlertRepeat": 1,
  "easAlertFontSize": "20.0f",
  "easAlertFont": "Helvetica-Bold",
  "easLanguage": "en-US",
  "helioEas": false,
  "helioFusionEas": false,

  "analyticsNetworkRequestTimeout": 10000,
  "analyticsEndPoint": "https://analytics.xcal.tv/comcast/player",
  "analyticsProtocol": "2.0",
  "maxBatchSize": 10,
  "maxQueueSize": 100,
  "batchInterval": 5000,

  "playerNetworkRequestTimeout": 10000,
  "heartbeatInterval": 60000,
  "autoplay": true,
  "retryInterval": 1000,
  "manifestManipulatorRetries": 1,
  "manifestManipulatorTimeout": 4000,
  "enableMultiSiteVODDAI": false,
  "retryOnMediaFailed": true,
  "updateInterval": 1000,
  "vpaidTimeout": 5000,
  "partnerId": "Comcast",
  "enableAds": false,
  "defaultEngine": "flash",
  "domainId": "",
  "zoneId": "",
  "customParams": {},
  "auditudeTimeout": 20000,
  "crsRulesEndpoint": "https://player.ccp.xcal.tv/desktop/config/crs/AdobeTVSDKConfig.json",
  "failOnNetworkDown": false,
  "handleClicks": false,

  "reportHelioMetrics": true,

  "disneyChromelessPath": "http://watchabc.go.com/vp2/s/vp2k/latest/ext/watchvp2k.swf",
  "disneyLoadTimeout": 45000,
  "disneyPartner": "comcast",
  "espnChromelessPath": "https://www.espn.com/watchespn/player.swf",
  "espnPartner": "comcast",
  "espnConfigUrl": "https://www.espn.com/watchespn/player/config?key=xfinity",
  "huluNewSiteUrlStd": "https://player.hulu.com/js/hulu_global_html5.js?guid=8FD85DB6-E19C-ED04-72DC-15CEE4B3DB20",
  "huluNewSiteUrlWide": "https://player.hulu.com/js/hulu_global_html5.js?guid=1B826623-ABFA-78F3-5EAC-6150B0FD8F76",
  "huluPartner": "COMCAST",
  "nbcConditionedStreamApi": {
    "serviceUrl": "https://stage-access-cloudpath.media.nbcuni.com/access/live",
    "adobeMvpdId": "",
    "channels": {
      "nbc": {
        "apiKey": ""
      },
      "tlmd": {
        "apiKey": ""
      }
    }
  },
  "audienceManager": {
    "enabled": false,
    "dilOptions": {
      "default": {
        "containerNSID": 1,
        "uuidCookieName": "aam_uuid",
        "uuidCookieDays": 30
      },
      "parentCompanies": {
        "fox": {
          "partner": "foxnet",
          "namespace": "723C39F756ABCD0B7F000101@AdobeOrg"
        }
      }
    },
    "fieldPrefix": "c_",
    "mvpd": "comcast",
    "platform": "",
    "playerId": "Player Platform",
    "heartbeatInterval": 60000
  },
  "vssDefaultServiceZoneType": "urn:comcast:location:service:zipcode",
  "vssServiceZoneCallbackTimeout": 10000,
  "defaultAsset": {
    "maximumRetries": 3,
    "ddPlus": false,
    "cdnRedundant": false,
    "crossStreamPrevention": "None",
    "initialBitrate": 0,
    "initialDefaultAdBitrate": 1100000,
    "initialBufferTime": 2000,
    "playingVODBufferTime": 80000,
    "playingLinearBufferTime": 15000,
    "initialPolicy": 0,
    "playingPolicy": 2,
    "playbackStalledEnabled": false,
    "maximumBitrate": 12000000,
    "minimumBitrate": 0,
    "trickPlayMaxFps": 2,
    "placementStatusNotificationEndPoint": "https://altcon-vexdr-prod1.ccp.xcal.tv/vex-director/PlacementStatusNotification",
    "urlRewrite": "",
    "easEnabled": false,
    "fwUseFusionRenderer": false,
    "httpStartTransferTimeout": 15000,
    "httpTotalTimeout": 20000,
    "forceHttps": false,
    "hnaEnabled": false,
    "hnaAWSEndpoint": "http://d2nuj85jf6t0f3.cloudfront.net/OddEvenPattern.test",
    "hnaCDNEndpoint": "OddEvenPattern.test",
    "hnaTimeout": 5000
  },

  "easAsset": {
    "httpStartTransferTimeout": 2000,
    "httpTotalTimeout": 3000,
    "playbackStalledEnabled": true
  },

  "title6Linear": {
    "easEnabled": true,
    "trickPlayMaxFps": 8,
    "httpStartTransferTimeout": 3000,
    "forceHttps": false
  },

  "cDvr": {
    "easEnabled": true
  },

  "title6Vod": {
    "easEnabled": true
  },

  "tveLinear": {
    "audienceManagerEnabled": true
  },

  "tveVod": {
    "audienceManagerEnabled": true
  },

  "ott": {
    "freeWheelConfig": {
      "mvpdString": "xtv",
      "playerProfileMvpd": "comcast_sports",
      "freewheelPlatform": "web",
      "playerProfilePlatform": "js",
      "networkId": "384777",
      "serverUrl": "http://5df09.v.fwmrm.net/",
      "contentType": "application/x-mpegurl",
      "preventFreeWheelTrackingEvents": false,
      "siteSectionFallbackBrand": "fallbacksitesection"
    }
  },
  "adobeVideoHeartbeat": {
    "enabled": true,
    "debug": false,
    "ssl": false,
    "analyticsTrackingServer": "comcastcom.d1.sc.omtrdc.net",
    "heartbeatTrackingServer": "comcastcom.hb.omtrdc.net",
    "reportSuiteAccountId": "comcastx1espnvideostreamdev",
    "marketingExperienceCloudOrgId": "DA11332E5321D0550A490D45@AdobeOrg"
  }
};

