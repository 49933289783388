
export class XREError {

    constructor(public name: string, public code: number, public warning: boolean = false) { }

    get description(): string {
        return `${this.name} (${this.code})`;
    }
}

enum XREErrorType {
    NETWORK_ERROR = 10,
    RESOURCE_UNAVAILABLE = 20,
    UNSUPPORTED_CONTENT = 30,
    CONTENT_UNAUTHORIZED = 40,
    DRM_FAILURE = 50,
    CONTENT_CANCELLED = 60,
    PLAYBACK_FAILURE = 70,
    PLAYBACK_TERMINATED = 80,
    INVALID_PARAMS = 90,
    OTHER = 100,
    TUNE_ERROR = 110,
    PROGRAM_DATA_UNAVAILABLE = 120,
    VOD_PLAYBACK_FAILURE = 130,
    BLACKOUT_DRM_FAILURE = 150
}

export enum XREErrorCode {
    kECGenericError = 6,
    kECAborted = 40,
    kECNetworkDown = 44,
    kECNetworkError = 33,
    kECHttpTimeOut = 43,
    kECHold = 53
}

const adobeXREErrors: { [x: string]: XREError } = {
    //Adobe Codes
    "-1": new XREError("kECEndOfPeriod", XREErrorType.OTHER),
    "0": new XREError("kECSuccess", XREErrorType.OTHER),
    "1": new XREError("kECAsyncOperationInProgress", XREErrorType.OTHER),
    "2": new XREError("kECEOF", XREErrorType.INVALID_PARAMS),
    "3": new XREError("kECDecoderFailed", XREErrorType.PLAYBACK_TERMINATED),
    "4": new XREError("kECDeviceOpenError", XREErrorType.RESOURCE_UNAVAILABLE),
    "5": new XREError("kECFileNotFound", XREErrorType.INVALID_PARAMS),
    [XREErrorCode.kECGenericError]: new XREError("kECGenericError", XREErrorType.OTHER),
    "7": new XREError("kECIrrecoverableError", XREErrorType.PLAYBACK_TERMINATED),
    "8": new XREError("kECLostConnectionRecoverable", XREErrorType.NETWORK_ERROR),
    "9": new XREError("kECNoFixedSize", XREErrorType.INVALID_PARAMS),
    "10": new XREError("kECNotImplemented", XREErrorType.RESOURCE_UNAVAILABLE),
    "11": new XREError("kECOutOfMemory", XREErrorType.PLAYBACK_TERMINATED),
    "12": new XREError("kECParseError", XREErrorType.INVALID_PARAMS),
    "13": new XREError("kECSizeUnknown", XREErrorType.INVALID_PARAMS),
    "14": new XREError("kECUnderFlow", XREErrorType.OTHER),
    "15": new XREError("kECUnsupportedConfig", XREErrorType.RESOURCE_UNAVAILABLE),
    "16": new XREError("kECUnsupportedOperation", XREErrorType.RESOURCE_UNAVAILABLE),
    "17": new XREError("kECWaitingForInit", XREErrorType.PLAYBACK_FAILURE),
    "18": new XREError("kECInvalidParameter", XREErrorType.INVALID_PARAMS),
    "19": new XREError("kECInvalidOperation", XREErrorType.PLAYBACK_FAILURE),
    "20": new XREError("kECOpOnlyAllowedInPausedState", XREErrorType.PLAYBACK_TERMINATED),
    "21": new XREError("kECOpInvalidWithAudioOnlyFile", XREErrorType.PLAYBACK_FAILURE),
    "22": new XREError("kECPreviousStepSeekInProgress", XREErrorType.PLAYBACK_FAILURE),
    "23": new XREError("kECSourceNotSpecified", XREErrorType.INVALID_PARAMS),
    "24": new XREError("kECRangeError", XREErrorType.INVALID_PARAMS),
    "25": new XREError("kECInvalidSeekTime", XREErrorType.INVALID_PARAMS),
    "26": new XREError("kECFileStructureInvalid", XREErrorType.INVALID_PARAMS),
    "27": new XREError("kECComponentCreationFailure", XREErrorType.RESOURCE_UNAVAILABLE),
    "28": new XREError("kECDRMInitError", XREErrorType.DRM_FAILURE),
    "29": new XREError("kECContainerNotSupported", XREErrorType.RESOURCE_UNAVAILABLE),
    "30": new XREError("kECSeekFailed", XREErrorType.PLAYBACK_FAILURE),
    "31": new XREError("kECCodecNotSupported", XREErrorType.RESOURCE_UNAVAILABLE),
    "32": new XREError("kECNetworkUnavailable", XREErrorType.NETWORK_ERROR),
    [XREErrorCode.kECNetworkError]: new XREError("kECNetworkError", XREErrorType.NETWORK_ERROR),
    "34": new XREError("kECOverflow", XREErrorType.PLAYBACK_FAILURE),
    "35": new XREError("kECVideoProfileNotSupported", XREErrorType.RESOURCE_UNAVAILABLE),
    "36": new XREError("kECPeriodNotLoaded", XREErrorType.PLAYBACK_TERMINATED),
    "37": new XREError("kECInvalidReplaceDuration", XREErrorType.PLAYBACK_TERMINATED),
    "38": new XREError("kECCalledFromWrongThread", XREErrorType.PLAYBACK_TERMINATED),
    "39": new XREError("kECFragmentReadError", XREErrorType.PLAYBACK_TERMINATED),
    [XREErrorCode.kECAborted]: new XREError("kECAborted", XREErrorType.PLAYBACK_TERMINATED),
    "41": new XREError("kECUnsupportedHLSVersion", XREErrorType.UNSUPPORTED_CONTENT),
    "42": new XREError("kECCannotFailOver", XREErrorType.PLAYBACK_FAILURE),
    [XREErrorCode.kECHttpTimeOut]: new XREError("kECHttpTimeOut", XREErrorType.NETWORK_ERROR),
    [XREErrorCode.kECNetworkDown]: new XREError("kECNetworkDown", XREErrorType.NETWORK_ERROR),
    "45": new XREError("kECNoUsableBitrateProfile", XREErrorType.UNSUPPORTED_CONTENT),
    "46": new XREError("kECBadManifestSignature", XREErrorType.CONTENT_UNAUTHORIZED),
    "47": new XREError("kECCannotLoadPlaylist", XREErrorType.RESOURCE_UNAVAILABLE),
    "48": new XREError("kECReplacementFailed", XREErrorType.PLAYBACK_TERMINATED),
    "49": new XREError("kECSwitchToAsymmetricProfile", XREErrorType.PLAYBACK_TERMINATED),
    "50": new XREError("kECLiveWindowMovedBackward", XREErrorType.PLAYBACK_TERMINATED),
    "51": new XREError("kECCurrentPeriodExpired", XREErrorType.INVALID_PARAMS),
    "52": new XREError("kECContentLengthMismatch", XREErrorType.INVALID_PARAMS),
    [XREErrorCode.kECHold]: new XREError("kECHold", XREErrorType.INVALID_PARAMS),
    "54": new XREError("kECLiveHold", XREErrorType.INVALID_PARAMS),
    "55": new XREError("kECBadMediaInterleaving", XREErrorType.PLAYBACK_FAILURE),
    "56": new XREError("kECDRMNotAvailable", XREErrorType.DRM_FAILURE),
    "57": new XREError("kECPlaybackNotAuthorized", XREErrorType.CONTENT_UNAUTHORIZED),
    "58": new XREError("kECBadMediaSampleFound", XREErrorType.PLAYBACK_TERMINATED),
    "59": new XREError("kECRangeSpansReadHead", XREErrorType.PLAYBACK_TERMINATED),
    "60": new XREError("kECPostrollWithLiveNotAllowed", XREErrorType.PLAYBACK_FAILURE),
    "61": new XREError("kECInternalError", XREErrorType.OTHER),
    "62": new XREError("kECSPSPPSFoundOutsideAVCC", XREErrorType.PLAYBACK_TERMINATED),
    "63": new XREError("kECPartialReplacement", XREErrorType.PLAYBACK_FAILURE),
    "64": new XREError("kECRenditionM3U8Error", XREErrorType.RESOURCE_UNAVAILABLE),
    "65": new XREError("kECNullOperation", XREErrorType.OTHER),
    "66": new XREError("kECSegmentSkippedOnFailure", XREErrorType.PLAYBACK_TERMINATED),
    "67": new XREError("kECIncompatibleRenderMode", XREErrorType.PLAYBACK_FAILURE),
    "68": new XREError("kECProtocolNotSupported", XREErrorType.PLAYBACK_FAILURE),
    "69": new XREError("kECParseErrorIncompatibleVersion", XREErrorType.RESOURCE_UNAVAILABLE),
    "70": new XREError("kECManifestFileUnexpectedlyChanged", XREErrorType.PLAYBACK_FAILURE),
    "71": new XREError("kECCannotSplitTimeline", XREErrorType.PLAYBACK_FAILURE),
    "72": new XREError("kECCannotEraseTimeline", XREErrorType.PLAYBACK_FAILURE),
    "73": new XREError("kECDidNotGetNextFragment", XREErrorType.PLAYBACK_TERMINATED),
    "74": new XREError("kECNoTimeline", XREErrorType.OTHER),
    "75": new XREError("kECListenerNotFound", XREErrorType.OTHER),
    "76": new XREError("kECAudioStartError", XREErrorType.PLAYBACK_FAILURE),
    "77": new XREError("kECNoAudioSink", XREErrorType.RESOURCE_UNAVAILABLE),
    "78": new XREError("kECFileOpenError", XREErrorType.RESOURCE_UNAVAILABLE),
    "79": new XREError("kECFileWriteError", XREErrorType.RESOURCE_UNAVAILABLE),
    "80": new XREError("kECFileReadError", XREErrorType.RESOURCE_UNAVAILABLE),
    "81": new XREError("kECID3ParseError", XREErrorType.RESOURCE_UNAVAILABLE),
    "82": new XREError("kECSecurityError", XREErrorType.CONTENT_UNAUTHORIZED),
    "83": new XREError("kECTimelineTooShort", XREErrorType.INVALID_PARAMS),
    "84": new XREError("kECAudioOnlyStreamStart", XREErrorType.OTHER),
    "85": new XREError("kECAudioOnlyStreamEnd", XREErrorType.OTHER),
    "87": new XREError("kECKeyNotFound", XREErrorType.RESOURCE_UNAVAILABLE),
    "88": new XREError("kECInvalidKey", XREErrorType.RESOURCE_UNAVAILABLE),
    "89": new XREError("kECKeyServerNotFound", XREErrorType.RESOURCE_UNAVAILABLE),
    "200": new XREError("kECPlaybackOperationFailed", XREErrorType.PLAYBACK_FAILURE),
    "201": new XREError("kECNativeWarning", XREErrorType.OTHER, true),
    "202": new XREError("kECAdResolverFailed", XREErrorType.OTHER, true),
    "203": new XREError("kECAdManifestLoadFailed", XREErrorType.OTHER, true),
    "300": new XREError("kCryptoErrorAlgNotSupported", XREErrorType.DRM_FAILURE),
    "301": new XREError("kCryptoErrorCorruptedData", XREErrorType.DRM_FAILURE),
    "302": new XREError("kCryptoErrorBufferTooSmall", XREErrorType.DRM_FAILURE),
    "303": new XREError("kCryptoErrorBadCertificate", XREErrorType.DRM_FAILURE),
    "304": new XREError("kCryptoErrorDigestUpdate", XREErrorType.DRM_FAILURE),
    "305": new XREError("kCryptoErrorDigestFinish", XREErrorType.DRM_FAILURE),
    "306": new XREError("kCryptoErrorBadParameter", XREErrorType.DRM_FAILURE),
    "307": new XREError("kCryptoErrorUnknown", XREErrorType.DRM_FAILURE),
    "3300": new XREError("kECAAXS_InvalidVoucher", XREErrorType.DRM_FAILURE),
    "3301": new XREError("kECAAXS_AuthenticationFailed", XREErrorType.DRM_FAILURE),
    "3302": new XREError("kECAAXS_RequireSSL", XREErrorType.DRM_FAILURE),
    "3303": new XREError("kECAAXS_ContentExpired", XREErrorType.DRM_FAILURE),
    "3304": new XREError("kECAAXS_AuthorizationFailed", XREErrorType.DRM_FAILURE),
    "3305": new XREError("kECAAXS_ServerConnectionFailed", XREErrorType.DRM_FAILURE),
    "3306": new XREError("kECAAXS_ClientUpdateRequired", XREErrorType.DRM_FAILURE),
    "3307": new XREError("kECAAXS_InternalFailure", XREErrorType.DRM_FAILURE),
    "3308": new XREError("kECAAXS_WrongLicenseKey", XREErrorType.DRM_FAILURE),
    "3309": new XREError("kECAAXS_CorruptedAdditionalHeader", XREErrorType.DRM_FAILURE),
    "3310": new XREError("kECAAXS_AppIDMismatch", XREErrorType.DRM_FAILURE),
    "3311": new XREError("kECAAXS_AppVersionMismatch,", XREErrorType.DRM_FAILURE),
    "3312": new XREError("kECAAXS_LicenseIntegrity", XREErrorType.DRM_FAILURE),
    "3313": new XREError("kECAAXS_WriteMicrosafeFailed", XREErrorType.DRM_FAILURE),
    "3314": new XREError("kECAAXS_CorruptedDRMMetadata", XREErrorType.DRM_FAILURE),
    "3315": new XREError("kECAAXS_PermissionDenied", XREErrorType.DRM_FAILURE),
    "3316": new XREError("kECAAXS_NOTUSED_MOVED", XREErrorType.DRM_FAILURE),
    "3317": new XREError("kECAAXS_LoadAdobeCPFailed", XREErrorType.DRM_FAILURE),
    "3318": new XREError("kECAAXS_IncompatibleAdobeCPVersion", XREErrorType.DRM_FAILURE),
    "3319": new XREError("kECAAXS_MissingAdobeCPGetAPI", XREErrorType.DRM_FAILURE),
    "3320": new XREError("kECAAXS_HostAuthenticateFailed", XREErrorType.DRM_FAILURE),
    "3321": new XREError("kECAAXS_I15nFailed", XREErrorType.DRM_FAILURE),
    "3322": new XREError("kECAAXS_DeviceBindingFailed", XREErrorType.DRM_FAILURE),
    "3323": new XREError("kECAAXS_CorruptGlobalStateStore", XREErrorType.DRM_FAILURE),
    "3324": new XREError("kECAAXS_MachineTokenInvalid", XREErrorType.DRM_FAILURE),
    "3325": new XREError("kECAAXS_CorruptServerStateStore", XREErrorType.DRM_FAILURE),
    "3326": new XREError("kECAAXS_StoreTamperingDetected", XREErrorType.DRM_FAILURE),
    "3327": new XREError("kECAAXS_ClockTamperingDetected", XREErrorType.DRM_FAILURE),
    "3328": new XREError("kECAAXS_ServerErrorTryAgain", XREErrorType.DRM_FAILURE),
    "3329": new XREError("kECAAXS_ApplicationSpecificError", XREErrorType.DRM_FAILURE),
    "3329.12012": new XREError("kECAAXS_ApplicationSpecificError", XREErrorType.CONTENT_UNAUTHORIZED),
    "3329.12013": new XREError("kECAAXS_ApplicationSpecificError", XREErrorType.CONTENT_UNAUTHORIZED),
    "3329.12017": new XREError("kECAAXS_ApplicationSpecificError", XREErrorType.BLACKOUT_DRM_FAILURE),
    "3330": new XREError("kECAAXS_NeedAuthentication", XREErrorType.DRM_FAILURE),
    "3331": new XREError("kECAAXS_ContentNotYetValid", XREErrorType.DRM_FAILURE),
    "3332": new XREError("kECAAXS_CachedLicenseExpired", XREErrorType.DRM_FAILURE),
    "3333": new XREError("kECAAXS_PlaybackWindowExpired", XREErrorType.DRM_FAILURE),
    "3334": new XREError("kECAAXS_InvalidDRMPlatform", XREErrorType.DRM_FAILURE),
    "3335": new XREError("kECAAXS_InvalidDRMVersion", XREErrorType.DRM_FAILURE),
    "3336": new XREError("kECAAXS_InvalidRuntimePlatform", XREErrorType.DRM_FAILURE),
    "3337": new XREError("kECAAXS_InvalidRuntimeVersion", XREErrorType.DRM_FAILURE),
    "3338": new XREError("kECAAXS_UnknownConnectionType", XREErrorType.DRM_FAILURE),
    "3339": new XREError("kECAAXS_NoAnalogPlaybackAllowed", XREErrorType.DRM_FAILURE),
    "3340": new XREError("kECAAXS_NoAnalogProtectionAvail", XREErrorType.DRM_FAILURE),
    "3341": new XREError("kECAAXS_NoDigitalPlaybackAllowed", XREErrorType.DRM_FAILURE),
    "3342": new XREError("kECAAXS_NoDigitalProtectionAvail", XREErrorType.DRM_FAILURE),
    "3343": new XREError("kECAAXS_IntegrityVerificationFailed", XREErrorType.DRM_FAILURE),
    "3344": new XREError("kECAAXS_MissingAdobeCPModule", XREErrorType.DRM_FAILURE),
    "3345": new XREError("kECAAXS_DRMNoAccessError", XREErrorType.DRM_FAILURE),
    "3346": new XREError("kECAAXS_MigrationFailed", XREErrorType.DRM_FAILURE),
    "3347": new XREError("kECAAXS_InsufficientDeviceCapabilites", XREErrorType.DRM_FAILURE),
    "3348": new XREError("kECAAXS_HardStopIntervalExpired", XREErrorType.DRM_FAILURE),
    "3349": new XREError("kECAAXS_ServerVersionTooHigh", XREErrorType.DRM_FAILURE),
    "3350": new XREError("kECAAXS_ServerVersionTooLow", XREErrorType.DRM_FAILURE),
    "3351": new XREError("kECAAXS_DomainTokenInvalid", XREErrorType.DRM_FAILURE),
    "3352": new XREError("kECAAXS_DomainTokenTooOld", XREErrorType.DRM_FAILURE),
    "3353": new XREError("kECAAXS_DomainTokenTooNew", XREErrorType.DRM_FAILURE),
    "3354": new XREError("kECAAXS_DomainTokenExpired", XREErrorType.DRM_FAILURE),
    "3355": new XREError("kECAAXS_DomainJoinFailed", XREErrorType.DRM_FAILURE),
    "3356": new XREError("kECAAXS_NoCorrespondingRoot", XREErrorType.DRM_FAILURE),
    "3357": new XREError("kECAAXS_NoValidEmbeddedLicense", XREErrorType.DRM_FAILURE),
    "3358": new XREError("kECAAXS_NoACPProtectionAvail", XREErrorType.DRM_FAILURE),
    "3359": new XREError("kECAAXS_NoCGMSAProtectionAvail", XREErrorType.DRM_FAILURE),
    "3360": new XREError("kECAAXS_DomainRegistrationRequired", XREErrorType.DRM_FAILURE),
    "3361": new XREError("kECAAXS_NotRegisteredToDomain", XREErrorType.DRM_FAILURE),
    "3362": new XREError("kECAAXS_OperationTimeoutError", XREErrorType.DRM_FAILURE),
    "3363": new XREError("kECAAXS_UnsupportedIOSPlaylistError", XREErrorType.DRM_FAILURE),
    "3364": new XREError("kECAAXS_NoDeviceId", XREErrorType.DRM_FAILURE),
    "3365": new XREError("kECAAXS_IncognitoModeNotAllowed,", XREErrorType.DRM_FAILURE),
    "3366": new XREError("kECAAXS_BadParameter,", XREErrorType.DRM_FAILURE),
    "3367": new XREError("kECMAX_ERRORS", XREErrorType.DRM_FAILURE)
};

const helioXREErrors: {[x: string]: XREError} = {
    "1337": new XREError("MEDIA_LOAD_FAILED", XREErrorType.TUNE_ERROR),
    "1.0": new XREError("INVALID_MIMETYPE", XREErrorType.RESOURCE_UNAVAILABLE),
    "1.1": new XREError("INVALID_CONTENTTYPE", XREErrorType.RESOURCE_UNAVAILABLE),
    "1.2": new XREError("INVALID_TIMETYPE", XREErrorType.RESOURCE_UNAVAILABLE),
    "1.3": new XREError("INVALID_MANIFEST", XREErrorType.RESOURCE_UNAVAILABLE),
    "1.4": new XREError("INVALID_DURATION", XREErrorType.RESOURCE_UNAVAILABLE),
    "1.5": new XREError("INVALID_RATIOTYPE", XREErrorType.RESOURCE_UNAVAILABLE),
    "1.6": new XREError("INVALID_BASE_URI", XREErrorType.RESOURCE_UNAVAILABLE),
    "1.7": new XREError("MALFORMED_XML_ERROR", XREErrorType.RESOURCE_UNAVAILABLE),
    "1.8": new XREError("DOCUMENT_FORMAT_ERROR", XREErrorType.RESOURCE_UNAVAILABLE),
    "1.9": new XREError("INVALID_RANGETYPE", XREErrorType.RESOURCE_UNAVAILABLE),
    "2.0": new XREError("LOAD_FAILURE", XREErrorType.NETWORK_ERROR),
    "2.1": new XREError("URL_NOT_FOUND", XREErrorType.RESOURCE_UNAVAILABLE),
    "2.2": new XREError("NETWORK_TIMEOUT", XREErrorType.NETWORK_ERROR),
    "3.0": new XREError("INVALID_PRESENTATIONTYPE", XREErrorType.UNSUPPORTED_CONTENT),
    "3.1": new XREError("UNKNOWN_ADAPTATION_SET", XREErrorType.UNSUPPORTED_CONTENT),
    "3.2": new XREError("UNABLE_TO_ADD_SOURCE_BUFFER", XREErrorType.PLAYBACK_FAILURE),
    "3.3": new XREError("INVALID_BITRATE", XREErrorType.PLAYBACK_FAILURE),
    "3.4": new XREError("MEDIA_ERROR", XREErrorType.PLAYBACK_FAILURE),
    "3.5": new XREError("UNSUPPORTED_MEDIAFORMAT", XREErrorType.UNSUPPORTED_CONTENT),
    "3.6": new XREError("UNSUPPORTED_RESPONSE_TYPE", XREErrorType.UNSUPPORTED_CONTENT),
    "3.7": new XREError("UNSUPPORTED_UPDATE_PERIOD", XREErrorType.UNSUPPORTED_CONTENT),
    "3.8": new XREError("NO_RESPONSE", XREErrorType.PLAYBACK_FAILURE),
    "4.0": new XREError("NO_SEGMENTS_FOUND", XREErrorType.PLAYBACK_FAILURE),
    "5.0": new XREError("UNKNOWN_ERROR", XREErrorType.OTHER),
    "6.1": new XREError("MEDIA_ERR_ABORTED", XREErrorType.PLAYBACK_TERMINATED),
    "6.2": new XREError("MEDIA_ERR_NETWORK", XREErrorType.NETWORK_ERROR),
    "6.3": new XREError("MEDIA_ERR_DECODE", XREErrorType.UNSUPPORTED_CONTENT),
    "6.4": new XREError("MEDIA_ERR_NOT_SUPPORTED", XREErrorType.UNSUPPORTED_CONTENT),
    "6.5": new XREError("MEDIA_ERR_ENCRYPTED", XREErrorType.DRM_FAILURE),
    "7.1": new XREError("UNEXPECTED_BUFFER", XREErrorType.PLAYBACK_FAILURE),
    "7.2": new XREError("UNSUPPORTED_BUFFER", XREErrorType.PLAYBACK_FAILURE),
    "8.1": new XREError("EMPTY_COLLECTION", XREErrorType.PLAYBACK_FAILURE),
    "9.1": new XREError("UNEXPECTED_BUFFER", XREErrorType.PLAYBACK_FAILURE),
    "9.2": new XREError("UNEXPECTED_STATE", XREErrorType.PLAYBACK_FAILURE),
    "10.1": new XREError("KEY_ERROR", XREErrorType.DRM_FAILURE),
    "10.2": new XREError("LICENSE_REQUEST_ERROR", XREErrorType.DRM_FAILURE),
    "10.3": new XREError("MEDIA_KEYS_ERROR", XREErrorType.DRM_FAILURE),
    "11.0": new XREError("PLAY_CALLED_ON_DETACH,", XREErrorType.OTHER),
    "11.1": new XREError("PAUSE_CALLED_ON_DETACH", XREErrorType.OTHER),
    "11.2": new XREError("SEEK_CALLED_ON_DETACH", XREErrorType.OTHER),
    "11.3": new XREError("SETTEXTTRACK_CALLED_ON_DETACH", XREErrorType.OTHER),
    "11.4": new XREError("SETVOLUME_CALLED_ON_DETACH", XREErrorType.OTHER),
    "11.5": new XREError("SETAUDIOBITRATE_CALLED_ON_DETACH", XREErrorType.OTHER),
    "11.6": new XREError("SETVIDEOBITRATE_CALLED_ON_DETACH", XREErrorType.OTHER),
    "11.7": new XREError("SETAUDIOTRACK_CALLED_ON_DETACH", XREErrorType.OTHER),
    "11.8": new XREError("SETPLAYBACKRATE_CALLED_ON_DETACH", XREErrorType.OTHER),
    "11.9": new XREError("DETACH_CALLED_ON_AN_INVALID_STATE", XREErrorType.OTHER)
};

export function getByErrorCode(errorCode: string): XREError {

    // if errorCode cannot be mapped to XREError, send XRE Generic error
    return adobeXREErrors[errorCode]
        || helioXREErrors[errorCode]
        || adobeXREErrors[XREErrorCode.kECGenericError];

}

