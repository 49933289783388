import { IPPSandbox, IPPModule } from "../PlayerPlatformApplication";
import * as app from "../Application";
import * as constants from "../PlayerPlatformConstants";
import { ConfigurationManager } from "../ConfigurationManager";
import { BaseAsset, AssetUrlType } from "../assets/BaseAsset";
import { BasePlayer } from "./base/BasePlayer";
import { AssetEngine } from "../assets/ContentOptions";
import { Logger } from "../util/Logger";
import { OttAsset } from "../services/urlService/overTheTop/OttAsset";

declare let global: any;

interface IEngineSelMap {
    [ET: string]: string;
}

export enum AdobePSDKType {
    // AdobePSDK not present
    NONE,

    // native/embedded PSDK (Digital Home Porting Kit)
    DHPK
}

/**
 *  EngineSelector constructor
 */
export class EngineSelector implements IPPModule<EngineSelector> {

    public static PSDK = "psdk";
    public static FLASH = "flash";
    public static DISNEY = "disney";
    public static ESPN = "espn";
    public static HULU = "hulu";
    public static HELIO = "helio";
    public static HELIO_FUSION = "helio_fusion";
    public static AAMP = "aamp";
    public static NULL = "null";

    private logger: Logger;
    private sandbox: IPPSandbox;
    private defaultEngineType: string;
    private supportedAssets: string[];
    private currentPlayer: BasePlayer;

    constructor() {
        // create the logger for this component
        this.logger = new Logger("EngineSelector");
        this.logger.trace("new");

        this.currentPlayer = null;
        this.supportedAssets = [];
    }

    public init(sandbox: IPPSandbox) {
        this.sandbox = sandbox;
        sandbox.subscribe(constants.SET_ASSET, this.publishEngine, constants.PRIORITY_HIGH, this);
        sandbox.subscribe(constants.DESTROY, this.onPlayerDestroy, constants.PRIORITY_DEFAULT, this);
        sandbox.subscribe(constants.PLAYER_CHECK, this.playerCheck, constants.PRIORITY_DEFAULT, this);

        this.defaultEngineType = ConfigurationManager.getInstance().get(ConfigurationManager.DEFAULT_ENGINE, EngineSelector.PSDK);

        return this;
    }

    public destroy(sandbox: IPPSandbox) {
        sandbox.remove(constants.SET_ASSET, this.publishEngine);
        sandbox.remove(constants.DESTROY, this.onPlayerDestroy);
        sandbox.remove(constants.PLAYER_CHECK, this.playerCheck);

        this.currentPlayer = null;
    }

    private nullEngine() {
        // start module
        const player = app.startModule(ENGINE_PLAYER_MAP[EngineSelector.NULL]) as BasePlayer;
        if (!player) {
            return;
        }
        player.assetEngine = EngineSelector.NULL;

        this.currentPlayer = player;
        this.sandbox.publish(constants.ENGINE_SELECTED, player);
    }

    private playerCheck() {
        this.checkSupportedAssets();
        if (!!this.currentPlayer) {
            this.sandbox.publish(constants.ENGINE_SELECTED, this.currentPlayer);
        } else {
            this.nullEngine();
        }
    }

    /**
     * Selects and creates the appropriate player engine for the asset passed in.
     * @param {BaseAsset} asset
     * @returns void
     */
    private publishEngine(asset: BaseAsset): void {

        let player: BasePlayer;

        const engineType = this.getEngineType(asset);

        const playerName: string = ENGINE_PLAYER_MAP[engineType];

        this.logger.trace("getEngine: type selected: " + engineType);

        if (!playerName || !app.contains(playerName)) {
            throw new Error("Engine type: " + engineType + " not supported");
        }

        if (this.currentPlayer) {
            const doPlayerStart = this.handleAssetsLoadedOnInitialization(engineType);
            if (!doPlayerStart) {
                return;
            }
        }

        // start module
        player = app.startModule(playerName);
        player.assetEngine = engineType;
        this.currentPlayer = player;
        this.sandbox.publish(constants.ENGINE_INITIALIZED, player);
        this.sandbox.publish(constants.ENGINE_SELECTED, player);
    }

    private getEngineType(asset: BaseAsset): string {
        let engineType: string;

        if (asset.assetEngine === EngineSelector.AAMP) {
            engineType = EngineSelector.AAMP;
        } else if (asset.assetEngine === EngineSelector.HELIO) {
            engineType = EngineSelector.HELIO;
        } else if (asset instanceof OttAsset && asset.isESPN()) {
            engineType = EngineSelector.ESPN;
        } else if (!asset.assetEngine || asset.assetEngine === AssetEngine.GENERIC) {
            engineType = this.findEngineFromUrlType(asset.getUrlType());
        } else {
            engineType = this.findEngineTypeFromAssetEngine(asset.assetEngine);
        }
        return engineType;
    }

    // workaround for players only loading assets on initialization. We can never use the cached player.
    // players: ESPN & Disney
    private handleAssetsLoadedOnInitialization(engineType: string): boolean {

        if (this.currentPlayer.assetEngine === engineType &&
            this.currentPlayer.assetEngine !== EngineSelector.ESPN &&
            this.currentPlayer.assetEngine !== EngineSelector.DISNEY &&
            this.currentPlayer.assetEngine !== EngineSelector.HULU) {

            this.sandbox.publish(constants.ENGINE_SELECTED, this.currentPlayer);
            return false;
        }

        // stop module
        try {
            app.stopModule(ENGINE_PLAYER_MAP[this.currentPlayer.assetEngine]);
        } catch (error) {
            this.logger.warn("Error encountered stopping module: " + this.currentPlayer.assetEngine + " error: " + error);
        }

        try {
            // clear out player container
            this.sandbox.params.videoElement.innerHTML = "";
        } catch (err) {
            //unit tests do not have a player container
        }
        return true;

    }

    private onPlayerDestroy() {
        app.stopModule(ENGINE_PLAYER_MAP[this.currentPlayer.assetEngine]);
    }

    /**
     * Builds a list of supported asset engine types
     */
    private checkSupportedAssets() {
        this.supportedAssets = [
            AssetEngine.GENERIC,
            AssetEngine.FLASH,
            AssetEngine.PSDK,
            AssetEngine.DISNEY,
            AssetEngine.ESPN,
            AssetEngine.HULU,
            AssetEngine.NBCUNI,
            AssetEngine.HELIO,
            AssetEngine.HELIO_FUSION,
            AssetEngine.AAMP

        ];

        this.sandbox.publish(constants.PLAYERS_AVAILABLE, this.supportedAssets);
    }

    /**
     * Returns the appropriate engine type based on the asset engine
     * @param {string} engine
     * @returns {string} engineType
     */
    private findEngineTypeFromAssetEngine(engine: string): string {
        this.logger.trace(`findEngineTypeFromAssetEngine: default: ${this.defaultEngineType}, assetEngine: ${engine}`);
        if (engine === AssetEngine.GENERIC) {
            return this._getAdobePSDKType() === AdobePSDKType.DHPK ? EngineSelector.PSDK : EngineSelector.FLASH;
        }
        return ASSET_ENGINE_MAP[engine] || this.defaultEngineType;
    }

    /**
     * Selects and returns the appropriate engine type based on the url type
     * @param {string} urlType
     * @returns {string} engineType
     */
    private findEngineFromUrlType(urlType: string): string {
        this.logger.trace("findEngineTypeFromExtension: default: " + this.defaultEngineType + ", urlType: " + urlType);

        switch (urlType) {
            case AssetUrlType.URLTYPE_MPD:
                return EngineSelector.HELIO;
            case AssetUrlType.URLTYPE_M3U:
                if (this._getAdobePSDKType() === AdobePSDKType.DHPK) {
                    return EngineSelector.PSDK;
                } else {
                    return EngineSelector.FLASH;
                }
            default:
                return this.defaultEngineType;
        }
    }

    /**
     * Returns a boolean indicating whether MediaSourceExtensions are present.
     * @method supportsMediaSource
     * @returns {Boolean} True if MediaSourceExtensions are present.
     */
    public static supportsMediaSource() {
        return "WebKitMediaSource" in window || "MediaSource" in window;
    }

    /**
     * Determines type of AdobePSDK if present.
     *
     * @method _getAdobePSDKType
     * @returns {AdobePSDKType}
     */
    private _getAdobePSDKType(): AdobePSDKType {
        let type = AdobePSDKType.DHPK;

        if (!global.AdobePSDK) {
            type = AdobePSDKType.NONE;
        }

        this.logger.info(`AdobePSDK type is ${AdobePSDKType[type]}`);

        return type;
    }
}

const ENGINE_PLAYER_MAP: IEngineSelMap = {
    [EngineSelector.PSDK]: "PSDKPlayer",
    [EngineSelector.FLASH]: "FlashPlayer",
    [EngineSelector.DISNEY]: "DisneyPlayer",
    [EngineSelector.ESPN]: "ESPNPlayer",
    [EngineSelector.HULU]: "HuluPlayer",
    [EngineSelector.HELIO]: "HelioPlayer",
    [EngineSelector.HELIO_FUSION]: "HelioFusionPlayer",
    [EngineSelector.AAMP]: "AAMPPlayer",
    [EngineSelector.NULL]: "NullPlayer"
};

const ASSET_ENGINE_MAP: { [x: string]: string } = {
    [AssetEngine.FLASH]: EngineSelector.FLASH,
    [AssetEngine.PSDK]: EngineSelector.PSDK,
    [AssetEngine.DISNEY]: EngineSelector.DISNEY,
    [AssetEngine.ESPN]: EngineSelector.ESPN,
    [AssetEngine.HULU]: EngineSelector.HULU,
    [AssetEngine.NBCUNI]: EngineSelector.FLASH,
    [AssetEngine.HELIO]: EngineSelector.HELIO,
    [AssetEngine.HELIO_FUSION]: EngineSelector.HELIO_FUSION,
    [AssetEngine.AAMP]: EngineSelector.AAMP

};

app.registerModule("EngineSelector", EngineSelector, { autostart: true });
