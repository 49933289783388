import { Logger } from "../util/Logger";
import { ITrackable } from "./tracking/ITrackable";
import TrickModeRestriction, { RestrictionLimits } from "./TrickModeRestriction";

export interface IVideoAdOptions {
    clickThrough?: string | ( () => void);
    companionAds?: object[];

    /**
     * Is the ad an vpaid based ad.
     */
    vpaid?: boolean;
}

export interface IVideoAdAssetInfo {
    assetId: string;
    providerId: string;
}

export class VideoAd implements ITrackable {

    private static logger: Logger = new Logger("VideoAd");

    public id: string;
    public startTime: number;
    public duration: number;
    public clickThrough: string | (() => void);
    public companionAds: object[];
    public seenCount: number = 0;
    public watched: boolean = false;

    /**
     * Represents if this is a VPAID framework based
     * ad.
     */
    public vpaid: boolean = false;

    public restrictions: TrickModeRestriction[] = [];
    public adAssetInfo: IVideoAdAssetInfo = undefined;

    constructor(id: string, startTime: number, duration: number, options: IVideoAdOptions = { clickThrough: undefined, companionAds: [], vpaid: false }) {
        this.id = id;
        this.startTime = startTime;
        this.duration = duration;
        this.clickThrough = options.clickThrough;
        this.companionAds = options.companionAds;
        this.vpaid = options.vpaid;
    }

    get endTime() {
        return this.startTime + this.duration;
    }

    /**
     * Indicates whether or not it's allowed to seek forward out of this ad.
     *
     * @returns {boolean}
     */
    get seekable() {
        return !this.restrictions.filter((restriction: TrickModeRestriction) => restriction.mode === TrickModeRestriction.MODE_FFWD).length;
    }

    /**
     * Indicates whether this ad can be paused.
     *
     * @returns {boolean}
     */
    get pausable() {
        return !this.restrictions.filter((restriction: TrickModeRestriction) => restriction.mode === TrickModeRestriction.MODE_PAUSE).length;
    }

    /**
     * Returns an object that specifies the limits for each restriction.
     *
     * @returns {RestrictionLimits}
     */
    get restrictionLimits(): RestrictionLimits {
        return this.restrictions.reduce((obj: RestrictionLimits, res: TrickModeRestriction) => {
            obj[res.mode] = res.limit;
            return obj;
        }, new RestrictionLimits());
    }

    /**
     * indicate if the ad includes the designated time
     * note that the start time of the ad is considered to be included in the ad, but the end time is not
     * coversLocation
     * @param {number} location (msec)
     * @returns {boolean}
     */
    public coversLocation(location: number): boolean {

        let res: boolean = false;

        if (this.startTime !== -1 && ((this.startTime <= location) && (location < this.endTime))) {
            res = true;
        }

        return res;
    }

    /**
     * Determines if the ad start time falls in the given range.
     *
     * @param begin
     * @param end
     * @returns {boolean}
     */
    public isInRange(begin: number, end: number) {
        return begin <= this.startTime && this.startTime <= end;
    }

    /**
     * increment the number of times this ad has been seen
     */
    public incrementSeenCount(): void {
        VideoAd.logger.trace("incrementSeenCount: " + this.seenCount);
        ++this.seenCount;
    }

    /**
     * return the percentage complete; 0 if position is before start or duration is 0; 100 if position is afer end
     * @param {number} adBreakPosition - msec
     * @returns {number}        - percent
     */
    public getPercentageComplete(adBreakPosition: number): number {
        let res: number = 0;
        if ((adBreakPosition < this.startTime) || (this.duration === 0)) {
            res = 0;
        } else if (adBreakPosition > (this.startTime + this.duration)) {
            res = 100;
        } else {
            res = ((adBreakPosition - this.startTime) / this.duration) * 100;
        }
        return res;
    }

    /**
     * Determines whether or not the given `speed` is allowed during this ad.
     *
     * @param speed
     * @returns {boolean}
     */
    public speedIsAllowed(speed: number): boolean {

        if (speed === 1) {
            return true;
        }

        if (speed === 0) {
            return this.pausable;
        }

        const limits = this.restrictionLimits;
        const limit = speed < 0 ? limits.rewind : limits.fastForward;
        return limit >= 0 && this.seenCount >= limit;
    }

    /**
     * Determines whether or not a seek would be restricted over this ad.
     *
     * @param start
     * @param target
     * @returns {boolean}
     */
    public seekIsAllowed(start: number, target: number): boolean {
        const isForward = start < target;
        const limits = this.restrictionLimits;
        const limit = isForward ? limits.fastForward : limits.rewind;
        const allowed = limit >= 0 && this.seenCount >= limit;

        VideoAd.logger.trace(`seekIsAllowed: ${allowed} -- target=${target}, limit=${limit}, seenCount=${this.seenCount}`);

        return allowed;
    }
}
