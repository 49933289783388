
import { PPError } from "../PPError";
import { Logger } from "../util/Logger";
import { IPPModule, IPPSandbox } from "../PlayerPlatformApplication";
import * as events from "../PlayerPlatformAPIEvents";
import * as constants from "../PlayerPlatformConstants";
import { BaseAsset } from "../assets/BaseAsset";
import { VideoAd, IVideoAdOptions } from "../ads/VideoAd";
import { AdManagerTypes } from "../ads/IAdConfig";
import { VideoAdBreak } from "../ads/VideoAdBreak";
import { arrayEquals } from "../util/JSUtil";
import { MediaSegment } from "../MediaSegment";
import { empty, merge, Subscription } from "rxjs";
import { catchError, distinctUntilChanged, map, takeUntil } from "rxjs/operators";
import { MILLISECONDS_PER_SECOND } from "../PlayerPlatformConstants";
import { IAdConfig } from "./IAdConfig";
import { MediatorChannel } from "publicious";

/**
 * AdManager
 * @constructor
 */
export abstract class AdManager implements IPPModule<AdManager> {

    protected logger: Logger;
    protected sandbox: IPPSandbox;

    public type: AdManagerTypes;
    public asset: BaseAsset;
    public cfg: IAdConfig;
    public deferredSeek: number;
    public subscribedTags: string[] = [];

    public ads: VideoAd[] = [];

    private _adBreaks: VideoAdBreak[] = [];

    private playspeedChanged: Subscription;

    constructor() {
        this.logger = new Logger("AdManager");
    }

    get adBreaks(): VideoAdBreak[] {
        return this._adBreaks;
    }

    set adBreaks( adBks: VideoAdBreak[]) {
        this._adBreaks = adBks || [];
    }

    public init(sandbox: IPPSandbox, cfg: IAdConfig = {} as IAdConfig, handleAdEvents: boolean = false): AdManager {
        this.sandbox = sandbox;
        this.cfg = cfg;

        // set getTimeline API to AdManager.getTimeline()
        sandbox.addAPI("getTimeline", this.getTimeline.bind(this));
        sandbox.addAPI("getMediaSegments", this.getMediaSegments.bind(this));
        sandbox.addAPI("getSupportedPlaybackSpeeds", this.getSupportedPlaybackSpeeds.bind(this));
        sandbox.subscribe(constants.SET_ASSET, this.onSetAsset, constants.PRIORITY_MID, this);

        events.addEventListener(events.PLAY_STATE_CHANGED, this.onPlayStateChanged, constants.PRIORITY_HIGH, this);

        if (handleAdEvents) {
            this.handleAdEvents();

            // intercept the following api calls to modify parameters
            sandbox.subscribe(constants.SET_POSITION, this.onSetPosition, constants.PRIORITY_HIGH, this);
            sandbox.subscribe(constants.SET_SPEED, this.onSetSpeed, constants.PRIORITY_HIGH, this);
            sandbox.subscribe(constants.PAUSE, this.onPause, constants.PRIORITY_HIGH, this);
        }

        return this;
    }

    public destroy(sandbox: IPPSandbox): void {

        sandbox.remove(constants.SET_ASSET, this.onSetAsset);
        sandbox.remove(constants.SET_POSITION, this.onSetPosition);
        sandbox.remove(constants.SET_SPEED, this.onSetSpeed);
        sandbox.remove(constants.PAUSE, this.onPause);

        events.removeEventListener(events.PLAY_STATE_CHANGED, this.onPlayStateChanged);
        events.removeEventListener(events.MEDIA_ENDED, this.onMediaEnded);
        events.removeEventListener(events.MEDIA_OPENED, this.onMediaOpened);
        events.removeEventListener(events.AD_BREAK_COMPLETE, this.onAdBreakComplete);
        events.removeEventListener(events.AD_BREAK_EXITED, this.onAdBreakExited);
        events.removeEventListener(events.AD_COMPLETE, this.onAdComplete);
        events.removeEventListener(events.AD_BREAK_UPCOMING, this.onUpcomingAdBreak);

        if (this.playspeedChanged) {
            this.playspeedChanged.unsubscribe();
        }

        this.reset();
    }

    public update( cfg: IAdConfig): void {
        this.cfg = cfg;
    }

    public handleAdEvents() {
        events.addEventListener(events.MEDIA_ENDED, this.onMediaEnded, constants.PRIORITY_DEFAULT, this);
        events.addEventListener(events.MEDIA_OPENED, this.onMediaOpened, constants.PRIORITY_HIGH, this);
        events.addEventListener(events.AD_BREAK_COMPLETE, this.onAdBreakComplete, constants.PRIORITY_HIGH, this);
        events.addEventListener(events.AD_BREAK_EXITED, this.onAdBreakExited, constants.PRIORITY_HIGH, this);
        events.addEventListener(events.AD_COMPLETE, this.onAdComplete, constants.PRIORITY_HIGH, this);
        events.addEventListener(events.AD_BREAK_UPCOMING, this.onUpcomingAdBreak, constants.PRIORITY_HIGH, this);
    }

    public onSetAsset(asset: BaseAsset): void {
        this.reset();

        this.asset = asset;
        asset.adMetadata = this.getMetadata();
        this.subscribedTags.forEach((tag: string) => {
            asset.addSubscribedTag(tag);
        });
    }

    public onSetPosition(target: number, ignoreAds?: boolean, channel?: MediatorChannel): void {

        if (ignoreAds) {
            this.logger.info("Ignoring the seek as the ignoreAds flag is true");
            return;
        }

        const start = this.sandbox.getCurrentPosition();
        const limit = this.getNewSeekPosition(start, target);

        if (target === start) {
            this.logger.info("Ignoring the seek as attempting to seek to current position");
            return;
        }

        // intercept setPosition call and modify target
        channel.stopPropagation();

        /**
         * We can always seek to where we are
         * (limit === start), if the new seek position
         * does not match our target we assume
         * we can not seek there based on some restriction
         */
        if (limit === start) {

            this.logger.info("setPosition: seek disallowed by AdManager");

            const error = new PPError(events.MEDIA_WARNING_TRICKMODE_DISALLOWED, null, "seek not allowed by current segment");

            // generate the appropriate media warning event
            events.dispatchEvent(new events.MediaWarningEvent(error));

            return;

        } else if (limit !== target) {
            this.deferredSeek = target;
            this.logger.info("setPosition: deferredSeek = target");
        } else {
            this.deferredSeek = null;
            this.logger.info("setPosition: deferredSeek = null");
        }

        this.logger.info("setPosition: start=" + start + " target=" + target + " limit=" + limit);

        // resend setPosition event with new prefix
        this.sandbox.publish("ads:setPosition", limit);
    }

    /**
     * onSetSpeed is a handler for a publicious subscriber.
     * It's arguments are somewhat dynamic depending on what was provided
     * by the publisher.
     * The MediatorChannel should never explicitly be defined as an arg, since
     * it dynamically appears as the last arg in whatever is published.
     * This allows the publisher and subcriber to be more losely coupled for
     * when additional arguments are added to the publish.
     */
    private onSetSpeed(speed: number, _overshootCorrection?: number) {
        const ad = this.getCurrentAd();
        const channel: MediatorChannel = arguments[arguments.length - 1];

        if (ad && !ad.speedIsAllowed(speed)) {
            this.logger.info("setSpeed: speed disallowed by TrickModeRestriction");

            channel.stopPropagation();

            const error = new PPError(events.MEDIA_WARNING_TRICKMODE_DISALLOWED, null, "speed change not allowed by current segment");

            // generate the appropriate media warning event
            events.dispatchEvent(new events.MediaWarningEvent(error));
        } else if ( speed > 1 ) {
            const ffDisabledAd: VideoAd = this.getTrickModeDisallowedAdInRange(speed);
            if (ffDisabledAd) {
                this.logger.info("Starting playback of trickmode restricted Ad");
                channel.stopPropagation();
                this.sandbox.publish(constants.SET_POSITION, ffDisabledAd.startTime);
            }
        }
    }

    public onPause(channel: MediatorChannel) {
        const ad = this.getCurrentAd();

        if (ad && !ad.pausable) {
            this.logger.info("pause disallowed by TrickModeRestriction");

            channel.stopPropagation();

            const error = new PPError(events.MEDIA_WARNING_TRICKMODE_DISALLOWED, null, "pause not allowed by current ad");

            // generate the appropriate media warning event
            events.dispatchEvent(new events.MediaWarningEvent(error));
        }
    }

    /**
     * Indicates whether this ad manager controls initial playback.
     * Some ad managers need to play a PRE-ROLL or other content before
     * play is called on an underlying ad engine.
     */
    public controlsInitialPlayback(): boolean {
        return false;
    }

    public onMediaOpened(event: events.MediaOpenedEvent) {
        this.setupSpeedsChangedEvent();
        event.numAds = this.ads.length;
    }

    /**
     * create a new VideoAd and record it in a VideoAdBreak. Create a new VideoAdBreak if necessary
     * @method addAd
     * @param {string} id              - unique identifier for ad
     * @param {number} time            - starting time of the ad in milliseconds
     * @param {number} duration        - duration of ad in milliseconds
     * @param {object} options
     */
    public addAd(id: string, time: number, duration: number, options: IVideoAdOptions = {}): void {
        this.logger.trace("addAd: id=" + id + " time=" + time + " duration=" + duration + " options=" + JSON.stringify(options));
        this.ads.push(new VideoAd(id, time, duration, options));
    }

    /**
     * indicate if there is an ad that includes the specified position
     * @method adCoversLocation
     * @param {number} position (msec)
     * @returns {boolean}
     */
    public adCoversLocation(position: number): boolean {

        return this.adBreakForLocation(position) !== undefined;
    }

    public adBreakForLocation(position: number): VideoAdBreak {
        for (const br of this.adBreaks) {
            if (br.coversLocation(position)) {
                return br;
            }
        }
    }

    /**
     * Given a position relative to a timeline containing ads,
     * recalculate the position for a timeline with no ads.
     *
     * @param {number} pos - position in timeline containing ads
     * @returns {number}
     */
    public getResumePositionNoAds(pos: number): number {
        const breaks = this.adBreaks.filter(function(adBreak) {
            if (adBreak.coversLocation(pos)) {
                pos = adBreak.endTime;
            }
            return adBreak.endTime <= pos;
        });

        if (!breaks.length) {
            return pos;
        }

        let totalDuration = 0;
        for (const adBreak of breaks) {
            totalDuration += adBreak.duration;
        }

        return pos - totalDuration;
    }

    public getClosestAdBreak(position: number): VideoAdBreak {
        let closest: VideoAdBreak;

        for (const adBreak of this.adBreaks) {
            if (position >= adBreak.startTime && (!closest || adBreak.startTime >= closest.startTime)) {
                closest = adBreak;
            }
        }

        return closest;
    }

    /**
     * AdManager Event Handlers *****************************************************
     */

    protected onAdBreakComplete() {

        if (this.sandbox.getCurrentPlaybackSpeed() === 1 && this.deferredSeek && !this.adCoversLocation(this.deferredSeek)) {
            this.logger.info(`setPosition: onAdBreakComplete deferredSeek = ${this.deferredSeek}`);
            this.sandbox.publish("ads:setPosition", this.deferredSeek);
        }

        this.deferredSeek = null;
    }

    private onAdBreakExited() {
        this.deferredSeek = null;
    }

    protected onAdComplete(event: events.AdCompleteEvent) {
        if (event.rate === 1) {
            event.videoAd.incrementSeenCount();
        }
    }

    public onUpcomingAdBreak(event: events.AdBreakUpcomingEvent) {
        const adBreak = event.videoAdBreak; // use the first one
        const speed = this.sandbox.getCurrentPlaybackSpeed();

        if (!adBreak.speedIsAllowed(speed)) {
            this.sandbox.publish(constants.SET_POSITION, event.videoAdBreak.startTime);
        }
    }

    public onMediaEnded() {
        this.deferredSeek = null;
    }

    /**
     * @method getMetadata
     * @returns {object}
     */
    public getMetadata() {
        return { adType: this.type };
    }

    public getTimeline() {
        return this.adBreaks;
    }

    public getSupportedPlaybackSpeeds(ignoreCurrentAd?: boolean) {
        const speeds: number[] = this.sandbox.player.getSupportedPlaybackSpeeds();

        // if an cue & trick mode restriction is placed at the start of an hls manifest
        // before any fragments, then the playback speeds would be returned based on the
        // ad trickmode restrictions instead of for the asset.
        // This check/flag allows us to request the assets playback speeds directly.
        // https://ccp.sys.comcast.net/browse/VPLAY-1905
        if (ignoreCurrentAd) {
            return speeds;
        }

        const ad = this.getCurrentAd();

        return ad ? speeds.filter(speed => ad.speedIsAllowed(speed)) : speeds;
    }

    public isAdPlaying() {
        return !!this.getCurrentAdBreak();
    }

    public getNewSeekPosition(_start: number, target: number): number {
        return target;
    }

    public reset() {
        this.deferredSeek = null;
        this.ads = [];
        this.adBreaks = [];
    }

    public getCurrentAdBreak(): VideoAdBreak {
        return this.adBreaks.filter(adBreak => adBreak.coversLocation(this.sandbox.getCurrentPosition()))[0];
    }

    public getCurrentAd(): VideoAd {
        return this.adBreaks
            .map((adBreak: VideoAdBreak) => adBreak.ads)
            .reduce((a: VideoAd[], b: VideoAd[]) => a.concat(b), [])
            .filter((ad: VideoAd) => ad.coversLocation(this.sandbox.getCurrentPosition()))[0];
    }

    /**
     * Returns VideoAd with passed trickspeed restriction near to current position
     * @param rate
     */
    public getTrickModeDisallowedAdInRange(rate: number): VideoAd {
        const currentPosition: number = this.sandbox.getCurrentPosition();
        return this.adBreaks
            .map((adBreak: VideoAdBreak) => adBreak.ads)
            .reduce((a: VideoAd[], b: VideoAd[]) => a.concat(b), [])
            .filter((ad: VideoAd) => (!ad.speedIsAllowed(rate) && ad.isInRange(currentPosition, currentPosition + rate * MILLISECONDS_PER_SECOND)))[0];
    }

    public getMediaSegments(): MediaSegment[] {
        if (this.ads.length && !this.adBreaks.length) {
            this.adBreaks = AdManager.sortIntoAdBreaks(this.ads);
        }

        if (!this.adBreaks.length) {
            return [];
        }

        this.logger.trace("creating media segments");
        return MediaSegment.createSegments(this.adBreaks, this.sandbox.asset, this.sandbox.getDuration());
    }

    /**
     * EventListener to PLAY_STATE_CHANGED event
     * @param evt
     * @protected
     */
    protected onPlayStateChanged(evt: events.PlayStateChangedEvent): void {
        if (evt.playState === constants.STATUS_INITIALIZED) {
            this.updateResumePosition();
        }
    }

    /**
     * Updates asset resumePosition based on AdBreak durations and positions
     * in the timeline
     * @protected
     */
    protected updateResumePosition(): void {
        if (this.asset.isContentPosition && !this.asset.isRetry) {
            this.logger.trace("Modifying resumePosition with ad offset.");
            this.logger.trace("Original position: ", this.asset.resumePosition);
            let adBreakDurations: number = 0;
            for (const adBreak of this.adBreaks) {
                const position: number = this.getResumePositionNoAds(adBreak.endTime);
                if (this.asset.resumePosition <= position) {
                    break;
                } else {
                    adBreakDurations += adBreak.duration;
                }
            }
            this.asset.resumePosition += adBreakDurations;
            this.logger.trace("New position: ", this.asset.resumePosition);
        }
    }

    public static sortIntoAdBreaks(ads: VideoAd[]): VideoAdBreak[] {
        if (!ads.length) {
            return [];
        }
        return ads
            .sort((ad1, ad2) => ad1.startTime - ad2.startTime)
            .reduce((arr: VideoAdBreak[], ad2: VideoAd) => {
                let adBreak: VideoAdBreak = arr[arr.length - 1];
                if (!adBreak.addAd(ad2)) {
                    adBreak = new VideoAdBreak();
                    adBreak.addAd(ad2);
                    arr.push(adBreak);
                }
                return arr;
            }, [new VideoAdBreak()]);
    }

    /**
     * Use ad event streams to track supported playback speeds and fire events when necessary
     */
    private setupSpeedsChangedEvent(): void {
        const { adStarts, adCompletes, adExiteds } = this.sandbox.streams;

        this.playspeedChanged = merge(adStarts, adCompletes, adExiteds)              // start with ad events
            .pipe(
                takeUntil(this.sandbox.streams.getPlayState(constants.STATUS_IDLE)),                // stop listening on idle
                map(() => this.getSupportedPlaybackSpeeds()),                                       // get speed array
                distinctUntilChanged(arrayEquals),                                                  // skip duplicates (not changed)
                map((speeds: number[]) => new events.PlaybackSpeedsChangedEvent(speeds)),           // convert to event
                catchError((err) => {                                                                   // catch any errors
                    this.logger.error("error firing speeds changed:", err);
                    return empty();
                })
            )
            .subscribe(events.emit);                                                            // emit event
    }

    public getContentFactory(): AdobePSDK.ContentFactory {
        return null;
    }

    public hasContentFactory(): boolean {
        return false;
    }
}


export class SeekRestrictedAdManager extends AdManager {

    /**
     * Returns the position to use when seeking past an
     * ad break and then starting playback of an ad break.
     */
    public getClosestStartTime(adBreak: VideoAdBreak): number {
        return adBreak.startTime;
    }

    public getNewSeekPosition(start: number, position: number): number {
        if (this.isAdPlaying() && !this.getCurrentAdBreak().watched && position > start) {
            return start;
        }

        const closest = this.getClosestAdBreak(position);

        if (closest && start > closest.endTime) {
            return position;
        }
        return closest && !closest.watched ? this.getClosestStartTime(closest) : position;
    }
}
