import { VideoAdBreak } from "./ads/VideoAdBreak";
import { VideoAd } from "./ads/VideoAd";
import { BaseAsset } from "./assets/BaseAsset";

/**
 * MediaSegmentRestriction
 * restrictions: limit=0 means never restricted, -1 means always restricted, and
 * any other value means the # of times the segment must be viewed to relax restriction
 * @constructor
 */
export class MediaSegmentRestrictions {
    public fastForward = 0;
    public rewind = 0;
    public pause = 0;
}

/**
 * MediaSegment
 * @param {String} id                   unique identifier to associate with segment
 * @param {String} type                  one of MediaSegment.TYPE_CONTENT or TYPE_AD
 * @param {Number} start                start time of segment in milliseconds
 * @param {Number} duration             duration of segment in milliseconds
 * @constructor
 */
export class MediaSegment {

    public static TYPE_CONTENT = "content";
    public static TYPE_AD = "ad";

    public restrictions: MediaSegmentRestrictions = new MediaSegmentRestrictions();

    constructor(
        public id: string,          // unique identifier for the segment
        public segmentType: string, // one of "content" or "ad"
        public start: number,       // offset of segment from the start of the asset, in milliseconds
        public duration: number     // duration of segment in milliseconds
    ) { }

    /**
     * set the trick mode restrictions for the media segment
     * @method setRestrictions
     * @param {Number} fastForward
     * @param {Number} rewind
     * @param {Number} pause
     */
    public setRestrictions(fastForward: number, rewind: number, pause: number) {
        this.restrictions.fastForward = fastForward;
        this.restrictions.rewind = rewind;
        this.restrictions.pause = pause;
    }

    /**
     * return the end time of the media segment in milliseconds
     * @method getEnd
     * @returns {Number}
     */
    public getEnd() {
        return this.start + this.duration;
    }

    /**
     * return a clone of this MediaSegment object
     * @method clone
     * @returns {Object}
     */
    public clone() {
        const newSeg = new MediaSegment(this.id, this.segmentType, this.start, this.duration);
        newSeg.restrictions.fastForward = this.restrictions.fastForward;
        newSeg.restrictions.rewind = this.restrictions.rewind;
        newSeg.restrictions.pause = this.restrictions.pause;
        return newSeg;
    }

    /**
     * Convert to new object to include only the required fields.
     *
     * @returns {Object}
     */
    public toObject(): IXREMediaSegment {
        return {
            id: this.id,
            segmentType: this.segmentType,
            start: this.start,
            duration: this.duration,
            restrictions: this.restrictions
        };
    }

    public static fromAd(ad: VideoAd): MediaSegment {
        const mediaSegment = new MediaSegment(ad.id, MediaSegment.TYPE_AD, ad.startTime, ad.duration);
        const limits = ad.restrictionLimits;
        mediaSegment.setRestrictions(limits.fastForward, limits.rewind, limits.pause);
        return mediaSegment;
    }

    public static createAdSegments(ads: VideoAdBreak[]): MediaSegment[] {
        return ads
            .map(adBreak => adBreak.ads)    // get VideoAds from breaks
            .reduce((prev: any[], next: any[]) => prev.concat(next), [])  // flatten
            .map(MediaSegment.fromAd); // convert to MediaSegment
    }

    public static createContentSegments(ads: VideoAdBreak[], asset: BaseAsset, duration: number): MediaSegment[] {
        if (!ads.length) {
            return;
        }

        // create all content segments except pre-roll
        const contentSegments = ads.reduce((prev: any[], next: VideoAdBreak, idx: number, arr: VideoAdBreak[]) => {
            if (next.startTime === 0) {
                return prev;
            }
            const startTime = (arr[idx - 1] || {} as any).endTime || 0;
            const calculatedDuration = next.startTime - startTime;

            return prev.concat(new MediaSegment(asset.id, MediaSegment.TYPE_CONTENT, startTime, calculatedDuration));
        }, []);

        // check for post roll
        const lastBreak = ads[ads.length - 1];
        if (lastBreak.endTime !== duration) {
            contentSegments.push(new MediaSegment(asset.id, MediaSegment.TYPE_CONTENT, lastBreak.endTime, duration - lastBreak.endTime));
        }

        return contentSegments;
    }

    public static createSegments(ads: VideoAdBreak[], asset: BaseAsset, duration: number): MediaSegment[] {
        const adSegments: MediaSegment[] = MediaSegment.createAdSegments(ads);
        const contentSegments: MediaSegment[] = MediaSegment.createContentSegments(ads, asset, duration);

        return contentSegments
            .concat(adSegments)
            .sort((seg1: MediaSegment, seg2: MediaSegment) => seg1.start - seg2.start);
    }
}

export interface IXREMediaSegment {
    id: string;
    segmentType: string;
    start: number;
    duration: number;
    restrictions: MediaSegmentRestrictions;
}
