import { MediaProgressEvent, PlayStateChangedEvent } from "../PlayerPlatformAPIEvents";
import { Observable } from "rxjs";
import { map, merge, pairwise, startWith, withLatestFrom } from "rxjs/operators";

/**
 * Abstraction for two MediaProgress events to encapsulate playback information
 * between two periods of time from the playback timeline.
 */
export class ProgressWindow {

    constructor(
        public prev: number,
        public next: number,
        public prevRate: number,
        public nextRate: number,
        public duration: number,
        public seeking: boolean = false,
        public stopped: boolean = false
    ) { }

    public equals(progress: ProgressWindow) {
        return this.prev === progress.prev && this.next === progress.next && this.prevRate === progress.prevRate && this.nextRate === progress.nextRate;
    }

    public duringTrickPlay(): boolean {
        return ( this.nextRate !== 1 || this.prevRate > 1 || this.prevRate < 0 );
    }

    /**
     * Creates a ProgressWindow based on a pair of `MediaProgressEvent`s or a `MediaProgressEvent` with
     * a `PlayStateChanged` event. If the second argument is a `PlayStateChangedEvent` then it is
     * assumed that the `ProgressWindow` should be considered stopped.
     */
    public static create(event1: MediaProgressEvent, event2: MediaProgressEvent | PlayStateChangedEvent): ProgressWindow {
        if (event2 instanceof MediaProgressEvent) {
            return new ProgressWindow(event1.position, event2.position, event1.playbackSpeed, event2.playbackSpeed, event2.endposition);
        } else {
            return new ProgressWindow(event1.position, event1.position, 0, 0, 0, false, true);
        }
    }

    /**
     * Create an observable of `ProgressWindow`s based on an observable of `MediaProgressEvent`s.
     */
    public static createObservable(progressObservable: Observable<MediaProgressEvent>): Observable<ProgressWindow> {
        return progressObservable
            .pipe(
                startWith(new MediaProgressEvent(-1, 1, 0, 0, 0)),
                pairwise(),
                map(events => ProgressWindow.create(events[0], events[1]))
            );
    }

    /**
     * Create an observable of `ProgressWindow`s that can be "stopped". This means that the given a `stopObservable`
     * is merged with the progress observable to create a `ProgressWindow` object that indicates the end of the window.
     */
    public static createStopObservable(progressObservable: Observable<MediaProgressEvent>, stopObservable: Observable<PlayStateChangedEvent>): Observable<ProgressWindow> {
        return ProgressWindow.createObservable(progressObservable)
            .pipe(
                merge(stopObservable
                        .pipe(
                            withLatestFrom(progressObservable),
                            map(events => ProgressWindow.create(events[1], events[0]))
                        )
               )
            );
    }
}
