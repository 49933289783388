
import { BaseAsset, AssetTypeMapping } from "../../assets/BaseAsset";
import { SessionManager } from "../../handlers/SessionManager";
import { AdobeRuntimeWrapper } from "./AdobeRuntimeWrapper";

export function getMediaPlayerConfig(asset: BaseAsset, contentFactory: AdobePSDK.ContentFactory | null) {

    const config = new AdobePSDK.MediaPlayerItemConfig();
    config.subscribeTags = asset.subscribedTags;
    config.adTags = asset.subscribedTags.filter(includeInAdTags);
    config.adSignalingMode = AdobeRuntimeWrapper.MANIFEST_CUES();
    config.advertisingFactory = contentFactory;

    if (asset.assetType === AssetTypeMapping.OTT) {
        config.networkConfiguration.readSetCookieHeader = true;
        config.networkConfiguration.useCookieHeaderForAllRequests = true;
    }

    config.networkConfiguration
        .addCustomHeader("X-MoneyTrace", [
            SessionManager.instance.moneyTrace.traceId,
            SessionManager.instance.moneyTrace.parentId,
            SessionManager.instance.moneyTrace.spanId
        ]);

    // Causes the asset to "step"
    SessionManager.instance.moneyTrace.createTraceMessage();

    return config;
}

function includeInAdTags(subscribedTag: string): boolean {
    return subscribedTag !== "#EXT-X-TARGETDURATION" && subscribedTag !== "#EXT-NOM-I-FRAME-DISTANCE";
}
