declare const __loglevel: LogLevel | string;

// include css in build
import "../static/css/playerPlatform.css";

// include polyfill for ie11 and edge
import "@babel/polyfill";

// required modules
import "./handlers/RetryHandler";
import "./handlers/PlaybackStalledHandler";
import "./handlers/AnalyticsHandler";
import "./engines/EngineSelector";
import "./engines/null/NullPlayer";
import "./handlers/CrossStreamPreventionHandler";

import * as constants from "./PlayerPlatformConstants";
import * as events from "./PlayerPlatformAPIEvents";
import { LogLevel, setLogLevel } from "./util/Logger";

// exposed modules
export { AssetFactory as Asset } from "./assets/AssetFactory";
export { PlayerPlatformAPI } from "./PlayerPlatformAPI";
export { ConfigurationManager, getConfigurationManager } from "./ConfigurationManager";
export { VideoAd } from "./ads/VideoAd";
export { VideoAdBreak } from "./ads/VideoAdBreak";
export { LogLevel, setLogLevel, setLogSinks, addLogSink, ConsoleSink } from "./util/Logger";

export const Events = events;
export const Constants = constants;

setLogLevel(typeof __loglevel === "string" ? parseInt(__loglevel, 10) : __loglevel);
