import { VideoAdBreak } from "./VideoAdBreak";
import { IAdBreak, IManifestTag } from "./IAdSlot";

export enum AdManagerTypes {
    NONE = "none",
    AUDITUDE = "auditude",
    C3 = "c3",
    MANIFEST = "manifest",
    HULU = "hulu",
    CUSTOM = "custom",
    TVELINEAR = "tvelinear",
    TVELINEARHLS = "tvelinearhls",
    FREEWHEELVOD = "freewheelvod"
}

export interface IAdConfig {
    type: AdManagerTypes;
}

export interface IManifestManipulatorAdConfig extends IFreeWheelAdConfig {
    deviceID?: string;
    terminalAddress?: string;
    /**
     * Number of times to retry urls
     */
    retries?: number;
    acrURL: string;
    // content duration value to be passed to freewheel
    vdur?: number;
}

export interface IAuditudeAdConfig extends IAdConfig {
    auditudeTimeout?: string;
    domainId?: string;
    targetingInfo?: object;
    zoneId?: string;
    crsRulesEndpoint?: string;
}

export interface IC3AdConfig extends IAdConfig {
    ads?: VideoAdBreak[];
}

export interface ITVELinearAdConfigOptions extends IAdConfig {
    assetNetworkId?: string;
    assetFallbackId?: number;
    siteSectionFallbackId?: number;
    postalCode?: string;
}

// this should be refactored.  we don't intend for most of these properties to be
// set in the asset content options passed into Asset.create.  they're here because
// we're repurposing this interface as a container for data passed from ad managers to the
// constructor of FreeWheelAdResolver. we should use a separate interface for that purpose
export interface IFreeWheelAdConfig extends IAdConfig {
    // this is overwrittten in ad managers
    networkId?: string;
    assetNetworkId?: string;
    // this is overwrittten in tvelinear ad manager
    siteSectionNetworkId?: string;
    // this is overwritten in ad managers
    serverUrl?: string;
    // this is overwritten in ad managers
    siteSectionId?: string;
    // this is overwritten in ad managers
    playerProfile?: string;
    // this is overwritten in ad managers
    assetId?: string;
    tags?: string[];
    // this is overwritten in ad managers
    contentType?: string;
    // this is overwritten in ad managers
    preventFreeWheelTrackingEvents?: boolean;
    // this is overwritten in tve linear ad manager
    mvpdString?: string;
    // this is overwritten in tve linear ad manager
    caidSuffix?: string;
    siteSectionFallbackId?: number;
    assetFallbackId?: number;
    sendGeoLocationParams?: boolean;
    // used by Manifest Manipulator VEX freewheel proxy for rogers
    countryCode?: string;
    // used by Manifest Manipulator VEX freewheel proxy for rogers
    postalCode?: string;
    siteSectionFallbackBrand?: string;
}

export interface ICustomAdConfig extends IAdConfig {
    tags: string[];
    resolve(tag: IManifestTag): Promise<IAdBreak>;
}

