
import { IPPModule, IPPSandbox } from "../PlayerPlatformApplication";
import * as events from "../PlayerPlatformAPIEvents";
import { registerModule, toObservable } from "../Application";
import { Logger } from "../util/Logger";
import { PPError } from "../PPError";
import { filter, takeUntil } from "rxjs/operators";

/**
 * Represents the handler for what to do when the network goes down. When
 * the network goes down a player emits an player:networkDown event. Depending
 * on how the application is then configured this player:networkDown is handled
 * in different ways. It can currently be configured by time. The time indicates
 * how long we wait until emitting a MediaFailedEvent. We emit MediaWaringEvent(s)
 * until we reach the configured time.
 */
export class NetworkDownHandler implements IPPModule<NetworkDownHandler> {

    private logger: Logger = new Logger("NetworkDownHandler");

    public static readonly CHANNEL_NAME: string = "player:networkDown";

    public init(sandbox: IPPSandbox): NetworkDownHandler {

        // this is deprecated
        const networkDownTimeout = sandbox.config.networkDownTimeout;
        if (networkDownTimeout !== undefined) {
            this.logger.warn("networkDownTimeout is DEPRECATED! Use failOnNetworkDown instead.");
        }

        // use failOnNetworkDown if configured, otherwise convert networkDownTimeout to a boolean
        const shouldFail = sandbox.config.failOnNetworkDown !== undefined ? sandbox.config.failOnNetworkDown : !networkDownTimeout;

        this.logger.trace(`NetworkDownHandler init : failOnNetworkDown=${shouldFail}`);

        toObservable<PPError>(NetworkDownHandler.CHANNEL_NAME)
            .pipe(
                filter(() => shouldFail),
                takeUntil(sandbox.destroyed)
            )
            .subscribe((error: PPError) => events.emit(new events.MediaFailedEvent(error)));

        return this;

    }

    public destroy(): void {
         /* */
    }

}

registerModule("NetworkDownHandler", NetworkDownHandler);
