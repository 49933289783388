
declare let __viperversion: string;
import { HomeState, IApplicationInfo, IDeviceInfo, IAnalyticsMoneyTrace } from "../analytics/IAnalyticsProvider";
import { IXuaAsset } from "./Assets";
import { IDiagnosticTestResult } from "../handlers/HomeNetworkAnalyticsHandler";
import { IPerformance } from "../../src/engines/helio/HelioPerformanceAggregator";
import { BufferEventTypes, IHeartbeatMessage, IApplicationErrorMessage, IEVT, ISES, IFragmentInfo } from "./IMessages";
import { SECONDS_IN_YEAR, MAX_BIT_RATE } from "../PlayerPlatformConstants";


// Some Analytics have fields which are recommended. Test a value for validity.
function validateNumber(val: number, legalMin: number, legalMax: number): boolean {
    return (!isNaN(val) && val >= legalMin && val <= legalMax);
}

// Some Analytics have fields which are required. Coalesce a value to a valid value.
function enforceNumber(val: number, legalMin: number, legalMax: number): number {
    let retValue: number;
    if (!isNaN(val)) {
        retValue = Math.max(legalMin, Math.min(legalMax, val));
    } else {
        retValue = legalMin;
    }
    return retValue;
}

export class BaseMessage {
    public APV: string;
    public APP: IApplicationInfo = {};
    public DEV: IDeviceInfo = {};
    public SES: ISES = {};
    public MONEY: IAnalyticsMoneyTrace;
    public EVT: IEVT;

    public static inHomeStateCallback: () => HomeState = () => HomeState.UNKNOWN;

    constructor(eventName: string, xuaAsset?: IXuaAsset) {
        this.EVT = {
            ETS: "",
            NAME: eventName,
            ASSET: xuaAsset,
            VALUE: {},
            NET: { IHS: BaseMessage.inHomeStateCallback() }
        };

        if (xuaAsset) {
            this.EVT.NET.SECURITY = xuaAsset.SECURITY;
        }
        this.setTimestamp(new Date());
    }

    public setTimestamp(eventTimestamp: Date): void {
        this.EVT.ETS = eventTimestamp.getTime().toString();
    }
}

export class HeartbeatMessage extends BaseMessage {
    constructor(config: IHeartbeatMessage) {

        super("xuaHeartBeat", config.xuaAsset);

        // Recommended but not required fields.
        this.validateAndSetPosition(config.currentPosition);
        this.validateAndSetBitrate(config.bitrate);
        this.validateAndSetFPS(config.fps);
        this.validateAndSetCC(config.cc);
        this.setFragmentInfo(config.fragmentInfo);
        this.setEnvInfo(config.envInfo);
        this.validateAndSetSAP(config.sap);

        if (Object.keys(this.EVT.VALUE).length === 0) {
            delete this.EVT.VALUE;
        }

    }

    private validateAndSetPosition(position: number): void {
        if (validateNumber(position, 0, SECONDS_IN_YEAR)) {
            this.EVT.VALUE.POS = position;
        }
    }

    private validateAndSetBitrate(bitrate: number): void {
        if (validateNumber(bitrate, 1, MAX_BIT_RATE)) {
            this.EVT.VALUE.XBR = bitrate;
        }
    }

    private validateAndSetFPS(fps: number): void {
        if (validateNumber(fps, 0, 60)) {
            this.EVT.VALUE.XFS = fps;
        }
    }

    private validateAndSetCC(cc: string): void {
        if (cc && (cc.length !== 0)) {
            this.EVT.VALUE.CC = cc;
        }
    }

    private validateAndSetSAP(sap: string): void {
        if (sap && (sap.length !== 0)) {
            this.EVT.VALUE.SAP = sap;
        }
    }

    private setFragmentInfo(fragmentInfo?: IFragmentInfo): void {
        if (fragmentInfo) {
            this.EVT.FRAGMENT = {
                NOF: fragmentInfo.count,
                FSZ: fragmentInfo.fragmentSize,
                LAT: 0, // we don't receive this info anymore
                DLD: fragmentInfo.downloadDuration,
                DUR: fragmentInfo.fragmentDuration
            };
        }
    }

    private setEnvInfo(envInfo?: string): void {
        if (envInfo) {
            this.EVT.ENV = envInfo;
        }
    }

}

export class PluginInitializedMessage extends BaseMessage {
    constructor() {
        super("xuaPluginInitialized");

        this.EVT.VALUE = {
            PLAYER_ID: "PlayerPlatformJS",
            PLAYER_VER: __viperversion,
            ORGANIZATION: "VIPER",
            NATIVE: false,
            SUPPORT_VOD: true,
            SUPPORT_LINEAR: true,
            SUPPORT_CDVR: true
        };
    }
}

/**
 * Core Event
 * Section 4.2.1 : xuaOpeningMedia
 */
export class OpeningMediaMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset, manifestUrl: string) {
        super("xuaOpeningMedia", xuaAsset);

        this.EVT.VALUE.MANIF = manifestUrl;
    }
}

/**
 * Core Event
 * Section 4.2.2 : xuaMediaOpened
 */
export class MediaOpenedMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset,
                channelOpenningLatency: number, currentPosition: number,
                manifestUrl: string, numAds: number = 0) {
        super("xuaMediaOpened", xuaAsset);
        this.EVT.VALUE.POS = enforceNumber(currentPosition, 0, SECONDS_IN_YEAR);
        this.EVT.VALUE.LAT = channelOpenningLatency;
        this.EVT.VALUE.MANIF = manifestUrl;
        this.EVT.VALUE.NUMADS = numAds;
    }
}

/**
 * Core Event
 * Section 4.2.3 : xuaMediaFailed
 */
export class MediaFailedMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset, code: number | string, description: string,
                errPosition: number, manifestUrl: string, adError: boolean,
                playerStatus: string) {

        super("xuaMediaFailed", xuaAsset);

        this.EVT.VALUE.CODE = code;
        this.EVT.VALUE.DESCR = description;
        this.EVT.VALUE.POS = enforceNumber(errPosition, 0, SECONDS_IN_YEAR);
        this.EVT.VALUE.MANIF = manifestUrl;
        this.EVT.VALUE.AD_ERROR = adError;
        if (playerStatus) {
            this.EVT.VALUE.STATUS = playerStatus;
        }
    }
}

/**
 * Core Event
 * Section 4.2.4 : xuaMediaEnded
 */
export class MediaEndedMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset) {
        super("xuaMediaEnded", xuaAsset);
    }
}

export class MediaInfoMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset, position: number, description: string) {
        super("xuaMediaInfo", xuaAsset);

        this.EVT.VALUE.POS = enforceNumber(position, 0, SECONDS_IN_YEAR);
        this.EVT.VALUE.DESCR = description;
    }
}

/**
 * Core Event
 * Section 4.2.5 : xuaBitrateChanged
 */
export class BitrateChangedMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset, bitrate: number) {
        super("xuaBitrateChanged", xuaAsset);

        this.EVT.VALUE.XBR = enforceNumber(bitrate, 0, MAX_BIT_RATE);
    }
}

/**
 * Core Event
 * Section 4.2.6 : xuaFPSChanged
 */
export class FPSChangedMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset, fps: number) {
        super("xuaFPSChanged", xuaAsset);
        this.EVT.VALUE.XFS = enforceNumber(fps, 1, 60);
    }
}

export class PlayStateChangedMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset, playerState: string) {
        super("xuaPlayStateChanged", xuaAsset);
        this.EVT.VALUE.VAL = playerState;
    }
}

/**
 * Core Event
 * Section 4.2.10 : xuaAdProgress
 */
export class AdProgressMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset, adProgress: number, adService: string | undefined, adId: string | undefined) {
        super("xuaAdProgress", xuaAsset);
        this.EVT.VALUE.VAL = enforceNumber(adProgress, 0, 100);
        this.EVT.VALUE.AD_SERVICE = adService ? adService : "Other";
        this.EVT.VALUE.AD_ID = adId ? adId : "Unknown";
    }
}

/**
 * Custom Event
 *               : xuaAdExited
 */
export class AdExitedMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset) {
        super("xuaAdExited", xuaAsset);
    }
}

/**
 * Custom Event
 *               : xuaAdBreakStart
 */
export class AdBreakStartMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset) {
        super("xuaAdBreakStart", xuaAsset);
    }
}

/**
 * Custom Event
 *               : xuaAdBreakComplete
 */
export class AdBreakCompleteMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset) {
        super("xuaAdBreakComplete", xuaAsset);
    }
}

export class AdBreakExitedMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset) {
        super("xuaAdBreakExited", xuaAsset);
    }
}

/**
 * Custom Event
 *               : xuaPerformance
 */
export class PerformanceMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset, latencyBreakdown: IPerformance) {
        super("xuaPerformance", xuaAsset);
        this.EVT.VALUE.LATENCY_BREAKDOWN = latencyBreakdown;
    }
}

/**
 * Core Event
 * Section 4.2.10 : xuaFragmentWarning
 */
export class FragmentWarningMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset, fragmentInfo: IFragmentInfo) {
        super("xuaFragmentWarning", xuaAsset);

        this.EVT.VALUE = {
            URL: fragmentInfo.fragmentUrl,
            FSZ: fragmentInfo.fragmentSize,
            LAT: 0, // we don't receive this info anymore
            DLD: fragmentInfo.downloadDuration,
            DUR: fragmentInfo.fragmentDuration
        };
    }
}

/**
 * Core Event
 * Section 4.2.11 : xuaError
 */
export class ApplicationErrorMessage extends BaseMessage {
    constructor(config: IApplicationErrorMessage) {

        super("xuaError", config.xuaAsset);

        this.EVT.VALUE.CODE = config.errorCode;
        this.EVT.VALUE.AD_ERROR = config.adError;
        this.EVT.VALUE.WARNING = config.warning;
        this.EVT.VALUE.MANIF = config.manifestUrl;

        if (config.errorClass) {
            this.EVT.VALUE.CLASS = config.errorClass;
        }
        if (config.errorDescription) {
            this.EVT.VALUE.DESCR = config.errorDescription;
        }
        if (config.retryCount) {
            this.EVT.VALUE.RETRY = config.retryCount;
        }
        if (config.fallback) {
            this.EVT.VALUE.FALLBACK = config.fallback;
        }
        if (config.fallbackType) {
            this.EVT.VALUE.FALLBACK_TYPE = config.fallbackType;
        }
        if (config.playerStatus) {
            this.EVT.VALUE.STATUS = config.playerStatus;
        }
    }
}

/**
 * Core Event
 * Section 4.2.13 : xuaEAS
 */
export class EmergencyAlertMessage extends BaseMessage {
    constructor(action: string, easLang: string, uriPath: string, errorCode?: string) {
        super("xuaEAS");

        this.EVT.VALUE.ACT = action;
        this.EVT.VALUE.LANG = easLang;
        this.EVT.VALUE.URI = uriPath;

        if (errorCode) {
            this.EVT.VALUE.CODE = errorCode;
        }
    }
}

/**
 * Trick Play Event
 * Section 4.3.1 : xuaTrickPlay
 */
export class TrickPlayMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset, currentPosition: number, trickPlayType: BufferEventTypes) {
        super("xuaTrickPlay", xuaAsset);

        this.EVT.VALUE.TYPE = trickPlayType;
        this.EVT.VALUE.POS = enforceNumber(currentPosition, 0, SECONDS_IN_YEAR);
    }
}

/**
 * Trick Play Event
 * Section 4.3.2 : xuaScrubStarted
 */
export class ScrubStartedMessage extends BaseMessage {
    constructor(currentPosition: number, xuaAsset: IXuaAsset) {
        super("xuaScrubStarted", xuaAsset);
        this.EVT.VALUE.POS = enforceNumber(currentPosition, 0, SECONDS_IN_YEAR);
    }
}

/**
 * Trick Play Event
 * Section 4.3.3 : xuaScrubEnded
 *
 */
export class ScrubEndedMessage extends BaseMessage {
    constructor(currentPosition: number, xuaAsset: IXuaAsset) {
        super("xuaScrubEnded", xuaAsset);
        this.EVT.VALUE.POS = enforceNumber(currentPosition, 0, SECONDS_IN_YEAR);
    }
}

/**
 * Buffer Start Event
 */
export class BufferStartMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset, betype: BufferEventTypes, position: number) {
        super("xuaBufferEvent", xuaAsset);
        this.EVT.VALUE.TYPE = betype;
        this.EVT.VALUE.STATE = "start";
        this.EVT.VALUE.POS = position;
    }

    public setTimestamp(date: Date): void {
        super.setTimestamp(date);
        this.EVT.VALUE.START = parseInt(this.EVT.ETS, 10);
    }
}

/**
 * Buffer Complete Event
 */
export class BufferCompleteMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset, betype: BufferEventTypes, startPosition: number, position: number) {
        super("xuaBufferEvent", xuaAsset);
        this.EVT.VALUE.TYPE = betype;
        this.EVT.VALUE.STATE = "stop";
        this.EVT.VALUE.START = startPosition;
        this.EVT.VALUE.POS = position;
    }
}


/**
 * PlaybackStarted Event : xuaPlaybackStarted
 */
export class PlaybackStartedMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset) {
        super("xuaPlaybackStarted", xuaAsset);
    }
}

/**
 * xuaDiagnosticEvent
 */
export class DiagnosticEventMessage extends BaseMessage {
    constructor(results: IDiagnosticTestResult[]) {
        super("xuaDiagnosticEvent");

        let totalDuration: number = 0;

        for (const result of results) {
            this.EVT.VALUE[result.test.name] = {
                SUCCESS: result.success,
                DURATION: result.duration
            };
            totalDuration += result.duration;
        }

        this.EVT.VALUE.DURATION = totalDuration;
    }
}

/**
 * xuaVStreamSwitch
 */
export class VStreamSwitchMessage extends BaseMessage {
    constructor(xuaAsset: IXuaAsset) {
        super("xuaVStreamSwitch", xuaAsset);
    }
}
