
type SimpleToLog = string | number | boolean;

/**
 * This is the simple interface for a log sink. The `Logger` will call
 * the `log` method for each message it receives.
 *
 * ### simple example of sending logs via XHR request
 *
 * ```
 *  function sendAjaxRequest(message) {
 *      // send message using ajax
 *  }
 *
 *  pp.addLogSink({
 *      log: function(level, name, messages) {
 *          let logLevelName = pp.LogLevel[level];
 *          let message = logLevelName + ": " + name + ": " + messages.join(", ");
 *          sendAjaxRequest(message);
 *      }
 *  });
 *
 * ```
 */
export interface ILoggerSink {
    log(level: LogLevel, name: string, msg: SimpleToLog[]): void;
}

/**
 * Available options to set when creating a new logger.
 */
export interface ILoggerOptions {
    level?: LogLevel;
    sinks?: ILoggerSink[];
}

/**
 * LogLevel enum. This matches jsnlog values for backwards compatibility.
 */
export enum LogLevel {
    ALL = -2147483648,
    TRACE = 1000,
    INFO = 3000,
    WARN = 4000,
    ERROR = 5000,
    FATAL = 6000,
    NONE = 2147483647
}

/**
 * Set the global logging level. This will be used unless a level is
 * set explicitly on an individual `Logger`.
 */
export function setLogLevel(level: LogLevel) {
    Logger.GLOBAL_LEVEL = level;
}

/**
 * Override the global array of `ILoggerSink`s. These will be used unless
 * the sinks are set explicitly on an individual `Logger`.
 */
export function setLogSinks(sinks: ILoggerSink[]) {
    Logger.GLOBAL_SINKS = sinks;
}

/**
 * Add a `ILoggerSink` to the global array of sinks.
 */
export function addLogSink(sink: ILoggerSink) {
    Logger.GLOBAL_SINKS.push(sink);
}

/**
 * ILoggerSink that outputs all messages to the browser console using the
 * appropriate console logging method.
 *
 * @hidden
 */
export class ConsoleSink implements ILoggerSink {

    public static funcMap: { [x: number]: (...whatToLog: SimpleToLog[]) => void } = {
        /*tslint:disable:no-console*/
        [LogLevel.TRACE]: console.debug.bind(console),
        [LogLevel.INFO]: console.info.bind(console),
        [LogLevel.WARN]: console.warn.bind(console),
        [LogLevel.ERROR]: console.error.bind(console),
        [LogLevel.FATAL]: console.error.bind(console, "FATAL!")
        /*tslint:enable:no-console*/
    };

    public log(level: LogLevel, name: string, msg: SimpleToLog[]): void {
        // TODO XRE-13412 is resolved we have to provide only one
        // argument to the native logging method. XRE fails to log
        // more than one argument to reveicer.log files on the set
        // top box otherwise as they only use the first argument
        ConsoleSink.funcMap[level](`${name}: ${msg.join(",")}`);
    }
}

/**
 * This logger class is meant to be used with `ILoggerSink`s to output
 * messages in the desired method. The default sink is a `ConsoleSink` which
 * will forward all messages to the appropriate console logging method. Individual
 * loggers can use their own sinks. If no sinks are set, this will use the global
 * setting.
 *
 * @hidden
 */
export class Logger {

    public static GLOBAL_LEVEL: LogLevel = LogLevel.TRACE;
    public static GLOBAL_SINKS: ILoggerSink[] = [new ConsoleSink()];

    private level: LogLevel;

    private _name: string;
    private _sinks: ILoggerSink[] = [];

    constructor(name: string, options: ILoggerOptions = {}) {
        this.level = options.level || Logger.GLOBAL_LEVEL;
        this._name = name;
        this._sinks = options.sinks || [];
    }

    private _log(level: LogLevel, msg: SimpleToLog[]) {
        const currentLevel = this.level;
        const currentSinks = this._sinks.length ? this._sinks : Logger.GLOBAL_SINKS;

        if (currentLevel === LogLevel.NONE) {
            return;
        }

        const timestampedMsg = [`${msg.slice()} : ${new Date().toTimeString()} `];

        if (currentLevel === LogLevel.ALL || level >= currentLevel) {
            currentSinks.forEach(sink => sink.log(level, this._name, timestampedMsg.slice()));
        }
    }

    public trace(...msg: SimpleToLog[]): void {
        this._log(LogLevel.TRACE, msg);
    }

    public info(...msg: SimpleToLog[]): void {
        this._log(LogLevel.INFO, msg);
    }

    public warn(...msg: SimpleToLog[]): void {
        this._log(LogLevel.WARN, msg);
    }

    public error(...msg: SimpleToLog[]): void {
        this._log(LogLevel.ERROR, msg);
    }

    public fatal(...msg: SimpleToLog[]): void {
        this._log(LogLevel.FATAL, msg);
    }
}
