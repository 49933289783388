import { IContentOptions, OttAuthCallback } from "../../../assets/ContentOptions";
import { BaseAsset } from "../../../assets/BaseAsset";
import { OttLocatorParser, IOttLocatorProperties, OttPlaybackTypes } from "./OttLocatorParser";

const OTT_PROVIDER_ESPN: string = "espn";

/**
 * This asset type is for 3rd party hosted assets that require player platform to authenticate with the 3rd party
 * before accessing the asset files.  The asset url provided to Asset.create will be a locator.  Authentication
 * occurs in a function provided to player platform by the host application.  The authentication function accepts
 * the locator and returns a promise with the resolved http url of the authenticated asset.
 *
 * see https://etwiki.sys.comcast.net/display/AAE/Third+Party+Hosted+IP+Stream+Authorization+and+Playback
 */
export class OttAsset extends BaseAsset {

    private static _locatorParser = new OttLocatorParser();

    private locatorProperties: IOttLocatorProperties;
    private provider: string;

    public ottAuthCallback?: OttAuthCallback;

    constructor(url: string, contentOptions: IContentOptions = {}) {
        super(url, contentOptions);

        this.ottAuthCallback = contentOptions.ottAuthCallback;
        this.locatorProperties = OttAsset._locatorParser.parse(url);
        this.provider = this.locatorProperties.provider;
        this.mediaGuid = this.locatorProperties.mediaguid;
        this.mediaAccountName = this.locatorProperties.mediaaccountname;
        this.providerId = this.locatorProperties.provider;
    }

    /**
     * Determines if this Ott asset is for use with ESPN
     */
    public isESPN(): boolean {
        return this.provider !== undefined && this.provider.toLowerCase() === OTT_PROVIDER_ESPN;
    }

    public assetTypeIsVod(): boolean {
        return this.locatorProperties !== undefined &&
            this.locatorProperties.playbacktype !== undefined &&
            this.locatorProperties.playbacktype.toLowerCase() === OttPlaybackTypes.Vod.toLowerCase();
    }

    public shouldStartFromLivePoint(_videoType: string): boolean {
        return false;
    }

    public getProvider(): string {
        return this.provider;
    }

    public getLocator(): IOttLocatorProperties {
        return this.locatorProperties;
    }

}
