
import { registerModule } from "../Application";
import { IPPModule, IPPSandbox } from "../PlayerPlatformApplication";
import { BaseAsset } from "../assets/BaseAsset";
import * as events from "../PlayerPlatformAPIEvents";
import { Logger } from "../util/Logger";
import * as constants from "../PlayerPlatformConstants";
import { PPError } from "../PPError";
import { HlsTag } from "../util/hls/HlsTag";
import { ConfigurationManager } from "../ConfigurationManager";
import { STATUS_ERROR, STATUS_IDLE, STATUS_PLAYING } from "../PlayerPlatformConstants";
import { buffer, filter, first, map, switchMap, takeUntil } from "rxjs/operators";
import { merge } from "rxjs";

/**
 * This module is used restrict playback based on Cross Stream Prevention tags in
 * the manifest. The main idea is to verify the stream ID on the `BaseAsset` matches the
 * CSP ID in the manifest. If there is a mismatch we error out and restrict playback.
 *
 * There are three settings available for CSP:
 *
 * - `None` - turn the feature completely off, ignoring CSP tags
 * - `CheckIfPresent` - fail on mismatches but allow playback if tag is missing in manifest
 * - `FailIfMissing` - fail on mismatches and do _not_ allow playback if tag is missing
 */
export class CrossStreamPreventionHandler implements IPPModule<CrossStreamPreventionHandler> {

    public static readonly CROSS_STREAM_PREVENTION_TAG: string = "#EXT-X-CONTENT-IDENTIFIER";
    public static readonly CROSS_STREAM_PREVENTION_MODE_NONE: string = "None";
    public static readonly CROSS_STREAM_PREVENTION_MODE_CHECKIFPRESENT: string = "CheckIfPresent";
    public static readonly CROSS_STREAM_PREVENTION_MODE_FAILIFMISSING: string = "FailIfMissing";

    private _logger: Logger = new Logger("CrossStreamPreventionHandler");
    private _sandbox: IPPSandbox;

    public init(sandbox: IPPSandbox): CrossStreamPreventionHandler {

        this._sandbox = sandbox;
        this._logger.trace(`Initializing CSP Handler`);
        sandbox.subscribe(constants.SET_ASSET, this.onSetAsset, constants.PRIORITY_MID, this);

        return this;
    }

    public destroy(sandbox: IPPSandbox): void {
        sandbox.remove(constants.SET_ASSET, this.onSetAsset);
    }

    private onSetAsset(asset: BaseAsset): void {
        const cspConfig: string = this._getCSPConfiguration(asset);
        if (this._canStartCheckIfPresent(cspConfig)) {
            asset.addSubscribedTag(CrossStreamPreventionHandler.CROSS_STREAM_PREVENTION_TAG);
            this._startCheckIfPresent();
        }
        if (this._canStartFailIfMissing(cspConfig)) {
            this._startFailIfMissing();
        }
    }

    private _isCSPTag(tag: HlsTag): boolean {
        return tag.name === CrossStreamPreventionHandler.CROSS_STREAM_PREVENTION_TAG;
    }

    private _parseContentId(tag: HlsTag): string {
        const content = tag.content;
        return content.substr(content.lastIndexOf(":") + 1);
    }

    private _startCheckIfPresent(): void {

        this._sandbox.streams.tags
            .pipe(
                filter((tag: HlsTag) => this._isCSPTag(tag)),
                map((tag: HlsTag) => this._parseContentId(tag)),
                filter((contentId: string) => contentId !== this._sandbox.asset.streamId),
                switchMap((contentId: string) => {
                    return this._sandbox.streams.getPlayState(constants.STATUS_INITIALIZED)
                        .pipe(
                            first(),
                            map(() => [contentId, this._sandbox.asset.streamId])
                        );
                }),
                takeUntil(merge(
                    this._sandbox.streams.getPlayState(STATUS_IDLE),
                    this._sandbox.streams.getPlayState(STATUS_PLAYING),
                    this._sandbox.streams.getPlayState(STATUS_ERROR),
                    this._sandbox.destroyed
                ))
            )
            .subscribe((checks: string[]) => {
                this._logger.error(`CSP failed, mismatch between asset.streamId ${checks[1]} and HLS content id ${checks[0]}`);
                this._sandbox.publish(constants.STOP);
                events.emit(new events.MediaFailedEvent(new PPError(8002, 1, "CSP failed, identifiers do not match", false)));
            });

    }

    private _startFailIfMissing(): void {

        this._sandbox.streams.tags
            .pipe(
                filter((tag: HlsTag) => this._isCSPTag(tag)),
                buffer(this._sandbox.streams.getPlayState(constants.STATUS_INITIALIZED)),
                filter((tags: HlsTag[]) => tags.length === 0),
                takeUntil(merge(
                    this._sandbox.streams.getPlayState(STATUS_IDLE),
                    this._sandbox.streams.getPlayState(STATUS_PLAYING),
                    this._sandbox.streams.getPlayState(STATUS_ERROR),
                    this._sandbox.destroyed
                ))
            )
            .subscribe(() => {
                this._logger.error("CSP failed, missing CSP tag");
                this._sandbox.publish(constants.STOP);
                events.emit(new events.MediaFailedEvent(new PPError(8002, 0, "CSP failed, missing CSP tag.", false)));
            });

    }

    private _getCSPConfiguration(asset: BaseAsset): string {
        return ConfigurationManager.getInstance().getByAssetType(ConfigurationManager.getInstance().getAssetType(asset),
                    ConfigurationManager.CROSS_STREAM_PREVENTION) || CrossStreamPreventionHandler.CROSS_STREAM_PREVENTION_MODE_NONE;
    }

    private _canStartCheckIfPresent(cspSetting: string): boolean {
        if (cspSetting === CrossStreamPreventionHandler.CROSS_STREAM_PREVENTION_MODE_CHECKIFPRESENT ||
            cspSetting === CrossStreamPreventionHandler.CROSS_STREAM_PREVENTION_MODE_FAILIFMISSING) {
            return true;
        }
        return false;
    }

    private _canStartFailIfMissing(cspSetting: string): boolean {
        if (cspSetting === CrossStreamPreventionHandler.CROSS_STREAM_PREVENTION_MODE_FAILIFMISSING) {
            return true;
        }
        return false;
    }
}

registerModule("CrossStreamPreventionHandler", CrossStreamPreventionHandler);
