import { PPError } from "../../PPError";
declare let global: any;

import * as constants from "../../PlayerPlatformConstants";
import * as events from "../../PlayerPlatformAPIEvents";
import { IPPModule, IPPSandbox } from "../../PlayerPlatformApplication";
import { registerModule, toObservable } from "../../Application";
import { ConfigurationManager } from "../../ConfigurationManager";
import { FlashPlayer } from "./FlashPlayer";
import { Logger } from "../../util/Logger";
import { create } from "../../util/hls/HlsTagFactory";
import { HlsTag } from "../../util/hls/HlsTag";
import { NetworkDownHandler } from "../../handlers/NetworkDownHandler";
import { empty, fromEvent, Subscription, BehaviorSubject } from "rxjs";
import { timeout, mapTo, filter, switchMap, take, takeUntil } from "rxjs/operators";
import { VideoAdBreak } from "../../ads/VideoAdBreak";
import { VideoAd } from "../../ads/VideoAd";
import { AdManagerKeys } from "../../ads/AdManagerFactory";
import { AdManager } from "../../ads/AdManager";
import { AdManagerTypes } from "../../ads/IAdConfig";

export const AD_BREAK_COMPLETE: string = "ads:adBreakComplete";
export const AD_BREAK_START: string = "ads:adBreakStart";
export const AD_COMPLETE: string = "ads:adComplete";
export const AD_PROGRESS: string = "ads:adProgress";
export const AD_START: string = "ads:adStart";
export const AD_VPAID: string = "ads:vpaid";

interface IFlashVPAIDEvent {
    type: events.VPAIDAdEventType;
}

/**
 * Media has encountered a warning that might impact video playback.
 * Flash specific version of MediaWarningEvent that includes resourceUrl
 * and runtimeCodeMessage
 */
export class FlashMediaWarningEvent extends events.MediaWarningEvent {
    /**
     * @param {PPError} error The error associated with this warning
     */
    constructor(public error: PPError, public resourceUrl: string, public runtimeCodeMessage: string) {
        super(error);
    }
}

export class FlashPlayerEvents implements IPPModule<FlashPlayerEvents> {

    private logger = new Logger("FlashPlayerEvents");
    private sandbox: IPPSandbox;
    private sizeEvent = { width: 0, height: 0 };
    private flashPlayer: FlashPlayer;
    private progressSubject: BehaviorSubject<events.MediaProgressEvent> = new BehaviorSubject<events.MediaProgressEvent>(null);
    private pendingCleanup: Subscription | null;

    public init(sandbox: IPPSandbox) {
        this.sandbox = sandbox;
        this.flashPlayer = sandbox.parent;

        if (this.pendingCleanup) {
            this.pendingCleanup.unsubscribe();
            this.pendingCleanup = null;
        }
        // set up as a global variable for External Interface calls
        global.flashPlayerEvents = this;

        this.initFlashInitialSeekWorkaroundForAuditude();
        this.workaroundForVPAIDPauseForAuditude();

        return this;
    }

    /**
     * As of adobe SWC version 1.4.32.859 when we performed
     * a seek when any flash ad metadata was used the seek
     * cause the following.
     * 1. Initial playback would begin at 0
     * 2. All segments would be requested until the resume point was reached
     * 3. Playback would then begin at resume point.
     *
     * This workaround manually emits a seek once playback has started.
     */
    private initFlashInitialSeekWorkaroundForAuditude(): void {

        this.sandbox.streams.getPlayState(constants.STATUS_PREPARED)
            .pipe(
                filter(() => this.flashPlayer.getAutoPlay()), // only if auto play
                switchMap(() => this.sandbox.streams.getPlayState(constants.STATUS_PLAYING).pipe(take(1))), // wait until first playing state
                filter(() => this.needsFlashAdManagerInitialSeekForAuditude()),
                takeUntil(this.sandbox.destroyed)
            )
            .subscribe(() => {
                this.logger.warn("Manually performing initial seek");
                this.flashPlayer.setPosition(this.sandbox.asset.resumePosition);
            });

    }

    /**
     * Determines if we will need the initial seek fix
     * with the current state. Any ad manager which
     * utilizes the internal flash code will need the fix.
     */
    private needsFlashAdManagerInitialSeekForAuditude(): boolean {
        return this.sandbox && // we have a sandbox
                this.sandbox.asset && this.sandbox.asset.resumePosition > 0 && // with an asset
                this.sandbox.adManager && this.sandbox.adManager.type === AdManagerKeys.AUDITUDE; // and an ad manager
    }

    public destroy() {
        this.logger.trace("destroy");
        // This needs to be the last one out so that other destroy's may fire
        // events.
        this.pendingCleanup = this.sandbox.streams.getPlayState(constants.STATUS_IDLE)
          .pipe(
              timeout(250),
              take(1)
          )
          .subscribe(() => delete global.flashPlayerEvents, () => delete global.flashPlayerEvents);
    }

    public onVPAID(event: IFlashVPAIDEvent): void {
        events.emit(new events.VPAIDAdEvent(event.type));
    }

    public onStateChanged(event: any) {
        this.logger.info("onStateChanged: " + event.state);

        if (event.state === this.flashPlayer.currentStatus) {
            return;
        }

        if (this.flashPlayer.currentStatus === constants.STATUS_STOPPING) {
            switch (event.state) {
                case constants.STATUS_IDLE:
                    event.state = constants.STATUS_COMPLETE;
                    this.logger.info("onStateChanged: stopping forces " + event.state);
                    break;
                case constants.STATUS_INITIALIZING:
                    // This should not occur but keeps an incomplete stop from preventing a new asset loading
                    this.logger.warn("onStateChanged: " + constants.STATUS_STOPPING + " to " + constants.STATUS_INITIALIZING);
                    break;
                default:
                    return;
            }
        }

        if (!this.vpaidAdOnTimelineWithAuditude() || event.state !== constants.STATUS_PAUSED) {
            this.flashPlayer.currentStatus = event.state;
            // THE INITIALIZED EVENT emitted here MUST happen prior
            // to `prepareToPlay` being called. There is a dependency
            // on this event happening for the underlying flash code.
            this.dispatch(new events.PlayStateChangedEvent(event.state));
        }

        const stateMap = {
          [constants.STATUS_INITIALIZED]: () => this.flashPlayer.prepareToPlay(),
          [constants.STATUS_PREPARED]: () => this.onPrepared(),
          [constants.STATUS_COMPLETE]: () => {
              this.progressSubject
                  .pipe(
                    take(1),
                    takeUntil(this.sandbox.destroyed)
                  )
                  .subscribe(progressEvent => this.dispatch(new events.MediaProgressEvent(
                      progressEvent.endposition,
                      progressEvent.playbackSpeed,
                      progressEvent.startposition,
                      progressEvent.endposition,
                      progressEvent.updateinterval
                  )));
              this.dispatch(new events.MediaEndedEvent());
          }
        };

        if (stateMap.hasOwnProperty(event.state)) {
            stateMap[event.state]();
        }
    }

    private onPrepared(): void {
        if (!(this.sandbox.adManager && this.sandbox.adManager.controlsInitialPlayback())) {
            this.flashPlayer.play();
        }
    }

    /**
     * Called by actionscript to resolve an opportunity
     */
    public doResolve(settings: object) {
        this.sandbox.publish("ads:resolve", settings);
    }

    /**
     * This is invoked by actionscript through ExternalInterface each time the timeline is updated.
     * A timeline is updated as we insert ads and as a live asset plays out. The provided timeline
     * always reflects what actionscript has on the timeline
     */
    public onTimelineUpdated(timeline: any): void {
        this.logger.trace("onTimelineUpdated", timeline);

        for (const line of timeline) {
            this.addAds(line.ads);
        }

        // Setup ad breaks with ad manager for freewheel
        if (this.sandbox.adManager && this.sandbox.adManager.type === AdManagerTypes.TVELINEAR) {
            this.sandbox.adManager.adBreaks = AdManager.sortIntoAdBreaks(this.sandbox.adManager.ads);
        }
    }

    public onMediaOpened(event: any) {
        this.logger.info("onMediaOpened: " + JSON.stringify(event));

        this.onTimelineUpdated(event.timeline);

        this.dispatch(new events.MediaOpenedEvent({
            mediaType: event.videoType,
            playbackSpeeds: event.playbackSpeeds,
            availableAudioLanguages: event.languages,
            width: event.width,
            height: event.height,
            openingLatency: event.openingLatency,
            hasDRM: event.hasDRM,
            hasCC: event.hasCC
        }));
    }

    public onProgress(event: any) {
        if (this.flashPlayer.getPlayerStatus() === constants.STATUS_COMPLETE) {
            return;
        }

        const progress = new events.MediaProgressEvent(
            event.time,
            event.rate,
            event.rangeBegin,
            event.rangeEnd,
            event.updateInterval
        );

        this.dispatch(progress);
        this.progressSubject.next(progress);
    }

    public onProfileChanged(event: any) {
        this.dispatch(new events.BitrateChangedEvent(
            event.profile, event.description, this.sizeEvent.width, this.sizeEvent.height
        ));
    }

    public onPlayerReady() {
        this.flashPlayer.setPlayerReady();
    }

    public onError(event: any): void {
        const ppError = this.getPPError(event);
        this.dispatch(new events.MediaFailedEvent(ppError, ppError.shouldRetry()));
    }

    private getPPError(event: any): PPError {
        const { runtimeCode, runtime_suberror_code, runtimeCodeMessage } = event.parameters;
        let major: number;
        let minor: number;

        if (runtime_suberror_code) {
            major = runtimeCode;
            minor = runtime_suberror_code;
        } else {
            major = event.code;
            minor = runtimeCode;
        }
        const code = `${major}.${minor}`;
        const description = event.description || runtimeCodeMessage;

        this.logger.info(`flash error observed: code=${code}, event=${JSON.stringify(event)}`);
        return new PPError(major, minor, description);
    }

    public onNetworkDown(event: any): void {
        this.sandbox.publish(NetworkDownHandler.CHANNEL_NAME, this.getPPError(event));
    }

    public onSegmentSkipped(event: any): void {
        this.dispatch(new events.MediaWarningEvent(this.getPPError(event)));
    }

    public onMediaWarning(event: any): void {
        this.dispatch(new FlashMediaWarningEvent(this.getPPError(event),
            event.parameters.resourceUrl,
            event.parameters.runtimeCodeMessage));
    }

    public onBufferStart() {
        this.dispatch(new events.BufferStartEvent());
    }

    public onBufferComplete() {
        this.dispatch(new events.BufferCompleteEvent());
    }

    public onSeekStart() {
        this.dispatch(new events.SeekStartEvent(this.flashPlayer.getCurrentPosition()));
    }

    public onSeekComplete() {
        this.dispatch(new events.SeekCompleteEvent(this.flashPlayer.getCurrentPosition()));
    }

    public onRatePlaying(event: any) {
        this.dispatch(new events.PlaybackSpeedChangedEvent(event.rate));
    }

    public onSize(event: any) {
        this.logger.trace("onSize: width: " + event.width + " height: " + event.height);
        this.sizeEvent.width = event.width;
        this.sizeEvent.height = event.height;
    }

    public onCaptionUpdated(event: any) {
        this.logger.trace("onCaptionUpdated");
        this.dispatch(new events.NumberOfClosedCaptionsStreamsChanged(event.tracks.length));

    }

    public onAudioUpdated(event: any) {
        this.logger.trace("onAudioUpdated");
        this.dispatch(new events.NumberOfAlternativeAudioStreamsChangedEvent(event.languages.length));

    }

    public onItemUpdated(timedMetadata: any[] = []) {

        this.sandbox.publish("player:timedMetadata", timedMetadata);

        timedMetadata
            .filter((metadata: any) => !!metadata.name)
            .map((metadata: any) => create(metadata.name, metadata.time, metadata.content))
            .forEach((tag: HlsTag) => this.sandbox.publish("player:tag", tag));
    }

    /**
     * Ad break start for auditude ads.
     * @param event
     */
    public onAdBreakStarted(event: any) {
        this.logger.trace("onAdBreakStarted");
        this.sandbox.publish(AD_BREAK_START, event);
    }

    /**
     * Ad break complete for auditude ads.
     * @param event
     */
    public onAdBreakCompleted(event: any) {
        this.logger.trace("onAdBreakCompleted");
        this.sandbox.publish(AD_BREAK_COMPLETE, event);
    }

    /**
     * Ad start for auditude ads.
     * @param event
     */
    public onAdStart(event: any) {
        this.logger.trace("onAdStart");
        this.sandbox.publish(AD_START, event);
    }

    /**
     * Ad complete for auditude ads.
     * @param event
     */
    public onAdComplete(event: any) {
        this.sandbox.publish(AD_COMPLETE, event);
    }

    /**
     * Ad progress for auditude ads.
     * @param event
     */
    public onAdProgress(event: any) {
        this.sandbox.publish(AD_PROGRESS, event);
    }

    public onLoadInfo(event: any) {
        this.dispatch(new events.FragmentInfoEvent(event.downloadDuration, event.size, event.url, event.mediaDuration));
    }

    public onDRMMetadataAvailable() {
        this.dispatch(new events.DRMMetadataEvent());
    }

    public onClick() {
        if (ConfigurationManager.getInstance().get(ConfigurationManager.HANDLE_CLICKS)) {
            this.sandbox.publish(constants.NOTIFY_CLICK);
        }
    }

    public addAds(ads: any) {
        for (const ad of ads) {
            let alreadyHaveAd: boolean = false;

            // Check if we have already added this
            if (this.sandbox.adManager) {
                for (const knownAd of this.sandbox.adManager.ads) {
                    if (knownAd.id === ad.id && knownAd.startTime === ad.time && knownAd.duration === ad.duration) {
                        this.logger.trace("Already have " + ad.id + " skipping");
                        alreadyHaveAd = true;
                        break;
                    }
                }
            }

            if (!alreadyHaveAd) {
                this.sandbox.publish("ads:ad", ad.id, ad.time, ad.duration, {
                    clickThrough: ad.clickThrough,
                    vpaid: ad.vpaid
                });
            }
        }
    }

    public addRestrictions(restrictions: any) {
        for (const restriction of restrictions) {
            this.sandbox.publish("ads:restriction", restriction.ADID, restriction.MODE, restriction.SCALE, restriction.LIMIT);
        }
    }

    public onOpportunitiesSent(opportunities: AdobePSDK.Opportunity[]) {
        this.sandbox.publish("ads:opportunitiesSent", opportunities);
    }

    public dispatch(playerEvent: events.PlayerPlatformAPIEvent) {
        try {
            events.dispatchEvent(playerEvent);
        } catch (error) {
            this.logger.error(error.toString() + ": " + error.stack);
        }
    }

    /**
     * Before playing a vpaid ad, flash player sends STATUS_PAUSED and then STATUS_PLAYING.
     * XTV does not want these change notification
     */
    private workaroundForVPAIDPauseForAuditude(): void {
        fromEvent(events, events.MEDIA_OPENED)
            .pipe(
                switchMap(() => {
                    // if we have a vpaid api:pause
                    if (this.vpaidAdOnTimelineWithAuditude()) {
                        return toObservable(constants.PAUSE).pipe(mapTo(constants.STATUS_PAUSED),
                            filter((state: string) => state !== this.flashPlayer.currentStatus));
                    } else {
                        return empty();
                    }
                }),
                takeUntil(this.sandbox.destroyed)
            )
            .subscribe((status: string) => {
                this.flashPlayer.currentStatus = status;
                this.dispatch(new events.PlayStateChangedEvent(status));
            });
    }

    /**
     * Returns true, if at least one vpaid ad is in the timeline
     * @returns {boolean}
     */
    private vpaidAdOnTimelineWithAuditude(): boolean {
        let retStatus: boolean = false;
        if (this.sandbox.adManager && this.sandbox.adManager.type === AdManagerKeys.AUDITUDE &&
            this.sandbox.getTimeline()
            .map((adBreak: VideoAdBreak) => adBreak.ads )
            .reduce((a: VideoAd[], b: VideoAd[]) => a.concat(b), [])
            .filter( (ad: VideoAd) => ad.vpaid )[0] ) {
            retStatus = true;
        }
        return retStatus;
    }
}

registerModule("FlashPlayerEvents", FlashPlayerEvents);
