import { BaseAsset } from "../../assets/BaseAsset";
import { IPlayerCCStyle } from "../../PlayerPlatformCCStyle";
import { IPPModule, IPPSandbox } from "../../PlayerPlatformApplication";
import { Logger } from "../../util/Logger";
import { SubResposne as SubResponse } from "publicious";
import * as constants from "../../PlayerPlatformConstants";

export abstract class BasePlayer implements IPPModule<BasePlayer> {

    public static readonly containerId: string = "viper-player-container";

    private playerReadyCallbacks: (() => void)[] = [];
    private subs: SubResponse[] = [];

    protected sandbox: IPPSandbox;
    protected logger: Logger;

    protected width: number;
    protected height: number;
    protected autoPlay: boolean;

    public player: any;
    public assetEngine: string;
    public playerReady = false;

    public init(sandbox: IPPSandbox, ..._args: any[]): BasePlayer {
        this.sandbox = sandbox;

        this.subs = [
            sandbox.subscribe(constants.PLAY, this.play, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.PAUSE, this.pause, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.STOP, this.stop, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_ASSET, this.setAsset, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_POSITION, this.setPosition, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_AUTOPLAY, this.setAutoPlay, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_VOLUME, this.setVolume, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_SPEED, this.setSpeed, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_BLOCK, this.setBlock, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_SCALE, this.setScale, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_VIDEO_DIMENSIONS, this.setDimensionsOfVideo, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_INITIAL_BITRATE, this.setInitialBitrate, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_BITRATE_RANGE, this.setBitrateRange, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_BITRATE_POLICY, this.setBitratePolicy, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_INITIAL_BUFFER_TIME, this.setInitialBufferTime, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_INTERSEGMENT_DELAY, this.setIntersegmentDelay, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_PLAYING_VOD_BUFFER_TIME, this.setPlayingVODBufferTime, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_PLAYING_LINEAR_BUFFER_TIME, this.setPlayingLinearBufferTime, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_PREFERRED_ZOOM_SETTING, this.setPreferredZoomSetting, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_PREFERRED_AUDIO_LANGUAGE, this.setPreferredAudioLanguage, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_CC_ENABLED, this.setClosedCaptionsEnabled, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_CC_STYLE, this.setClosedCaptionsStyle, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_CC_TRACK, this.setClosedCaptionsTrack, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_CURRENT_TIME_UPDATE_INTERVAL, this.setCurrentTimeUpdateInterval, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_OFFSET, this.setOffset, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SEEK_TO_LIVE, this.seekToLive, constants.PRIORITY_DEFAULT, this),
            sandbox.subscribe(constants.SET_AUDIO_ONLY, this.setAudioOnly, constants.PRIORITY_DEFAULT, this),

            // for when ads modify positioning
            sandbox.subscribe("ads:setPosition", this.setPosition, constants.PRIORITY_DEFAULT, this)
        ];

        return this;
    }

    public destroy(sandbox: IPPSandbox) {
        this.playerReady = false;

        this.stop();

        // remove all subscriptions
        this.subs.forEach((sub: SubResponse) => {
            sandbox.remove((sub.channel as any).namespace, sub.fn as () => void);
        });

        // remove all callbacks
        this.playerReadyCallbacks = [];
    }

    public onPlayerReady(callback: (...args: any[]) => void): void {
        if (this.playerReady) {
            // if it's already ready, execute callback immediately
            callback.call(this, this.player);
        } else {
            this.playerReadyCallbacks.push(callback);
        }
    }

    public setPlayerReady(): void {

        this.playerReady = true;

        for (const callback of this.playerReadyCallbacks) {
            if (callback && typeof callback === "function") {
                try {
                    callback.call(this, this.player);
                } catch (error) {
                    // need to call the rest of the callbacks if one fails.
                    this.logger.error("onPlayerReady callback failed: " + error);
                }
            }
        }

        // remove all callbacks
        this.playerReadyCallbacks = [];
    }

    public getAssetEngineType(): string {
        return this.assetEngine;
    }

    public getAutoPlay(): boolean {
        return this.autoPlay;
    }

    public getAvailableBitrates(): number[] {
        return [];
    }

    public getAvailableAudioLanguages(): string[] {
        return [];
    }

    public getAvailableClosedCaptionTracks(): string[] {
        return [];
    }

    public getBitrateRange(): number[] {
        return [];
    }

    public getBufferFilledLength() {
        return -1;
    }

    public getBufferTime() {
        return -1;
    }

    public getClosedCaptionsStatus() {
        return false;
    }

    public getCurrentAudioLanguage(): string {
        return null;
    }

    public getCurrentBitrate() {
        return 0;
    }

    public getCurrentClosedCaptionsStyle(): IPlayerCCStyle {
        return null;
    }

    public getCurrentClosedCaptionTrack(): string {
        return null;
    }

    public getCurrentDroppedFrames() {
        return -1;
    }

    public getCurrentFPS() {
        return 0;
    }

    public getCurrentPlaybackSpeed() {
        return -1;
    }

    public getCurrentPosition() {
        return 0;
    }

    public getDuration() {
        return -1;
    }

    public getEndPosition() {
        return -1;
    }

    public getInitialBitrate() {
        return -1;
    }

    public getPlayerStatus() {
        return "";
    }

    public getSeenCount() {
        return -1;
    }

    public getStartPosition() {
        return -1;
    }

    public getSupportedClosedCaptionsOptions(): any {
        return null;
    }

    public getSupportedPlaybackSpeeds(): number[] {
        return [];
    }

    public getVersion() {
        return "";
    }

    public getVideoHeight() {
        return -1;
    }

    public getVideoWidth() {
        return -1;
    }

    public getVideoType(): string {
        return "";
    }

    public getVolume(): number {
        return 0;
    }

    public hasCC() {
        return false;
    }

    public hasDRM() {
        return false;
    }

    public setAutoPlay(flag?: boolean): void {
        this.autoPlay = flag;
    }

    public setAsset(_asset?: BaseAsset): void { /* */ }
    public setVolume(_volume?: number): void { /* */ }
    public setPosition(_position?: number): void { /* */ }
    public seekToLive(): void { /* */ }
    public setBitratePolicy(_policy?: number): void { /* */ }
    public setBitrateRange(_min?: number, _max?: number): void { /* */ }
    public setBlock(_flag?: boolean): void { /* */ }
    public setClosedCaptionsEnabled(_flag?: boolean): void { /* */ }
    public setClosedCaptionsStyle(_style?: IPlayerCCStyle): void { /* */ }
    public setClosedCaptionsTrack(_track?: string): void { /* */ }
    public setCurrentTimeUpdateInterval(_interval?: number): void { /* */ }
    public setDimensionsOfVideo(_width?: number, _height?: number): void { /* */ }
    public setInitialBitrate(_initial?: number): void { /* */ }
    public setInitialBufferTime(_time?: number): void { /* */ }
    public setIntersegmentDelay(_time?: number): void { /* */ }
    public setOffset(_x?: number, _y?: number): void { /* */ }
    public setPlayingLinearBufferTime(_time?: number): void { /* */ }
    public setPlayingVODBufferTime(_time?: number): void { /* */ }
    public setPreferredAudioLanguage(_language?: string): void { /* */ }
    public setPreferredZoomSetting(_setting?: string): void { /* */ }
    public setScale(_x?: number, _y?: number): void { /* */ }
    public setSpeed(_speed?: number, _overshootCorrect?: number): void { /* */ }
    public setAudioOnly(_mute?: boolean): void { /* */ }
    public stop(): void { /* */ }
    public play(): void { /* */ }
    public pause(): void { /* */ }
}
