import { registerModule } from "../../Application";
import { IPPModule, IPPSandbox } from "../../PlayerPlatformApplication";
import { STATUS_IDLE, STATUS_INITIALIZED, STATUS_SEEKING } from "../../PlayerPlatformConstants";
import { Logger } from "../../util/Logger";
import { ProgressWindow } from "../../util/ProgressWindow";
import {
    emit,
    AdBreakStartEvent,
    AdBreakCompleteEvent,
    AdStartEvent,
    AdCompleteEvent,
    AdProgressEvent,
    AdExitedEvent,
    AdBreakExitedEvent,
    AdBreakUpcomingEvent,
    PlayerPlatformAPIEvent
} from "../../PlayerPlatformAPIEvents";
import { TimelineTracker } from "./TimelineTracker";
import { ITrackable } from "./ITrackable";
import { TrackerEventType, ITrackerEvent } from "./TrackerEvent";
import { VideoAdBreak } from "../VideoAdBreak";
import { VideoAd } from "../VideoAd";
import { from, never, Observable, merge } from "rxjs";
import { distinctUntilChanged, tap, filter, flatMap, takeUntil } from "rxjs/operators";

/**
 * This module starts tracking ads and firing ad events when it is initialized. It tracks
 * the ads that are returned by `AdManager.getTimelien()` which is exposed on the sandbox.
 * An instance of `TimelineTracker` is used to track ads by passing desired event types and
 * then mapping those to the appropriate ad events.
 *
 * The Observables are created in such a way that the following order of events is preserved:
 * 1. AdBreakStartEvent
 * 2. AdStartEvent
 * 3. AdProgressEvent
 * 4. AdCompleteEvent/AdExitedEvent
 * 5. AdBreakCompleteEvent/AdBreakExitedEvent
 */
export class AdTracker implements IPPModule<AdTracker> {

    public static logger: Logger = new Logger("AdTracker");

    private _sandbox: IPPSandbox;
    private _tracker: TimelineTracker;

    public init(sandbox: IPPSandbox, tracker?: TimelineTracker): AdTracker {
        this._sandbox = sandbox;

        const [start, progress] = this._setupTrackerObservables();
        tracker = this._tracker = tracker || new TimelineTracker(start, progress);

        // observable for ad complete/exit events first
        const adCompleteObs = tracker.trackCb(this._ads.bind(this), [TrackerEventType.COMPLETE, TrackerEventType.EXIT, TrackerEventType.INTERRUPT]);

        // observable for ads skipped by progress events
        const adSkippedObs = tracker.trackCb(this._ads.bind(this), [TrackerEventType.SKIPPED]);

        // observable for all adBreak events
        const adBreakObs = tracker.trackCb(this._adBreaks.bind(this), [TrackerEventType.COMPLETE, TrackerEventType.EXIT, TrackerEventType.INTERRUPT,
        TrackerEventType.START, TrackerEventType.ENTER, TrackerEventType.UPCOMING]);

        // observable for the rest of the ad events
        const adObs = tracker.trackCb(this._ads.bind(this), [TrackerEventType.START, TrackerEventType.ENTER, TrackerEventType.PROGRESS]);

        // merge the above observables, map to events and fire
        merge(adCompleteObs, adSkippedObs, adBreakObs, adObs)
            .pipe(
                takeUntil(sandbox.destroyed),
                flatMap(mapEvent),      // convert ITrackerEvent to PlayerPlatformAPIEvent
                filter(Boolean)        // filter out undefineds if returned by mapEvent
            )
            .subscribe(emit);       // fire event

        return this;
    }

    public destroy(): void {
        this._tracker.destroy();
    }

    private _setupTrackerObservables(): Observable<any>[] {

        const startObs = this._sandbox.streams.getPlayState(STATUS_INITIALIZED);
        const stopObs = this._sandbox.streams.getPlayState(STATUS_IDLE);

        const progWindow = ProgressWindow.createStopObservable(this._sandbox.streams.mediaProgresses, stopObs)
            .pipe(
                tap(progress => progress.seeking = this._sandbox.getPlayerStatus() === STATUS_SEEKING),
                distinctUntilChanged((x, y) => x.equals(y)),
                filter(filterDrift)
            );

        return [startObs, progWindow];
    }

    /**
     * Creates a VideoAdBreak observable from the adManager timeline
     */
    private _adBreaks(): Observable<VideoAdBreak> {
        return from<VideoAdBreak>(this._sandbox.getTimeline() || []);
    }

    /**
     * Creates a VideoAd observable by concatenating all ads from the ad breaks
     * retrieved from the timeline.
     */
    private _ads(progress: ProgressWindow): Observable<VideoAd> {
        const adBreaks = this._sandbox.getTimeline() || [];
        const ads = adBreaks.reduce((l: VideoAd[], r: VideoAdBreak) => l.concat(r.ads), []);
        return from<VideoAd>(progress.nextRate < 0 ? ads.reverse() : ads);
    }
}

interface IFromTrackerEvent<T extends ITrackable = ITrackable> {
    fromTrackerEvent(event: ITrackerEvent<T>): PlayerPlatformAPIEvent;
}

interface ITrackerEventMap<T extends ITrackable = ITrackable> { [x: number]: IFromTrackerEvent<T> | IFromTrackerEvent<T>[]; }

/**
 * Maps an `ITrackerEvent` to a `PlayerPlatformAPIEvent`
 */
function mapEvent(event: ITrackerEvent<VideoAdBreak | VideoAd>): Observable<PlayerPlatformAPIEvent> {

    const map: ITrackerEventMap = event.trackable instanceof VideoAdBreak ? VIDEO_AD_BREAK_EVENT_MAP : VIDEO_AD_EVENT_MAP;
    const fn = map[event.type];

    if (!fn) {
        AdTracker.logger.error(`A corresponding event was not found for tracking event ${TrackerEventType[event.type]}`);
        return never() as any;
    } else {
        // convert to array
        const arr: IFromTrackerEvent[] = [].concat(fn);

        // convert array items to PlayerPlatformAPIEvents
        return from<PlayerPlatformAPIEvent>(arr.map(val => val.fromTrackerEvent(event)));
    }
}

/**
 * This filters out progress events that "drift" backwards or forwards
 * during trickplay. "Drift" happens when the position reported by a
 * progress event moves in the opposite direction when trickplaying.
 */
function filterDrift(progWindow: ProgressWindow): boolean {
    if (progWindow.nextRate > 1) {
        return progWindow.prev < progWindow.next;
    }

    if (progWindow.nextRate < 0) {
        return progWindow.prev > progWindow.next;
    }

    return true;
}

const VIDEO_AD_EVENT_MAP: ITrackerEventMap<VideoAd> = {
    [TrackerEventType.START]: AdStartEvent,
    [TrackerEventType.ENTER]: AdStartEvent,
    [TrackerEventType.COMPLETE]: AdCompleteEvent,
    [TrackerEventType.EXIT]: AdExitedEvent,
    [TrackerEventType.INTERRUPT]: AdExitedEvent,
    [TrackerEventType.PROGRESS]: AdProgressEvent,
    [TrackerEventType.SKIPPED]: [AdStartEvent, AdCompleteEvent]
};

const VIDEO_AD_BREAK_EVENT_MAP: ITrackerEventMap<VideoAdBreak> = {
    [TrackerEventType.START]: AdBreakStartEvent,
    [TrackerEventType.ENTER]: AdBreakStartEvent,
    [TrackerEventType.COMPLETE]: AdBreakCompleteEvent,
    [TrackerEventType.EXIT]: AdBreakExitedEvent,
    [TrackerEventType.INTERRUPT]: AdBreakExitedEvent,
    [TrackerEventType.UPCOMING]: AdBreakUpcomingEvent
};

registerModule("AdTracker", AdTracker);
