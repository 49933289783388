"use strict";

/**
 * Given an object, copy all properties from all <code>sources</code>
 * objects into the <code>destination</code> object.
 *
 * Adapted from Underscore.js
 *
 * @param obj - destination object
 * @param {...object} sources - variable number of source objects
 * @returns {object}
 */
export function extend(obj: any, ..._: any[]): any {
    const length = arguments.length;
    if (length < 2 || !obj) {
        return obj;
    }
    for (let index = 1; index < length; index++) {
        const source = arguments[index];
        const keys = Object.keys(source);
        const l = keys.length;
        for (let i = 0; i < l; i++) {
            const key = keys[i];
            obj[key] = source[key];
        }
    }
    return obj;
}

/**
 * Given a url, parse its query parameters into an object
 *
 * @param {string} url
 * @returns {object}
 */
export function parseQueryParams(url: string): any {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const params: any = {};
    let match = regex.exec(url);

    while (match) {
        params[match[1]] = decodeURIComponent(match[2]);
        match = regex.exec(url);
    }

    return params;
}

/**
 * given a url and an object containing desired query parameters,
 * replace the query parameters on the url with the parameters
 * in the params object.
 *
 * @param {string} url
 * @param {object} params
 * @returns {string}
 */
export function replaceQueryParams(url: string = "", params: any): string {
    return `${url.split("?")[0]}?${toQueryParams(params)}`;
}

/**
 * Given an object, convert its key/value pairs into query parameters.
 *
 * @param {object} obj
 * @returns {string}
 */
export function toQueryParams(obj: any): string {
    return Object.keys(obj)
        .map((key: string) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join("&");
}

export function getQueryVariable(variable: string): string | undefined {
    const query = window.location.search.substring(1);
    const variables = query.split("&");
    for (const each of variables) {
        const pair: string[] = each.split("=");
        if (decodeURIComponent(pair[0]) === variable && pair[1]) {
            return decodeURIComponent(pair[1]);
        }
    }
}

export function keyForValue(obj: any, value: string): string {
    return Object.keys(obj).filter((key: string) => obj[key] === value)[0];
}

export function getNestedProperty(object: any, property: string | number): any {

    if (!object || typeof object !== "object") {
        return object;
    } else if (typeof property === "string") {
        property = property.replace(/\[(\d+)\]/g, ".$1");
        const split = property.split(".");
        return split.reduce(function(obj, prop) {
            return obj && obj[prop];
        }, object);
    } else if (typeof property === "number") {
        return object[property];
    } else {
        return object;
    }
}

export function arrayEquals(arr1: any[], arr2: any[]): boolean {
    if (!arr1 || !arr2) {
        return false;
    }
    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0, n = arr1.length; i < n; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
}

/**
 * GUIDcreate: mimics ActionScript GUIDUtil.create
 * @returns {String}
 */
export function createGUID(): string {
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

/**
 * Mimics strftime("%FT%T.%L%:z", new Date())
 * @param {object} timeval (optional)
 * @returns {String}
 */
export function formatLocalDateTimeStamp(timeval?: any): string {
    const now = timeval || new Date();
    const tzo: number = -now.getTimezoneOffset();
    const dif: string = tzo >= 0 ? "+" : "-";
    const pad = (num: number) => {
        const norm = Math.abs(Math.floor(num));
        return (norm < 10 ? "0" : "") + norm;
    };
    const padTwo = (num: number) => {
        const norm = Math.abs(Math.floor(num));
        return (norm < 10 ? "00" : (norm < 100 ? "0" : "")) + norm;
    };
    return now.getFullYear()
        + "-" + pad(now.getMonth() + 1)
        + "-" + pad(now.getDate())
        + "T" + pad(now.getHours())
        + ":" + pad(now.getMinutes())
        + ":" + pad(now.getSeconds())
        + "." + padTwo(now.getMilliseconds())
        + dif + pad(tzo / 60)
        + ":" + pad(tzo % 60);
}

export function imageSource(url: string): void {
    new Image().src = url;
}

