import { Logger } from "./Logger";

const PATTERN: RegExp = /trace-id=([A-F\d]{8}-[A-F\d]{4}-[A-F\d]{4}-[A-F\d]{4}-[A-F\d]{12});/i;

export interface IMoneyTraceOptions {
    traceId?: string;
    spanId?: string;
    parentId?: string;
}

/**
 * Encapsulates money trace functionality. If a money trace string is passed in,
 * the string string is parsed and the trace-id value is used. If it is not, a
 * money trace string (context) is generated.
 *
 * MoneyTrace's can be used in two ways, one as the origin, two as a node.
 * The way in which it's used depends on the arguments to the MoneyTrace
 * constructor.
 *
 * For Video fragments, the player HTML is not to be considered the originator
 * of the trace. For those traces, accept content options, and pass them
 * along.
 *
 *
 * @param {string|IMoneyTraceOptions} [context]
 * @returns {object}
 * @constructor
 */
export class MoneyTrace implements IMoneyTraceOptions {

    public parentId: string = "";
    public spanId: string = "";
    public traceId: string = "";

    private _logger = new Logger("MoneyTrace");

    constructor(context?: IMoneyTraceOptions | string) {
        if (typeof context === "string") {
            this._logger.warn("MoneyTrace string argument is deprecated.");
            this._parseTraceContext(context);
        } else if (typeof context === "object") {
            if (context.traceId) {
                this.traceId = context.traceId;
            }
            if (context.parentId) {
                this.parentId = context.parentId;
            }
            if (context.spanId) {
                this.spanId = context.spanId;
            } else {
                this.spanId = this.randomLong();
            }
        } else {
            this._generateTraceContext();
        }
    }

    /**
     * Create the money trace string out of traceId, parentId and spanId
     * To be used in the X-MoneyTrace header. Everytime this is called the trace
     * get's 'stepped' by generating new spanId & parentId.
     *
     * For SecClient, a valid MoneyTrace does not include a playbackCount, When
     * the noplaybackcount boolean (default false) is set to true, strip out the
     * appended playback count
     *
     * @param {boolean|false} [noplaybackcount]
     * @returns {string}
     */
    public createTraceMessage(noplaybackcount: boolean = false): string {
        let traceMessage;
        if (noplaybackcount) {
            const traceId = this.traceId.substring(0, this.traceId.lastIndexOf("-"));
            traceMessage = `trace-id=${traceId};parent-id=${this.parentId};span-id=${this.spanId};`;
        } else {
            traceMessage = `trace-id=${this.traceId};parent-id=${this.parentId};span-id=${this.spanId};`;
        }
        this.spanId = this.randomLong();
        return traceMessage;
    }

    /**
     * Produces a string containing the value of a int64_t (long long int)
     * The requirement from moneytrace explicitly referenced an int64_t (long long int)
     * as the data type. Javascript does not safely support this range as it is base 53.
     * As this is a random value, base 53 should be good enough.
     */
    private randomLong(): string {
        return "" + Math.floor((Math.random() < 0.5 ? -1 : 1) * Math.random() * (Math.pow(2, 53) - 1));
    }

    /**
     * If the context parameter is passed into the constructor, this
     * will try and parse out the trace-id using a regex pattern.
     * If the string cannot be parsed, a new money trace context will be generated.
     */
    private _parseTraceContext(context: string): void {
        const matches = PATTERN.exec(context);

        if (!matches || matches.length < 2) {
            this._logger.warn("Unable to parse trace context. Generating random money trace");
            this._generateTraceContext();
        } else {
            this._logger.warn("parent-id needed when trace is not an origin");
            this._generateTraceContext(matches[1]);
        }
    }

    /**
     * Generates trace values using either the trace-id passed in or a newly generated one.
     * The spanId is created anew.
     */
    private _generateTraceContext(id: string = generateUUID()): void {
        this.traceId = id;
        this.parentId = "0";
        this.spanId = this.randomLong();
    }

}

export function generateUUID(): string {
    let d = new Date().getTime();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
    });
}
