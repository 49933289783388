/**
 * This overlay is used as a anomaly modal with XRE.
 * To show and hide this modal XRE directly calls out to the DOM and assumes there
 * is an element with an id of anomalyModal. The display state of that modal is then toggled
 * between none and block. We start this overlay with display none and assume XRE will manually
 * call to show the modal on their end.
 *
 * XRE code to interact with this modal:
 *
 * QString evalOverlayDisplayStr = QString evalOverlayDisplayStr = QString("document.getElementById('anomalyModal').style.display == 'block'; null;");
 */

import { h, app } from "hyperapp";

/**
 * actions that manipulate state of the DOM
 */
export interface IOverlayActionsAnomaly {
  setAnomalyList: (logs: string[]) => void;
  setAnomalyStyle: (styles: number[]) => void;
}

/**
 * Displays the Anomaly logs
 */
const AnomalyLogs = ({ 
  anomalyList,
  anomalyStyle
 }) => {
  const outerStyle = {
    position: "absolute",
    border: "1px solid #2B363D",
    backgroundColor: "rgba(0,0,0,0.5)",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
    color: "#fff",
    textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    WebkitAnimationName: "animatetop",
    WebkitAnimationDuration: "0.4s",
    width: "90%",
    height: "auto",
    bottom: "2%",
    left: 0,
    right: 0,
    margin: "0 auto",
    paddingTop: "2px",
    textAlign: "left",
    font: "13px arial, sans-serif"
  };

  const redStyle = {
    color: "#FF5953"
  };

  const orangeStyle = {
    color: "#FFB21B"
  };

  const whiteStyle = {
    color: "#F5FDFF"
  };

  return (
    <div style={outerStyle}>
        <ul>
          {anomalyList.map(( log, index) => (
            <li
              style={
                anomalyStyle[index] === 0 ? redStyle : (anomalyStyle[index] === 1 ? orangeStyle : whiteStyle )
              }
            >
              {log}
            </li>
          ))}
        </ul>
    </div>
  );
};

/**
 * the entire view
 */
const viewAnomaly = state => {
  return (
    <div>
      <AnomalyLogs
       anomalyList={state.anomalyList}
       anomalyStyle={state.anomalyStyle}
      />
    </div>
  );
};

/**
 * defines actions that manipulate state
 */
const actionsAnomaly: IOverlayActionsAnomaly = {
  setAnomalyList: anomalyList => ({ anomalyList }),
  setAnomalyStyle: anomalyStyle => ({ anomalyStyle })
};

/**
 * attaches a hyperapp app to a DOM element and returns the actions
 */
export function attachAnomaly(elementAnomaly): IOverlayActionsAnomaly {
  return app({}, actionsAnomaly, viewAnomaly, elementAnomaly) as IOverlayActionsAnomaly;
}
