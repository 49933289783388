export const MILLISECONDS_PER_SECOND = 1000;
export const BYTES_PER_KILOBYTE = 1024;
export const SECONDS_IN_YEAR: number = 31536000;
export const MAX_BIT_RATE: number = 600000000;
export const FFWD_SET_POSITION_DELAY = 500;

export const ZOOM_SETTING_NONE = "none";
export const ZOOM_SETTING_FULL = "full";

// Player states
export const STATUS_IDLE = "idle";
export const STATUS_COMPLETE = "complete";
export const STATUS_ERROR = "error";
export const STATUS_INITIALIZED = "initialized";
export const STATUS_INITIALIZING = "initializing";
export const STATUS_PREPARING = "preparing";
export const STATUS_PREPARED = "prepared";
export const STATUS_PAUSED = "paused";
export const STATUS_PLAYING = "playing";
export const STATUS_READY = "ready";
export const STATUS_SEEKING = "seeking";
export const STATUS_RELEASED = "released";
export const STATUS_BUFFERING = "buffering";
export const STATUS_STOPPING = "stopping";
export const STATUS_STOPPED = "stopped";

export const enum VideoType {
    UNKNOWN = "unknown",
    LIVE = "live",
    VOD = "vod"
}

/* States that other engines besides PSDK might use */
export const STATUS_SEGMENT_END = "segment_ended";
export const PRIORITY_TOP: number = 0;
export const PRIORITY_HIGH: number = 1;
export const PRIORITY_MID: number = 2;
export const PRIORITY_LOW: number = 3;
export const PRIORITY_DEFAULT: number = 4;

/* FONTS */
export const COURIER_NEW_SERIF = "Courier New,serif";
export const COURIER_NEW = "Courier New";

/* ERRORS */
export const ERROR_NO_AD_INSTANCE = "Unable to find ad instance";
export const ERROR_UNSUPPORTED_IN_AAMP = ") is unsupported as its not required by AAMP!";

/* FREEWHEEL */
export const FW_REQUEST_TIMEOUT_SECONDS = 5;

/* API Constants*/
export const SET_ASSET = "api:setAsset";
export const SET_SPEED = "api:setSpeed";
export const PAUSE = "api:pause";
export const PLAY = "api:play";
export const STOP = "api:stop";
export const SET_AUTOPLAY = "api:setAutoPlay";
export const SWAP_ASSET = "api:swapAsset";
export const SET_VOLUME = "api:setVolume";
export const NOTIFY_CLICK = "api:notifyClick";
export const SET_POSITION = "api:setPosition";
export const ENGINE_SELECTED = "api:engineSelected";
export const PLAYER_CHECK = "api:playerCheck";
export const ENGINE_INITIALIZED = "api:engineInitialized";
export const PLAYERS_AVAILABLE = "api:playersAvailable";
export const UPDATE_PLAYER_CONFIG = "api:updatePlayerConfig";
export const SET_BLOCK = "api:setBlock";
export const DESTROY = "api:destroy";
export const SEEK_TO_LIVE = "api:seekToLive";
export const MEDIA_INFO = "api:mediaInfo";
export const SET_BITRATE_POLICY = "api:setBitratePolicy";
export const AD_MANAGER_SELECTED = "api:adManagerSelected";
export const CONFIGURE_ADS = "ads:configureAds";
export const METRIC_UPDATE = "api:metricUpdate";
export const PERFORMANCE_MESSAGE = "api:performanceMessage";
export const SET_SCALE = "api:setScale";
export const SET_CC_ENABLED = "api:setClosedCaptionsEnabled";
export const SET_VIDEO_DIMENSIONS = "api:setDimensionsOfVideo";
export const SET_INITIAL_BITRATE = "api:setInitialBitrate";
export const SET_BITRATE_RANGE = "api:setBitrateRange";
export const SET_INITIAL_BUFFER_TIME = "api:setInitialBufferTime";
export const SET_INTERSEGMENT_DELAY = "api:setIntersegmentDelay";
export const SET_PLAYING_VOD_BUFFER_TIME = "api:setPlayingVODBufferTime";
export const SET_PLAYING_LINEAR_BUFFER_TIME = "api:setPlayingLinearBufferTime";
export const SET_PREFERRED_ZOOM_SETTING = "api:setPreferredZoomSetting";
export const SET_PREFERRED_AUDIO_LANGUAGE = "api:setPreferredAudioLanguage";
export const SET_CC_STYLE = "api:setClosedCaptionsStyle";
export const SET_CC_TRACK = "api:setClosedCaptionsTrack";
export const SET_CURRENT_TIME_UPDATE_INTERVAL = "api:setCurrentTimeUpdateInterval";
export const SET_OFFSET = "api:setOffset";
export const SET_AUDIO_ONLY = "api:setAudioOnly";
export const SET_XACT = "api:setXact";
