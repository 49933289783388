import { registerModule } from "../../Application";
import { IPPModule, IPPSandbox } from "../../PlayerPlatformApplication";
import * as events from "../../PlayerPlatformAPIEvents";
import * as constants from "../../PlayerPlatformConstants";
import { Logger } from "../../util/Logger";

declare let global: any;

/**
 * Initialize the XRE CC Handler event system and register listeners
 *
 * XRECCHandler is responsible for listening to PlayerPlatform events (to turn
 * CC on/off), and an engine event for the decoder handle, in both cases
 * sending this to the XRE client on the STB. Currently this module is used by
 * PSDKPlayer, but eventually will need to be used by Helio also.
 *
 * Future support for this module will include allowing applications to query
 * the XRE client for the CC status. As of now each app will need to manage
 * this state.
 *
 * @constructor
 */
export class XRECCHandler implements IPPModule<XRECCHandler> {

    private logger: Logger = new Logger("XRECCHandler");
    public ignoreNullDecoder: boolean = false;

    public init(sandbox: IPPSandbox) {
        sandbox.subscribe(constants.SET_CC_ENABLED, this.setClosedCaptionsEnabled, constants.PRIORITY_DEFAULT, this);
        sandbox.subscribe("xre:decoderAvailable", this.onDecoderAvailable, constants.PRIORITY_DEFAULT, this);
        sandbox.subscribe(constants.STOP, this.stopCC, constants.PRIORITY_TOP, this);
        events.addEventListener(events.MEDIA_ENDED, this.stopCC, constants.PRIORITY_TOP, this);

        // This is temporary due to a bug in the Receiver & PSDK where an unentitled
        // tune dispatches multiple drm errors, PlayerPlatform then reports multiple
        // null decoderHandle events and gets into a recursive situation
        this.ignoreNullDecoder = global._xrePlayerPlatform !== null;

        return this;
    }

    public destroy(sandbox: IPPSandbox) {
        sandbox.remove(constants.SET_CC_ENABLED, this.setClosedCaptionsEnabled);
        sandbox.remove("xre:decoderAvailable", this.onDecoderAvailable);
        sandbox.remove(constants.STOP, this.stopCC);
        events.removeEventListener(events.MEDIA_ENDED, this.stopCC);
    }

    /**
     * setClosedCaptionsEnabled is an event handler for playerPlatformAPI's
     * setClosedCaptionsEnabled function call. In non-XRE applications, when
     * a customer needs to show/hide closed captions, PlayerPlatform needs
     * to notify the XRE client, since the XRE client is doing all the CC
     * rendering (see onDecoderAvailable for more info on that process). Even
     * though the app is non-XRE controlled, the XRE client (Receiver) still
     * manages the app process, and the CC presentation.
     *
     * @param {boolean} enable show/hide the closed caption rednering done by
     *                         the XRE client.
     * @returns {void}
     * @private
     *
     */
    private setClosedCaptionsEnabled(enable: boolean): void {
        this.logger.trace("XRE:onClosedCaptions");
        if (global.XREReceiver) {
            global.XREReceiver.onEvent("onClosedCaptions", { enable });
        }
    }

    private stopCC(): void {
        this.onDecoderAvailable(null);
    }

    /**
     * onDecoderAvailable is an event handler that receives a JavaScript
     * event from an engine. In the current case, PSDK on the STB uses
     * the Broadcom NEXUS or Intel ISMD APIs to play video. This address needs
     * to reach the Receiver to be able to present 608 captions found in the
     * video stream.
     *
     * @param {Number} decoderHandle the address of a NEXUS_VideoDecoderHandle
     * @returns {void}
     * @private
     *
     */
    private onDecoderAvailable(decoderHandle: number): void {
        this.logger.trace(decoderHandle ? "XRE:onDecoderAvailable" : "stopClosedCaption");
        if (global.XREReceiver) {
            if (decoderHandle === null && this.ignoreNullDecoder) {
                return;
            }
            global.XREReceiver.onEvent("onDecoderAvailable", { decoderHandle });
        }
    }

}

registerModule("XRECCHandler", XRECCHandler);
