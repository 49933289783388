
import "../../handlers/CrossStreamPreventionHandler";
import "../../handlers/NetworkDownHandler";
import "../../handlers/VirtualStreamStitcherHandler";
import "./AAMPPlayerEvents";
import { IPPSandbox } from "../../PlayerPlatformApplication";
import { registerModule } from "../../Application";
import { BaseAsset, AssetTypeMapping } from "../../assets/BaseAsset";
import { BasePlayer } from "../base/BasePlayer";
import { Logger } from "../../util/Logger";
import * as constants from "../../PlayerPlatformConstants";
import * as urlService from "../../services/urlService/URLService";
import { SessionManager } from "../../handlers/SessionManager";
import { XREPlayerPlatform } from "../../xre/XREPlayerPlatform";
import { ConfigurationManager } from "../../ConfigurationManager";
import { attach, IOverlayActions } from "./debugOverlay";
import { attachAnomaly, IOverlayActionsAnomaly } from "./anomalyOverlay";
import { XREEventManager } from "../../xre/XREEventManager";

declare const _xrePlayerPlatform: XREPlayerPlatform;
declare const __viperversion: string;

/**
 * Frequency which AAMP will provide player updates
 */
export const UPDATE_INTERVAL_MS: number = 250;

export enum AAMPPlayerState {
    IDLE,
    INITIALIZING,
    INITIALIZED,
    PREPARING,
    PREPARED,
    BUFFERING,
    PAUSED,
    SEEKING,
    PLAYING,
    STOPPING,
    STOPPED,
    COMPLETE,
    ERROR,
    RELEASED
}

export const AAMP_PLAYERSTATE_MAP: { [x: number]: string } = {
    [AAMPPlayerState.IDLE]: constants.STATUS_IDLE,
    [AAMPPlayerState.INITIALIZING]: constants.STATUS_INITIALIZING,
    [AAMPPlayerState.INITIALIZED]: constants.STATUS_INITIALIZED,
    [AAMPPlayerState.PREPARING]: constants.STATUS_PREPARING,
    [AAMPPlayerState.PREPARED]: constants.STATUS_PREPARED,
    [AAMPPlayerState.BUFFERING]: constants.STATUS_BUFFERING,
    [AAMPPlayerState.PAUSED]: constants.STATUS_PAUSED,
    [AAMPPlayerState.SEEKING]: constants.STATUS_SEEKING,
    [AAMPPlayerState.PLAYING]: constants.STATUS_PLAYING,
    [AAMPPlayerState.STOPPING]: constants.STATUS_STOPPING,
    [AAMPPlayerState.STOPPED]: constants.STATUS_STOPPED,
    [AAMPPlayerState.COMPLETE]: constants.STATUS_COMPLETE,
    [AAMPPlayerState.ERROR]: constants.STATUS_ERROR,
    [AAMPPlayerState.RELEASED]: constants.STATUS_RELEASED
};

declare const global: any;

export class AAMPPlayer extends BasePlayer {

    private asset: BaseAsset;
    private playerVersion = "unavailable";

    private offsetx: number = 0;
    private offsety: number = 0;
    private scaleW: number = 1.0;
    private scaleH: number = 1.0;
    private playerState: AAMPPlayerState;
    private currentLanguage: string;
    // availableLanguages being undefined represents a state before tune operation
    private availableLanguages?: string[];
    private volume: number = 1;
    private currentPosition: number = 0;
    private totalDuration: number = 0;
    private startPosition: number = 0;
    private endPosition: number = 0;
    private currentPlaybackSpeed: number = 0;
    private supportedPlaybackSpeeds: number[] = XREEventManager.PLAYBACK_SPEEDS;
    private availableBitrates: number[] = [];
    private hasDrm: boolean = false;
    private currentBitrate: number = 0;
    private debugOverlay?: IOverlayActions;
    private anomalyOverlay?: IOverlayActionsAnomaly;
    private maxRetryLimit: number = 0;
    private currentRetryCount: number = 0;
    private anomalyList: string[];
    private anomalyStyle: number[];

    protected logger: Logger = new Logger("AAMPPlayer");

    public startTime: number = 0;

    public get player(): AAMPNative.AAMP {
       return global.AAMP as AAMPNative.AAMP;
    }

    public init(sandbox: IPPSandbox) {
        super.init(sandbox);

        this.width = 1280;
        this.height = 720;
        this.scaleW = 1.0;
        this.scaleH = 1.0;
        this.offsetx = 0;
        this.offsety = 0;
        this.anomalyList = [];
        this.anomalyStyle = [];

        this.reset();
        this.initPlayer();
        this.playerState = AAMPPlayerState.IDLE;

        this.setPlayerReady();

        return this;
    }

    public destroy(sandbox: IPPSandbox): void {
        super.destroy(sandbox);

        this.stop();
    }

    /**
     * Reset all state variables back to initial state
     * prior to playback starting or anything loaded for
     * an asset
     */
    private reset(): void {
        this.availableBitrates = [];
        this.availableLanguages = undefined;
        this.currentBitrate = 0;
        this.currentLanguage = "";
        this.currentPlaybackSpeed = 0;
        this.currentPosition = 0;
        this.endPosition = 0;
        this.hasDrm = false;
        this.startPosition = 0;
        this.totalDuration = 0;
        this.supportedPlaybackSpeeds = XREEventManager.PLAYBACK_SPEEDS;
    }

    /**
     * AAMPPlayer Functions
     */
    private initPlayer(): void {

        this.logger.trace("initPlayer");

        // AAMP is attached to window on certain RDK builds
        // it is never included as a javascript library.
        if (global.AAMP === undefined) {
            // If AAMP is not defined nothing will work
            // exit early after logging
            this.logger.error("initPlayer: AAMP is undefined");
            return;
        }

        // only attach the overlay once
        // and re-use it if we initialize twice
        if (this.debugOverlay === undefined) {
            //Call the function to inject the overlay widget
            this.insertDebugOverlay();
        }

        if (this.anomalyOverlay === undefined) {
            //Call the function to inject the anomaly widget
            this.insertAnomalyOverlay();
        }

        //insert Viper version and URL into the overlay
        if (this.debugOverlay !== undefined) {
            this.debugOverlay.setViperVersion(__viperversion);
            const locURL = String(window.location.href.match(/.+?(?=\?)/));
            this.debugOverlay.setViperURL(locURL);
        }

        //Required for XRE-10360
        this.player.toString = () => {
            return "[object _AAMP_MediaPlayer]";
        };

        /*
         * AAMPPlayer Engine Version stats
         * 1.0 - Initial AAMPPlayer Engine
         * 2.0 - Includes server side DAI support
         */

        if (this.player.version) {
            this.playerVersion = "AAMP_VERSION=" + this.player.version;
        } else {
            this.playerVersion = "AAMP_VERSION=UNAVAILABLE";
        }
        this.logger.info("initPlayer: " + this.playerVersion);

        // In order to set playback position reporting interval
        this.player.reportInterval = UPDATE_INTERVAL_MS;

        const configMgr = ConfigurationManager.getInstance();

        if (typeof configMgr.get(ConfigurationManager.LICENSE_SERVER_URL) !== "undefined") {
            //In order to set the URL of the server for license requests
            try {
                this.player.setLicenseServerURL(configMgr.get(ConfigurationManager.LICENSE_SERVER_URL));
            } catch (error) {
                this.logger.warn("initPlayer: Error occurred while setting LicenseServerURL: " + JSON.stringify(error));
            }
        }

        if (typeof configMgr.get(ConfigurationManager.PREFERRED_DRM) !== "undefined") {
            //For setting the Preferred DRM
            try {
                this.player.setPreferredDRM(configMgr.get(ConfigurationManager.PREFERRED_DRM));
            } catch (error) {
                this.logger.warn("initPlayer: Error occurred while setting PreferredDRM: " + JSON.stringify(error));
            }
        }

        // Update the value of maxRetryLimit
        this.maxRetryLimit = configMgr.getByAssetType(AssetTypeMapping.DEFAULT, ConfigurationManager.MAXIMUM_RETRIES);
    }

    public play(): void {
        this.logger.trace("play");
        // Avoid play being invoked prematurely during channel change. setAsset invokes play internally
        if (this.playerState === AAMPPlayerState.PAUSED) {
            this.player.setRate(1);
        }
    }

    public pause(): void {
        this.logger.trace("pause");
        this.player.setRate(0);
    }

    public stop(): void {
        this.logger.trace("stop");
        this.reset();
        this.player.stop();
    }

    public seekToLive(): void {
        this.logger.trace("seekToLive");
        this.player.seekToLive();
    }

    private getContentType(): string {
        return _xrePlayerPlatform.contentOptions.extendedPlaybackMode || _xrePlayerPlatform.contentOptions.playbackMode;
    }

    private getSessionUUID(): string {
        return _xrePlayerPlatform.contentOptions.metricLogUUID;
    }

    public setAsset(asset: BaseAsset): void {

        this.logger.trace("setAsset: asset=" + JSON.stringify(asset));

        this.reset();

        // If a new URL comes, reset the retry count
        if (this.asset) {
            const origURL = this.asset.originalUrl;
            if (origURL.localeCompare(asset.originalUrl) !== 0) {
                this.currentRetryCount = 0;
            }
        }

        this.asset = asset;

        //To set seek position in case AAMPPlayer was not initialized at that time or on retry following a failed playback attempt
        if (this.sandbox.asset.resumePosition > 0) {
            this.logger.trace("Asset resumePosition:" + this.sandbox.asset.resumePosition);
            this.player.seek(this.sandbox.asset.resumePosition / 1000.00);
        }

        this.asset.addSubscribedTag("#EXT-X-TARGETDURATION");
        try {
            this.player.setSubscribeTags(this.asset.subscribedTags);
        } catch (error) {
            this.logger.warn("setAsset: Error occurred while setting subscribedTags: " + JSON.stringify(error));
        }
        this.logger.warn("setAsset: asset.url=" + this.asset.url);

        // addCustomHTTPHeader is not supported in AAMP player version 2.1 or older
        try {

            this.player.addCustomHTTPHeader("X-MoneyTrace", [
                SessionManager.instance.moneyTrace.traceId,
                SessionManager.instance.moneyTrace.parentId,
                SessionManager.instance.moneyTrace.spanId
            ]);
            SessionManager.instance.moneyTrace.createTraceMessage();

        } catch (error) {
            this.logger.warn("setAsset: Error occurred while adding custom X-MoneyTrace HTTP header: " + JSON.stringify(error));
        }

        this.startTime = Date.now();

        this.callTune();

        if (this.debugOverlay !== undefined) {
            this.debugOverlay.setUrl(this.asset.url);
            this.debugOverlay.setManifestType(this.asset.getUrlType());
        }

        this.currentPlaybackSpeed = 1;
    }

    public setBitrateRange(min: number, max: number): void {

        /*
         * AAMP doesn't support this API right now.
         */
        this.logger.warn("AAMPPlayer.setBitrateRange(" + min + ", " + max + constants.ERROR_UNSUPPORTED_IN_AAMP);
    }

    public setBlock(flag: boolean): void {
        this.logger.trace("setBlock: " + flag);
        if (flag === true) {
            this.player.setAudioVolume(0);
            this.player.setVideoMute(true);
        } else {
            this.player.setAudioVolume(this.volume * 100);
            this.player.setVideoMute(false);
        }
    }

    public setBufferControlParameters(initial: number, playback: number): void {

        /*
         * AAMP doesn't support this API right now.
         */
        this.logger.warn("AAMPPlayer.setBufferControlParameters(" + initial + ", " + playback + constants.ERROR_UNSUPPORTED_IN_AAMP);
    }

    public setClosedCaptionsEnabled(flag: boolean): void {

        /*
         * AAMP doesn't include ClosedCaptions support.
         * CC is handled by ClosedCaption module in XRE Native Receiver (in STB).
         */

        this.logger.warn("AAMPPlayer.setClosedCaptionsEnabled(" + flag + ") is unsupported by AAMP");
    }

    public setClosedCaptionsTrack(track: string): void {

        /*
         * AAMP doesn't include ClosedCaptions support.
         * CC is handled by ClosedCaption module in XRE Native Receiver (in STB).
         */

        this.logger.warn("AAMPPlayer.setClosedCaptionsTrack(" + track + ") is unsupported by AAMP");
    }

    public setCurrentTimeUpdateInterval(interval: number): void {

        /*
         * AAMP doesn't support this API right now.
         */
        this.logger.warn("AAMPPlayer.setCurrentTimeUpdateInterval(" + interval + constants.ERROR_UNSUPPORTED_IN_AAMP);
    }

    public setDimensionsOfVideo(width: number, height: number): void {
        this.logger.trace("setDimensionsOfVideo: offset " + this.offsetx + "x" + this.offsety + "wxh " + width + "x" + height + " scale " + this.scaleW + "x" + this.scaleH );
        this.player.setRect(this.offsetx * this.scaleW, this.offsety * this.scaleH, width * this.scaleW, height * this.scaleH);
    }

    public setInitialBitrate(initialBitrate: number): void {

        /*
         * AAMP doesn't support this API right now.
         */
        this.logger.warn("AAMPPlayer.setInitialBitrate(" + initialBitrate + constants.ERROR_UNSUPPORTED_IN_AAMP);
    }

    public setPosition(msecs: number): void {
        this.logger.trace("setPosition: " + msecs);
        this.player.seek(msecs / 1000.00);
    }

    public setPositionRelative(msecs: number): void {
        this.logger.trace("setPositionRelative: " + msecs);
        const position: number = this.currentPosition + msecs;
        this.setPosition(position);
    }

    public setPreferredAudioLanguage(language: string): void {
        this.logger.trace("setPreferredAudioLanguage: " + language);
        if (this.availableLanguages === undefined) {
            // When availableLanguages is undefined AAMP needs to set the language in-order to send it to native
            this.currentLanguage = language;
            this.player.setLanguage(this.currentLanguage);
        } else {
            // When availableLanguages list is not empty
            const len = this.availableLanguages.length;
            if (len > 0) {
                let langFound: boolean = false;
                for (let langCount = 0; langCount < len; langCount++) {
                    if (this.availableLanguages[langCount] === language) {
                        langFound = true;
                        this.currentLanguage = language;
                        this.player.setLanguage(this.currentLanguage);
                        break;
                    }
                }
                if (langFound === false) {
                    // If user provided language is not available in availableLanguages list
                    this.logger.warn("Requested language " + language + " not available in this asset " + this.availableLanguages);
                    return;
                }
            } else {
                this.logger.warn("Language list is empty ");
            }
        }
    }

    public setPreferredZoomSetting(setting: string): void {
        this.logger.trace("setPreferredZoomSetting " + setting);
        this.player.setZoom(setting);
    }

    public setSpeed(speed: number, overshootCorrection: number): void {
        this.logger.trace("setSpeed: spd=" + speed + " ovr=" + overshootCorrection);
        this.player.setRate(speed, overshootCorrection);
    }

    public setVolume(volume: number): void {
        this.logger.trace("setVolume: " + volume);
        this.volume = volume;
        this.player.setAudioVolume(volume * 100);
    }

    public setInitialBufferTime(msecs: number): void {

        /*
         * AAMP doesn't support this API right now.
         */
        this.logger.warn("AAMPPlayer.setInitialBufferTime(" + msecs + constants.ERROR_UNSUPPORTED_IN_AAMP);
    }

    public setPlayingVODBufferTime(msecs: number): void {

        /*
         * AAMP doesn't support this API right now.
         */
        this.logger.warn("AAMPPlayer.setPlayingVODBufferTime(" + msecs + constants.ERROR_UNSUPPORTED_IN_AAMP);
    }

    public setPlayingLinearBufferTime(msecs: number): void {

        /*
         * AAMP doesn't support this API right now.
         */
        this.logger.warn("AAMPPlayer.setPlayingLinearBufferTime(" + msecs + constants.ERROR_UNSUPPORTED_IN_AAMP);
    }

    public setScale(w: number, h: number): void {
        this.logger.trace("setScale: " + w + "x" + h + " current " + this.width + "x" + this.height);
        this.scaleH = h;
        this.scaleW = w;
    }

    public setOffset(x: number, y: number): void {
        this.logger.trace("setOffset: x=" + x + "y=" + y);
        if ((x >= 1280) || (y >= 720)) {
            this.logger.warn("setOffset: Reset offsets. set x=0 y=0");
            this.offsetx = 0;
            this.offsety = 0;
        } else {
            this.offsetx = x;
            this.offsety = y;
        }
    }

    public setTrickplayMaxFps(maxFps: number): void {

        /*
         * AAMP doesn't support this API right now.
         */
        this.logger.warn("AAMPPlayer.setTrickplayMaxFps(" + maxFps + constants.ERROR_UNSUPPORTED_IN_AAMP);
    }

    public setAudioOnly(mute: boolean): void {
        this.logger.trace("setAudioOnly: " + mute);
        this.player.setVideoMute(mute);
    }

    public getVideoHeight(): number {
        return this.height;
    }

    public getVideoWidth(): number {
        return this.width;
    }

    public getVideoType(): string {
        return this.player.mediaType;
    }

    public getAvailableBitrates(): number[] {
        this.logger.trace("getAvailableBitrates: " + this.availableBitrates);
        return this.availableBitrates || [];
    }

    public getAvailableAudioLanguages(): string[] {
        this.logger.trace("getAvailableAudioLanguages: " + this.availableLanguages);
        return this.availableLanguages;
    }

    public getPlayerStatus(): string {
        return AAMP_PLAYERSTATE_MAP[this.playerState];
    }

    public getPlayerState(): number {
        return this.playerState;
    }

    public getCurrentAudioLanguage(): string {
        return this.player.audioLanguage || this.currentLanguage;
    }

    public getCurrentBitrate(): number {
        return this.currentBitrate;
    }

    public getVersion(): string {
        return this.playerVersion;
    }

    public getCurrentPosition(): number {
        this.logger.trace("currentPosition :" + this.currentPosition);
        return this.currentPosition;
    }

    public getDuration(): number {
        this.logger.trace("duration :" + this.totalDuration);
        return this.totalDuration;
    }

    public getStartPosition(): number {
        this.logger.trace("StartPosition :" + this.startPosition);
        return this.startPosition;
    }

    public getEndPosition(): number {
        this.logger.trace("EndPosition :" + this.endPosition);
        return this.endPosition;
    }

    public getCurrentPlaybackSpeed(): number {
        this.logger.trace("currentPlaybackSpeed :" + this.currentPlaybackSpeed);
        return this.currentPlaybackSpeed;
    }

    public getSupportedPlaybackSpeeds(): number[] {
        this.logger.trace("SupportedPlaybackSpeeds :" + this.supportedPlaybackSpeeds);
        return this.supportedPlaybackSpeeds;
    }

    public getVolume(): number {
        return this.volume;
    }

    public hasDRM(): boolean {
        return this.hasDrm;
    }

    public setMetaDataInfo(event: AAMPNative.MetadataEvent): void {
        this.availableLanguages = event.languages;
        this.totalDuration = event.durationMiliseconds;
        this.availableBitrates = event.bitrates;
        this.width = event.width;
        this.height = event.height;
        this.hasDrm = Boolean(event.hasDrm);
        if (event.playbackSpeeds !== undefined) {
            this.supportedPlaybackSpeeds = event.playbackSpeeds;
        } else {
            this.supportedPlaybackSpeeds = XREEventManager.PLAYBACK_SPEEDS;
        }

        if (this.debugOverlay !== undefined) {
            this.debugOverlay.setBitrates(this.availableBitrates);
            this.debugOverlay.setContentType(this.getContentType());
        }
    }

    public showDRMType(): void {
        if (this.debugOverlay !== undefined) {
            this.logger.trace("showDRMType: currentDRM = " + this.player.currentDRM);
            this.debugOverlay.setDrmType(this.player.currentDRM);
        }
    }

    public setProgressMetaData(position: number, duration: number, start: number, end: number): void {
        this.currentPosition = position;
        this.totalDuration = duration;
        this.startPosition = start;
        this.endPosition = end;
    }

    public setPlaybackSpeed(speed: number): void {
        this.currentPlaybackSpeed = speed;
    }

    public setPlayerState(state: number): void {
        // If successful playback, then reset the retry count
        if (state === AAMPPlayerState.PLAYING) {
            this.currentRetryCount = 0;
        }

        this.playerState = state;
    }

    public setBitrate(bitrate: number): void {
        this.currentBitrate = bitrate;
        if (this.debugOverlay !== undefined) {
            this.debugOverlay.setCurrentBitrate(bitrate);
        }
    }

    public updateAnomalyWidget(description: string, severity: number): void {
        this.logger.trace("updateAnomalyWidget called to update Anomaly Modal");
        if (this.anomalyList.length === 15) {
            this.anomalyList.shift();
            this.anomalyList.push(description);
            this.anomalyStyle.shift();
            this.anomalyStyle.push(severity);
        } else {
            this.anomalyList.push(description);
            this.anomalyStyle.push(severity);
        }
        this.logger.trace("updateAnomalyWidget anomalyList: " + this.anomalyList + "anomalyStyle: " + this.anomalyStyle);
        if (this.anomalyOverlay !== undefined) {
            this.anomalyOverlay.setAnomalyList(this.anomalyList);
            this.anomalyOverlay.setAnomalyStyle(this.anomalyStyle);
        }
    }

    /**
     * The function invokes tune according to the AAMP version
     */
    private callTune(): void {
        if (parseFloat(this.player.version) > 2.3) {
            // For AAMP Version 2.4 onwards pass 4 arguments to the tune() API
            if (this.asset.isRetry) {
                // Increment currentRetryCount by 1
                this.currentRetryCount++;
            }

            const isInitialAttempt = !Boolean(this.asset.isRetry || this.asset.isRollback);
            const isFinalAttempt = (this.currentRetryCount >= this.maxRetryLimit);

            if (parseFloat(this.player.version) === 2.4) {
                this.player.tune(urlService.getURLForPlayback(this.asset.url, this.asset),
                    this.getContentType(),
                    isInitialAttempt,
                    isFinalAttempt);
            } else {
                const tuneParams: AAMPNative.TuneParamsObject = {
                    contentType: this.getContentType(),
                    isInitialAttempt: isInitialAttempt,
                    isFinalAttempt: isFinalAttempt,
                    sessionUUID: this.getSessionUUID()
                };
                this.player.load(urlService.getURLForPlayback(this.asset.url, this.asset), tuneParams);
            }

        } else if (parseFloat(this.player.version) === 2.3) {
            // For AAMP Version 2.3 pass optional arg content type to the tune() API
            this.player.tune(urlService.getURLForPlayback(this.asset.url, this.asset), this.getContentType());
        } else {
            // For AAMP Version upto 2.2 send only the URL to the tune() API
            this.player.tune(urlService.getURLForPlayback(this.asset.url, this.asset));
        }
    }

    /**
     * The function makes an overlay widget and injects it into the HTML page
     */
    private insertDebugOverlay(): void {
        this.logger.trace("insertDebugOverlay called and attached overlayModal");
        this.debugOverlay = attach(document.getElementById("overlayModal"));
    }

    /**
     * The function makes an anomaly widget and injects it into the HTML page
     */
    private insertAnomalyOverlay(): void {
        this.logger.trace("insertAnomalyOverlay called and attached anomalyModal");
        this.anomalyOverlay = attachAnomaly(document.getElementById("anomalyModal"));
    }
}

registerModule("AAMPPlayer", AAMPPlayer, {
    children: [
        "CrossStreamPreventionHandler",
        "VirtualStreamStitcherHandler",
        "AAMPPlayerEvents"
    ]
});
