
import * as events from "../../PlayerPlatformAPIEvents";
import { IPPModule, IPPSandbox } from "../../PlayerPlatformApplication";
import { registerModule } from "../../Application";
import { AAMPPlayer, AAMPPlayerState, AAMP_PLAYERSTATE_MAP, UPDATE_INTERVAL_MS } from "./AAMPPlayer";
import { Logger } from "../../util/Logger";
import { PPError } from "../../PPError";
import { create } from "../../util/hls/HlsTagFactory";
import { HlsTag } from "../../util/hls/HlsTag";

const CONTENT_UNAUTHORIZED = 40;

declare const global: any;

export class EventListener {
    public object: any;
    public type: string;
    public handler: (event: object) => void;
}

export class AAMPPlayerEvents implements IPPModule<AAMPPlayerEvents> {

    private logger = new Logger("AAMPPlayerEvents");
    private sandbox: IPPSandbox;
    private aamp: AAMPPlayer;

    private listeners: EventListener[] = [];

    public init(sandbox: IPPSandbox) {
        this.sandbox = sandbox;
        this.aamp = sandbox.parent;
        this.initEvents();
        return this;
    }

    public destroy() {
        this.logger.trace("destroy");

        while (this.listeners.length > 0) {
            const lastListener: EventListener = this.listeners.pop();
            if (lastListener) {
                lastListener.object.removeEventListener(lastListener.type, lastListener.handler);
            }
        }
    }

    private addListener(object: any, type: string, handler: (event: object) => void) {
        const newHandler = handler.bind(this);
        object.addEventListener(type, newHandler, null);
        const trio: EventListener = { object: object, type: type, handler: newHandler };
        this.listeners.push(trio);
    }

    private initEvents() {
        this.logger.trace("initEvents");
        this.addListener(this.aamp.player, "tuned", this.onTuned);
        this.addListener(this.aamp.player, "tuneFailed", this.onTuneFailed);
        this.addListener(this.aamp.player, "speedChanged", this.onSpeedChanged);
        this.addListener(this.aamp.player, "eos", this.onPlaybackEnded);
        this.addListener(this.aamp.player, "playlistIndexed", this.onPlaylistIndexed);
        this.addListener(this.aamp.player, "progress", this.onProgress);
        this.addListener(this.aamp.player, "decoderAvailable", this.onDecoderAvailable);
        this.addListener(this.aamp.player, "jsEvent", this.onJSEvent);
        this.addListener(this.aamp.player, "metadata", this.onMetadata);
        this.addListener(this.aamp.player, "enteringLive", this.onEnteringLive);
        this.addListener(this.aamp.player, "bitrateChanged", this.onBitrateChanged);
        this.addListener(this.aamp.player, "timedMetadata", this.onTimedMetadata);
        this.addListener(this.aamp.player, "statusChanged", this.onStatusChanged);
        //speedsChanged event is most likely triggered if iframe availability changes mid-playback
        //We don't have any use-case for this scenario right now. Hence omitting it out.
        this.addListener(this.aamp.player, "drmMetadata", this.onDRMMetadata);
        this.addListener(this.aamp.player, "anomalyReport", this.onAnomalyReport);
    }

    private onTuned() {
        this.logger.trace("[AAMP_JS_EVENT] event onTuned");
        global.XREReceiver.onEvent("onTuned", {});
        this.aamp.showDRMType();
    }

    private onTuneFailed(event: AAMPNative.TuneFailedEvent) {
        this.logger.trace("[AAMP_JS_EVENT] event onTuneFailed");
        const ppErr = new PPError(event.code, null, event.description);
        let isRetry: boolean = (event.code !== CONTENT_UNAUTHORIZED);
        if (event.shouldRetry !== undefined) {
            isRetry = (isRetry && event.shouldRetry);
        }
        events.dispatchEvent(new events.MediaFailedEvent(ppErr, isRetry));
    }

    private onSpeedChanged(event: AAMPNative.SpeedChangedEvent) {
        this.logger.trace("[AAMP_JS_EVENT] event onSpeedChanged speed=" + event.speed + " reason=" + event.reason);
        this.aamp.setPlaybackSpeed(event.speed);
        events.dispatchEvent(new events.PlaybackSpeedChangedEvent(event.speed));
    }

    private onPlaybackEnded() {
        this.logger.trace("[AAMP_JS_EVENT] event onEOS");
        events.dispatchEvent(new events.MediaEndedEvent());
    }

    private onPlaylistIndexed() {
        this.logger.trace("[AAMP_JS_EVENT] event onPlaylistIndexed");
    }

    private onProgress(event: AAMPNative.ProgressEvent) {
        const playerEvent = new events.MediaProgressEvent(
            event.positionMiliseconds,
            event.playbackSpeed,
            event.startMiliseconds,
            event.endMiliseconds,
            UPDATE_INTERVAL_MS);
        this.aamp.setProgressMetaData(event.positionMiliseconds, event.durationMiliseconds, event.startMiliseconds, event.endMiliseconds);
        events.dispatchEvent(playerEvent);
    }

    private onDecoderAvailable(event: AAMPNative.DecoderAvailableEvent) {
        this.logger.trace("[AAMP_JS_EVENT] event onDecoderAvailable decoderHandle=" + event.decoderHandle);
        global.XREReceiver.onEvent("onDecoderAvailable", { decoderHandle : event.decoderHandle });
    }

    private onJSEvent() {
        this.logger.trace("[AAMP_JS_EVENT] event onJSEvent");
    }

    private onMetadata(event: AAMPNative.MetadataEvent) {
        this.logger.trace("[AAMP_JS_EVENT] event onMetadata " + JSON.stringify(event));

        const len = event.languages.length;
        const langs3: string[] = [];

        for (let i = 0; i < len; i++) {
            langs3.push(event.languages[i]);
        }

        this.aamp.setMetaDataInfo(event);

        const width = event.width || 1280;
        const height = event.height || 720;
        const openingLatency = (Date.now() - this.aamp.startTime);
        const hasDRM = Boolean(event.hasDrm);

        const playerEvent = new events.MediaOpenedEvent({
            mediaType: this.aamp.getVideoType(),
            playbackSpeeds: this.aamp.getSupportedPlaybackSpeeds(),
            availableAudioLanguages: langs3,
            width: width,
            height: height,
            openingLatency: openingLatency,
            hasDRM: hasDRM,
            hasCC: true
        });
        events.dispatchEvent(playerEvent);

    }

    private onEnteringLive() {
        this.logger.trace("[AAMP_JS_EVENT] event onEnteringLive");
        this.sandbox.publish("xre:onenteringlive");
    }

    private onBitrateChanged(event: AAMPNative.BitrateChangedEvent) {
        this.logger.trace("[AAMP_JS_EVENT] event onBitrateChanged at time: " + event.time);
        this.aamp.setBitrate(event.bitRate);
        const playerEvent = new events.BitrateChangedEvent(event.bitRate, //bitrate
            event.description, //change reason
            event.width,       //width
            event.height);     //height
        events.dispatchEvent(playerEvent);
    }

    private onTimedMetadata(event: AAMPNative.TimedMetadataEvent) {
        this.logger.trace("[AAMP_JS_EVENT] onTimedMetadata time: " + event.timedMetadata.time);
        this.sandbox.publish("player:timedMetadata", event.timedMetadata);

        try {
            const metadata = event.timedMetadata;
            this.logger.trace("onTimedMetadata metadata: ", JSON.stringify(metadata));
            const hlsTag: HlsTag = create(metadata.name, metadata.time, metadata.content);
            this.sandbox.publish("player:tag", hlsTag);
        } catch (error) {
            this.logger.error("[AAMP_JS_EVENT] Error occurred while responding to timed metadata event: ");
        }
    }

    private onStatusChanged(event: AAMPNative.StatusChangedEvent) {
        this.logger.trace("[AAMP_JS_EVENT] onStatusChanged status: " + event.state);

        //Temp workaround for timedMetaData not getting processed before initialized state change
        const playState = event.state;

        if (playState === AAMPPlayerState.INITIALIZED || playState === AAMPPlayerState.PREPARING) {
            return;
        } else if (playState === AAMPPlayerState.PREPARED) {
            this.aamp.setPlayerState(AAMPPlayerState.INITIALIZED);
            events.dispatchEvent(new events.PlayStateChangedEvent(AAMP_PLAYERSTATE_MAP[AAMPPlayerState.INITIALIZED]));
            this.aamp.setPlayerState(AAMPPlayerState.PREPARING);
            events.dispatchEvent(new events.PlayStateChangedEvent(AAMP_PLAYERSTATE_MAP[AAMPPlayerState.PREPARING]));
        }

        if (this.aamp.getPlayerState() !== event.state) {
            this.aamp.setPlayerState(event.state);
            events.dispatchEvent(new events.PlayStateChangedEvent(AAMP_PLAYERSTATE_MAP[event.state]));
        }
    }

    private onDRMMetadata(event: AAMPNative.DRMMetadataEvent) {
        this.logger.trace("[AAMP_JS_EVENT] onDRMMetadata description: " + event.description + " code: " + event.code);
        const drmMetadataInfo = new Object();
        const drmAttribute = event.description;
        drmMetadataInfo[drmAttribute] = event.code;
        events.dispatchEvent(new events.DRMMetadataEvent(drmMetadataInfo));
    }

    private onAnomalyReport(event: AAMPNative.AnomalyReportEvent) {
        this.logger.trace("[AAMP_JS_EVENT] onAnomalyReport description: " + event.description + " severity: " + event.severity);
        this.aamp.updateAnomalyWidget(event.description, event.severity);
    }
}

registerModule("AAMPPlayerEvents", AAMPPlayerEvents);
