export const MPX_ERROR: number = 7510;
export const STALLED_ERROR: number = 7600;
export const VIRTUAL_STREAM_STITCHER_ERROR: number = 7700;
export const FREEWHEEL_VOD_ERROR: number = 7701;
export const FREEWHEEL_AUTO_PLAY_ERROR: number = 7702;

// Major Code
export const SEC_CLIENT_ERROR = 1;
export const MEDIA_ERROR = 2;

export enum VirtualStreamStitcherErrorCodes {
    NO_CALLBACK_PROVIDED = 1,
    CALLBACK_THREW_ERROR = 2,
    CALLBACK_TIMED_OUT = 3,
    CALLBACK_NOT_A_FUNCTION = 4,
    CALLBACK_ERROR = 5,
    NO_SERVICE_ZONE_TYPE_AVAILABLE = 6
}

// Sec Client Minor Code
export enum SecClientErrorCodes {
    NO_MANIFEST_ERROR = 1,
    UNFOUND_PSSH_ERROR = 2,
    KEY_SYSTEM_NOT_FOUND_ERROR = 3,
    CONTENT_META_DATA_NOT_FOUND_ERROR = 4
}

export enum MediaErrorCodes {
    MEDIA_TYPE_RETRY = 1
}

/**
 * Container class for errors generated by PlayerPlatform. An error should always have a major code
 * and a description but a minor code is optional.
 */
export class PPError {

    constructor(public major: number | string, public minor: number | string | null, public description: string, public isWarning: boolean = false) { }

    /**
     * retrieve the error code string with major and minor separated by
     * a period if a minor code is defined.
     *
     * @returns {string}
     */
    get code(): string {
        if (this.minor === undefined || this.minor === null || this.minor === "") {
            return `${this.major}`;
        } else {
            return `${this.major}.${this.minor}`;
        }
    }

    public toString(): string {
        if (this.description) {
            return `[${this.code}] ${this.description}`;
        } else {
            return `[${this.code}]`;
        }
    }

    public shouldRetry(): boolean {
        // Major Error 3329 are entitlement errors which should not be retried.
        const major = (typeof this.major === "string" ? parseInt(this.major, 10) : this.major);
        return major !== 3329 && major !== 412;
    }
}
