/**
 * Initialize the PSDK event system and register listeners
 *
 */

import * as constants from "../../PlayerPlatformConstants";
import * as events from "../../PlayerPlatformAPIEvents";
import { MEDIA_WARNING_TRICKMODE_DISALLOWED } from "../../PlayerPlatformAPIEvents";
import { IPPModule, IPPSandbox } from "../../PlayerPlatformApplication";
import { registerModule } from "../../Application";
import { PSDKPlayer } from "./PSDKPlayer";
import { XREError, XREErrorCode, getByErrorCode } from "./XREErrors";
import { Logger } from "../../util/Logger";
import { PPError } from "../../PPError";
import { create } from "../../util/hls/HlsTagFactory";
import { HlsTag } from "../../util/hls/HlsTag";
import { ConfigurationManager } from "../../ConfigurationManager";
import { NetworkDownHandler } from "../../handlers/NetworkDownHandler";
import { AdobeRuntimeWrapper } from "./AdobeRuntimeWrapper";
import { AdManager } from "../../ads/AdManager";

import { fromEvent, merge, Observable } from "rxjs";
import { takeUntil, filter, map } from "rxjs/operators";

interface IProgressParameters {
    position?: number;
    rate?: number;
}

// PSDKPlayerEvent Methods
/**
 * PSDKPlayerEvents
 * @constructor
 */
export class PSDKPlayerEvents implements IPPModule<PSDKPlayerEvents> {

    private logger = new Logger("PSDKPlayerEvents");
    private sizeEvent = { "width": 0, "height": 0 };
    private listeners: any[] = [];
    private lastRate: number;
    private lastProgressParams: IProgressParameters = { position: undefined, rate: undefined };
    private lastStatus: number;
    // PSDK's playing event fires prior to filling the buffer with the
    // fragment data. For better accuracy in the actual playing event we queue
    // up this PSDK event and only dispatch it once we receive the first rate
    // change event where rate === 1. The closest event would be the
    // onDecoderAvailable event, but because that's a Comcast extenstion,
    // we use the rate event which seems to fire within 1ms of the decoder
    // event. This allows the PSDK to buffer content, decrypt it, and obtain
    // a decoder handle from the underlying HW.
    private queuedPlayingEvent?: events.PlayStateChangedEvent;

    private sandbox: IPPSandbox;
    private psdk: PSDKPlayer;

    private _ignoreSeek: boolean;

    public init(sandbox: IPPSandbox) {
        this.sandbox = sandbox;
        this.psdk = sandbox.parent;
        this.initEvents();
        this.callPreparetoPlayStream();

        this._ignoreSeek = false;

        return this;
    }

    public destroy() {
        this.logger.trace("destroy");

        if (this.queuedPlayingEvent) {
            this.queuedPlayingEvent = undefined;
        }

        this.lastProgressParams.position = undefined;
        this.lastProgressParams.rate = undefined;

        while (this.listeners.length > 0) {
            const lastListener = this.listeners.pop();
            if (!!lastListener) {
                lastListener.object.removeEventListener(lastListener.type, lastListener.handler);
            }
        }
    }

    private addListener(object: any, type: string, handler: any) {
        const newHandler = handler.bind(this);
        object.addEventListener(type, newHandler);
        const trio = { object: object, type: type, handler: newHandler };
        this.listeners.push(trio);
    }


    private initEvents() {
        this.logger.trace("initEvents");
        this.addListener(this.psdk.player, AdobeRuntimeWrapper.PROFILE_CHANGED(), this.onProfileChanged);
        this.addListener(this.psdk.player, "bitrateChanged", this.onBitrateChanged);
        this.addListener(this.psdk.player, AdobeRuntimeWrapper.STATUS_CHANGED(), this.onStatusChanged);
        this.addListener(this.psdk.player, AdobeRuntimeWrapper.SIZE_AVAILABLE(), this.onSize);
        this.addListener(this.psdk.player, AdobeRuntimeWrapper.RATE_PLAYING(), this.onRatePlaying);
        this.addListener(this.psdk.player, AdobeRuntimeWrapper.LOAD_INFORMATION_AVAILABLE(), this.onLoadInfoAvailable);
        this.addListener(this.psdk.player, AdobeRuntimeWrapper.AUDIO_UPDATED(), this.onAudioUpdated);
        this.addListener(this.psdk.player, AdobeRuntimeWrapper.CAPTIONS_UPDATED(), this.onCaptionsUpdated);

        this.addListener(this.psdk.player, AdobeRuntimeWrapper.CC_DECODER_AVAILABLE_EVENT(), this.onDecoderAvailable);
        this.addListener(this.psdk.player, AdobeRuntimeWrapper.TIMED_METADATA_AVAILABLE(), this.onTimedMetadata);
        this.addListener(this.psdk.player, AdobeRuntimeWrapper.TIMELINE_UPDATED(), this.onTimelineUpdated);
        this.addListener(this.psdk.player, AdobeRuntimeWrapper.BUFFERING_BEGIN(), this.onBufferStart);
        this.addListener(this.psdk.player, AdobeRuntimeWrapper.BUFFERING_END(), this.onBufferComplete);

        merge(
            fromEvent(this.psdk.player, AdobeRuntimeWrapper.OPERATION_FAILED()),
            this.getRNG150OperationFaileds()
        ).pipe(
            map((event: AdobePSDK.cc_PSDKEventOperationFailed) => this.mapToEvent(event)),
            filter((event: AdobePSDK.cc_PSDKEventOperationFailed) => !this.shouldIgnore(event)),
            filter((event: AdobePSDK.cc_PSDKEventOperationFailed) => !this.isNetworkDownEvent(event)),
            map((event: AdobePSDK.cc_PSDKEventOperationFailed) => this.mapToPPError(event)),
            map((error: PPError) => this.mapToMediaEvent(error)),
            takeUntil(this.sandbox.destroyed)
        )
            .subscribe((event: events.PlayerPlatformAPIEvent) => events.dispatchEvent(event));

        merge(
            fromEvent(this.psdk.player, AdobeRuntimeWrapper.OPERATION_FAILED()),
            this.getRNG150OperationFaileds()
        ).pipe(
            map((event: AdobePSDK.cc_PSDKEventOperationFailed) => this.mapToEvent(event)),
            filter((event: AdobePSDK.cc_PSDKEventOperationFailed) => this.isNetworkDownEvent(event)),
            map((event: AdobePSDK.cc_PSDKEventOperationFailed) => this.mapToPPError(event)),
            takeUntil(this.sandbox.destroyed)
        )
            .subscribe((error: PPError) => this.sandbox.publish(NetworkDownHandler.CHANNEL_NAME, error));


        this.addListener(this.psdk.player, "drmError", this.onDrmError);

        this.addListener(this.psdk.player, AdobeRuntimeWrapper.DRM_METADATA_INFO_AVAILABLE(), this.onDRMMetadata);

        this.addListener(this.psdk.player, AdobeRuntimeWrapper.CC_ENTERING_LIVE_EVENT(), this.onEnteringLive);

        this.setupOnProgressSubscriber();
        this.setUpSeekSubscribers();
    }

    private callPreparetoPlayStream(): void {
        this.sandbox.streams.getPlayState(constants.STATUS_INITIALIZED)
            .pipe(
                takeUntil(this.sandbox.destroyed)
            )
            .subscribe(() => {
                if (this.sandbox.asset.shouldStartFromLivePoint(this.psdk.getVideoType())) {
                    this.sandbox.asset.resumePosition = -2;
                }
                this.logger.info(`onStatusChanged: PLAYER_STATUS_INITIALIZED. Calling prepareToPlay with resumePosition=${this.sandbox.asset.resumePosition}`);
                this.psdk.prepareToPlay();
            });
    }

    private mapToMediaEvent(error: PPError): events.PlayerPlatformAPIEvent {
        return error.isWarning ? new events.MediaWarningEvent(error) :
            new events.MediaFailedEvent(error, error.shouldRetry());
    }

    private mapToPPError(event: AdobePSDK.cc_PSDKEventOperationFailed): PPError {

        const nativeErrorCode: number = this.getNativeErrorCode(event);
        const subErrorCode: number = this.getNativeSubErrorCode(event);

        const xreError: XREError = getByErrorCode("" + nativeErrorCode);
        return new PPError(nativeErrorCode, subErrorCode, `${xreError.name} - ${xreError.description}`, this.isAVERetryingEvent(event));
    }

    /**
     * The RNG150 1.x does not send onOperationFailed
     * events. It's operation faileds come through as a
     * statusChanged event as PLAYER_STATUS_ERROR. If
     * we get an operationFailed we stop listening..
     */
    private getRNG150OperationFaileds(): Observable<any> {

       return fromEvent(this.psdk.player, AdobeRuntimeWrapper.STATUS_CHANGED())
                  .pipe(
                      filter((event: any) => this.getStatus(event) === AdobeRuntimeWrapper.STATUS_ERROR()),
                      takeUntil(fromEvent(this.psdk.player, AdobeRuntimeWrapper.OPERATION_FAILED()))
                  );

    }

    //Safe to call play after it's been prepared..
    private onPrepared() {
        this.logger.trace("onPrepared");
        this.logger.info("onPrepared dispatching MediaOpened event");

        this._ignoreSeek = false;

        this.lastProgressParams.position = undefined;
        this.lastProgressParams.rate = undefined;

        const playerEvent = new events.MediaOpenedEvent({
            mediaType: this.psdk.getVideoType(),
            playbackSpeeds: this.psdk.getSupportedPlaybackSpeeds(),
            availableAudioLanguages: this.psdk.getAvailableAudioLanguages(),
            width: this.psdk.getVideoWidth(),
            height: this.psdk.getVideoHeight(),
            openingLatency: Date.now() - this.psdk.latencyStart,
            hasDRM: this.psdk.hasDRM(),
            hasCC: this.psdk.hasCC()
        });
        events.dispatchEvent(playerEvent);

        if (this.psdk.getAutoPlay() === true) {
            this.psdk.play();
        }
    }

    /**
     * Rumor has it some set top boxes send the status
     * on the `state` property and not the `status` property.
     */
    private getStatus(event: any): number {
        return event.hasOwnProperty("status") ? event.status : event.state;
    }

    private onStatusChanged(event: AdobePSDK.cc_PSDKEventStatusChange) {

        const status = this.getStatus(event);

        if (this.lastStatus === status) {
            this.logger.warn(`Received duplicate state changed event: status=${status}`);
            return;
        }

        if (this._ignoreSeek && status === AdobeRuntimeWrapper.STATUS_SEEKING()) {
            this.logger.warn("Ignoring PSDK seek state during rewinding to start position");
            return;
        }

        this.lastStatus = status;

        this.logger.trace("onStatusChanged: event status=" + status);

        if (status === AdobeRuntimeWrapper.STATUS_PREPARED()) {
            this.onPrepared();
        }
        const newStatus = this.psdk.mapStateToStatus(status);
        this.logger.trace("onStatusChanged: event status=" + newStatus);
        const playerEvent = new events.PlayStateChangedEvent(newStatus);

        if (status === AdobeRuntimeWrapper.STATUS_PLAYING() && !this.queuedPlayingEvent) {
            this.logger.info("Queueing up playing status change");
            this.queuedPlayingEvent = playerEvent;
            return;
        }

        events.dispatchEvent(playerEvent);

        // The PlayStateChangeEvent complete should occur before MediaEnededEvent
        if (status === AdobeRuntimeWrapper.STATUS_COMPLETE()) {
            events.dispatchEvent(new events.MediaEndedEvent());
        }
    }

    /**
     * Notes: Size event will dispatch prior to onBitrateChanged
     * Custom event properties
     * .width
     * .height
     */
    private onSize(event: any) {
        this.logger.trace("onSize: " + event.width + "x" + event.height);
        this.sizeEvent.width = event.width;
        this.sizeEvent.height = event.height;
    }

    /**
     * Custom event properties
     * .time
     * .bitRate
     */
    private onBitrateChanged(event: any) {
        if (!event.bitRate) {
            return;
        }

        this.logger.trace("onBitrateChanged Time:" + event.time + " BitRate:" + event.bitRate);

        const playerEvent = new events.BitrateChangedEvent(event.bitRate, // bitrate
            "BitrateChanged", // change reason
            this.sizeEvent.width, //width
            this.sizeEvent.height); // height
        events.dispatchEvent(playerEvent);
    }

    private onProfileChanged(event: any) {
        const bitrates = this.psdk.getAvailableBitrates();
        const bitrate = bitrates[event.profile] || this.psdk.getCurrentBitrate();

        this.logger.trace(`onProfileChanged Time:${event.time} Profile:${event.profile} Bitrate:${bitrate}`);

        const playerEvent = new events.BitrateChangedEvent(bitrate, // bitrate
            event.description, // change reason
            event.width, //width
            event.height); // height
        events.dispatchEvent(playerEvent);
    }

    /**
     * Custom event properties
     * .rate
     */
    private onRatePlaying(event: AdobePSDK.cc_PSDKEventRatePlaying) {
        this.logger.trace("onRatePlaying: " + event.rate);
        if (event.rate === 1 && this.queuedPlayingEvent) {
            events.dispatchEvent(this.queuedPlayingEvent);
            this.queuedPlayingEvent = undefined;
        }
        if (this.lastRate !== event.rate) {
            this.lastRate = event.rate;
            const playerEvent = new events.PlaybackSpeedChangedEvent(event.rate); //playbackSpeed
            events.dispatchEvent(playerEvent);
        }
    }

    private onLoadInfoAvailable(event: AdobePSDK.cc_PSDKEventLoadInformationAvailable) {
        // TODO(estobb200): This error occurs if you try to log the event..
        // DATA_NOT_AVAILABLE. Have you defined PORTING_KIT_DIR and PORTING_KIT_DATA_DIR?
        const loadInfo: AdobePSDK.LoadInformation = event.loadInformation;

        const playerEvent = new events.FragmentInfoEvent(
            loadInfo.downloadDuration, //downloadDuration
            loadInfo.size,	//fragmentSize
            loadInfo.url, 	//fragmentUrl
            loadInfo.mediaDuration //mediaDuration
        );
        events.dispatchEvent(playerEvent);
    }

    private onAudioUpdated(event: any) {
        if (!event.item.audioTracks) {
            return;
        }
        events.dispatchEvent(new events.NumberOfAlternativeAudioStreamsChangedEvent(event.item.audioTracks.length));
    }

    private onCaptionsUpdated(event: any) {
        if (!event.item.closedCaptionsTracks) {
            return;
        }
        events.dispatchEvent(new events.NumberOfClosedCaptionsStreamsChanged(event.item.closedCaptionsTracks.length));
    }

    /**
     * Event containing the address of the Decoder or DecoderStruct
     * to be passed to the AVEWebVideoItem for use with CC.
     * .data
     */
    private onDecoderAvailable(event: any) {
        this.logger.trace("onDecoderAvailable");

        this.sandbox.publish(constants.UPDATE_PLAYER_CONFIG);

        try {
            //set trickplayMaxFPS from config only if it isn't already set from the EXT-NOM-I-FRAME-DISTANCE tag
            if (!this.psdk.playerTrickPlayFPSMaxAlreadySet) {
                this.psdk.setTrickplayMaxFps(ConfigurationManager.getInstance().get(ConfigurationManager.TRICKPLAY_MAX_FPS));
            }
            if (this.psdk.getVideoType() === "live") {
                this.logger.info("play: setting live BufferControlParameters: initial=" +
                    this.psdk.initialBufferTime + " msec; playing=" + this.psdk.playingLinearBufferTime + " msec");
                this.psdk.setBufferControlParameters(this.psdk.initialBufferTime, this.psdk.playingLinearBufferTime);
            } else {
                this.logger.info("play: setting VOD BufferControlParameters: initial=" +
                    this.psdk.initialBufferTime + " msec; playing=" + this.psdk.playingVODBufferTime + " msec");
                this.psdk.setBufferControlParameters(this.psdk.initialBufferTime, this.psdk.playingVODBufferTime);
            }
        } catch (error) {
            this.logger.warn("Warning!! Error occurred while setting PSDK buffer control parameters: " + JSON.stringify(error));
        }

        this.sandbox.publish("xre:decoderAvailable", event.data);
    }

    /**
     * onTimedMetadata
     * time: msec
     * duration: sec
     */
    private onTimedMetadata(event: any) {

        this.sandbox.publish("player:timedMetadata", event.timedMetadata);

        try {
            const metadata = event.timedMetadata;
            const hlsTag: HlsTag = create(metadata.name, metadata.time, metadata.content);
            this.sandbox.publish("player:tag", hlsTag);
        } catch (error) {
            this.logger.error("Error!! Error occurred while responding to timed metadata event: ");
        }
    }

    /**
     * Called when the underlying timeline is updated.
     * Situations where the timeline gets updated
     * * Inserted content
     * * Removed content
     * * Rolling window
     * * Growing window
     */
    private onTimelineUpdated(_event: AdobePSDK.cc_AdobeEvent) {
        this.logger.trace("onTimelineUpdated");

        if (this.sandbox.adManager) {
            this.logger.trace("Updating ads for ad manager");
            this.sandbox.adManager.ads = [];
            this.sandbox.adManager.adBreaks = [];
            for (const timelineItem of this.psdk.player.timeline.timelineItems) {
                let totalDuration: number = 0;
                for (const ad of timelineItem.adBreak.ads) {
                    this.sandbox.publish("ads:ad", ad.id, timelineItem.time + totalDuration, ad.duration, {});
                    totalDuration += ad.duration;
                }
            }
            this.sandbox.adManager.adBreaks = AdManager.sortIntoAdBreaks(this.sandbox.adManager.ads);
        }
    }

    /**
     *
     */
    public onPlayStart() {
        this.logger.trace("onPlayStart");
        // Note: a separate state changed event is posted to mark entry to play state. No need to duplicate that here.
    }

    /**
     *
     */
    private onBufferStart() {
        this.logger.trace("onBufferStart");
        const playerEvent = new events.BufferStartEvent();
        events.dispatchEvent(playerEvent);
    }

    /**
     *
     */
    private onBufferComplete(): void {
        this.logger.trace("onBufferComplete");
        const playerEvent = new events.BufferCompleteEvent();
        events.dispatchEvent(playerEvent);
    }

    public setUpSeekSubscribers() {

        //SeekStart Event
        fromEvent(this.psdk.player, AdobeRuntimeWrapper.SEEK_BEGIN())
            .pipe(
                filter((event: AdobePSDK.cc_PSDKEventSeek) => {
                    //ignore PSDK Seek during rewind to start position
                    if (event.actualPosition === 0 && this.lastRate < 0) {
                        this._ignoreSeek = true;
                        this.logger.warn("Ignoring PSDK seekBegin during rewinding to start position");
                        return false;
                    }
                    return true;
                }),
                map(() => {
                    return new events.SeekStartEvent(this.psdk.getCurrentPosition());
                }),
                takeUntil(this.sandbox.destroyed)
            )
            .subscribe((event: events.SeekStartEvent) => {
                this.logger.trace("onSeekStart");
                events.dispatchEvent(event);
            });

        //SeekComplete Event
        fromEvent(this.psdk.player, AdobeRuntimeWrapper.SEEK_END())
            .pipe(
                filter(() => {
                    //ignore PSDK Seek during rewind to start position
                    if (this._ignoreSeek) {
                        this._ignoreSeek = false;
                        this.logger.warn("Ignoring PSDK seekEnd during rewinding to start position");
                        return false;
                    }
                    return true;
                }),
                map(() => {
                    return new events.SeekCompleteEvent(this.psdk.getCurrentPosition());
                }),
                takeUntil(this.sandbox.destroyed)
            )
            .subscribe((event: events.SeekCompleteEvent) => {
                this.logger.trace("onSeekComplete");
                events.dispatchEvent(event);
            });
    }

    /**
     *  MediaProgress Event
     */
    private setupOnProgressSubscriber(): void {

        fromEvent(this.psdk.player, "timeChanged")
            .pipe(
                map((event: any) => {
                    const position = event.time;
                    const playbackSpeed = this.seekToStartDuringRewind(position, this.psdk.getCurrentPlaybackSpeed())
                        ? this.lastProgressParams.rate
                        : this.psdk.getCurrentPlaybackSpeed();
                    const startPosition = this.psdk.player.seekableRange.begin;
                    const endPosition = (position > this.psdk.player.seekableRange.end) ? position : this.psdk.player.seekableRange.end;
                    const updateInterval = this.psdk.updateInterval;

                    this.lastProgressParams.position = event.time;
                    this.lastProgressParams.rate = this.psdk.getCurrentPlaybackSpeed();
                    return new events.MediaProgressEvent(
                        position,                   // current play/pause position relative to tune time - starts at zero)
                        playbackSpeed,              // current trick speed (1.0 for normal play rate)
                        startPosition,              // time shift buffer start position (relative to tune time - starts at zero)
                        endPosition,                // time shift buffer end position (relative to tune time - starts at zero)
                        updateInterval);
                }),
                takeUntil(this.sandbox.destroyed)
            )
            .subscribe((event: events.MediaProgressEvent) => {
                events.dispatchEvent(event);
            });
    }

    /**
     * During rewind action in STB, when the timeline is near to startPosition
     * Adobe calls a seek to 0 and the playRate is changed to 1.
     * @param position
     * @param plabackSpeed
     * @returns {boolean}
     */
    private seekToStartDuringRewind(position: number, playbackSpeed: number): boolean {
        const retStatus: boolean = (position === 0 && playbackSpeed === 1 && this.lastProgressParams.rate
                                    && this.lastProgressParams.rate < 0);
        if (retStatus) {
            this.logger.warn("Seek to start position during rewind. Using last progress playback rate.");
        }
        return retStatus;
    }

    private getOperationFailedMetadata(event: AdobePSDK.cc_PSDKEventOperationFailed): any {
        return Object.prototype.hasOwnProperty.call(event, "metadata") ? event["metadata"] : event.notification.metadata;
    }

    /**
     * Determines a native sub error code from an OperationFailedEvent
     */
    private getNativeSubErrorCode(event: AdobePSDK.cc_PSDKEventOperationFailed): number {
        let subErrorCode: number;
        const metadata: any = this.getOperationFailedMetadata(event);
        if (metadata && !isNaN(parseInt(metadata.NATIVE_SUBERROR_CODE, 10))) {
            subErrorCode = parseInt(metadata.NATIVE_SUBERROR_CODE, 10);
        }
        return subErrorCode;
    }

    /**
     * Determines the native error code from an OperationFailedEvent.
     */
    private getNativeErrorCode(event: AdobePSDK.cc_PSDKEventOperationFailed): number {
        const metadata: any = this.getOperationFailedMetadata(event);
        let nativeErrorCode: number = event["code"] || 6;
        if (metadata && (metadata.NATIVE_ERROR_CODE || metadata.PSDK_ERROR_CODE)) {
            nativeErrorCode = parseInt((metadata.NATIVE_ERROR_CODE || metadata.PSDK_ERROR_CODE), 10);
        }
        return nativeErrorCode;
    }

    /**
     * According to old comments in the code base, something version 2.0
     * introduced a change where the event we expected was stored in
     * a notification property. This method finds the event we need. It
     * is most likely not needed anymore.
     */
    private mapToEvent(event: AdobePSDK.cc_PSDKEventOperationFailed): AdobePSDK.cc_PSDKEventOperationFailed | AdobePSDK.Notification {
        if (Object.prototype.hasOwnProperty.call(event, "notification")) {
            return event.notification;
        }
        return event;
    }

    /**
     * Determines if we can ignore the operation failed event.
     * The PSDK player currently send a magnitude of kECHold warnings
     * during live playback. These errors can be ignored.
     */
    private shouldIgnore(event: AdobePSDK.cc_PSDKEventOperationFailed): boolean {

        const nativeErrorCode: number = this.getNativeErrorCode(event);
        const xreError: XREError = getByErrorCode("" + nativeErrorCode);

        return nativeErrorCode === XREErrorCode.kECHold ||
            (this.isAVERetryingEvent(event) && xreError.code === MEDIA_WARNING_TRICKMODE_DISALLOWED);

    }

    /**
     * note - some media failure events arrive with WARNING in the description
     * This indicates that AVE is continuing to retry the operation that failed.
     * If and when AVE gives up, it will issue the same event with ERROR in the
     * description, at which point it will fall through the test below and pass
     * the event along to the PlayerPlatformAPI.
     */
    private isAVERetryingEvent(event: AdobePSDK.cc_PSDKEventOperationFailed): boolean {
        const nativeErrorCode: number = this.getNativeErrorCode(event);
        const xreError: XREError = getByErrorCode("" + nativeErrorCode);
        const metadata = this.getOperationFailedMetadata(event);
        return xreError.warning || (metadata && metadata.hasOwnProperty("WARNING"));
    }

    /**
     * Adobe sends network down events as a warning. This method
     * determines if a provided operation failed event is a network down
     * event.
     */
    private isNetworkDownEvent(event: AdobePSDK.cc_PSDKEventOperationFailed): boolean {
        const metadata = this.getOperationFailedMetadata(event);
        return metadata && metadata.WARNING && metadata.NATIVE_ERROR === "NETWORK_DOWN";
    }

    /**
     * DRMError is a placeholder for DRM errors while Adobe finishes
     * DRMOperationError
     */
    private onDrmError(event: any) {
        this.logger.error("onDrmError major: " + event.majorError + " minor: " + event.minorError);
        const descCode = "DRM Major Error:" + event.majorError + " Minor Error:" + event.minorError;
        const error = new PPError(event.majorError, event.minorError, descCode);
        // 3329.12017 signals that it's a blackout license failure and should
        // not retry, 12000 being the CAM code and 17 for CAM's blackout subcode.
        events.dispatchEvent(new events.MediaFailedEvent(error, error.shouldRetry()));
    }

    // event prop drmMetadataInfo
    private onDRMMetadata(event: any) {
        this.logger.trace("onDRMMetadata");
        events.dispatchEvent(new events.DRMMetadataEvent(event.drmMetadataInfo));
    }

    /**
     * Event notification to notify livepoint is reached
     * @param None
     */
    private onEnteringLive() {
        this.logger.trace("onEnteringLive");
        this.sandbox.publish("xre:onenteringlive");
    }

    // create an ad from a timed metadata event
    public createAd(metadata: any) {

        this.sandbox.publish("ads:ad",
            metadata.metadata.ID,
            metadata.time,
            metadata.metadata.DURATION * constants.MILLISECONDS_PER_SECOND,
            {}
        );
    }

    public createTrickModeRestriction(metadata: any) {
        this.logger.trace("createTrickModeRestriction");

        if ((metadata === undefined) || (metadata === null)) {
            metadata = {};
        }

        const id = metadata.ADID;
        const mode = metadata.MODE;
        const scale = metadata.SCALE;
        const limit = metadata.LIMIT;

        this.sandbox.publish("ads:restriction", id, mode, scale, limit);
    }
}

registerModule("PSDKPlayerEvents", PSDKPlayerEvents);
