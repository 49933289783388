import { X2JS } from "x2json";
const xml2js = new X2JS();

export function parse(xsct: string) {
    const rawToken = atob(xsct);
    const msg = xml2js.xml_str2json(rawToken)["xcal-auth-message"];

    const obj = (msg.attribute || []).reduce((prev: any, next: { _key: string, __text: string }) => {
        prev[next._key] = next.__text;
        return prev;
    }, {});

    return {
        get zipCode() {
            return obj["device:xcal:locationHomeZip"] || obj["device:xcal:locationSessionZip"];
        },
        get inHome() {
            return obj["device:xcal:locationInHome"] === "true";
        },
        get accountId() {
            return obj["account:xcal:id"];
        },
        get deviceId() {
            return obj["device:xcal:id"];
        }
    };
}
