import { keyForValue } from "./JSUtil";

const langMap: any = {
    eng: "en",
    spa: "es",
    fra: "fr",
    deu: "de",
    ita: "it",
    por: "pt"
};

export function convert3to2(lang: string): string {
    return langMap[lang] || "en";
}

export function convert2to3(lang: string): string {
    return keyForValue(langMap, lang) || "eng";
}
