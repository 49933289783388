import { IXuaAsset, PAIDAsset, GenericAsset, STRIDAsset, RIDAsset, EASAsset, IVStream } from "./Assets";
import { BaseAsset } from "../assets/BaseAsset";
import { isEAS, FOG_RE } from "../assets/ContentOptions";
import { Logger } from "../util/Logger";
import { VirtualStreamStitcherAsset } from "../assets/VirtualStreamStitcherAsset";
import { ConfigurationManager } from "../ConfigurationManager";

export class XuaAssetFactory {

    private _logger: Logger = new Logger("XuaAssetFactory");

    private _isPAIDAsset(asset: BaseAsset): boolean {
        return asset.providerId > "" && asset.assetId > "";
    }

    private _isGENERICAsset(asset: BaseAsset): boolean {
        return Boolean(asset.mediaGuid || asset.assetId);
    }

    private _isRIDAsset(asset: BaseAsset): boolean {
        return asset.recordingId > "";
    }

    private _isSTRIDAsset(asset: BaseAsset): boolean {
        return asset.streamId > "";
    }

    public create(asset: BaseAsset): IXuaAsset {

        let xuaAsset: IXuaAsset;

        if (this._isPAIDAsset(asset)) {
            xuaAsset = new PAIDAsset(asset.providerId, asset.assetId, asset.assetType, asset.mediaGuid);
        } else if (this._isGENERICAsset(asset)) {
            xuaAsset = new GenericAsset(asset.mediaGuid, asset.assetType, asset.assetId);
        } else if (this._isRIDAsset(asset)) {
            xuaAsset = new RIDAsset(asset.recordingId, asset.assetType);
        } else if (this._isSTRIDAsset(asset)) {
            xuaAsset = new STRIDAsset(asset.streamId, asset.assetType, this.buildVstream(asset));
        } else if (isEAS(asset)) {
            xuaAsset = new EASAsset(asset.easPath);
        } else {
            this._logger.warn("Unable to build IXuaAsset for provided asset", asset.url, asset.providerId, asset.assetId, asset.mediaGuid, asset.recordingId, asset.streamId, asset.assetType);
        }

        if (xuaAsset && FOG_RE.test(asset.url)) {
            xuaAsset.PLAYBACK_TYPE = "FOG";
        }

        if (xuaAsset && ConfigurationManager.getInstance().getByAssetType(asset.assetType, ConfigurationManager.FORCE_HTTPS, false)) {
            xuaAsset.SECURITY = "TRUE";
        }

        return xuaAsset;

    }

    private buildVstream(asset: BaseAsset): IVStream {
        if (asset instanceof VirtualStreamStitcherAsset) {
            return {
                SRCID: asset.sourceStreamId,
                SIGID: asset.signalId,
                SRVZONE: asset.serviceZone
            };
        }
    }
}
